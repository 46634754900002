import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import * as Yup from "yup";
import { bool } from "prop-types";

import { actions as transactionsActions } from "reducers/transactions";

import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import AmountField from "pages/_components/fields/formik/AmountField";

import * as i18n from "util/i18n";

const FORM_ID = "transactions.list.filters.amount";

class AmountFilter extends Component {
    static propTypes = {
        fetching: bool,
    };

    static defaultProps = {
        fetching: false,
    };

    render() {
        const { fetching } = this.props;

        return (

            <Container className="container--layout items-center" gridClassName="form-content">
                <Col sm={12} md={6} className="col-12 col-no-pad-mobile">
                    <Field component={AmountField} hideCurrency idForm={FORM_ID} name="minAmount" />
                </Col>
                <Col sm={12} md={6} className="col-12 col-no-pad-mobile">
                    <Field component={AmountField} hideCurrency idForm={FORM_ID} name="maxAmount" />
                </Col>
            </Container>

        );
    }
}

export default AmountFilter;
