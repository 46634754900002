import * as API from "middleware/api";

export const readBankReference = (typeAccount, documentNumber, numberAccount) =>
    API.executeWithAccessToken("/bankReference.thirdParties.pre", {
        typeAccount,
        _documentNumber: documentNumber,
        _numberAccount: numberAccount,
    });

export const downloadBankReference = (bankReference, documentNumberClient) =>
    API.executeWithAccessToken("/bankReference.thirdParties.download", {
        _bankReference: bankReference,
        _documentNumberClient: documentNumberClient,
    });
