import React, { Component } from "react";
import { string, shape, bool } from "prop-types";

import * as i18nUtils from "util/i18n";
import Password from "pages/_components/fields/credentials/Password";

const styles = {
    hidden: {
        opacity: 0,
        position: "absolute",
        zIndex: "-9999",
    },
};

class Credential extends Component {
    static propTypes = {
        type: string,
        field: shape({}),
        copyEnabled: bool,
        inputmode: string,
        relativeStyleError: bool,
        warningIcon: bool,
        inputRef: shape(),
    };

    static defaultProps = {
        type: "password",
        field: {},
        copyEnabled: true,
        inputmode: "numeric",
        relativeStyleError: false,
        warningIcon: "tooltip.svg",
        inputRef: React.createRef(),
    };

    render() {
        const { type, inputmode = "numeric", relativeStyleError, warningIcon, ...props } = this.props;

        if (type === "password") {
            return <Password pattern="\S+" autoComplete="new-password" copyEnabled {...props} />;
        }

        if (type === "otp") {
            const {
                field: { name },
            } = this.props;

            return (
                <React.Fragment>
                    {/*
                        The objetive of this input is to avoid the differents
                        behaviors of autocomplete="off" of browsers.
                    */}
                    <input name={name} style={styles.hidden} type="password" aria-hidden tabIndex="-1" />
                    <Password
                        autoComplete="off"
                        pattern="[0-9]{0,6}"
                        copyEnabled
                        relativeStyleError={relativeStyleError}
                        {...{ ...props, inputmode }}
                        warningMessage={i18nUtils.get("form.credential.otp.warning")}
                        warningIcon={warningIcon}
                    />
                </React.Fragment>
            );
        }

        return null;
    }
}

export default Credential;
