import React from "react";
import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import FormattedDate from "pages/_components/FormattedDate";
import Row from "pages/_components/Row";
import { Col } from "react-bootstrap";
import classNames from "classnames";
import { arrayOf, shape, string } from "prop-types";

const RemittanceCheckItem = ({ check, onRow, statusOptionsSelect }) => {
    const getStatusLabelByValue = (value) => {
        const statusOption = statusOptionsSelect.find((option) => option.value === value);
        return statusOption ? statusOption.label : null;
    };

    return (
        <>
            <Box
                className="row-checks"
                pointer
                onClick={() => {
                    onRow(check);
                }}>
                <Row>
                    <Col xs="6">
                        <Box className="col-no-order">
                            <Box className="mb-2 color-text-grey">
                                <I18n id="remittance.checks.table.item.number" defaultValue="Cheque" />
                            </Box>
                            <Box>{check.checkNumber}</Box>
                        </Box>
                    </Col>
                    <Col xs="6">
                        <Box display="flex" alignX="end" className="text-right mb-2 color-text-grey">
                            <div
                                className={classNames(
                                    "fit-content-width",
                                    { "check-status check-status-text": check.status === "C" },
                                    { "check-status check-status-err-text": check.status === "T" },
                                    { "check-status check-status-war-text": check.status === "E" },
                                )}>
                                <span className="data-desc">{getStatusLabelByValue(check.status)}</span>
                            </div>
                        </Box>
                    </Col>

                    <Col xs="6">
                        <Box className="mb-2 color-text-grey">
                            <I18n id="remittance.checks.table.item.account" defaultValue="Cuenta girada" />
                        </Box>
                        <Box display="flex">{check.accountNumber}</Box>
                    </Col>
                    <Col xs="6">
                        <Box className="text-right mb-2 color-text-grey">
                            <I18n id="remittance.checks.table.item.entryDate" defaultValue="Fecha Ingreso" />
                        </Box>
                        <Box display="flex" alignX="flex-end">
                            {/* <FormattedDate date={check.entryDate} anotherFormat="DD/MM/YYYY" /> */}
                            {check.entryDate}
                        </Box>
                    </Col>
                    <Col xs="6">
                        <Box className="mb-2 color-text-grey">
                            <I18n
                                id="remittance.checks.table.item.confirmationDate"
                                defaultValue="Fecha Confirmación"
                            />
                        </Box>
                        <Box display="flex">
                            {/* <FormattedDate date={check.confirmationDate} anotherFormat="DD/MM/YYYY" /> */}
                            {check.confirmationDate}
                        </Box>
                    </Col>
                    <Col xs="6">
                        <Box className="text-right mb-2 color-text-grey">
                            <I18n id="remittance.checks.table.item.amount" defaultValue="Monto USD" />
                        </Box>
                        <Box display="flex" alignX="flex-end">
                            <FormattedAmount quantity={check.value} noCurrency className="text-bold" />
                        </Box>
                    </Col>
                </Row>
            </Box>
        </>
    );
};

RemittanceCheckItem.propTypes = {
    check: shape({}).isRequired,
    onRow: arrayOf(string).isRequired,
    blueStatus: arrayOf(string).isRequired,
    redStatus: arrayOf(string).isRequired,
    grayStatus: arrayOf(string).isRequired,
};

export default RemittanceCheckItem;
