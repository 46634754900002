import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import SearchField from "pages/_components/fields/SearchField";
import DefaultModal from "pages/_components/modal/DefaultModal";
import MessageItem from "pages/communications/_components/MessageItem";
import NoResults from "pages/communications/_components/NoResults";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { actions as communicationAction } from "reducers/communication";
import { actions as communicationsActions, selectors as communicationsSelectors } from "reducers/communications";
import * as i18n from "util/i18n";

const trays = {
    all: "all",
    unread: "unread",
    read: "read",
    sent: "sent",
};

class List extends Component {
    static propTypes = {
        isFetching: bool.isRequired,
        isDesktop: bool.isRequired,
        totalPages: number.isRequired,
        currentPage: number.isRequired,
        list: arrayOf(shape({})).isRequired,
        dispatch: func.isRequired,
        handleLoadMoreState: func,
        activeTray: string.isRequired,
        handleMessageModalShow: func,
        headerTitle: func,
    };

    static defaultProps = {
        handleLoadMoreState: () => {},
        handleMessageModalShow: null,
        headerTitle: null,
    };

    state = {
        activeTray: trays.all,
        searchSubject: "",
        filtersVisibility: false,
        deleteModalShow: false,
        idCommunication: "",
        index: "",
    };

    componentDidUpdate(prevProps) {
        const { list } = this.props;
        if (prevProps.list.length !== list.length && list.length === 0) {
            this.handleFetchMore();
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(communicationAction.setSelectedIndex(null));
    }

    handleUnreadClick = () => {
        const { dispatch } = this.props;
        this.setState({ activeTray: trays.unread });
        dispatch(communicationsActions.listRequest({ direction: "BANK_TO_CUSTOMER", onlyUnread: true }));
    };

    handleAllClick = () => {
        const { dispatch } = this.props;
        this.setState({ activeTray: trays.all });
        dispatch(communicationsActions.listRequest({ direction: "BANK_TO_CUSTOMER" }));
    };

    handleSentClick = () => {
        const { dispatch } = this.props;
        this.setState({ activeTray: trays.sent });
        dispatch(communicationsActions.listRequest({ direction: "CUSTOMER_TO_BANK" }));
    };

    handleReadClick = () => {
        const { dispatch } = this.props;
        this.setState({ activeTray: trays.read });
        dispatch(communicationsActions.listRequest({ direction: "BANK_TO_CUSTOMER", onlyUnread: false }));
    };

    handleSelectMessageClick = (idCommunication, userRead, index) => {
        const { dispatch } = this.props;

        dispatch(communicationAction.setSelectedIndex(index));
        dispatch(communicationAction.setSelectedIdCommunication(idCommunication));

        dispatch(communicationsActions.showReadPanel());
        dispatch(communicationAction.detailRequest(idCommunication, index, userRead));
        if (!userRead) {
            dispatch(communicationsActions.toggleMessageStatus(index));
        }
    };

    handleChangeMessageStatus = (e, idCommunication, userRead, index) => {
        e.stopPropagation();
        e.preventDefault();

        const { dispatch } = this.props;
        const { activeTray } = this.state;

        if (userRead) {
            dispatch(communicationAction.markAsUnReadRequest(idCommunication));
        } else {
            dispatch(communicationAction.markAsReadRequest(idCommunication));
        }

        if (activeTray !== trays.all) {
            dispatch(communicationsActions.removeFromTray(index));
        } else {
            dispatch(communicationsActions.toggleMessageStatus(index));
        }
    };

    handleRemoveClick = (e, idCommunication, index, userRead) => {
        e.stopPropagation();
        e.preventDefault();

        this.setState({ idCommunication });
        this.setState({ userRead });
        this.setState({ index });
        this.setState({ deleteModalShow: true });
    };

    handleFetchMoreClick = () => {
        const { currentPage, dispatch, isFetching, handleLoadMoreState } = this.props;
        const { activeTray } = this.state;
        let filters = {};

        if (!isFetching) {
            switch (activeTray) {
                case trays.read:
                    filters.onlyUnread = false;
                    break;
                case trays.unread:
                    filters.onlyUnread = true;
                    break;
                case trays.sent:
                    filters.direction = "CUSTOMER_TO_BANK";
                    break;
                case trays.all:
                    filters.direction = "BANK_TO_CUSTOMER";
                    break;
                default:
                    filters = {};
            }

            filters.pageNumber = currentPage + 1;

            dispatch(communicationsActions.fetchMoreRequest(filters));
        }
        handleLoadMoreState();
    };

    handleSearchChange = (e) => {
        this.setState({ searchSubject: e.target.value });
    };

    hideDefaultModal = () => {
        this.setState({ deleteModalShow: false });
    };

    handleDeleteMessage = () => {
        const { dispatch } = this.props;
        const { idCommunication, index, userRead } = this.state;
        dispatch(communicationAction.deleteRequest(idCommunication, index, userRead));
        this.setState({ deleteModalShow: false });
    };

    getItems = () => {
        const { list, handleMessageModalShow, headerTitle } = this.props;
        const { searchSubject } = this.state;
        const search = searchSubject.toLocaleLowerCase();

        return list.reduce((acc, communication, index) => {
            if (communication.subject.toLocaleLowerCase().indexOf(search) >= 0) {
                return [
                    ...acc,
                    <MessageItem
                        communication={communication}
                        index={index}
                        handleRemoveClick={this.handleRemoveClick}
                        handleChangeMessageStatus={this.handleChangeMessageStatus}
                        handleSelectMessageClick={this.handleSelectMessageClick}
                        key={communication.idCommunication}
                        handleMessageModalShow={handleMessageModalShow}
                        headerTitle={headerTitle}
                    />,
                ];
            }
            return acc;
        }, []);
    };

    handleFetchMoreClick() {
        this.handleFetchMore();
    }

    handleFetchMore() {
        const { currentPage, dispatch, isFetching, handleLoadMoreState, activeTray } = this.props;
        let filters = {};

        if (!isFetching) {
            switch (activeTray) {
                case trays.read:
                    filters.onlyUnread = false;
                    break;
                case trays.unread:
                    filters.onlyUnread = true;
                    break;
                case trays.sent:
                    filters.direction = "CUSTOMER_TO_BANK";
                    break;
                case trays.all:
                    filters.direction = "BANK_TO_CUSTOMER";
                    break;
                default:
                    filters = {};
            }

            filters.pageNumber = currentPage + 1;

            dispatch(communicationsActions.fetchMoreRequest(filters));
        }
        handleLoadMoreState();
    }

    render() {
        const { currentPage, totalPages, isDesktop, isFetching } = this.props;
        const { activeTray, searchSubject, filtersVisibility, deleteModalShow } = this.state;
        const moreMessages = totalPages > currentPage;
        const list = this.getItems();

        return (
            <>
                <DefaultModal
                    modalShow={deleteModalShow}
                    acceptFunction={this.handleDeleteMessage}
                    cancelFunction={this.hideDefaultModal}
                    headingText={i18n.get("communications.delete.modal.title")}
                    text={i18n.get("communications.delete.modal.text")}
                />
                <Box
                    component="section"
                    background="menu-background"
                    className={classNames("pb-5 full-height", { "pt-7 mx-n-5 px-5": !isDesktop })}
                    position="sticky"
                    top="0"
                    zIndex="8">
                    <Box className="mb-5">
                        <SearchField id="search" onChange={this.handleSearchChange} />
                    </Box>
                    {filtersVisibility && (
                        <Box className="mt-5">
                            <Button
                                label="communications.trays.received"
                                className={`btn btn-outline btn-hint-icon ${activeTray === trays.all ? "active" : ""}`}
                                onClick={this.handleAllClick}
                                image="images/inbox.svg"
                                block
                            />
                            <Button
                                label="communications.trays.unread"
                                className={`btn btn-outline btn-hint-icon ${
                                    activeTray === trays.unread ? "active" : ""
                                }`}
                                onClick={this.handleUnreadClick}
                                image="images/email.svg"
                                block
                            />
                            <Button
                                label="communications.trays.read"
                                className={`btn btn-outline btn-hint-icon ${activeTray === trays.read ? "active" : ""}`}
                                onClick={this.handleReadClick}
                                image="images/read.svg"
                                block
                            />
                            <Button
                                label="communications.trays.sent"
                                className={`btn btn-outline btn-hint-icon ${activeTray === trays.sent ? "active" : ""}`}
                                onClick={this.handleSentClick}
                                image="images/sent.svg"
                                block
                            />
                        </Box>
                    )}
                    {list.length > 0 ? (
                        <>
                            <Box
                                component="ul"
                                background="white"
                                borderRadius="md"
                                border="background-divider"
                                {...(isDesktop && { className: "mb-7" })}>
                                {list}
                                {moreMessages && (
                                    <Box
                                        display="flex"
                                        alignX="center"
                                        alignY="center"
                                        className="no-more-data out-of-table">
                                        <Button
                                            bsStyle="link"
                                            onClick={this.handleFetchMoreClick}
                                            image="images/arrow-down.svg"
                                            imageRight
                                            loading={isFetching}
                                            label="communications.messages.more"
                                            small
                                            block
                                            className="py-4"
                                        />
                                    </Box>
                                )}
                            </Box>
                        </>
                    ) : (
                        <>
                            {searchSubject ? (
                                <NoResults message="communications.list.search.empty" />
                            ) : (
                                <NoResults message="communications.list.empty" />
                            )}
                        </>
                    )}
                </Box>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    list: communicationsSelectors.list(state),
    currentPage: communicationsSelectors.currentPage(state),
    totalPages: communicationsSelectors.totalPages(state),
    isFetching: communicationsSelectors.isFetching(state),
});

export default connect(mapStateToProps)(List);
