import classNames from "classnames";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";

import * as i18n from "util/i18n";

import Notification from "pages/_components/Notification";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import Iframe from "../_components/Iframe/Iframe";

class OwnBankReferences extends Component {
    constructor(props) {
        super(props);
        this.onBack = this.onBack.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(formActions.preCustomForm(["onlinereferences"]));
    }

    onBack() {
        const { dispatch } = this.props;
        dispatch(goBack());
    }

    render() {
        const { isMobile, fetching, data } = this.props;

        return (
            <>
                <Notification scopeToShow="form" />
                <Head
                    onBack={this.onBack}
                    titleText={i18n.get("bankReferences.ownBankReferences.title")}
                    textBack="bankReferences.label.returnToBankReferences"
                />
                <MainContainer
                    showLoader={fetching}
                    className="main-container border-radius-lg background-white box-shadow-small">
                    <Box
                        display="flex"
                        fullWidth
                        borderRadius="default"
                        className={classNames("box-shadow-small", {
                            "p-2": !isMobile,
                            "mt-5": isMobile,
                        })}>
                        <Iframe src={data?.[0]?.iframeUrl || ""} />
                    </Box>
                </MainContainer>
            </>
        );
    }
}

OwnBankReferences.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    fetching: bool.isRequired,
    data: shape({
        iframeUrl: string.isRequired,
    }).isRequired,
};

const mapStateToProps = (state) => ({
    fetching: formSelectors.getFetching(state),
    data: formSelectors.getCustomTransactionData(state),
});

export default connect(mapStateToProps)(resizableRoute(OwnBankReferences));
