import * as API from "middleware/api";

export const swiftDocuments = (typeOperation, startDate, endDate, idAccount, beneficary, page) =>
    API.executeWithAccessToken("/swift.document.list", {
        idAccount,
        typeOperation,
        startDate,
        endDate,
        beneficary,
        page,
    });

export const downloadDocument = ({ typeOperation, swiftCode, mti, idAccount }) =>
    API.executeWithAccessToken("/swift.document.download", { typeOperation, swiftCode, mti, idAccount });

export const taxDocuments = (typeDocument, startDate, endDate) =>
    API.executeWithAccessToken("/tax.document.list", {
        typeDocument,
        startDate,
        endDate,
    });

export const taxDownloadDocument = ({ format, documentNumber }) =>
    API.executeWithAccessToken("/tax.document.download", { format, documentNumber });

export const operationalDocumentsRequest = (
    typeOperation,
    typeProduct,
    typeTransaction,
    typeDocument,
    startDate,
    endDate,
) =>
    API.executeWithAccessToken("/operational.document.list", {
        typeOperation,
        typeProduct,
        typeTransaction,
        typeDocument,
        startDate,
        endDate,
    });

export const operationalDownloadDocument = (documentId) =>
    API.executeWithAccessToken("/operational.document.download", {
        documentId,
    });
