import * as API from "middleware/api";

export const unlockUserStep1 = (userName, mail) =>
    API.executeAnonymous("/session.unlockUser.step1", {
        _userName: userName,
        _mail: mail,
    });

export const unlockUserStep2 = (secondFactor, exchangeToken) =>
    API.executeWithExchangeToken(
        "/session.unlockUser.step2",
        {
            _secondFactor: secondFactor,
        },
        exchangeToken,
    );

export const unlockUserResendOtp = (exchangeToken) =>
    API.executeWithExchangeToken("/session.recoveryPassword.resendOtp", {}, exchangeToken);
