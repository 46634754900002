import React, { useEffect, useState } from "react";
import { bool, func, string } from "prop-types";
import Button from "pages/_components/Button";
import Box from "pages/_components/Box";
import { actions as accountsActions } from "reducers/accounts";
import { connect } from "react-redux";
import * as config from "util/config";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import { actions as creditCardsActions, selectors as creditCardsSelectors } from "reducers/creditCard";

const StatementLines = ({
    idAccount,
    openingDate,
    downloadStatement,
    isDesktop,
    productType,
    openTCStatusSATServices,
    creditCard,
    creditCardDetail,
    isfetchingToken,
}) => {
    const [statementLines, setStatementLines] = useState([]);
    const [fetchingToken, setFetchingToken] = useState(false);
    const [selectedLine, setSelectedLine] = useState(null);
    const PRODUCT_TYPE_TC = "TC";

    const generateLines = () => {
        const result = [];

        const openingAccountDate = new Date(openingDate);
        openingAccountDate.setDate(1);

        const today = new Date();

        const startDate = new Date(today.getFullYear(), today.getMonth(), 1);

        const generationDay = config.getInteger("statementLines.generationDay", 5);
        // El estado de cuenta digital estará disponible desde el quinto día de cada mes (mes vencido)
        if (today.getDate() < generationDay) {
            startDate.setMonth(startDate.getMonth() - 2);
        } else {
            startDate.setMonth(startDate.getMonth() - 1);
        }

        const max = config.getInteger("bank.catalog.ESCTADG", 6);
        let count = 0;

        while (count < max && openingAccountDate <= startDate) {
            result.push({
                label: `${startDate.toLocaleString("es-ES", { month: "long" })} ${startDate.getFullYear()}`,
                month: startDate.getMonth() + 1,
                year: startDate.getFullYear(),
                downloading: false,
            });
            startDate.setMonth(startDate.getMonth() - 1);
            count += 1;
        }

        return result;
    };

    useEffect(() => {
        setStatementLines(generateLines());
    }, []);

    const onFinishDonwload = (index) => {
        const newArr = [...statementLines];

        newArr[index].downloading = false;

        setStatementLines(newArr);
    };

    const onClickDonwload = (index, month, year) => {
        const newArr = [...statementLines];

        newArr[index].downloading = true;

        setStatementLines(newArr);
        downloadStatement(idAccount, month, year, index, onFinishDonwload);
    };

    const handleOpenTCStatusSATServices = (productDataTC) => {
        if (!fetchingToken) {
            setFetchingToken(true);
            openTCStatusSATServices(() => {
                setFetchingToken(false);
            }, productDataTC);
        }
    };

    const renderEmptyResults = () => (
        <Box className="m-7">
            <Image src="images/empty.svg" />
            <Box className="text-center mt-5 mb-4">
                <Text labelKey="accounts.StatementLines.empty.title" bold size="3" />
            </Box>
            <Box className="text-center">
                <Text labelKey="accounts.StatementLines.empty.text" />
            </Box>
        </Box>
    );

    const renderLines = () => (
        <>
            {statementLines.length === 0 && <>{renderEmptyResults()}</>}
            {statementLines.length > 0 && (
                <>
                    <Box display="flex" className="py-5" fullWidth>
                        <b>{statementLines.length}</b>&nbsp;Resultados
                    </Box>
                    <Box className="children-bg-color">
                        {isDesktop && (
                            <Box display="flex" className="p-5 color-text-disabled-color" borderRadius="md" fullWidth>
                                Fecha
                            </Box>
                        )}
                        {statementLines.map((line, index) => (
                            <Box
                                key={`${line.month}-${line.year}`}
                                display="flex"
                                className="p-5"
                                borderRadius="md"
                                fullWidth>
                                <Box display="flex" fullWidth alignY="center" className="text-capitalize">
                                    {line.label}
                                </Box>

                                <Box display="flex" fullWidth alignX="end">
                                    <Button
                                        bsStyle="outline"
                                        className="outline-download text-regular"
                                        image="images/download.svg"
                                        label="accounts.statementLine.button.download"
                                        loading={line.downloading || (isfetchingToken && selectedLine === index)}
                                        onClick={() => {
                                            if (productType == PRODUCT_TYPE_TC) {
                                                const productDataTC = [];
                                                productDataTC.push({
                                                    cuenta: creditCardDetail?.account || creditCard?.account,
                                                });
                                                productDataTC.push({
                                                    marca: creditCardDetail?.franchise || creditCard?.franchise,
                                                });
                                                productDataTC.push({ anio: line.year.toString() });
                                                productDataTC.push({ mes: line.month.toString() });
                                                productDataTC.push({ costo: 0.0 });
                                                productDataTC.push({ idProduct: idAccount });
                                                setSelectedLine(index);
                                                handleOpenTCStatusSATServices(productDataTC);
                                            } else {
                                                onClickDonwload(index, line.month, line.year);
                                            }
                                        }}
                                        imageRight
                                        btnUppercase={false}
                                    />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </>
            )}
        </>
    );

    return <Box className="mt-3">{renderLines()}</Box>;
};

StatementLines.propTypes = {
    idAccount: string.isRequired,
    openingDate: string,
    downloadStatement: func.isRequired,
    isDesktop: bool,
    openTCStatusSATServices: func.isRequired,
    isfetchingToken: bool,
};

StatementLines.defaultProps = {
    openingDate: null,
    isDesktop: true,
    isfetchingToken: false,
};

const mapDispatchToProps = (dispatch) => ({
    downloadStatement: (idAccount, month, year, index, onFinishDonwload) =>
        dispatch(accountsActions.downloadStatement(idAccount, month, year, index, onFinishDonwload)),
    openTCStatusSATServices: (callback, productDataTC) =>
        dispatch(creditCardsActions.openTCStatusSATServices(callback, productDataTC)),
});

export default connect(null, mapDispatchToProps)(StatementLines);
