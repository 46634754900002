import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import Box from "pages/_components/Box";
import EmptyTextBox from "pages/_components/EmptyTextBox";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PaginatedDataTable from "pages/_components/PaginatedTableComponent";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import AccountsList from "pages/accounts/List";
import EquivalentTotalBalance from "pages/accounts/_components/EquivalentTotalBalance";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { isDesktop } from "react-device-detect";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { actions as productsActions } from "reducers/products";
import { selectors as sessionSelectors } from "reducers/session";
import * as config from "util/config";
import * as i18n from "util/i18n";
import NoProduct from "./../../pages/desktop/widgets/_components/NoProduct.jsx";

class Accounts extends Component {
    static propTypes = {
        isRequestAvailable: bool,
        accounts: arrayOf(shape({ length: number })),
        dispatch: func.isRequired,
        fetching: bool,
        equivalentTotalBalance: string,
        equivalentTotalBalanceEUR: string,
        showProductIcon: bool,
        completeFavorites: bool,
    };

    static defaultProps = {
        isRequestAvailable: false,
        fetching: false,
        accounts: [],
        equivalentTotalBalance: "",
        equivalentTotalBalanceEUR: "",
        showProductIcon: false,
        completeFavorites: false,
    };

    state = {
        showNoLimitAccountRequestModal: false,
        searchWord: "",
        rowsPerPage: config.getInteger("table.rowsPerPage", 10),
        downloading: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(accountsActions.listAccounts());
    }

    componentDidUpdate;

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.push("/desktop"));
    };

    hasOnlyOneSimplificateAccount = () => {
        const { accounts } = this.props;

        if (accounts.length !== 1) {
            return false;
        }

        let simplicateList = config.get("accounts.subcategory.simplified");
        simplicateList = simplicateList !== null ? simplicateList.split("|") : null;

        if (simplicateList === null) {
            return false;
        }

        let simplificadas = 0;
        accounts.forEach((account) => {
            if (simplicateList.includes(account.subProductType)) {
                simplificadas += 1;
            }
        });

        return simplificadas === 1;
    };

    onFinishDownload = () => {
        this.setState({
            downloading: false,
        });
    };

    handleClickDownload = (format) => {
        const { dispatch } = this.props;
        this.setState({
            downloading: true,
        });

        dispatch(accountsActions.downloadAccounts(format, this.onFinishDownload));
    };

    renderHeader = () => {
        const { isRequestAvailable } = this.props;
        const { downloading } = this.state;

        const documents = [
            {
                label: "global.txtFile",
                onClick: () => this.handleClickDownload("txt"),
            },
            {
                label: "global.pdfFile",
                onClick: () => this.handleClickDownload("pdf"),
            },
            {
                label: "global.xlsFile",
                onClick: () => this.handleClickDownload("xls"),
            },
        ];

        if (isRequestAvailable) {
            if (this.hasOnlyOneSimplificateAccount()) {
                return (
                    <Head
                        onBack={this.handleBack}
                        title="menu.accounts.title"
                        onAdd={this.showAccountRequestModal}
                        addLinkToLabel="accounts.new"
                    />
                );
            }

            return (
                <Head
                    onBack={this.handleBack}
                    title="menu.accounts.title"
                    addLinkToLabel="accounts.new"
                    externalRefValue={config.get("accessBar.requests.url")}
                    externalHrefButton
                />
            );
        }

        return (
            <Head
                {...(!isDesktop && { onBack: this.handleBack })}
                title="menu.accounts.title"
                exportList={documents}
                exportListBsStyle={isDesktop ? "download" : "only-icon"}
                isFetchingExport={downloading}
                // textBack="global.returnToHome"
            />
        );
    };

    setFavoriteAccount = (idProduct, productType, favorite) => {
        const { dispatch } = this.props;
        dispatch(productsActions.toggleFavorite(idProduct, productType, favorite, null, true));
    };

    showAccountRequestModal = () => {
        this.setState({
            showNoLimitAccountRequestModal: true,
        });
    };

    handleChange = (event) => {
        const { value } = event.target;
        this.setState({ searchWord: value });
    };

    filterAccounts = () => {
        const { accounts } = this.props;
        const { searchWord } = this.state;
        return accounts.filter(
            (account) =>
                account.number.toLowerCase().includes(searchWord.toLowerCase()) ||
                account.productAlias?.toLowerCase().includes(searchWord.toLowerCase()),
        );
    };

    render() {
        const { accounts, fetching, showProductIcon, completeFavorites } = this.props;
        const { showNoLimitAccountRequestModal, searchWord, rowsPerPage } = this.state;
        const isLoading = fetching && !accounts.length;
        const filtered = isLoading ? [] : this.filterAccounts();
        return (
            <>
                <Notification scopeToShow="accounts" />
                <TextAndButtonsModal
                    modalShow={showNoLimitAccountRequestModal}
                    acceptFunction={() => {
                        this.setState({
                            showNoLimitAccountRequestModal: false,
                        });
                    }}
                    headingText={i18n.get("productRequest.drawer.account.noLimit.title")}
                    text={i18n.get("productRequest.drawer.account.noLimit.text")}
                    loading={false}
                    modalId="productRequest.drawer.account.noLimitBalance"
                    textClassName="mx-md-0"
                />
                {!isLoading && this.renderHeader()}
                <MainContainer
                    showLoader={isLoading}
                    className={classNames("main-container border-radius-lg", {
                        "background-white p-0": !isDesktop,
                    })}>
                    <Box
                        background="white"
                        borderRadius="lg"
                        className={classNames("box-shadow-small texture-header", {
                            "p-7": isDesktop,
                            "p-5": !isDesktop,
                        })}>
                        <Col xs={12} md={8} mdOffset={2}>
                            {accounts.length ? (
                                <>
                                    {!isEmpty(accounts) && <EquivalentTotalBalance {...this.props} />}
                                    <TextSimpleField
                                        name="search"
                                        placeholder={i18n.get("accounts.search.placeholder")}
                                        value={searchWord}
                                        onChange={this.handleChange}
                                        serarchStyle
                                        maxLength={60}
                                    />
                                    <PaginatedDataTable
                                        nameList="accounts"
                                        data={filtered}
                                        hasFilterApplied={filtered.length !== accounts.length}
                                        rowsPerPage={rowsPerPage}
                                        component={AccountsList}
                                        componentProps={{
                                            showProductIcon,
                                            completeFavorites,
                                        }}
                                    />
                                </>
                            ) : (
                                <NoProduct text="desktop.widgets.accounts.empty" />
                            )}
                        </Col>
                    </Box>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    accounts: accountsSelectors.getAccounts(state),
    equivalentTotalBalance: accountsSelectors.getEquivalentTotalBalance(state),
    equivalentTotalBalanceEUR: accountsSelectors.getEquivalentTotalBalanceEUR(state),
    fetching: accountsSelectors.getFetching(state),
    completeFavorites: accountsSelectors.getCompleteFavorites(state),
    isRequestAvailable: sessionSelectors.hasPermissions(state, ["accountOpen"]),
});

export default connect(mapStateToProps)(Accounts);
