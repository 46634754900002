import { takeLatest, call, put, select } from "redux-saga/effects";

import { push, replace } from "react-router-redux";

import { selectors, types } from "reducers/recoveryPassword";
import { types as secondFactorTypes, actions as secondFactorActions } from "reducers/secondFactor";
import { actions as notificationActions } from "reducers/notification";
import { actions as sessionActions } from "reducers/session";
import { adjustIdFieldErrors } from "util/form.js";
import { isDesktop } from "react-device-detect";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";

import * as recovery from "middleware/recoveryPassword";

const sagas = [
    takeLatest(types.RECOVERY_PASS_BACK_TO_STEP_1, handleGoToStep1),
    takeLatest(types.RECOVERY_PASS_STEP1_REQUEST, handleRecoveryPassStep1),
    takeLatest(types.RECOVERY_PASS_STEP2_REQUEST, handleRecoveryPassStep2),
    takeLatest(types.RECOVERY_PASS_STEP3_REQUEST, handleRecoveryPassStep3),
    takeLatest(types.RECOVERY_PASS_STEP4_REQUEST, handleRecoveryPassStep4),
    takeLatest(types.RECOVERY_RESEND_OTP_AUTHENTICATE_CODE_REQUEST, handleRecoveryPassResendOtp),
];

export default sagas;

function* handleGoToStep1() {
    yield put(push("/recoveryPassword/step1"));
}

function* handleRecoveryPassStep1({ username, email, formikBag }) {
    try {
        const oldSATUrl = configUtils.get("recover.password.previous.SAT.url");
        const enabledRedirect = configUtils.getBoolean("previous.SAT.enabled.redirect");
        const response = yield call(recovery.recoveryPassStep1, username, email);

        if (response.type === "W") {
            formikBag.setFieldValue("captcha", undefined);
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));

            let notificationScope = "recoveryPassword";
            switch (response.data.code) {
                case "API601W":
                    yield put(push("/loginStep1"));
                    notificationScope = "externalLayout";

                    break;
                case "API605W":
                    yield put(replace("/userBlockedBank"));
                    notificationScope = undefined;
                    break;
                case "API720W":
                    yield put(replace("/errorActiveSession"));
                    notificationScope = undefined;
                    break;
                case "COR020W":
                    notificationScope = undefined;
                    if (isDesktop && enabledRedirect && oldSATUrl) {
                        yield call(() => window.open(oldSATUrl, "_blank"));
                    }
                    break;
                case "API527W":
                    yield put(push("/loginStep1"));
                    notificationScope = "externalLayout";
                    break;
                default:
                    // exchangeToken expired, restart flow (esto no pasa)
                    yield put({ type: types.CLEAN_UP });
                    yield put(push("/recoveryPassword/step1"));
                    notificationScope = "externalLayout";
            }

            if (notificationScope) {
                yield put(notificationActions.showNotification(response.data.message, "error", [notificationScope]));
            }
        } else {
            const { _exchangeToken } = response.data.data;
            yield put({
                type: types.RECOVERY_PASS_STEP1_SUCCESS,
                username,
                email,
                exchangeToken: _exchangeToken,
            });
            yield put(secondFactorActions.secondFactorStatusTokenRequest({ exchangeToken: _exchangeToken }));
            yield put(push("/recoveryPassword/step2"));
        }
    } catch (e) {
        if (e?.data?.code === "COR019E") {
            if (formikBag) {
                formikBag.setSubmitting(false);
            }
            yield put(replace("/userBlocked"));
        }
    }
    formikBag.setSubmitting(false);
}

function* handleRecoveryPassStep2({ secondFactor, exchangeToken, formikBag }) {
    // const username = yield select(selectors.getUsername);
    // const notificationType = yield select(selectors.getNotificationType);
    const response = yield call(recovery.recoveryPassStep2, secondFactor, exchangeToken);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
        let notificationScope = "externalLayout";
        switch (response.data.code) {
            case "COR020W":
                notificationScope = undefined;
                break;
            case "API707W":
                yield put({ type: types.CLEAN_UP });
                yield put(sessionActions.logoutUserBlocked());
                return;
            case "API708W":
                yield put({ type: types.CLEAN_UP });
                yield put(
                    notificationActions.showNotification(
                        i18n.get(
                            "secondFactor.credential.otp.expired",
                            "Código OTP expirado, solicite un nuevo código OTP",
                        ),
                        "warning",
                        ["externalLayout"],
                    ),
                );
                return;
            case "API525W":
            case "API526W":
                break;
            case "API527W":
                yield put(push("/loginStep1"));
                notificationScope = "externalLayout";
                break;
            default:
                // exchangeToken expired, restart flow (esto no pasa)
                yield put({ type: types.CLEAN_UP });
                yield put(push("/recoveryPassword/step1"));
                notificationScope = "externalLayout";
        }
        if (notificationScope) {
            yield put(notificationActions.showNotification(response.data.message, "error", [notificationScope]));
        }
    } else {
        const { _exchangeToken, showCaptcha, contact } = response.data.data;

        yield put({
            type: types.RECOVERY_PASS_STEP2_SUCCESS,
            exchangeToken: _exchangeToken,
            showCaptcha,
            contact,
            idTransaction: response.data.idTransaction,
        });
        yield put(push("/recoveryPassword/step3"));
    }

    formikBag.setSubmitting(false);
}

function* handleRecoveryPassStep3({ newPassword, newPasswordRepeat, exchangeToken, formikBag }) {
    const response = yield call(recovery.recoveryPassStep3, newPassword, newPasswordRepeat, exchangeToken);
    yield put(notificationActions.removeNotification());

    if (response.type === "W") {
        let notificationScope = "recoveryPassword";
        let notificationErrorMessage = response.data.message;
        switch (response.data.code) {
            case "COR048W":
                notificationErrorMessage = response.data.data._newPassword;
                formikBag.setErrors(adjustIdFieldErrors(response.data.data));
                break;
            case "COR020W":
                formikBag.setErrors(adjustIdFieldErrors(response.data.data));
                break;
            default:
                // exchangeToken expired, restart flow (no pasa esto)
                yield put({ type: types.CLEAN_UP });
                yield put(push("/recoveryPassword/step1"));
                notificationScope = "externalLayout";
        }

        if (response.data.code !== "COR020W") {
            yield put(notificationActions.showNotification(notificationErrorMessage, "error", [notificationScope]));
        }
    } else {
        yield put({ type: types.RECOVERY_PASS_STEP3_SUCCESS });
        yield put(push("/recoveryPassword/step4"));
    }

    formikBag.setSubmitting(false);
}

function* handleRecoveryPassStep4() {
    yield put(notificationActions.removeNotification());
    yield put({ type: types.RECOVERY_PASS_STEP4_SUCCESS });
    yield put(replace("/loginStep1"));
}

function* handleRecoveryPassResendOtp() {
    const exchangeToken = yield select(selectors.getExchangeToken);
    const response = yield call(recovery.recoveryPassResendOtp, exchangeToken);
    yield put(notificationActions.removeNotification());
    let notificationScope = "externalLayout";
    if (response.type === "W") {
        let notificationErrorMessage = response.data.message;
        switch (response.data.code) {
            case "COR048W":
                break;
            case "COR020W":
                break;
            case "API722W":
                notificationErrorMessage = response.data.data.message;
                break;
            default:
                // exchangeToken expired, restart flow (no pasa esto)
                yield put({ type: types.CLEAN_UP });
                yield put(push("/recoveryPassword/step1"));
                notificationScope = "externalLayout";
        }

        if (response.data.code !== "COR020W") {
            yield put(notificationActions.showNotification(notificationErrorMessage, "error", [notificationScope]));
        }
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("recoveryPassword.resendOtp.confirmation"), "success", [
                notificationScope,
            ]),
        );
    }
}
