import React, { useEffect, useState } from "react";
import { selectors as sessionSelectors } from "reducers/session";
import { connect } from "react-redux";
import { actions as documentsActions, selectors as documentsSelectors } from "reducers/documents";
import Box from "pages/_components/Box/Box";
import Button from "pages/_components/Button/Button";
import { resizableRoute } from "pages/_components/Resizable";
import { arrayOf, bool, func, number, shape } from "prop-types";
import { selectors as accountsSelectors } from "reducers/accounts";
import { PRODUCT_TYPE, TYPE_FILTER } from "constants.js";
import * as configUtil from "util/config";
import Col from "react-bootstrap/lib/Col";
import Row from "pages/_components/Row";
import DateField from "pages/_components/fields/DateField";
import Text from "pages/_components/Text/Text";
import moment from "moment";
import * as i18n from "util/i18n";
import classNames from "classnames";
import Tooltip from "pages/_components/Tooltip";
import FieldError from "pages/_components/fields/FieldError";
import Select from "pages/forms/_components/_fields/Select";
import TableDocuments from "../TableDocuments";
import Filters from "../Filters";

const TYPE_OPERATION = "N";
const InternationalTransfers = ({
    dispatch,
    isDesktop,
    isFetching,
    documents,
    totalRecords,
    pageNumber,
    accounts,
}) => {

    const filteredAccounts = accounts?.filter(account => account.permissions && account.permissions["documents.swift"] === true);

    const accountsOptions = filteredAccounts
        ?.filter(({ productType }) => productType === PRODUCT_TYPE.ACCOUNT_CC)
        .map(({ idProduct, numberMask, ownerName, productTypeBackend, currency }) => ({
            value: idProduct,
            label: `${numberMask} - ${ownerName} - ${productTypeBackend} - ${currency}`,
        }));
        
    const rowsPerPage = configUtil.getInteger("documents.rowsPerPage") || 10;
    const maxRangeDays = configUtil.getInteger("documents.range.maxdays");
    const minDateRange = configUtil.getInteger("documents.range.months.min");
    const [selectedAccount, setSelectedAccount] = useState("");
    const [selectedTypeFilter, setSelectedTypeFilter] = useState(TYPE_FILTER.DATE);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [errorPeriod, setErrorPeriod] = useState(false);
    const [errorRange, setErrorRange] = useState(false);
    const [errorAccount, setErrorAccount] = useState(false);
    const [afterSearch, setAfterSearch] = useState(false);
    const [inputNumberOfcase, setInputNumberOfcase] = useState("");
    const [filters, setFilters] = useState({});
    const [showTooltip, setShowTooltip] = useState("");

    useEffect(() => {
        dispatch(documentsActions.clean());
        setSelectedTypeFilter(TYPE_FILTER.DATE);
        // dispatch(accountsActions.listAccounts());
        // dispatch(checksActions.loadInitChecks());
    }, []);

    const isValidForm = () => {
        if (selectedTypeFilter === TYPE_FILTER.DATE) {
            return !!startDate && !!endDate && endDate.isSameOrAfter(startDate) && !errorRange;
        }
        return false;
    };

    const handleClickFilter = () => {
        setShowTooltip("");
        let validForm;

        if (selectedTypeFilter === TYPE_FILTER.DATE) {
            validForm = !errorPeriod && !errorRange;
        } else {
            validForm = false;
        }
        if (!selectedAccount) {
            validForm = false;
            setErrorAccount(true);
        }
        if (validForm) {
            setAfterSearch(true);
            setErrorAccount(false);
            const filters = {
                typeOperation: TYPE_OPERATION,
                startDate,
                endDate,
                idAccount: selectedAccount,
                beneficary: inputNumberOfcase,
                // nextIndex: 0,
            };
            setFilters(filters);

            dispatch(documentsActions.listDocumentsRequest(filters, 0));
        }
    };

    const handleStartDate = (date) => {
        if (endDate) {
            if (date.isAfter(endDate)) {
                setErrorPeriod(true);
            } else {
                setErrorPeriod(false);
            }
            if (endDate.diff(date, "days") > maxRangeDays) {
                setErrorRange(true);
            } else {
                setErrorRange(false);
            }
        }
        setStartDate(date);
    };

    const handleEndDate = (date) => {
        if (startDate) {
            if (startDate.isAfter(date)) {
                setErrorPeriod(true);
            } else {
                setErrorPeriod(false);
            }
            if (date.diff(startDate, "days") > maxRangeDays) {
                setErrorRange(true);
            } else {
                setErrorRange(false);
            }
        }
        setEndDate(date);
    };


    const handleBlur = () => {
        if (inputNumberOfcase.length < 20 && inputNumberOfcase.length !== 0) {
            const paddedValue = inputNumberOfcase.padStart(20, "0");
            setInputNumberOfcase(paddedValue);
        }
    };

    const renderFilterDate = () => (
        <>
            <Col xs={12} md={3}>
                <Text
                    component="label"
                    htmlFor="react-select-accountBy-input"
                    labelKey="checkbooks.filter.dateFrom.label"
                    className="data-label"
                    size="5"
                    bold
                />
                <Tooltip
                    buttonId="tooltipDate"
                    image="images/information.svg"
                    text="documents.swift.info.date.tooltip"
                    position="top-left"
                    className="swift-widget-tooltip"
                    showTooltip={showTooltip === "tooltipDate"}
                    handleClick={() => {
                        setShowTooltip("tooltipDate");
                    }}
                    onHover={isDesktop}
                />
                <DateField
                    idForm="checks.filters"
                    name="startDate"
                    field={{ name: "startDate" }}
                    placeholderText="dd/mm/aaaa"
                    hideLabel
                    labelNoMarginTop
                    handleChange={handleStartDate}
                    minDate={moment()
                        .add(-minDateRange, "months")
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })}
                    maxDate={endDate || moment()}
                    popperPlacement="bottom"
                    valueSelectedDate={startDate}
                    popperModifiers={{
                        flip: {
                            behavior: ["bottom"],
                        },
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    className={errorRange ? "error-date-field" : "custom-date-field"}
                    onFocus={(e) => e.target.blur()}
                />
            </Col>
            <Col xs={12} md={3}>
                <Text
                    component="label"
                    htmlFor="react-select-accountBy-input"
                    labelKey="checkbooks.filter.dateTo.label"
                    className="data-label"
                    size="5"
                    bold
                />
                <DateField
                    idForm="checks.filters"
                    name="endDate"
                    field={{ name: "endDate" }}
                    placeholderText="dd/mm/aaaa"
                    hideLabel
                    labelNoMarginTop
                    handleChange={handleEndDate}
                    minDate={startDate || moment().add(-minDateRange, "months")}
                    maxDate={moment()}
                    popperPlacement="bottom"
                    valueSelectedDate={endDate}
                    popperModifiers={{
                        flip: {
                            behavior: ["bottom"],
                        },
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    className={errorRange ? "error-date-field" : "custom-date-field"}
                    onFocus={(e) => e.target.blur()}
                />
            </Col>

            {!isDesktop && <>{renderErrors()}</>}
        </>
    );

    const renderFilterDesktop = () => (
        <Col xs={12} md={9}>
            <Box>
                <Row alignY="flex-start" gapX={7} gapY={3}>
                    {selectedTypeFilter === TYPE_FILTER.DATE && renderFilterDate()}
                    <Col xs={12} md={6} className="align-items-baseline">
                        <Box className="form-group form-group--select">
                            <Box display="flex" alignY="center" className="data-label-special-mb mt-0">
                                <Text
                                    component="label"
                                    htmlFor="react-select-accountBy-input"
                                    labelKey="documents.swift.list.filter.accountBy.label"
                                    className="mt-0"
                                    size="5"
                                    bold
                                />
                            </Box>
                            <Box className="input-group">
                                <Select
                                    id="accountBy"
                                    name="accountBy"
                                    placeholder={
                                        isDesktop
                                            ? i18n.get("documents.swift.international.web.account.placeholder")
                                            : i18n.get("documents.swift.international.account.placeholder")
                                    }
                                    onChange={(option) => {
                                        setSelectedAccount(option.value);
                                        setErrorAccount(false);
                                    }}
                                    options={accountsOptions}
                                    value={selectedAccount}
                                />
                            </Box>
                        </Box>
                        {renderErrorAccount()}
                    </Col>
                </Row>
            </Box>

            <Row alignY="flex-end" gapX={7} gapY={3}>
                {isDesktop && <>{renderErrors()}</>}
            </Row>
            <Box className="mt-5">
                <Row alignY="flex-end" gapX={7} gapY={3}>
                    {/* {selectedTypeFilter === TYPE_FILTER.DATE && renderFilterDate()} */}
                    <Col xs={12} md={6}>
                        <Box className="form-group">
                            <Box className="data-label-special-mb">
                                <Text semibold labelKey="documents.swift.list.filter.accountNumber.label" />
                            </Box>
                            <div onBlur={handleBlur}>
                                <Box className="input-group">
                                    <input
                                        id="numberValue1"
                                        className="form-control"
                                        name="numberValue1"
                                        value={inputNumberOfcase}
                                        onChange={(e) => {
                                            if (e.target.value === "" || /^[a-zA-Z0-9]*$/.test(e.target.value)) {
                                                setInputNumberOfcase(e.target.value);
                                            }
                                        }}
                                        placeholder={
                                            isDesktop
                                                ? ""
                                                : i18n.get("documents.swift.international.beneficiary.placeholder")
                                        }
                                        maxLength={20}
                                        pattern="^[a-zA-Z0-9]*$"
                                    />
                                </Box>
                            </div>
                        </Box>
                    </Col>
                    <Col xs={12} md={3}>
                        <Button
                            label="global.search"
                            bsStyle="primary"
                            className={classNames("mt-auto full-width", { "px-9": isDesktop, "px-8": !isDesktop })}
                            disabled={!isValidForm()}
                            loading={isFetching}
                            onClick={handleClickFilter}
                        />
                    </Col>
                </Row>
            </Box>
        </Col>
    );

    const getResultCounter = () => {
        let rows = 0;
        if (pageNumber === 1) {
            rows = documents[pageNumber]?.length || 0;
        }
        if (pageNumber > 1) {
            rows = (pageNumber - 1) * rowsPerPage + documents[pageNumber]?.length;
        }
        
        return <Text labelKey="global.pagination.results" color="text" size="5" rows={rows} totalRows={totalRecords} />;
    };

    const renderErrorAccount = () => (
        <>
            {errorAccount && (
                <Box className="pt-2 pb-5 size-6 form-errors-color">
                    <FieldError error={i18n.get("documents.swift.account.validation")} />
                </Box>
            )}
        </>
    );

    const renderErrors = () => (
        <>
            {errorRange && (
                <Box className="pt-2 pb-5 size-6 form-errors-color">
                    <FieldError error={i18n.get("documents.swift.filter.period.msg.error.range")} />
                </Box>
            )}
        </>
    );

    return (
        <>
            <Box background="white" className={classNames("mt-5 pt-2 pb-7")}>
                <Filters
                    isDesktop={isDesktop}
                    resultCounter={getResultCounter}
                    renderFilters={renderFilterDesktop}
                    renderErrors={renderErrors}
                    typeOperation={TYPE_OPERATION}
                />
                <Box
                    component="article"
                    background="white"
                    borderRadius="default"
                    className={classNames(" mb-5", {
                        "pt-5 ": isDesktop,
                        "px-5 pb-5": !isDesktop,
                    })}
                    toBorderInMobile>
                    <TableDocuments
                        typeOperation={TYPE_OPERATION}
                        afterSearch={afterSearch}
                        filters={filters}
                        rowsPerPage={rowsPerPage}
                        showTooltip={showTooltip}
                        setShowTooltip={setShowTooltip}
                    />
                </Box>
            </Box>
        </>
    );
};

InternationalTransfers.propTypes = {
    dispatch: func.isRequired,
    match: shape({}).isRequired,
    isDesktop: bool.isRequired,
    isFetching: bool.isRequired,
    checks: shape({}).isRequired,
    accounts: arrayOf(shape({})).isRequired,
    offset: number.isRequired,
    totalCount: number.isRequired,
    pageNumber: number.isRequired,
    fetchingAccounts: bool.isRequired,
    deposit: shape({}),
    movements: shape({}).isRequired,
};

const mapStateToProps = (state) => ({
    isFetching: documentsSelectors.getFetching(state),
    documents: documentsSelectors.getDocuments(state),
    offset: documentsSelectors.getOffset(state),
    totalPages: documentsSelectors.getTotalPages(state),
    totalRecords: documentsSelectors.getTotalRecords(state),
    pageNumber: documentsSelectors.getPageNumber(state),

    accounts: accountsSelectors.getAccounts(state),
    fetchingAccounts: accountsSelectors.getFetching(state),

    loggedUser: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps)(resizableRoute(InternationalTransfers));
