import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import { bool, func, shape } from "prop-types";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { actions as payServiceActions, selectors as payServiceSelectors } from "reducers/payService";
import { compose } from "redux";
import * as i18n from "util/i18n";
import classNames from "classnames";
import Button from "pages/_components/Button";
import { goBack, replace } from "react-router-redux";
import TicketData from "pages/_components/TicketData";
import { Col } from "react-bootstrap";
import Row from "pages/_components/Row";
import { PREFIX } from "./hooks/servicePaymentFormHooks";

export const FORM_ID = "servicePayments.delete";

const DeleteService = ({ isDesktop, service, dispatch }) => {
    const handleBack = () => {
        dispatch(goBack());
    };

    useEffect(() => {
        if (service === null) {
            dispatch(replace("/servicePayments"));
        }
    }, []);

    const initialValues = {
        id: service?.id,
    };

    const handleSubmitForm = () => {
        dispatch(payServiceActions.deleteRequest(service));
    };

    const actionsButtons = ({ isSubmitting }) => (
        <Row
            gapX="7"
            className={classNames("mt-7 mb-7", {
                "d-flex flex-column-reverse": !isDesktop,
            })}>
            <Col xs={3} xsOffset={3}>
                <Button bsStyle="outline" label="global.cancel" block type="button" onClick={handleBack} />
            </Col>
            <Col xs={3}>
                <Button bsStyle="primary" label="global.confirm" loading={isSubmitting} block type="submit" />
            </Col>
        </Row>
    );

    const renderDetail = () => {
        if (!service) {
            return null;
        }
        const { category, companyLabel, agreementLabel, identificationLabel, identificationValue, alias } = service;
        return (
            <Row className="mb-6">
                <Col xs={12} md={8} mdOffset={2}>
                    <Box className="mb-6">
                        <Text semibold size={4} labelKey="forms.transaction.confirmation.title" />
                    </Box>

                    <TicketData.Main
                        label="serviceRegistration.serviceType.label"
                        value={i18n.get(`servicePayment.category.${category}`)}
                        // currency={USD_CURRENCY}
                        // type="amount"
                    />
                    <Box>
                        <Box className="mt-3 mb-4">
                            <Text size="6" bold labelKey="paymentService.pay.send.service.title" />
                        </Box>
                        <TicketData.Data label={`${PREFIX}.service`} value={companyLabel} />
                        <TicketData.Data label={`${PREFIX}.agreement`} value={agreementLabel} />
                        <TicketData.Data label="servicePayments.pay.identificationType" value={identificationLabel} />
                        <TicketData.Data label={identificationLabel} value={identificationValue} />
                        <TicketData.Data label={`${PREFIX}.alias`} value={alias} />
                    </Box>
                </Col>
            </Row>
        );
    };

    return (
        <>
            <Notification scopeToShow="servicePayments" />
            <Head title={`${FORM_ID}.title`} textBack="servicePayments.return.enrolled" onBack={handleBack} />
            <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmitForm}>
                {(formik) => (
                    <Form>
                        <MainContainer
                            showLoader={service === null}
                            className={classNames("main-container service-payments", {
                                "mb-login-layout border-radius-lg  background-white p-5 full-height": !isDesktop,
                            })}>
                            <Box
                                background="white"
                                borderRadius="lg"
                                className={classNames({
                                    "p-7 box-shadow-small": isDesktop,
                                    // "full-height": !isDesktop,
                                })}>
                                {renderDetail()}
                            </Box>
                            {actionsButtons(formik)}
                        </MainContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

DeleteService.propTypes = {
    service: shape({}).isRequired,
    isDesktop: bool.isRequired,
    dispatch: func.isRequired,
};

const mapStateToProps = (state) => ({
    service: payServiceSelectors.getServiceAction(state),
});

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(DeleteService));
