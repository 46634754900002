import * as API from "middleware/api";

export const getUserData = (validateWally) =>
    API.executeWithAccessToken("/preferences.userData.modify.pre", {
        validateWally: validateWally || false,
    });

export const sendMailCode = (mail, otp) =>
    API.executeWithAccessToken("/preferences.userData.mail.sendCode", {
        mail,
        _otp: otp,
    });

export const updateMail = (mailCode, captcha) =>
    API.executeWithAccessToken("/preferences.userData.mail.update", {
        idForm: "modifyUserData",
        mailCode,
        _captcha: captcha,
    });

export const sendMobilePhoneCode = (mobilePhone, prefix, otp) =>
    API.executeWithAccessToken("/preferences.userData.mobilePhone.sendCode", {
        mobilePhone,
        prefix,
        _otp: otp,
    });

export const updateMobilePhone = (mobilePhoneCode) =>
    API.executeWithAccessToken("/preferences.userData.mobilePhone.update", {
        idForm: "modifyUserData",
        mobilePhoneCode,
    });

export const changeLanguage = (lang) => API.executeWithAccessToken("/preferences.lang.modify", { userLang: lang });

export const changeDefaultEnvironment = (idEnvironment) =>
    API.executeWithAccessToken("/preferences.environment.modify", { idEnvironmentToSetDefault: idEnvironment });

export const notificationsConfigurationPre = () =>
    API.executeWithAccessToken("/preferences.notifications.configuration.pre");

export const communicationTypePre = (communicationType) =>
    API.executeWithAccessToken("/preferences.notifications.configuration.modify.pre", {
        communicationType,
    });

export const modifyNotificationConfigurations = (notificationType, transport, subscribed) =>
    API.executeWithAccessToken("/preferences.notifications.configuration.modify", {
        notificationType,
        transport,
        subscribed,
    });

export const modifyPaperlessConfigurations = (idProduct, paperless) =>
    API.executeWithAccessToken("/core.product.setPaperlessValue", {
        idProduct,
        paperless,
    });

export const fetchProductsList = () => API.executeWithAccessToken("/preferences.products.list", {});

export const setAllPaperlessValue = (paperless) =>
    API.executeWithAccessToken("/core.product.setAllPaperlessValue", {
        paperless,
    });

export const changePassword = (password, newPassword, newPasswordConfirmation) =>
    API.executeWithAccessToken("/preferences.changepassword.send", {
        _password: password,
        _newPassword: newPassword,
        _newPasswordConfirmation: newPasswordConfirmation,
    });

export const changePasswordConfirm = (secondFactor, finalPassword, password) =>
    API.executeWithAccessToken("/preferences.changepassword.confirm.send", {
        _secondFactor: secondFactor,
        _finalPassword: finalPassword,
        _password: password,
    });

export const changePasswordResendOtp = () => API.executeWithAccessToken("/session.recoveryPassword.resendOtp", {});

export const listSecuritySeals = () => API.executeWithAccessToken("/preferences.securityseals.list");

export const modifySecuritySeal = (securitySeal, otp) =>
    API.executeWithAccessToken("/preferences.securityseals.modify", {
        _securitySeal: securitySeal,
        _otp: otp,
    });

export const listFingerprintConfiguredUserDevices = (deviceId, deviceModel) =>
    API.executeWithAccessToken("/preferences.fingerprint.pre", {
        deviceId,
        deviceModel,
    });

export const saveFingerprint = (deviceId, deviceModel) =>
    API.executeWithAccessToken("/preferences.fingerprint.save", {
        deviceId,
        deviceModel,
    });

export const deleteFingerprint = (idSession) =>
    API.executeWithAccessToken("/preferences.fingerprint.delete", {
        idSession,
    });

export const deleteAllFingerprint = () => API.executeWithAccessToken("/preferences.fingerprint.deleteAll");

export const pushNotificationsListUserDevices = () =>
    API.executeWithAccessToken("/communications.pushnotifications.listUserDevices");

export const pushNotificationsRegisterUserDevice = (idDevice, pushToken, extraInfo) =>
    API.executeWithAccessToken("/communications.pushnotifications.subscribe", {
        idDevice,
        pushToken,
        extraInfo,
    });

export const pushNotificationsDeleteUserDevice = (idDevice) =>
    API.executeWithAccessToken("/communications.pushnotifications.unsubscribe", {
        idDevice,
    });

export const listAllUserDevices = () => API.executeWithAccessToken("/preferences.userData.listAllUserDevices");

export const listAllGeneralConditionDocuments = () =>
    API.executeAnonymous("/session.listAllGeneralConditionDocuments", {});

export const downloadDocument = (fileNameKey) =>
    API.executeAnonymous("/session.downloadGeneralConditions", { fileNameKey });

export const getAddressesInformation = () =>
    API.executeWithAccessToken("/preferences.userData.getUserAddressInformation");

export const setAddressesInformation = (address, mailingAddress, otp) =>
    API.executeWithAccessToken("/preferences.userData.setUserAddressInformation", {
        address,
        mailingAddress,
        _otp: otp,
    });

export const getSecurityQuestions = () => API.executeWithAccessToken("/security.get.questions", {});

export const registerSecurityQuestions = (questions, secondFactor) =>
    API.executeWithAccessToken("/security.register.questions.send", {
        _questions: questions,
        _secondFactor: secondFactor,
    });

export const updateSecurityQuestions = (questions, secondFactor) =>
    API.executeWithAccessToken("/security.update.questions.send", {
        _questions: questions,
        _secondFactor: secondFactor,
    });

export const getEnvironments = () => API.executeWithAccessToken("/preferences.get.environments");
