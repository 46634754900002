import React, { Component } from "react";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import { connect } from "react-redux";
import { actions as creditCardsActions, selectors as creditCardsSelectors } from "reducers/creditCard";
import { bool, func, shape, string } from "prop-types";
import Button from "pages/_components/Button";
import classNames from "classnames";
import Row from "pages/_components/Row";
import { Col } from "react-bootstrap";
import PageLoading from "pages/_components/PageLoading";
import NumberFormat from "react-number-format";

class PointsTab extends Component {
    componentDidMount() {
        const { dispatch, creditCard } = this.props;
        // Dispatch the action to fetch points when the component is mounted
        dispatch(creditCardsActions.creditCardPointsRequest(creditCard.idProduct));
    }

    handleDownload = () => {
        const { dispatch, creditCard } = this.props;
        dispatch(creditCardsActions.downloadCreditCardPoints(creditCard?.idProduct, () => {}));
    };

    // This method checks if points are available
    hasPointsAvailable = (data) =>
       data?.availablePoints !== null && data?.availablePoints !== "0" && data?.redeemedPoints !== null;

    renderLoadingPage = () => (
        <Box>
            <PageLoading relative loading />
        </Box>
    );

    renderResourceNotFound = () => (
        <Box className="m-7">
            <Image src="images/empty.svg" />
            <Box className="text-center mt-5 mb-4">
                <Text labelKey="creditcard.detail.tab.points.noPointsRegistered" bold size="3" />
            </Box>
            <Box className="text-center">
                <Text labelKey="creditcard.detail.tab.points.noSearchResults" />
            </Box>
        </Box>
    );
    renderResourceNotAvailable = () => (
        <Box className="m-7">
            <Image src="images/empty.svg" />
            <Box className="text-center mt-5 mb-4">
                <Text labelKey="creditcard.detail.tab.points.noPointsRegisteredAvailable" bold size="3" />
            </Box>
            <Box className="text-center">
                <Text labelKey="creditcard.detail.tab.points.noSearchResultsAvailable" />
            </Box>
        </Box>
    );

    render() {
        const { isDesktop, data, isFetching, isDownloadFetching } = this.props;
        if(data==null && !isFetching){
            return this.renderResourceNotAvailable();

        }
        if (!this.hasPointsAvailable(data)) {
            return this.renderResourceNotFound();
        }

        return (
            <Box>
                {isFetching && this.renderLoadingPage()}
                {isDesktop && (
                    <Box display="flex" alignX="between" alignY="center">
                        <Box display="flex" alignY="center" className="p-3 mb-1" fullWidth>
                            <Box className="info-deposit-mov-list col-transaction">
                                <Text
                                    size="5"
                                    color="text-grey-color"
                                    labelKey="creditcard.detail.tab.points.availablePoints"
                                    light
                                />
                            </Box>
                            <Box className="info-deposit-mov-list col-transaction">
                                <Text
                                    size="5"
                                    color="text-grey-color"
                                    labelKey="creditcard.detail.tab.points.redeemedPoints"
                                    light
                                />
                            </Box>
                            <Box className="info-deposit-mov-list" />
                        </Box>
                    </Box>
                )}

                <Box className="table-rows-color pt-3">
                    <Box display="flex" alignX="between" alignY="center" borderRadius="md">
                        {isDesktop ? (
                            <Box
                                display="flex"
                                alignY="center"
                                fullWidth
                                className={classNames("p-3 justify-content-between background-table-odd")}>
                                <Box className="info-deposit-mov-list col-transaction size-5 text-bold">
                                    <Box>
                                        <NumberFormat
                                            value={data?.availablePoints}
                                            displayType="text"
                                            thousandSeparator
                                            renderText={(formattedValue) => <span>{formattedValue}</span>}
                                        />
                                    </Box>
                                </Box>
                                <Box className="info-deposit-mov-list col-transaction">
                                    <NumberFormat
                                        value={data?.redeemedPoints}
                                        displayType="text"
                                        thousandSeparator
                                        renderText={(formattedValue) => <span>{formattedValue}</span>}
                                    />
                                </Box>
                                <Box className="info-deposit-mov-list col-transaction pr-2 pl" display="flex">
                                    <Row>
                                        <Col xs={6} xsOffset={6}>
                                            <Button
                                                bsStyle="outline"
                                                className="outline-download text-regular"
                                                image="images/download.svg"
                                                label="accounts.statementLine.button.download"
                                                onClick={this.handleDownload}
                                                imageRight
                                                btnUppercase={false}
                                                labelClassName="py-0"
                                                loading={isDownloadFetching}
                                                block
                                            />
                                        </Col>
                                    </Row>
                                </Box>
                            </Box>
                        ) : (
                            <>
                                <Row className="px-3 pt-5 pb-4">
                                    <Col xs="10">
                                        <Box>
                                            <Text
                                                size="4"
                                                labelKey="creditcard.detail.tab.points.availablePoints"
                                                light
                                            />
                                        </Box>
                                    </Col>
                                    <Col xs="2">
                                        <Box className="text-right text-bold">
                                            <NumberFormat
                                                value={data?.availablePoints}
                                                displayType="text"
                                                thousandSeparator
                                                renderText={(formattedValue) => <span>{formattedValue}</span>}
                                            />
                                        </Box>
                                    </Col>
                                    <Col xs="10">
                                        <Box>
                                            <Text size="4" labelKey="creditcard.detail.tab.points.redeemedPoints" />
                                        </Box>
                                    </Col>
                                    <Col xs="2">
                                        <Box className="text-right">
                                            <NumberFormat
                                                value={data?.redeemedPoints}
                                                displayType="text"
                                                thousandSeparator
                                                renderText={(formattedValue) => <span>{formattedValue}</span>}
                                            />
                                        </Box>
                                    </Col>
                                    <Col xs="12">
                                        <Box display="flex" fullWidth>
                                            <Button
                                                bsStyle="outline"
                                                className="outline-download text-regular"
                                                image="images/download.svg"
                                                label="accounts.statementLine.button.download"
                                                onClick={this.handleDownload}
                                                imageRight
                                                btnUppercase={false}
                                                block
                                                labelClassName="py-0"
                                                loading={isDownloadFetching}
                                            />
                                        </Box>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
        );
    }
}

PointsTab.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isFetching: bool.isRequired,
    data: shape({
        availablePoints: string.isRequired,
        redeemedPoints: string.isRequired,
    }).isRequired,
    creditCard: shape({}).isRequired,
    isDownloadFetching: bool.isRequired,
};

const mapStateToProps = (state) => ({
    isFetching: creditCardsSelectors.isFetching(state),
    isDownloadFetching: creditCardsSelectors.getDownloadFetching(state),
    data: creditCardsSelectors.getCreditCardPoints(state),
});

export default connect(mapStateToProps)(resizableRoute(PointsTab));
