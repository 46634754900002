import * as configUtils from "util/config";

export const invitationCodeMask = () => {
    const invitationCodeUnmaskedLength = configUtils.getInteger("backoffice.invitationCodes.unmaskedLength", 4);

    let mask = new Array(invitationCodeUnmaskedLength).fill(/[a-zA-Z\d]/);
    for (let i = 0; i < 2; i++) {
        mask = [...mask, "-", ...new Array(invitationCodeUnmaskedLength).fill(/[a-zA-Z\d]/)];
    }

    return mask;
};

export const timeCodeMask = () => [/\d/, /\d/, ":", /\d/, /\d/];

export const timeRegex = () => "^(?:0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$";

export const maskUsername = (username) => {
    if (!username) {
        return username;
    }
    if (username.length < 3) {
        return username;
    }
    const plane = username.substring(0, 2);
    const mask = "•".repeat(username.length - 2);

    return plane + mask;
};

export const maskDocumentNumber = (documentNumber) => {
    if (!documentNumber) {
        return documentNumber;
    }
    if (documentNumber.length < 3) {
        return documentNumber;
    }
    const plane = documentNumber.substring(0, 2);
    const mask = "X".repeat(documentNumber.length - 4);
    const end = documentNumber.substring(documentNumber.length - 3, documentNumber.length - 1);

    return plane + mask + end;
};

export default {};
