import React, { Component } from "react";
import { func, node, number } from "prop-types";
import Tabs from "pages/_components/Tabs";

class UsersTabs extends Component {
    static propTypes = {
        children: node.isRequired,
        onSelect: func.isRequired,
        selectedIndex: number,
    };

    static defaultProps = {
        selectedIndex: undefined,
    };

    render() {
        const { children, onSelect, selectedIndex } = this.props;
        return <Tabs className="pt-0" components={children} onSelect={onSelect} selectedIndex={selectedIndex} />;
    }
}

export default UsersTabs;
