import classNames from "classnames";
// import A11yNav from "pages/_components/A11yNav";
import AccessBar from "pages/_components/AccessBar";
import Box from "pages/_components/Box";
import ErrorBoundary from "pages/_components/ErrorBoundary";
import I18n from "pages/_components/I18n";
import NavigationBarDesktop from "pages/_components/NavigationBarDesktop";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import ResizableComponent from "pages/_components/ResizableComponent";
import Tour from "pages/_components/Tour";
import { dashboardTourSteps } from "pages/_components/Tour/dashboardTourSteps";
import TourMobile from "pages/_components/TourMobile";
import { dashboardTourStepsMobile } from "pages/_components/TourMobile/dashboardTourStepsMobile";
import Menu from "pages/_components/menu/Menu";
import { arrayOf, bool, func, number, oneOf, shape, string } from "prop-types";
import RevelockPositionProvider from "providers/RevelockPositionProvider";
import React, { Component } from "react";
import { connect } from "react-redux";
import Route from "react-router-dom/Route";
import withRouter from "react-router-dom/withRouter";
import PageTransition from "react-router-page-transition";
import { actions as desktopActions, selectors as desktopSelectors } from "reducers/desktop";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as tourSelectors } from "reducers/tour";
import * as config from "util/config";
import { getTransition } from "util/transition";
import WithPermissionsRedirect from "../_components/WithPermissionsRedirect";
import { actions as paginatedTableActions } from "reducers/paginatedTable";
import { push } from "react-router-redux";

// const SCREEN_WIDTH_REFERENCE = 1024;
class DashboardLayout extends Component {
    constructor(props) {
        super(props);
        this.mainContent = React.createRef();
    }

    state = {
        transition: "",
        styles: {},
    };

    componentDidMount() {
        const { dispatch } = this.props;
        if (window.innerWidth < 1232) {
            dispatch(desktopActions.changeStatusSidebarCollapsed(true));
        }
    }

    componentDidUpdate() {
        const { dispatch, toggleMenuBtnPress } = this.props;
        if (window.innerWidth < 1232 && !toggleMenuBtnPress) {
            dispatch(desktopActions.changeStatusSidebarCollapsed(true));
        }
    }

    handlerResizeEventSidebar = () => {
        const { dispatch } = this.props;
        const isSizeTablet = window.innerWidth < 1232;
        dispatch(desktopActions.changeStatusSidebarCollapsed(isSizeTablet));
    };

    /* eslint-disable-next-line react/sort-comp, camelcase */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { location } = this.props;

        if (location.pathname !== nextProps.location.pathname) {
            this.setState({
                transition:
                    (nextProps.location.state && nextProps.location.state.transition) ||
                    nextProps.transition ||
                    getTransition(this.props, nextProps),
            });
        }
    }

    getMobileLayout = (matchProps) => {
        const {
            activeEnvironment,
            component: ReceivedComponent,
            isMobile,
            isMobileNative,
            isDesktop,
            noBar,
            aditionalClassName,
            skipsCounter,
            isTourHidden,
            dispatch,
        } = this.props;
        const extras = { isMobile, isDesktop };
        const { transition, styles } = this.state;

        const accessBarBtns = [
            {
                image: "images/icons/menu-home.svg",
                label: "login.access.bar.home",
                href: "/desktop",
                className: "secondary ab-home",
            },
            {
                image: "images/icons/menu-transactions.svg",
                label: "menu.transactions",
                className: "secondary ab-history",
                onClick: ()=>{
                    const { dispatch } = this.props;
                    dispatch(paginatedTableActions.clearPagedDataList());
                    dispatch(push("/transactions/list"));
                }
            },
            {
                image: "images/icons/user.svg",
                label: "menu.settings",
                href: "/settings",
                className: "secondary ab-settings",
            },
            {
                image: "images/icons/menu-burger.svg",
                label: "login.access.bar.menu",
                // externalHref: config.get("accessBar.requests.url"),
                className: "secondary ab-menu",
            },
        ];

        const wally = {
            image: "",
            label: "",
            href: "",
            className: "",
        };

        return (
            <Box display="flex" column position="relative" className="transition-item-wrapper" fullHeight fullWidth>
                <Box display="flex" column className={classNames(transition)} style={styles} fullHeight>
                    <PageTransition timeout={transition ? 600 : 0}>
                        <div className="transition-item full-height">
                            <Box
                                className={classNames("view-app", aditionalClassName, {
                                    "padding-for-access-bar": !noBar && isMobileNative,
                                })}
                                background="primary">
                                <ErrorBoundary>
                                    {skipsCounter < 3 && !isTourHidden ? (
                                        <TourMobile steps={dashboardTourStepsMobile} />
                                    ) : (
                                        <>
                                            <Notification scopeToShow="menu" />
                                            <ReceivedComponent {...matchProps} {...extras} />
                                        </>
                                    )}
                                </ErrorBoundary>
                            </Box>
                        </div>
                    </PageTransition>

                    {isMobile && !noBar && (
                        <AccessBar options={accessBarBtns} dispatch={dispatch} showNotification wally="" />
                    )}
                </Box>
            </Box>
        );
    };

    getDesktopLayout = (matchProps) => {
        const {
            component: ReceivedComponent,
            dispatch,
            isMobile,
            isDesktop,
            isTablet,
            isMobileNative,
            componentProps,
            isSidebarCollapsed,
            skipsCounter,
            isTourHidden,
        } = this.props;
        const extras = { isMobile, isDesktop, isTablet, isMobileNative, ...componentProps };
        const buildNumber = window.BUILD_NUMBER ? `(Build ${window.BUILD_NUMBER})` : "";
        return (
            <ErrorBoundary>
                <ResizableComponent onResize={this.handlerResizeEventSidebar}>
                    <Box
                        id="appDefaultContainer"
                        className={classNames("app-default-container", {
                            "sidebar-collapsed": isSidebarCollapsed,
                        })}>
                        {skipsCounter < 3 && !isTourHidden && <Tour steps={dashboardTourSteps} />}

                        {/* {!isMobile && (
                            // TODO (LABEL a11y)
                            <A11yNav
                                background="component-background"
                                ariaLabel="Atajo en Navegación secundaria"
                                buttonList={[
                                    { label: "Ir a Sección principal", focusOnSection: "main" },
                                    { label: "Ir a Menu Principal", focusOnSection: "menu" },
                                    { label: "Ir a Transacciones rápidas", focusOnSection: "fast-access" },
                                ]}
                            />
                        )} */}

                        <Box component="header" className="app-default-header">
                            <NavigationBarDesktop isDashboard />
                        </Box>

                        {/* TODO (LABEL a11y) */}
                        <Box
                            component="nav"
                            id="menu"
                            role="navigation"
                            // TODO (LABEL a11y)
                            ariaLabel="Menú principal"
                            onClick={() => {
                                dispatch(desktopActions.changeStatusSidebarCollapsed(false));
                            }}
                            className="app-default-sidebar">
                            <Menu isMobile={isMobile} isDashboard />
                            <p className="app-version">
                                {/* global process */}
                                <I18n id="global.version" component="small" /> {process.env.REACT_APP_VERSION}{" "}
                                {buildNumber}
                            </p>
                        </Box>
                        <Box component="main" id="main" className="app-default-main dashboard-main" scrollable>
                            <Box display="flex" column component="section" className="full-height">
                                <Notification scopeToShow="menu" />
                                <ReceivedComponent {...matchProps} {...extras} />
                            </Box>
                        </Box>
                    </Box>
                </ResizableComponent>
            </ErrorBoundary>
        );
    };

    render() {
        const { component: ReceivedComponent, somePermissions, permissions, comparator, ...rest } = this.props;

        return (
            <WithPermissionsRedirect
                somePermissions={somePermissions}
                permissions={permissions}
                comparator={comparator}>
                <RevelockPositionProvider {...this.props}>
                    <Route
                        {...rest}
                        render={(matchProps) => {
                            const { isMobile } = this.props;

                            if (isMobile) {
                                return <>{this.getMobileLayout(matchProps)}</>;
                            }

                            return <>{this.getDesktopLayout(matchProps)}</>;
                        }}
                    />
                </RevelockPositionProvider>
            </WithPermissionsRedirect>
        );
    }
}

DashboardLayout.propTypes = {
    activeEnvironment: shape({
        type: string,
    }).isRequired,
    aditionalClassName: string,
    dispatch: func.isRequired,
    dashboard: bool,
    isDesktop: bool,
    isMobile: bool,
    isTablet: bool,
    isMobileNative: bool,
    component: func.isRequired,
    componentProps: shape({}),
    transition: oneOf([
        "transition-drill-out",
        "transition-drill-in",
        "transition-flow-close",
        "transition-flow-open",
        "transition-change-feature",
        "",
    ]),
    location: shape({
        pathname: string.isRequired,
        state: shape({
            transition: oneOf([
                "noTransition",
                "transition-drill-out",
                "transition-drill-in",
                "transition-flow-close",
                "transition-flow-open",
                "transition-change-feature",
            ]),
        }),
    }).isRequired,
    withoutLayout: bool,
    somePermissions: bool,
    permissions: arrayOf(string),
    comparator: func,
    scrollable: bool,
    isSidebarCollapsed: bool.isRequired,
    noBar: bool,
    skipsCounter: number,
    isTourHidden: bool,
    sidebarCollapsed: bool,
    toggleMenuBtnPress: bool.isRequired,
};

DashboardLayout.defaultProps = {
    aditionalClassName: null,
    componentProps: null,
    dashboard: false,
    isDesktop: false,
    scrollable: false,
    isMobile: false,
    isTablet: false,
    isMobileNative: false,
    transition: "",
    withoutLayout: false,
    somePermissions: false,
    permissions: null,
    comparator: null,
    noBar: false,
    skipsCounter: 0,
    isTourHidden: false,
    sidebarCollapsed: false,
};

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    isSidebarCollapsed: desktopSelectors.isSidebarCollapsed(state),
    toggleMenuBtnPress: desktopSelectors.toggleMenuBtnPress(state),
    skipsCounter: tourSelectors.getSkipsCounter(state),
    isTourHidden: tourSelectors.isHidden(state),
});

export default withRouter(connect(mapStateToProps)(resizableRoute(DashboardLayout)));
