import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Sticker from "pages/_components/Sticker";
import Text from "pages/_components/Text";
import BolivarianoCheckbox from "pages/_components/fields/BolivarianoCheckbox";
import { arrayOf, bool, func, number, objectOf, shape, string } from "prop-types";
import React, { Component } from "react";
import { isDesktop } from "react-device-detect";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions, selectors } from "reducers/administration/users";
import { selectors as sessionSelectors } from "reducers/session";
import PaginatedDataTable from "pages/_components/PaginatedTableComponent";
import { TYPE_FILTER_USERS } from "constants.js";
import UsersTableHead from "./_components/advancedUsers/UsersTableHead";
import UsersTabs from "./_components/advancedUsers/UsersTabs";
import UserList from "./_components/UserList";
import UserListActions from "./_components/UserListActions";
import UsersFilter from "./_components/UsersFilter";

// NO BORRAR (IMPLEMENTACION DE PRODUCTO)
// import I18n from "pages/_components/I18n";
// import Checkbox from "pages/_components/fields/Checkbox";
// import FeatureFlag from "pages/_components/FeatureFlag";
// import Table from "rc-table";

// NO BORRAR (IMPLEMENTACION DE PRODUCTO)
// const UserName = ({ name, dispatcher, massiveEnabled }) => (
//     <div className="data-wrapper data-wrapper-flex">
//         <span className="data-desc">{name}</span>
//         {!massiveEnabled && <Image src="images/administrator.svg" className="svg-icon svg-caret" />}
//         <FeatureFlag id="feature.signatureSchema.dispatchControl">
//             {dispatcher && <Image src="images/dispatcher.svg" className="svg-icon svg-caret" />}
//         </FeatureFlag>
//     </div>
// );

// NO BORRAR (IMPLEMENTACION DE PRODUCTO)
// UserName.propTypes = {
//     name: string.isRequired,
//     dispatcher: bool.isRequired,
//     massiveEnabled: bool.isRequired,
// };

const contextOptions = [
    { id: "1", label: "administration.users.list.pending.resendInvitation", onClick: () => {} },
    { id: "2", label: "administration.users.list.pending.editInvitation", onClick: () => {} },
    { id: "3", label: "administration.users.list.pending.deleteInvitation", onClick: () => {} },
];
const items = [
    { id: "administratorUser", icon: "images/icons/key.svg" },
    { id: "secondaryUser", icon: "images/icons/users.svg" },
];

class AdministrationUsers extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        activeEnvironment: shape({ administrationScheme: string }).isRequired,
        users: arrayOf(shape({ fullName: string, email: string, lastLoginAsMediumString: string })).isRequired,
        usersInfo: objectOf(
            shape({
                massiveEnabled: bool.isRequired,
                status: string.isRequired,
                signatureLevel: string,
            }),
        ).isRequired,
        currentPage: number,
        totalPages: number,
        fetching: bool,
        fetchingMoreUsers: bool,
        hasMoreData: bool,
    };

    static defaultProps = {
        currentPage: 0,
        totalPages: 0,
        fetching: false,
        fetchingMoreUsers: false,
        hasMoreData: false,
    };

    state = {
        selectedUsers: new Map(),
        selectAllChecked: false,
        blockedQuantity: 0,
        unblockedQuantity: 0,
        selectedButtonId: "users",
        fetchingAction: false,
        listToBeFiltered: [],
        hasFilterApplied: {},
        selectedIndex: 0,
    };

    constructor() {
        super();
        this.state = {
            selectedUsers: new Map(),
            selectAllChecked: false,
            blockedQuantity: 0,
            unblockedQuantity: 0,
            selectedButtonId: "users",
            fetchingAction: false,
            listToBeFiltered: [],
            selectedIndex: 0,
        };
    }

    componentDidMount() {
        const { dispatch, selectedIndex } = this.props;

        dispatch(actions.loadListRequest());
        if (this.props.users) {
            this.setState({ listToBeFiltered: this.props.users, selectedIndex });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.users !== this.props.users) {
            this.setState({ listToBeFiltered: this.props.users });
        }
    }

    getColumnsTemplate = () => {
        const { selectedButtonId } = this.state;
        if (selectedButtonId === "users") {
            return "1fr 1fr 1fr 1fr 5rem 1fr 1.5rem";
        }
        return "minmax(max-content, 6rem) 1fr 1fr 1fr 1fr 2.5rem";
    };

    onRowClick = (record) => {
        const {
            dispatch,
            activeEnvironment: { administrationScheme },
        } = this.props;
        const path = {
            simple: "simple/permissions",
            medium: "medium/details",
            advanced: "advanced/details",
        };

        dispatch(routerActions.push(`/administration/${path[administrationScheme]}/${record.idUser}`));
    };

    // NO BORRAR (IMPLEMENTACION DE PRODUCTO)
    // generateTableColumns = (massiveDissabledQuantity) => {
    //     const { selectAllChecked, selectedUsers } = this.state;
    //     const { activeEnvironment, users } = this.props;
    //     const columnArray = [
    //         {
    //             key: "check",
    //             dataIndex: "check",
    //             title: (
    //                 <Checkbox
    //                     hideLabel
    //                     onChange={this.handleSelectAll}
    //                     name="selectAllUsers"
    //                     checked={
    //                         selectAllChecked ||
    //                         (users.length === selectedUsers.size + massiveDissabledQuantity && selectedUsers.size !== 0)
    //                     }
    //                     disabled={users.length === massiveDissabledQuantity}
    //                 />
    //             ),
    //             width: 20,
    //             onCell: () => ({
    //                 onClick(e) {
    //                     e.stopPropagation();
    //                 },
    //             }),
    //         },
    //         {
    //             key: "userName",
    //             dataIndex: "userName",
    //             title: i18n.get("administration.users.list.username"),
    //             width: 150,
    //         },
    //         {
    //             className: "text-ellipsis",
    //             key: "email",
    //             dataIndex: "email",
    //             title: i18n.get("administration.users.list.email"),
    //             width: 300,
    //         },
    //         {
    //             key: "signature",
    //             dataIndex: "signature",
    //             title: i18n.get("administration.users.list.signature"),
    //             width: 50,
    //         },
    //         {
    //             className: "text-center",
    //             key: "lastLogin",
    //             dataIndex: "lastLogin",
    //             title: i18n.get("administration.users.list.lastLogin"),
    //             width: 150,
    //         },
    //         {
    //             className: "text-center",
    //             key: "status",
    //             dataIndex: "status",
    //             title: i18n.get("administration.users.list.status"),
    //             width: 100,
    //         },
    //     ];
    //     if (activeEnvironment.administrationScheme !== "simple") {
    //         return columnArray;
    //     }
    //     const auxArray = columnArray.slice(1, columnArray.length);
    //     auxArray.splice(2, 1);
    //     return auxArray;
    // };

    // NO BORRAR (IMPLEMENTACION DE PRODUCTO)
    // populateUsersData = (massiveDissabledQuantity) => {
    //     const { activeEnvironment, users, usersInfo } = this.props;
    //     const { selectedUsers, selectAllChecked } = this.state;

    //     return users.map((user) => ({
    //         key: user.idUser,
    //         check: activeEnvironment.administrationScheme !== "simple" && (
    //             <Checkbox
    //                 onChange={() => this.handleCheckClick(user.idUser, user.fullName, massiveDissabledQuantity)}
    //                 hideLabel
    //                 id={user.idUser}
    //                 name={user.idUser}
    //                 checked={
    //                     usersInfo[user.idUser].massiveEnabled && (selectAllChecked || selectedUsers.has(user.idUser))
    //                 }
    //                 disabled={!usersInfo[user.idUser].massiveEnabled}
    //             />
    //         ),
    //         idUser: user.idUser,
    //         userName: (
    //             <UserName
    //                 name={user.fullName}
    //                 massiveEnabled={usersInfo[user.idUser].massiveEnabled}
    //                 dispatcher={usersInfo[user.idUser].dispatcher}
    //             />
    //         ),
    //         email: user.email,
    //         signature: this.signatureToLabel(usersInfo[user.idUser].signatureLevel),
    //         lastLogin: user.lastLoginAsMediumString,
    //         status: (
    //             <div className="data-wrapper data-wrapper-flex">
    //                 <div
    //                     className={`data-tag ${usersInfo[user.idUser].status === "blocked" ? "data-tag-blocked" : ""}`}>
    //                     <span> {i18n.get(`user.status.${usersInfo[user.idUser].status}`)} </span>
    //                 </div>
    //             </div>
    //         ),
    //     }));
    // };

    renderPageHeader = () => (
        <Head
            title="administration.users.list.title"
            addLinkTo="/administration/users/invite"
            addLinkToLabel="administration.users.list.addUser"
            // rightChildren={
            //     <Box display="flex" gap="7">
            //         <Button
            //             bsStyle="primary"
            //             className="btn-width-lg"
            //             label="administration.users.list.addUser"
            //             href="/administration/users/invite"
            //             image="images/icons/plus-circle.svg"
            //         />
            //     </Box>
            // }
        />
    );

    handleCheckClick = (user, massiveDissabledQuantity) => {
        const { users, usersInfo } = this.props;
        const { selectedUsers, selectAllChecked } = this.state;
        let { blockedQuantity, unblockedQuantity } = this.state;

        if (!selectedUsers.has(user.idUser)) {
            selectedUsers.set(user.idUser, user);
            if (usersInfo[user.idUser].status === "blocked") {
                blockedQuantity += 1;
            } else {
                unblockedQuantity += 1;
            }

            if (selectedUsers.size === users.length - massiveDissabledQuantity) {
                this.setState({
                    selectAllChecked: true,
                });
            }
        } else {
            selectedUsers.delete(user.idUser);
            if (usersInfo[user.idUser].status === "blocked") {
                blockedQuantity -= 1;
            } else {
                unblockedQuantity -= 1;
            }
            if (selectAllChecked) {
                this.setState({ selectAllChecked: false });
            }
        }
        this.setState({
            selectedUsers,
            blockedQuantity,
            unblockedQuantity,
        });
    };

    handleSelectAll = () => {
        const { selectAllChecked } = this.state;
        const { users, usersInfo } = this.props;
        const newSelectedUsers = new Map();
        let blockedQuantity = 0;
        let unblockedQuantity = 0;
        if (!selectAllChecked) {
            users.forEach((user) => {
                if (usersInfo[user.idUser].massiveEnabled) {
                    newSelectedUsers.set(user.idUser, user.fullName);
                    if (usersInfo[user.idUser].status === "blocked") {
                        blockedQuantity += 1;
                    } else {
                        unblockedQuantity += 1;
                    }
                }
            });
        }

        this.setState((prevState) => ({
            selectAllChecked: !prevState.selectAllChecked,
            selectedUsers: newSelectedUsers,
            blockedQuantity,
            unblockedQuantity,
        }));
    };

    handleMoreDataClick = () => {
        const { dispatch, currentPage } = this.props;

        this.setState({ selectAllChecked: false });
        dispatch(actions.loadMoreRequest({ pageNumber: currentPage + 1 }));
    };

    // NO BORRAR (IMPLEMENTACION DE PRODUCTO)
    // handleClickDownload = (format) => {
    //     const { dispatch } = this.props;

    //     dispatch(actions.exportListRequest(format));
    // };

    handleActionClick = (action) => {
        const { dispatch } = this.props;
        const { selectedUsers } = this.state;
        dispatch(actions.changeUserStatusPreview([...selectedUsers.keys()], [...selectedUsers.values()], action, true));
    };

    // NO BORRAR (IMPLEMENTACION DE PRODUCTO
    // handleInviteUserClcik = () => {
    //     const { dispatch } = this.props;

    //     dispatch(routerActions.push("/administration/users/invite"));
    // };

    // NO BORRAR (IMPLEMENTACION DE PRODUCTO)
    // renderTableFooter = () => {
    //     const { totalPages, hasMoreData, fetchingMoreUsers } = this.props;

    //     return (
    //         <div>
    //             {totalPages > 1 &&
    //                 (hasMoreData ? (
    //                     <div className="text-center no-more-data" key="noMoreUsers">
    //                         <Button
    //                             className="btn btn-link"
    //                             onClick={this.handleMoreDataClick}
    //                             loading={fetchingMoreUsers}
    //                             image="images/show.svg"
    //                             label="administration.users.list.more"
    //                         />
    //                     </div>
    //                 ) : (
    //                     <div className="text-center no-more-data" key="noMoreUsers">
    //                         <p className="text-lead">
    //                             <I18n id="administration.users.list.more.noMoreData" />
    //                         </p>
    //                     </div>
    //                 ))}
    //         </div>
    //     );
    // };

    renderLoadMore = () => {
        const { fetchingMoreUsers, totalPages, currentPage, hasMoreData } = this.props;
        if (totalPages > currentPage && hasMoreData) {
            return (
                <Box
                    display="flex"
                    fullWidth
                    alignX="center"
                    className="no-more-data no-border-radius background-transparent mt-auto">
                    <Button
                        bsStyle="link"
                        onClick={this.handleMoreDataClick}
                        image="images/arrow-down.svg"
                        imageRight
                        loading={fetchingMoreUsers}
                        label="Cargar más"
                        className="btn-small"
                    />
                </Box>
            );
        }
        return null;
    };

    emptyUsers = (tabId) => (
        <Box className="m-7">
            <Image src="images/empty.svg" />
            <Box className="text-center mt-5 mb-4">
                <Text labelKey={`administration.users.list.${tabId}.empty.title`} bold size="3" />
            </Box>
            <Box className="text-center">
                <Text labelKey={`administration.users.list.${tabId}.empty.text`} />
            </Box>
        </Box>
    );

    renderTableUsers = (users, itemId) => {
        const { hasFilterApplied, selectedUsers, unblockedQuantity, blockedQuantity } = this.state;
        const {
            activeEnvironment: { administrationScheme },
            usersInfo,
        } = this.props;
        return (
            <PaginatedDataTable
                nameList="users"
                data={users}
                hasFilterApplied={hasFilterApplied && hasFilterApplied[itemId] && hasFilterApplied[itemId] !== ""}
                rowsPerPage={10}
                component={UserList}
                componentProps={{
                    administrationScheme,
                    usersInfo,
                    selectedUsers,
                    handleCheckClick: this.handleCheckClick,
                    showCheckBox: itemId === "secondaryUser",
                }}
                rowActions={UserListActions}
                rowActionsProps={{
                    selectedUsers,
                    unblockedQuantity,
                    blockedQuantity,
                    handleActionClick: this.handleActionClick,
                    showCheckBox: itemId === "secondaryUser",
                }}
                emptyMessage={() => this.emptyUsers(itemId)}
            />
        );
    };

    renderUser = (user, itemId) => {
        const { usersInfo } = this.props;
        const { selectedButtonId, selectedUsers } = this.state;
        const massiveDissabledQuantity = Object.values(usersInfo).filter((userInfo) => !userInfo.massiveEnabled).length;
        const extendedInfo = usersInfo[user.idUser] || {};
        const { hasSignature } = extendedInfo;
        // const isLoggedUser = loggedUser?.userId === user.idUser;

        if (!user) {
            return (
                <Box display="flex" alignX="center" alignY="center" fullHeight>
                    <Text labelKey="administration.users.list.empty" />
                </Box>
            );
        }

        const defaultChecked = selectedUsers.has(user.idUser);
        let userEnvironmentStatus = usersInfo[user.idUser]?.status;
        const userStatus = user.idUserStatus;

        if (userStatus === "blocked") {
            userEnvironmentStatus = "blocked_bo";
        }

        const status = () => {
            if (userStatus === "blocked") {
                return "error";
            }
            if (userEnvironmentStatus === "active") {
                return "success";
            }
            if (userEnvironmentStatus === "blocked") {
                return "error";
            }
            if (userEnvironmentStatus === "pending") {
                return "warning";
            }
            return null;
        };

        return (
            <GridTable.Container
                key={user.idUser}
                columnsTemplate={this.getColumnsTemplate()}
                onClick={() => this.onRowClick(user)}
                background="transparent">
                <GridTable.Data columnStart={1} alignX="flex-start">
                    <Box display="flex" column alignY="center" alignX="flex-start">
                        <Text labelKey="administration.users.list.header.name" />
                        <Box display="flex" alignY="center" gap="2" fullWidth>
                            {itemId === "secondaryUser" && (
                                <BolivarianoCheckbox
                                    name={`checkbox-${user.idUser}`}
                                    value="testing"
                                    defaultChecked={defaultChecked}
                                    onChange={() => {
                                        this.handleCheckClick(user.idUser, user.fullName, massiveDissabledQuantity);
                                    }}
                                    hideLabel
                                    disabled={!usersInfo[user.idUser]?.massiveEnabled}
                                />
                            )}
                            {extendedInfo.isAdmin && (
                                <Box display="flex">
                                    <Image src="images/icons/key.svg" />
                                </Box>
                            )}
                            <Text bold>{user.fullName}</Text>
                        </Box>
                    </Box>
                </GridTable.Data>
                {selectedButtonId === "users" && (
                    <GridTable.Data columnStart={3} alignX="flex-start">
                        <Box display="flex" column alignY="center" alignX="flex-start">
                            <Text labelKey="administration.users.list.header.identification" />
                            <Text bold>
                                {user.documentType} {user.documentNumber}
                            </Text>
                        </Box>
                    </GridTable.Data>
                )}
                <GridTable.Data columnStart={selectedButtonId === "users" ? 4 : 3} alignX="flex-start">
                    <Box display="flex" column alignY="center" alignX="flex-start">
                        <Text labelKey="administration.users.list.header.signature" />
                        <Text bold labelKey={`administration.user.signature.${hasSignature}`} uppercase />
                    </Box>
                </GridTable.Data>
                {selectedButtonId === "users" ? (
                    <>
                        <GridTable.Data columnStart={5} alignX="center">
                            <Box display="flex" column alignY="center" alignX="flex-start">
                                <Text labelKey="administration.users.list.header.status" />
                                <Box display="flex" alignX="center" gap="2" fullWidth>
                                    <Sticker
                                        // TODO (LABEL)
                                        labelKey={`global.${userEnvironmentStatus}`}
                                        uppercase={false}
                                        bold
                                        status={status()}
                                        textSize="7"
                                        addPaddingY
                                    />
                                </Box>
                            </Box>
                        </GridTable.Data>
                    </>
                ) : (
                    <>
                        <GridTable.Data columnStart={4} alignX="flex-start">
                            <Box
                                display="flex"
                                alignX="center"
                                alignY="center"
                                borderRadius="xxl"
                                className="p-2 min-width-2-5rem">
                                {/* TODO (LABEL) */}
                                <Text ellipsis>{user.email}</Text>
                            </Box>
                        </GridTable.Data>
                        <GridTable.Data columnStart={5} alignX="flex-end">
                            <Box
                                display="flex"
                                alignX="center"
                                alignY="center"
                                borderRadius="xxl"
                                className="p-2 min-width-2-5rem">
                                {/* TODO (LABEL) */}
                                <FormattedDate date={user.creationDate} anotherFormat="DD/MM/YY" />
                            </Box>
                        </GridTable.Data>
                        <GridTable.Data columnStart={6} alignX="center" overflow="visible">
                            <Dropdown
                                image="images/contextualMenu.svg"
                                buttonClass="p-2 icon-big btn-context"
                                bsStyle="only-icon"
                                ariaLabel="global.contextMenu.a11y"
                                pullLeft
                                pullCenter>
                                {contextOptions.map((item) => (
                                    <Button
                                        key={item.label}
                                        label={item.label}
                                        ariaLabel={item.label}
                                        className="dropdown__item-btn"
                                        bsStyle="link"
                                        href={item.href}
                                        onClick={(e) => {
                                            if (item.onClick) {
                                                e.preventDefault();
                                                item.onClick();
                                            }
                                        }}
                                    />
                                ))}
                            </Dropdown>
                        </GridTable.Data>
                    </>
                )}
            </GridTable.Container>
        );
    };

    filterUsers = (tabId, sourceData) => {
        const { usersInfo } = this.props;
        if (tabId === "administratorUser") {
            return sourceData?.filter((user) => usersInfo[user.idUser]?.isAdmin);
        }
        if (tabId === "secondaryUser") {
            return sourceData?.filter((user) => !usersInfo[user.idUser]?.isAdmin);
        }
        return sourceData;
    };

    handleShowAll = (tabId) => {
        const { dispatch } = this.props;
        const selectedIndex = items.findIndex((item) => item.id === tabId);
        dispatch(actions.setSelectedIndex(selectedIndex));
        this.setState({ listToBeFiltered: this.filterUsers(tabId, this.props.users), selectedIndex });
    };

    renderTable = (itemId) => {
        const { usersInfo, totalPages, users, currentPage } = this.props;
        const {
            // selectedUsers,
            selectedButtonId,
            // selectAllChecked,
            // unblockedQuantity,
            // blockedQuantity,
            // fetchingAction,
            listToBeFiltered,
        } = this.state;

        // const massiveDissabledQuantity = Object.values(usersInfo).filter((userInfo) => !userInfo.massiveEnabled).length;

        // NO BORRAR (IMPLEMENTACION DE PRODUCTO)
        // const columns = this.generateTableColumns(massiveDissabledQuantity);
        // this.setState({ listToBeFiltered: users });
        const adminUsers = this.filterUsers("administratorUser", listToBeFiltered);
        const secondaryUsers = this.filterUsers("secondaryUser", listToBeFiltered);
        const allAdminUsers = this.filterUsers("administratorUser", users);
        const allSecondaryUsers = this.filterUsers("secondaryUser", users);

        // const buttonsGroup = [{ id: "users", label: "administration.users.index.title" }];

        /* const buttonsWithSelection = buttonsGroup.map((button) => ({
            ...button,
            selected: button.id === selectedButtonId,
        }));

        const chipBtnHandler = (buttonId) => {
            this.setState({
                selectedButtonId: buttonId,
            });
        }; */

        const handleFilterData = (tabId, filters, hasFilterApplied) => {
            const { usersInfo } = this.props;
            // let sourceData = [];
            // if (tabId === "administratorUser") {
            //     sourceData = adminUsers;
            // } else if (tabId === "secondaryUser") {
            //     sourceData = secondaryUsers;
            // }
            const sourceData = this.filterUsers(tabId, this.props.users);
            this.setState({ hasFilterApplied: { [tabId]: hasFilterApplied } });

            const dataToFilter = sourceData.map((user) => ({
                ...user,
                extraInfoSignatureLevel: usersInfo[user.idUser]?.signatureLevel,
                extraInfoStatus: usersInfo[user.idUser]?.status,
            }));
            let data = [];

            if (filters?.typeFilter === TYPE_FILTER_USERS.NAME && filters?.nameFilter) {
                data = dataToFilter.filter((user) =>
                    user?.fullName.toLowerCase().includes(filters?.nameFilter.toLowerCase()),
                );
                this.setState({ listToBeFiltered: data });
            } else if (filters?.typeFilter === TYPE_FILTER_USERS.STATUS && filters?.statusFilter) {
                data = dataToFilter.filter((user) =>
                    user?.extraInfoStatus?.toLowerCase().includes(filters?.statusFilter.toLowerCase()),
                );
                this.setState({ listToBeFiltered: data });
            } else if (filters?.typeFilter === TYPE_FILTER_USERS.DOCUMENT && filters?.documentFilter) {
                data = dataToFilter.filter((user) => user?.documentNumber.includes(filters?.documentFilter));
                this.setState({ listToBeFiltered: data });
            } else if (filters?.typeFilter === TYPE_FILTER_USERS.SIGNATURE && filters?.signatureFilter) {
                data = dataToFilter.filter((user) => {
                    if (filters.signatureFilter === "NO") {
                        return user.extraInfoSignatureLevel === null;
                    }
                    return user.extraInfoSignatureLevel?.includes(filters?.signatureFilter);
                });

                this.setState({ listToBeFiltered: data });
            } else {
                this.handleShowAll(tabId);
            }
        };

        return (
            <Box className="mt-6">
                <GridTable>
                    <GridTable.Header className="noHeader">
                        <GridTable.Container columnsTemplate={this.getColumnsTemplate()}>
                            <GridTable.Data columnStart={1} alignX="flex-start" size="7" inHeader>
                                <Text labelKey="administration.users.list.header.name" />
                            </GridTable.Data>
                            <GridTable.Data
                                columnStart={selectedButtonId === "users" ? 3 : 2}
                                alignX="flex-start"
                                size="7"
                                inHeader>
                                <Text labelKey="administration.users.list.header.identification" />
                            </GridTable.Data>
                            <GridTable.Data
                                columnStart={selectedButtonId === "users" ? 4 : 3}
                                alignX="flex-start"
                                size="7"
                                inHeader>
                                <Text
                                    labelKey={
                                        selectedButtonId === "users"
                                            ? "administration.users.list.header.signature"
                                            : "administration.users.list.header.email"
                                    }
                                />
                            </GridTable.Data>
                            <GridTable.Data
                                columnStart={selectedButtonId === "users" ? 5 : 4}
                                alignX={selectedButtonId === "users" ? "center" : "flex-start"}
                                size="7"
                                inHeader>
                                <Text labelKey="administration.users.list.header.status" />
                            </GridTable.Data>
                        </GridTable.Container>
                    </GridTable.Header>
                    <GridTable.Body className={classNames("min-height-10rem", { "pb-4": totalPages === currentPage })}>
                        {itemId === "administratorUser" && allAdminUsers?.length > 0 && (
                            <UsersFilter
                                handleSearch={(filters, hasFilterApplied) =>
                                    handleFilterData(itemId, filters, hasFilterApplied)
                                }
                            />
                        )}

                        {itemId === "secondaryUser" && allSecondaryUsers?.length > 0 && (
                            <UsersFilter
                                handleSearch={(filters, hasFilterApplied) =>
                                    handleFilterData(itemId, filters, hasFilterApplied)
                                }
                            />
                        )}
                        {/* {(!users || users?.length === 0) && (
                            <Box display="flex" alignX="center" alignY="center" flex1>
                                <Text labelKey="administration.users.list.empty" />
                            </Box>
                        )} */}
                        {itemId === "administratorUser" && (
                            <>
                                {/* {adminUsers.map((user) => this.renderUser(user, itemId))}
                                {this.renderLoadMore()} */}

                                {this.renderTableUsers(adminUsers, itemId)}
                            </>
                        )}
                        {itemId === "secondaryUser" && <>{this.renderTableUsers(secondaryUsers, itemId)}</>}
                        {selectedButtonId !== "users" && (
                            <>
                                {/* TODO (pendingUsers) */}
                                {users.map((user) => this.renderUser(user, itemId))} {this.renderLoadMore()}
                            </>
                        )}
                    </GridTable.Body>
                </GridTable>
                {/* NO BORRAR (IMPLEMENTACION DE PRODUCTO) */}
                {/* <Table
                    columns={columns}
                    data={this.populateUsersData(massiveDissabledQuantity)}
                    rowKey={(record) => record.key}
                    emptyText={i18n.get("administration.users.list.noRecords")}
                    onRow={(record) => ({
                        onClick: () => this.onRowClick(record),
                    })}
                    footer={totalPages > 1 ? this.renderTableFooter : null}
                /> */}
            </Box>
        );
    };

    renderItem = (item) => (
        <Box
            key={item.id}
            iconLabel={item.icon}
            isDesktop={isDesktop}
            keyLabel={item.label ? item.label : `administration.users.list.${item.id}.label`}>
            {this.renderTable(item.id)}
        </Box>
    );

    render() {
        const { fetching } = this.props;
        const { fetchingAction, selectedIndex } = this.state;

        return (
            <>
                <Notification scopeToShow="administrationUsers" />
                {this.renderPageHeader()}
                <MainContainer
                    className="main-container border-radius-lg p-7 mt-3 box-shadow-small background-white texture-header"
                    showLoader={fetching || fetchingAction}>
                    <Box>
                        <UsersTableHead />
                        <Box>
                            <UsersTabs
                                onSelect={(index) => this.handleShowAll(items[index]?.id)}
                                selectedIndex={selectedIndex}>
                                {items.map((item) => this.renderItem(item))}
                            </UsersTabs>
                        </Box>
                    </Box>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    users: selectors.getUsers(state),
    usersInfo: selectors.getUsersInfo(state),
    currentPage: selectors.getCurrentPage(state),
    totalPages: selectors.getTotalPages(state),
    fetching: selectors.isFetching(state),
    fetchingMoreUsers: selectors.isFetchingMoreUsers(state),
    hasMoreData: selectors.getHasMoreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    selectedIndex: selectors.getSelectedIndex(state),
    // loggedUser: sessionSelectors.getUser(state),
});

AdministrationUsers.propTypes = {
    dispatch: func.isRequired,
    activeEnvironment: shape({ administrationScheme: string }).isRequired,
    users: arrayOf(shape({ fullName: string, email: string, lastLoginAsMediumString: string })).isRequired,
    usersInfo: objectOf(
        shape({
            massiveEnabled: bool.isRequired,
            status: string.isRequired,
            signatureLevel: string,
            hasSignature: bool,
        }),
    ).isRequired,
    currentPage: number,
    totalPages: number,
    fetching: bool,
    fetchingMoreUsers: bool,
    hasMoreData: bool,
};

AdministrationUsers.defaultProps = {
    currentPage: 0,
    totalPages: 0,
    fetching: false,
    fetchingMoreUsers: false,
    hasMoreData: false,
};

export default connect(mapStateToProps)(AdministrationUsers);
