import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { goBack } from "react-router-redux";
import { Formik, Form, Field } from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import { maskDocumentNumber } from "util/mask";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import Text from "pages/_components/Text/Text";
import Image from "pages/_components/Image";
import Notification from "pages/_components/Notification";
import Selector from "pages/_components/fields/formik/Selector";
import MainContainer from "pages/_components/MainContainer";
import { bool, func, shape, string } from "prop-types";
import { Collapse } from "react-bootstrap";
import TextField from "pages/_components/fields/TextField";
import NoResults from "pages/_components/NoResultsMessage";
import Button from "pages/_components/Button";
import Row from "pages/_components/Row";
import Col from "react-bootstrap/lib/Col";
import { actions as formActions } from "reducers/form";
import * as i18n from "util/i18n";
import { actions as bankReferenceActions, selectors as bankReferenceSelectors } from "reducers/bankReference";
import { validationDocumentNumberEcu } from "../../util/validationSchemaUtil";
import DetailThirdPartiesReference from "./DetailThirdPartiesReference";

const FORM_ID = "bankReferences.third.parties.filter";

const ThirdPartiesBankReferences = (props) => {
    const [open, setOpen] = useState(true);
    const [afterSearch, setAfterSearch] = useState(false);
    const [valuesSubmit, setValuesSubmit] = useState(null);

    const { isDesktop, dispatch, fetching, fetchingDownload, data, documentNumberClient } = props;

    const typeAccountOptions = [
        {
            value: "CTE",
            label: "Corriente",
        },
        {
            value: "AHO",
            label: "Ahorro",
        },
    ];

    useEffect(() => {
        dispatch(formActions.preCustomForm(["thirdparties.references"]));
    }, []);

    useEffect(() => {
        dispatch(bankReferenceActions.resetFilters());
    }, []);

    const onBack = () => {
        dispatch(goBack());
    };

    const renderFilterDesktop = () => {
        const validateFields = ({ values }) => {
            if (!valuesSubmit) {
                return !values.documentNumber || !values.typeAccount || !values.numberAccount || afterSearch;
            }

            return (
                !values.documentNumber ||
                !values.typeAccount ||
                !values.numberAccount ||
                afterSearch ||
                JSON.stringify(values) === JSON.stringify(valuesSubmit)
            );
        };

        const validationSchema = Yup.object().shape({
            documentNumber: validationDocumentNumberEcu(FORM_ID),
            typeAccount: Yup.string().required(i18n.get(`${FORM_ID}.typeAccount.required`)),
            numberAccount: Yup.string().required(i18n.get(`${FORM_ID}.numberAccount.required`)),
        });

        const handleSubmit = (values) => {
            setAfterSearch(true);
            setValuesSubmit(values);
            dispatch(
                bankReferenceActions.readBankReference(values.typeAccount, values.documentNumber, values.numberAccount),
            );
        };

        return (
            <Formik
                enableReinitialize
                initialValues={{
                    documentNumber: "",
                    typeAccount: "",
                    numberAccount: "",
                }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}>
                {(formik) => (
                    <Form>
                        <Row gapX="7">
                            <Col lg={3}>
                                <Field
                                    name="documentNumber"
                                    idForm={FORM_ID}
                                    component={TextField}
                                    labelClassName="text-bold"
                                    pattern="^[0-9]*$"
                                    onKeyUp={(e) => {
                                        e.preventDefault();
                                        setAfterSearch(false);
                                    }}
                                    maxLength={13}
                                    spellCheck="false"
                                />
                            </Col>
                            <Col lg={3} style={{ "align-self": "end" }}>
                                <Field
                                    component={Selector}
                                    idForm={FORM_ID}
                                    name="typeAccount"
                                    options={typeAccountOptions}
                                    renderAs="combo"
                                    radioClassNames="radio-like-buttons"
                                    labelClassName="text-bold"
                                    handleChange={() => {
                                        setAfterSearch(false);
                                    }}
                                />
                            </Col>
                            <Col lg={3}>
                                <Field
                                    name="numberAccount"
                                    idForm={FORM_ID}
                                    component={TextField}
                                    labelClassName="text-bold"
                                    pattern="^[0-9]*$"
                                    maxLength={10}
                                    onKeyUp={(e) => {
                                        e.preventDefault();
                                        setAfterSearch(false);
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={3} lg={3} style={{ "align-self": "end" }}>
                                <Button
                                    label="global.search"
                                    bsStyle="primary"
                                    type="submit"
                                    btnUppercase={false}
                                    className={classNames("mt-auto full-width", {
                                        "px-9": isDesktop,
                                        "px-8": !isDesktop,
                                    })}
                                    disabled={validateFields(formik)}
                                    loading={fetching}
                                />
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        );
    };

    const renderEmptyList = () => (
        <Box className="m-7">
            <Image src="images/empty.svg" />
            <Box className="text-center">
                <Text labelKey="bankReferences.third.parties.none.text" />
            </Box>
        </Box>
    );

    return (
        <>
            <Notification scopeToShow="bankReference.thirdParties" />
            <Head
                onBack={onBack}
                titleText={i18n.get("bankReferences.third.parties.title")}
                textBack="bankReferences.label.returnToBankReferences"
                rightChildren={
                    <Box display="flex" gap="7">
                        <Button
                            image="images/download.svg"
                            bsStyle="primary"
                            className="btn-width-lg"
                            label="bankReferences.third.parties.button.downloadPDF"
                            onClick={() => {
                                dispatch(
                                    bankReferenceActions.downloadBankReference(
                                        data,
                                        maskDocumentNumber(documentNumberClient),
                                    ),
                                );
                            }}
                            loading={fetchingDownload}
                            disabled={!data}
                        />
                    </Box>
                }
            />
            <MainContainer className="main-container border-radius-lg background-white box-shadow-small texture-header mt-3">
                <Box background="white" className="mt-5 px-7 pt-2 pb-7">
                    <Collapse in={isDesktop ? true : open}>{renderFilterDesktop()}</Collapse>
                    <Box
                        component="article"
                        background="white"
                        borderRadius="default"
                        className={classNames("pt-5 mb-5")}
                        toBorderInMobile>
                        {data && (
                            <>
                                <DetailThirdPartiesReference
                                    bankReference={data}
                                    documentNumber={documentNumberClient}
                                />
                            </>
                        )}
                        {!isDesktop && (
                            <Box display="flex" alignX="between" alignY="center" className="pr-5 pl-5 mb-3">
                                {!data && (
                                    <>
                                        <Button
                                            label={
                                                open
                                                    ? "global.productFilters.btnFilter.opened.label"
                                                    : "transactions.button.showFilters"
                                            }
                                            bsStyle="link"
                                            image="images/icons/filter.svg"
                                            className="mt-auto"
                                            onClick={() => {
                                                setOpen(!open);
                                            }}
                                        />
                                    </>
                                )}
                            </Box>
                        )}
                        {!data &&
                            (!afterSearch || fetching ? (
                                renderEmptyList()
                            ) : (
                                <Box className="mt-10">
                                    <NoResults description="bankReferences.result.empty" />
                                </Box>
                            ))}
                    </Box>
                </Box>
            </MainContainer>
        </>
    );
};

ThirdPartiesBankReferences.defaultProps = {
    data: null,
    documentNumberClient: null,
};

ThirdPartiesBankReferences.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    fetching: bool.isRequired,
    fetchingDownload: bool.isRequired,
    documentNumberClient: string,
    data: shape({}),
};

const mapStateToProps = (state) => ({
    fetching: bankReferenceSelectors.getFetching(state),
    fetchingDownload: bankReferenceSelectors.getFetchingDownload(state),
    data: bankReferenceSelectors.getBankReferenceItem(state),
    documentNumberClient: bankReferenceSelectors.getDocumentNumber(state),
});

export default connect(mapStateToProps)(ThirdPartiesBankReferences);
