import { call, put, takeLatest } from "redux-saga/effects";

import * as i18n from "util/i18n";

import { types } from "reducers/bankReference";
import { actions as notificationActions } from "reducers/notification";
import { downloadPdf } from "util/download";

import * as bankReferenceMiddleware from "middleware/bankReference";

const sagas = [
    takeLatest(types.BANK_REFERENCES_PRE_REQUEST, bankReferenceRead),
    takeLatest(types.DOWNLOAD_BANK_REFERENCES_REQUEST, downloadBankReference),
];

export default sagas;

function* bankReferenceRead({ typeAccount, documentNumber, numberAccount }) {
    const response = yield call(bankReferenceMiddleware.readBankReference, typeAccount, documentNumber, numberAccount);

    if (response && response.status === 200) {
        yield put({
            type: types.BANK_REFERENCES_PRE_SUCCESS,
            bankReferenceItem: response.data.data ? response.data.data.bankReference : response.data,
            documentNumber,
        });
        if(response.data.data && response.data.data.bankReference==null){
            yield put(
                notificationActions.showNotification(i18n.get("global.emptyData", "No existen datos"), "error", [
                    "bankReference.thirdParties",
                ]),
            );
        }        
    } else {
        yield put({ type: types.BANK_REFERENCES_PRE_FAILURE });
    }
}

function* downloadBankReference({ bankReference, documentNumberClient }) {
    const response = yield call(bankReferenceMiddleware.downloadBankReference, bankReference, documentNumberClient);
    if (response.type === "W") {
        yield put({ type: types.DOWNLOAD_BANK_REFERENCES_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "bankReference.thirdParties",
            ]),
        );
    } else {
        yield put({ type: types.DOWNLOAD_BANK_REFERENCES_SUCCESS });

        const { content, fileName } = response.data.data;

        downloadPdf(fileName, content);
    }
}
