import * as config from "util/config";
import * as i18n from "util/i18n";

export const getServiceCategoriesList = () => {
    const list = config.get("payments.services.defaultCategories");
    return list === null ? [] : JSON.parse(list);
};

export const getCategorySelected = (id) => getServiceCategoriesList().filter((el) => el?.idServicePayment === id);

// Filtro valores para enviar
export const filerValues = (values, field, billerId) => {
    const filterVal = {};
    let billInqRq = [];
    const pmtUtilAddRq = [];

    field.forEach((element) => {
        Object.keys(values).forEach((key) => {
            if (key === element.fieldId) {
                filterVal[element.serviceField] = values[key];
                if (element?.serviceOper === "BillInqRq") {
                    // caso monto TotalPmt
                    if (element?.serviceField === "TotalPmt") {
                        let valFormat = "0.00";
                        if (typeof values[key] === "number") {
                            valFormat = values[key].toFixed(2);
                        }

                        billInqRq.push({ label: "BillSummAmtCode", value: "TotalPmt" });
                        billInqRq.push({ label: "Amt", value: valFormat });
                        billInqRq.push({ label: "CurCode", value: "USD" });
                    } else {
                        billInqRq.push({ label: element?.serviceField, value: `${values[key]}` });
                    }
                }
                if (element?.serviceOper === "PmtUtilAddRq") {
                    pmtUtilAddRq.push({ label: element?.serviceField, value: `${values[key]}` });
                }
            }
        });
    });

    // caso recargas ensa idBiller 2-22-1
    if (billerId === "2-22-1") {
        billInqRq = billInqRq.filter((el) => el.label !== "IssuedIdentType");
    }

    filterVal.billInqRq = billInqRq;
    filterVal.pmtUtilAddRq = pmtUtilAddRq;
    return filterVal;
};

export const getFieldBalance = (values, field, biller) => {
    const val = filerValues(values, field, biller.billerId);

    return {
        paymentUser: {
            alias: values.alias,
            idCategory: biller.category,
            accountNumber: biller.accountNumber,
            idBiller: biller.billerId,
            nameBiller: biller.name,
            favorite: false,
            extraData: biller,
            idBill: val?.BillId || val?.BillRef, // caso: campo BillId sin datos
            billInqRq: val?.billInqRq,
            pmtUtilAddRq: val?.pmtUtilAddRq,
        },
        biller,
        savePayment: values.saveService,
        isFastRecharge: values.isFastRecharge,
    };
};

export const inputFieldValues = (field, query) => {
    const filterVal = [];
    const values = query?.paymentUser?.billInqRq
        ? [...query?.paymentUser?.billInqRq, ...query?.paymentUser?.pmtUtilAddRq]
        : [];
    field.forEach((element) => {
        if (values.length > 0) {
            values.forEach((el) => {
                if (el?.label === element.serviceField) {
                    filterVal.push({ ...element, value: el?.value });
                }
            });
        }
    });

    if (query.paymentUser.alias !== "") {
        filterVal.push({
            fieldCalc: null,
            fieldId: "CampoAlias",
            fieldInq: false,
            fieldLabel: "Alias",
            fieldType: "String",
            listValues: [],
            restriction: {
                isReadOnly: true,
                maxLength: null,
                minLength: null,
                maxValue: null,
                minValue: null,
            },
            serviceField: "Alias",
            serviceOper: "Alias",
            value: query.paymentUser.alias,
        });
    }
    return filterVal;
};
export const ouputFieldValues = (field, billBalance, isPayment = false) => {
    let filterVal = [];
    field.forEach((element) => {
        if (element.serviceField === "PartyName" && !!billBalance?.partyName) {
            filterVal.push({ ...element, value: billBalance.partyName });
        }
        if (element.serviceField === "PartyId" && !!billBalance?.partyId) {
            filterVal.push({ ...element, value: billBalance.partyId });
        }
        if (element.serviceField === "IssuedIdentType" && !!billBalance?.issuedIdentType) {
            filterVal.push({ ...element, value: billBalance.issuedIdentType });
        }
        if (element.serviceField === "Type" && !!billBalance?.procedureType) {
            filterVal.push({ ...element, value: billBalance.procedureType });
        }
        if (element.serviceField === "Value" && !!billBalance?.procedureValue) {
            filterVal.push({ ...element, value: billBalance.procedureValue });
        }
        if ((element.serviceField === "OpenDt" || element.serviceField === "DueDt") && !!billBalance?.openDt) {
            filterVal.push({ ...element, value: billBalance.openDt });
        }
    });

    if (isPayment) {
        field.forEach((elem) => {
            if (elem.fieldId === "PartyName" && !!billBalance?.partyName) {
                filterVal = [...filterVal.filter((el) => el.serviceField !== elem.serviceField)];
                filterVal.push({ ...elem, value: billBalance.partyName });
            }
            if (elem.fieldId === "PartyId" && !!billBalance?.partyId) {
                filterVal = [...filterVal.filter((el) => el.serviceField !== elem.serviceField)];
                filterVal.push({ ...elem, value: billBalance.partyId });
            }
            if (elem.fieldId === "IssuedIdentType" && !!billBalance?.issuedIdentType) {
                filterVal = [...filterVal.filter((el) => el.serviceField !== elem.serviceField)];
                filterVal.push({ ...elem, value: billBalance.issuedIdentType });
            }
            if (elem.fieldId === "Type" && !!billBalance?.procedureType) {
                filterVal = [...filterVal.filter((el) => el.serviceField !== elem.serviceField)];
                filterVal.push({ ...elem, value: billBalance.procedureType });
            }
            if (elem.fieldId === "Value" && !!billBalance?.procedureValue) {
                filterVal = [...filterVal.filter((el) => el.serviceField !== elem.serviceField)];
                filterVal.push({ ...elem, value: billBalance.procedureValue });
            }
            if ((elem.fieldId === "BillId" || elem.fieldId === "BillRef") && !!billBalance?.billId) {
                filterVal = [...filterVal.filter((el) => el.serviceField !== elem.serviceField)];
                filterVal.push({ ...elem, value: billBalance.billId });
            }
        });
    }
    billBalance.listSummAmount.forEach((element) => {
        field.forEach((el) => {
            if (el.serviceField === element.code) {
                filterVal.push({ ...el, value: element.amount.toFixed(2) });
            }
        });
    });
    return filterVal;
};

export const fieldSubTotal = (field) => {
    // validacion dinamica isReadOnly en subTotal
    let isReadOnly = false;
    let label = i18n.get("servicePayment.dinamicForm.label.subTotal");
    const fieldSub = field.filter((el) => el.serviceField === "SubTotal");
    if (fieldSub.length > 0) {
        const { restriction, fieldLabel } = fieldSub[0];
        isReadOnly = !!restriction.isReadOnly;

        if (fieldLabel) {
            label = fieldLabel;
        }
    }
    return [isReadOnly, label];
};


export const getServiceCategoriesOptions = () => {
    let list = config.get("payments.services.defaultCategories");
    list = list === null ? [] : JSON.parse(list);

    const typeServiceOptions = list.map(({ idServicePayment }) => ({
        value: idServicePayment,
        //label: idServicePayment.replace(/_/g, " "),
        label:i18n.get(`servicePayment.category.${idServicePayment}`)
    }));

    return typeServiceOptions;
    //labelKey={`servicePayment.category.${elem?.idServicePayment}`}
};
