import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Box from "pages/_components/Box/Box";
import Button from "pages/_components/Button/Button";
import NoResults from "pages/_components/NoResultsMessage";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, number, shape } from "prop-types";
import { actions as documentsActions, selectors as documentsSelectors } from "reducers/documents";
import { selectors as accountsSelectors } from "reducers/accounts";
import Text from "pages/_components/Text/Text";
import * as i18n from "util/i18n";
import Image from "pages/_components/Image";
import FormattedDate from "pages/_components/FormattedDate";
import Loader from "pages/_components/Loader";
import classNames from "classnames";
import Dropdown from "pages/_components/Dropdown";
import PaginatedDataTable from "pages/_components/PaginatedDataTable";

const TableTaxDocuments = ({ dispatch, isDesktop, isFetching, documents, afterSearch, rowsPerPage, downloadState }) => {
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        if (documents) {
            setTotalCount(documents.length);
        }
    }, [documents]);

    const renderEmptyList = () => (
        <Box className="m-7">
            <Image src="images/empty.svg" />
            <Box className="text-center mt-6">
                <Text labelKey="checks.none.text" />
            </Box>
        </Box>
    );

    const onClickDonwload = (id, format) => {
        const data = {
            format,
            documentNumber: id,
        };
        dispatch(documentsActions.downloadTaxRequest(id, data));
    };

    const generateTableColumns = () => {
        const columnArray = [];

        columnArray.push({
            key: "fechaEmi",
            dataIndex: "fechaEmi",
            title: i18n.get("documents.tax.issueDate.label"),
            width: 90,
        });
        // }
        columnArray.push({
            key: "tipoDoc",
            dataIndex: "tipoDoc",
            title: i18n.get("documents.tax.typeDocument.label"),
            width: 90,
        });
        columnArray.push({
            key: "description",
            dataIndex: "description",
            title: i18n.get("documents.tax.description.label"),
            width: 120,
        });
        columnArray.push({
            key: "numeroDoc",
            dataIndex: "numeroDoc",
            title: i18n.get("documents.tax.documentNumber.label"),
            width: 90,
        });
        columnArray.push({
            key: "donwload",
            dataIndex: "donwload",
            title: "",
            width: "15%",
            align: "end",
        });

        return columnArray;
    };

    const setDescription = (tipoDoc) => {
        let description = "";
        switch (tipoDoc) {
            case "01":
                description = i18n.get("documents.tax.01");
                break;
            case "07":
                description = i18n.get("documents.tax.07");
                break;
            default:
                description = i18n.get("documents.tax.04");
        }
        return description;
    };

    const handleClickDownload = (numDoc, format) => {
        onClickDonwload(numDoc, format);
    };

    const documentsToDownload = [
        {
            label: "global.rideFile",
            onClick: (numDoc) => handleClickDownload(numDoc, "pdf"),
        },
        {
            label: "global.xmlFile",
            onClick: (numDoc) => handleClickDownload(numDoc, "xml"),
        },
    ];

    const docsData = (currentRows) =>
        currentRows.map((document) => ({
            key: document.numeroDoc,
            fechaEmi: (
                <div className="data-wrapper data-wrapper-flex">
                    <FormattedDate date={document.fechaEmi} anotherFormat="DD/MM/YYYY" />
                </div>
            ),
            tipoDoc: (
                <div className="data-wrapper data-wrapper-flex">
                    <span className="data-desc">{document.tipoDoc}</span>
                </div>
            ),
            description: (
                <div className="data-wrapper data-wrapper-flex">
                    <span className="data-desc">{setDescription(document.tipoDoc)}</span>
                </div>
            ),
            numeroDoc: (
                <div className="data-wrapper data-wrapper-flex">
                    <span className="data-desc">{document.numeroDoc}</span>
                </div>
            ),
            donwload: (
                <Box display="flex" fullWidth alignX="end">
                    <Dropdown
                        image="images/util/arrow-down.svg"
                        label="global.download"
                        className={classNames({ "display-flex content-center": !isDesktop })}
                        buttonClass={`btn btn-outline btn-download background-white ${!isDesktop && "p-0"}`}
                        fetching={downloadState[document.id]}
                        imageRight
                        pullRight>
                        {documentsToDownload.map((item) => (
                            <Button
                                key={item.label}
                                label={item.label}
                                // loading={downloadState[document.id]}
                                onClick={() => {
                                    item.onClick(document.id);
                                }}
                                className="dropdown__item-btn"
                                bsStyle="link"
                            />
                        ))}
                    </Dropdown>
                </Box>
            ),
        }));

    return (
        <>
            {isFetching ? (
                <Loader bgTransparent />
            ) : (
                <>
                    {totalCount > 0 && (
                        <>
                            <PaginatedDataTable
                                className="overflowNone no-col-group"
                                data={documents}
                                columns={generateTableColumns()}
                                populateRows={docsData}
                                rowsPerPage={rowsPerPage}
                            />
                        </>
                    )}
                </>
            )}
            {totalCount === 0 &&
                (!afterSearch ? (
                    renderEmptyList()
                ) : (
                    <Box className="mt-10">
                        {!isFetching && <NoResults description="documents.swift.search.error" />}
                    </Box>
                ))}
        </>
    );
};

TableTaxDocuments.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isFetching: bool.isRequired,
    documents: shape([]).isRequired,
    downloadState: shape({}).isRequired,
    rowsPerPage: number.isRequired,
    afterSearch: bool.isRequired,
};

const mapStateToProps = (state) => ({
    isFetching: documentsSelectors.getFetching(state),
    documents: documentsSelectors.getTaxDocuments(state),
    accounts: accountsSelectors.getAccounts(state),
    downloadState: documentsSelectors.downloadState(state),
});

export default connect(mapStateToProps)(resizableRoute(TableTaxDocuments));
