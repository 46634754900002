import { selectors as sessionSelectors } from "reducers/session";
import { connect } from "react-redux";
import Box from "pages/_components/Box/Box";
import Button from "pages/_components/Button/Button";
import { resizableRoute } from "pages/_components/Resizable";
import { arrayOf, bool, func, number, shape } from "prop-types";
import { actions as documentsActions, selectors as documentsSelectors } from "reducers/documents";
import { TYPE_FILTER } from "constants.js";
import * as configUtil from "util/config";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import Row from "pages/_components/Row";
import DateField from "pages/_components/fields/DateField";
import Text from "pages/_components/Text/Text";
import moment from "moment";
import * as i18n from "util/i18n";
import classNames from "classnames";
import Tooltip from "pages/_components/Tooltip";
import FieldError from "pages/_components/fields/FieldError";
import TableDocuments from "../TableDocuments";
import Filters from "../Filters";

const TYPE_OPERATION = "CCI";
const ImportLettersCredit = ({ dispatch, isDesktop, isFetching, documents, totalRecords, pageNumber }) => {
    const rowsPerPage = configUtil.getInteger("documents.rowsPerPage") || 10;
    const maxRangeDays = configUtil.getInteger("documents.range.maxdays");
    const minDateRange = configUtil.getInteger("documents.range.months.min");
    const [selectedTypeFilter, setSelectedTypeFilter] = useState(TYPE_FILTER.DATE);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [errorPeriod, setErrorPeriod] = useState(false);
    const [errorRange, setErrorRange] = useState(false);
    const [afterSearch, setAfterSearch] = useState(false);
    const [filters, setFilters] = useState({});

    const [showTooltip, setShowTooltip] = useState("");

    useEffect(() => {
        dispatch(documentsActions.clean());
        setSelectedTypeFilter(TYPE_FILTER.DATE);
    }, []);

    const isValidForm = () => {
        if (selectedTypeFilter === TYPE_FILTER.DATE) {
            return !!startDate && !!endDate && endDate.isSameOrAfter(startDate) && !errorRange;
        }
        return false;
    };

    const handleClickFilter = () => {
        setShowTooltip("");
        let validForm;

        if (selectedTypeFilter === TYPE_FILTER.DATE) {
            validForm = !errorPeriod && !errorRange;
        } else {
            validForm = false;
        }
        if (validForm) {
            setAfterSearch(true);
            const filtersDoc = {
                typeOperation: TYPE_OPERATION,
                startDate,
                endDate,
                idAccount: "",
                beneficary: "",
                // nextIndex: 0,
            };
            setFilters(filtersDoc);
            dispatch(documentsActions.listDocumentsRequest(filtersDoc, 0));
        }
    };

    const handleStartDate = (date) => {
        if (endDate) {
            if (date.isAfter(endDate)) {
                setErrorPeriod(true);
            } else {
                setErrorPeriod(false);
            }
            if (endDate.diff(date, "days") > maxRangeDays) {
                setErrorRange(true);
            } else {
                setErrorRange(false);
            }
        }
        setStartDate(date);
    };

    const handleEndDate = (date) => {
        if (startDate) {
            if (startDate.isAfter(date)) {
                setErrorPeriod(true);
            } else {
                setErrorPeriod(false);
            }
            if (date.diff(startDate, "days") > maxRangeDays) {
                setErrorRange(true);
            } else {
                setErrorRange(false);
            }
        }
        setEndDate(date);
    };

    const renderErrors = () => (
        <>
            {errorRange && (
                <Box className="pt-2 pb-5 size-6 form-errors-color">
                    {errorRange && <FieldError error={i18n.get("documents.swift.filter.period.msg.error.range")} />}
                </Box>
            )}
        </>
    );

    const renderFilterDate = () => (
        <>
            <Col xs={12} md={3}>
                <Text
                    component="label"
                    htmlFor="react-select-accountBy-input"
                    labelKey="checkbooks.filter.dateFrom.label"
                    className="data-label"
                    size="3"
                    bold
                />
                <Tooltip
                    buttonId="tooltipDate"
                    image="images/information.svg"
                    text="documents.swift.info.date.tooltip"
                    position="top-left"
                    className="swift-widget-tooltip"
                    showTooltip={showTooltip === "tooltipDate"}
                    handleClick={() => {
                        setShowTooltip("tooltipDate");
                    }}
                    onHover={isDesktop}
                />
                <DateField
                    idForm="checks.filters"
                    name="startDate"
                    field={{ name: "startDate" }}
                    placeholderText="dd/mm/aaaa"
                    hideLabel
                    labelNoMarginTop
                    handleChange={handleStartDate}
                    minDate={moment()
                        .add(-minDateRange, "months")
                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })}
                    maxDate={endDate || moment()}
                    popperPlacement="bottom"
                    valueSelectedDate={startDate}
                    popperModifiers={{
                        flip: {
                            behavior: ["bottom"],
                        },
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    className={errorRange ? "error-date-field" : "custom-date-field"}
                    onFocus={(e) => e.target.blur()}
                    inputMode="none"
                />
            </Col>
            <Col xs={12} md={3} className="mt-5">
                <Text
                    component="label"
                    htmlFor="react-select-accountBy-input"
                    labelKey="checkbooks.filter.dateTo.label"
                    className="data-label"
                    size="3"
                    bold
                />
                <DateField
                    idForm="checks.filters"
                    name="endDate"
                    field={{ name: "endDate" }}
                    placeholderText="dd/mm/aaaa"
                    hideLabel
                    labelNoMarginTop
                    handleChange={handleEndDate}
                    minDate={startDate || moment().add(-minDateRange, "months")}
                    maxDate={moment()}
                    popperPlacement="bottom"
                    valueSelectedDate={endDate}
                    popperModifiers={{
                        flip: {
                            behavior: ["bottom"],
                        },
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    className={errorRange ? "error-date-field" : "custom-date-field"}
                    onFocus={(e) => e.target.blur()}
                />
            </Col>
            {!isDesktop && <>{renderErrors()}</>}
        </>
    );

    const renderFilterDesktop = () => (
        <Col xs={12} md={9}>
            <Box className="mt-5">
                <Row alignY="flex-end" gapX={7} gapY={3}>
                    {selectedTypeFilter === TYPE_FILTER.DATE && renderFilterDate()}
                    <Col xs={12} md={3}>
                        <Button
                            label="global.search"
                            bsStyle="primary"
                            className={classNames("mt-5 full-width", { "px-9": isDesktop, "px-8": !isDesktop })}
                            disabled={!isValidForm()}
                            loading={isFetching}
                            onClick={handleClickFilter}
                        />
                    </Col>
                </Row>
                {isDesktop && <>{renderErrors()}</>}
            </Box>
        </Col>
    );

    const getResultCounter = () => {
        let rows = 0;
        if (pageNumber === 1) {
            rows = documents[pageNumber]?.length || 0;
        }
        if (pageNumber > 1) {
            rows = (pageNumber - 1) * rowsPerPage + documents[pageNumber]?.length;
        }
        return <Text labelKey="global.pagination.results" color="text" size="5" rows={rows} totalRows={totalRecords} />;
    };

    return (
        <>
            <Box background="white" className={classNames("mt-5 pt-2 pb-7")}>
                <Filters isDesktop={isDesktop} resultCounter={getResultCounter} renderFilters={renderFilterDesktop} />
                <Box
                    component="article"
                    background="white"
                    borderRadius="default"
                    className={classNames("mb-5", {
                        "pt-5 ": isDesktop,
                        "px-5 pb-5": !isDesktop,
                    })}
                    toBorderInMobile>
                    <TableDocuments
                        typeOperation={TYPE_OPERATION}
                        afterSearch={afterSearch}
                        filters={filters}
                        rowsPerPage={rowsPerPage}
                        showTooltip={showTooltip}
                        setShowTooltip={setShowTooltip}
                    />
                </Box>
            </Box>
        </>
    );
};

ImportLettersCredit.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isFetching: bool.isRequired,
    documents: arrayOf(shape({})).isRequired,
    // accounts: arrayOf(shape({})).isRequired,
    // offset: number.isRequired,
    totalRecords: number.isRequired,
    pageNumber: number.isRequired,
};

const mapStateToProps = (state) => ({
    isFetching: documentsSelectors.getFetching(state),
    documents: documentsSelectors.getDocuments(state),
    // offset: documentsSelectors.getOffset(state),
    // totalPages: documentsSelectors.getTotalPages(state),
    totalRecords: documentsSelectors.getTotalRecords(state),
    pageNumber: documentsSelectors.getPageNumber(state),
    loggedUser: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps)(resizableRoute(ImportLettersCredit));
