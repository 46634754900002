/* eslint-disable*/
import { call, put, takeEvery, select } from "redux-saga/effects";
import { replace } from "react-router-redux";
import * as payService from "middleware/payService";
import { actions, types, selectors } from "reducers/payService";
import { actions as notificationActions } from "reducers/notification";
import { actions as transactionsActions } from "reducers/transactions";
import * as config from "util/config";
import * as i18n from "util/i18n";
import { capitalizeFirstLetter } from "util/string";
import { isMobileNativeFunc } from "util/device";

const sagas = [
    takeEvery(types.LIST_PAYSERVICE_REQUEST, listPayServiceRequest),
    takeEvery(types.FAVORITE_REQUEST, setFavoritePayService),
    takeEvery(types.UPDATE_REQUEST, updatePayService),
    takeEvery(types.CREATE_REQUEST, createPayService),
    takeEvery(types.GET_REQUEST, getPayServiceRequest),
    takeEvery(types.DELETE_REQUEST, deletePayService),
    takeEvery(types.LIST_CATEGORIES_REQUEST, listCategoriesRequest),
    takeEvery(types.LIST_RULES_BILLER_REQUEST, listRulesBillerRequest),
    takeEvery(types.CHECK_DEBT_REQUEST, checkDebtRequest),
    takeEvery(types.NEW_PAY_SERVICE, newPayService),
];

export default sagas;

function* listPayServiceRequest({ category }) {
    try {
        const response = yield call(payService.listPayService, category);
        if (response && response.status === 200) {
            const { enrolledServiceList: list } = response.data.data;
            yield put(actions.listPayServiceSuccess(list));
        }
    } catch (e) {
        yield put(actions.listPayServiceFailure());
    }
}

function* setFavoritePayService({ payServiceObj }) {
    const response = yield call(
        payService.setFavoritePayService,
        payServiceObj?.idPaymentUser,
        payServiceObj?.favorite,
    );
    if (response && response.status === 200) {
        const paymentUserList = yield select(selectors.getListPayService);
        paymentUserList.map((obj) => {
            obj.idPaymentUser === payServiceObj.idPaymentUser && (obj.favorite = !payServiceObj?.favorite);
        });

        yield put(actions.favoriteSuccess(paymentUserList));
    }
}

function* updatePayService({ payServiceObj }) {
    payServiceObj.billInqRq = JSON.stringify(payServiceObj.billInqRq);
    payServiceObj.pmtUtilAddRq = JSON.stringify(payServiceObj.pmtUtilAddRq);
    const response = yield call(payService.updatePayService, payServiceObj);
    if (response && response.status === 200) {
        yield put(replace("/servicePayments"));
        const message = payServiceObj?.fastRecharge
            ? "servicePayments.fast.recharge.modify.success"
            : "servicePayment.modify.success";
        yield put(notificationActions.showNotification(i18n.get(message), "success", ["servicePayments"]));
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["servicePayments"]),
        );
    }
}

function* createPayService({ payServiceObj }) {
    payServiceObj.billInqRq = JSON.stringify(payServiceObj.billInqRq);
    payServiceObj.pmtUtilAddRq = JSON.stringify(payServiceObj.pmtUtilAddRq);
    const response = yield call(payService.createPayService, payServiceObj);
    if (response && response.status !== 200) {
        yield put(
            notificationActions.showNotification(i18n.get("servicePayments.error.save.payment"), "error", ["form"]),
        );
    }
}

function* getPayServiceRequest({ idPaymentUser }) {
    const response = yield call(payService.getPayService, idPaymentUser);
    if (response && response.status === 200) {
        const { paymentUser } = response.data.data;
        if (paymentUser) {
            const list = config.get("payments.services.defaultCategories");
            const serviceCategoriesList = list === null ? [] : JSON.parse(list);
            //union con detalle de categorías por idCategory
            const category = serviceCategoriesList.filter((el) => el.idServicePayment === paymentUser?.idCategory);
            paymentUser.category = category.length > 0 ? category[0] : {};
            paymentUser.extraData = JSON.parse(paymentUser?.extraData);
            paymentUser.billInqRq = JSON.parse(paymentUser?.billInqRq);
            paymentUser.pmtUtilAddRq = JSON.parse(paymentUser?.pmtUtilAddRq);
            yield put(actions.getPayServiceSuccess(paymentUser));
        } else {
            yield put(replace("/servicePayments"));
        }
    }
}

function* deletePayService({ service }) {
    const response = yield call(payService.deletePayService, service);
    if (response && response.status === 200) {
        const { enrolledServiceList: list } = response.data.data;
        yield put(actions.deleteSuccess(list));
        yield put(transactionsActions.showNotificationMessage());
        yield put(transactionsActions.setHiddenBackButton(true));
        yield put(
            transactionsActions.setActionSecondaryByPath(
                "global.goToHome",
                null,
                "/desktop",
                isMobileNativeFunc(),
            ),
        );
        yield put(replace(`/transaction/${response.data.idTransaction}`));
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["servicePayments"]),
        );
    }
}

function* listCategoriesRequest({ categoryId }) {
    const response = yield call(payService.listCategories, categoryId);
    if (response && response.status === 200) {
        const { billers } = response.data.data;
        if (billers) {
            const tmp_billers = billers.map((el) => {
                return { ...el, name: capitalizeFirstLetter(el?.name ? el?.name.toLowerCase() : "") };
            });
            yield put(actions.listCategoriesSuccess(tmp_billers));
        } else {
            yield put(replace("/addNewServiceCategory"));
        }
    }
}

function* listRulesBillerRequest({ idBiller }) {
    const response = yield call(payService.listRulesBiller, idBiller);
    if (response && response.status === 200) {
        const { field } = response.data.data;
        if (field) {
            yield put(actions.listRulesBillerSuccess(field));
        } else {
            yield put(replace("/addNewServiceCategory"));
        }
    }
}

function* checkDebtRequest({ serviceId, onFinish, isMassive = true }) {
    try {
        const list = yield select(selectors.getListPayService);
        const payServiceToCheck = list.find((serv) => serv.id === serviceId);

        const {
            category,
            agreementId,
            companyId,
            identificationValue,
            identificationType,
            extendedData,
        } = payServiceToCheck;
        let additionalData = null;
        if (extendedData) {
            const extendedDataJson = JSON.parse(extendedData);
            additionalData = extendedDataJson?.additionalData;
        }

        // const categoryId = "LUZ";
        // const agreement = "1127";
        // const company = "1111";
        // const identifier = "0900210680";
        // const identifierType = "codigo_asociado";
        const response = yield call(
            payService.checkDebt,
            category,
            agreementId,
            companyId,
            identificationValue,
            identificationType,
            additionalData,
        );

        if (!response) {
            // no se obtuvo respuesta
            yield put(actions.checkDebtFailure());
            onFinish(serviceId);
            yield put(
                notificationActions.showNotification(
                    i18n.get("servicePayments.registeredServices.list.item.unexpected.error"),
                    "warning",
                    ["servicePayments"],
                ),
            );
        } else {
            const { type } = response;
            const { code, message } = response.data;
            if (type === "W") {
                yield put(actions.checkDebtFailure());
                // onFinish(serviceId);
                finishCheckDebt({ serviceId, service: payServiceToCheck, isMassive, onFinish });

                if (code === "API801W") {
                    yield put(notificationActions.showNotification(message, "warning", ["servicePayments"]));
                }

                if (code === "API802W") {
                    yield put(actions.checkDebtFailureNoDebt(serviceId));
                    yield put(
                        notificationActions.showNotification(
                            i18n.get("servicePayments.registeredServices.list.item.noDebt"),
                            "warning",
                            ["servicePayments"],
                        ),
                    );
                }
            } else {
                const {
                    data: { debt },
                } = response?.data;
                yield put(actions.checkDebtSuccess(serviceId, debt));

                if (debt?.paymentMethodBill === "UNICO_CRONOLOGICO") {
                    finishCheckDebt({ serviceId, service: payServiceToCheck, isMassive: false, debt, onFinish });
                } else {
                    finishCheckDebt({ serviceId, service: payServiceToCheck, isMassive, debt, onFinish });
                }
            }
        }
    } catch (e) {
        yield put(actions.checkDebtFailure());
        finishCheckDebt({ serviceId, service: payServiceToCheck, isMassive, onFinish });
        yield put(
            notificationActions.showNotification(
                i18n.get("servicePayments.registeredServices.list.item.unexpected.error"),
                "warning",
                ["servicePayments"],
            ),
        );
    }
}

function* newPayService({ category, identification, onFinish }) {
    try {
        const response = yield call(payService.listPayService, category);
        let payServiceToCheck = undefined;
        if (response && response.status === 200) {
            const { enrolledServiceList: list } = response.data.data;
            yield put(actions.listPayServiceSuccess(list));

            // yield put(replace(`/servicePayments/registered/${category}`));

            payServiceToCheck = list.find((serv) => serv.identificationValue === identification);
        }
        
        if (payServiceToCheck) {
            yield put(actions.checkDebtRequest(payServiceToCheck.id, onFinish, false));
        }
        
    } catch (e) {
        yield put(replace(`/servicePayments/registered/${category}`));
    }
}

const finishCheckDebt = ({ serviceId, service, isMassive, debt, onFinish }) => {
    const data = {
        serviceId,
        isMassive,
        debt,
        service,
        // amount: debt?.total,
    };
    onFinish(data);
};
