import React from "react";
import classNames from "classnames";
import { bool, shape, string } from "prop-types";

import Box from "pages/_components/Box";
import Info from "pages/_components/Info";
import Container from "pages/_components/Container";
import { maskDocumentNumber } from "util/mask";
import AlertInformation from "pages/_components/AlertInformation";

import * as i18n from "util/i18n";

const FORM_ID = "bankReferences.third.parties.detail";

const DetailThirdPartiesReference = (props) => {
    const { isDesktop, documentNumber, bankReference, data } = props;
    const {
        nameAccount,
        typeAccount,
        numberAccount,
        dateStart,
        statusAccount,
        balanceLetters,
        numberProtests,
        justifiedNumberProtests,
        messageCobro,
    } = bankReference;

    return (
        <Container gridClassName="container-fluid">
            <Box
                fullWidth
                display="flex"
                alignX="between"
                alignY="center"
                className="box-shadow-small border-radius-lg mb-4 mt-5 ">
                <Box
                    fullWidth
                    display="flex"
                    borderRadius="lg"
                    column
                    className={classNames("box-shadow-small ", {
                        "p-7": isDesktop,
                        "px-7 pt-1 pb-7 pt-5": !isDesktop,
                    })}
                    fullHeight>
                    <Box className="mb-4 mt-2">
                        <AlertInformation
                            withIcon
                            background="info"
                            text={messageCobro}
                            textClassName="align-justify"
                        />
                    </Box>
                    <Box fullWidth display="flex" alignX="between">
                        <Box
                            className={classNames({
                                "Box-template-1fr gap-0": !isDesktop,
                            })}>
                            <Box>
                                <Box flex className="flex-column py-2">
                                    <Info
                                        labelKey={`${FORM_ID}.documentNumber`}
                                        text={maskDocumentNumber(documentNumber)}
                                        classNameValue="size-6"
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Box flex className="flex-column py-2">
                                    <Info labelKey={`${FORM_ID}.dateStart`} text={dateStart} classNameValue="size-6" />
                                </Box>
                            </Box>
                            <Box>
                                <Box flex className="flex-column py-2">
                                    <Info
                                        labelKey={`${FORM_ID}.numberProtests`}
                                        text={numberProtests}
                                        classNameValue="size-6"
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            className={classNames({
                                "Box-template-1fr gap-0": !isDesktop,
                            })}>
                            <Box className="Box-3">
                                <Box flex className="flex-column py-2">
                                    <Info
                                        labelKey={`${FORM_ID}.clientName`}
                                        text={nameAccount}
                                        classNameValue="size-6"
                                    />
                                </Box>
                            </Box>
                            <Box className="Box-3">
                                <Box flex className="flex-column py-2">
                                    <Info
                                        labelKey={`${FORM_ID}.statusAccount`}
                                        text={i18n.get(`bankReferences.third.parties.account.status.${statusAccount}`)}
                                        classNameValue="size-6"
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            className={classNames({
                                "Box-template-1fr gap-0": !isDesktop,
                            })}>
                            <Box className="Box-3">
                                <Box flex className="flex-column py-2">
                                    <Info
                                        labelKey={`${FORM_ID}.typeAccount`}
                                        text={typeAccount}
                                        classNameValue="size-6"
                                    />
                                </Box>
                                <Box flex className="flex-column py-2">
                                    <Info
                                        labelKey={`${FORM_ID}.balanceNumber`}
                                        text={balanceLetters}
                                        classNameValue="size-6"
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Box flex className="flex-column py-2">
                                <Info
                                    labelKey={`${FORM_ID}.numberAccount`}
                                    text={numberAccount}
                                    classNameValue="size-6"
                                />
                            </Box>
                            <Box flex className="flex-column py-2">
                                <Info
                                    labelKey={`${FORM_ID}.justifiedNumberProtests`}
                                    text={justifiedNumberProtests}
                                    classNameValue="size-6"
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};
DetailThirdPartiesReference.propTypes = {
    isDesktop: bool.isRequired,
    documentNumber: string.isRequired,
    bankReference: shape({}).isRequired,
};

export default DetailThirdPartiesReference;
