import { createReducer, makeActionCreator } from "util/redux";
import globalTypes from "reducers/types/global";

export const types = {
    CLEAN_DATA: "documents/CLEAN_DATA",

    LIST_REQUEST: "documents/LIST_REQUEST",
    LIST_FAILURE: "documents/LIST_FAILURE",
    LIST_SUCCESS: "documents/LIST_SUCCESS",
    TAX_LIST_REQUEST: "documents/TAX_LIST_REQUEST",
    TAX_LIST_FAILURE: "documents/TAX_LIST_FAILURE",
    TAX_LIST_SUCCESS: "documents/TAX_LIST_SUCCESS",
    TAX_LOAD_MORE_REQUEST: "documents/TAX_LOAD_MORE_REQUEST",
    OPERATIONAL_LIST_REQUEST: "documents/OPERATIONAL_LIST_REQUEST",
    OPERATIONAL_LIST_FAILURE: "documents/OPERATIONAL_LIST_FAILURE",
    OPERATIONAL_LIST_SUCCESS: "documents/OPERATIONAL_LIST_SUCCESS",

    LOAD_MORE_REQUEST: "documents/LOAD_MORE_REQUEST",
    SET_PAGE_NUMBER_CACHE: "documents/SET_PAGE_NUMBER_CACHE",

    DOWNLOAD_REQUEST: "documents/DOWNLOAD_REQUEST",
    DOWNLOAD_FAILURE: "documents/DOWNLOAD_FAILURE",
    DOWNLOAD_SUCCESS: "documents/DOWNLOAD_SUCCESS",
    TAX_DOWNLOAD_REQUEST: "documents/TAX_DOWNLOAD_REQUEST",
    TAX_DOWNLOAD_FAILURE: "documents/TAX_DOWNLOAD_FAILURE",
    TAX_DOWNLOAD_SUCCESS: "documents/TAX_DOWNLOAD_SUCCESS",

    OPERATIONAL_DOWNLOAD_REQUEST: "documents/OPERATIONAL_DOWNLOAD_REQUEST",
    OPERATIONAL_DOWNLOAD_SUCCESS: "documents/OPERATIONAL_DOWNLOAD_SUCCESS",
    OPERATIONAL_DOWNLOAD_FAILURE: "documents/OPERATIONAL_DOWNLOAD_FAILURE",
};

export const INITIAL_STATE = {
    documents: {},
    taxDocuments: [],
    operationalDocuments: [],
    downloadState: {},
    // letterList: [],
    // transfersList: [],
    // standByList: [],
    pageNumber: 0,
    totalPages: 0,
    totalRecords: 0,
    fetching: false,
};

export default createReducer(INITIAL_STATE, {
    [globalTypes.CLEAN_UP]: () => ({
        ...INITIAL_STATE,
    }),
    [types.CLEAN_DATA]: () => ({
        ...INITIAL_STATE,
    }),
    [types.LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        pageNumber: 0,
        totalPages: 0,
        totalRecords: 0,
        documents: {},
    }),
    [types.LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        documents: {},
    }),
    [types.LIST_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        documents: {
            ...state.documents,
            [action.pageNumber]: action.documents,
        },
        pageNumber: action.pageNumber,
        totalRecords: action.totalRecords,
        offset: action.offset,
        totalPages: action.totalPages,
    }),
    [types.TAX_LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        pageNumber: 1,
        documents: {},
        // downloadState: {},
    }),
    [types.TAX_LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        documents: {},
    }),
    [types.TAX_LIST_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        taxDocuments: action.documents,
        pageNumber: action.pageNumber,
        totalRecords: action.totalRecords,
        offset: action.offset,
        totalPages: action.totalPages,
    }),
    [types.TAX_LOAD_MORE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.OPERATIONAL_LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        operationalDocuments: [],
    }),
    [types.OPERATIONAL_LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        operationalDocuments: [],
    }),
    [types.OPERATIONAL_LIST_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        operationalDocuments: action.documents,
    }),
    [types.LOAD_MORE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.SET_PAGE_NUMBER_CACHE]: (state, action) => ({
        ...state,
        fetching: false,
        pageNumber: action.pageNumber,
    }),
    [types.DOWNLOAD_REQUEST]: (state, action) => ({
        ...state,
        downloadState: {
            ...state.downloadState,
            [action.idRecord]: true,
        },
    }),
    [types.DOWNLOAD_SUCCESS]: (state, action) => ({
        ...state,
        downloadState: {
            ...state.downloadState,
            [action.idRecord]: false,
        },
    }),
    [types.DOWNLOAD_FAILURE]: (state, action) => ({
        ...state,
        downloadState: {
            ...state.downloadState,
            [action.idRecord]: false,
        },
    }),
    [types.TAX_DOWNLOAD_REQUEST]: (state, action) => ({
        ...state,
        downloadState: {
            ...state.downloadState,
            [action.idRecord]: true,
        },
    }),
    [types.TAX_DOWNLOAD_SUCCESS]: (state, action) => ({
        ...state,
        downloadState: {
            ...state.downloadState,
            [action.idRecord]: false,
        },
    }),
    [types.TAX_DOWNLOAD_FAILURE]: (state, action) => ({
        ...state,
        downloadState: {
            ...state.downloadState,
            [action.idRecord]: false,
        },
    }),
    [types.OPERATIONAL_DOWNLOAD_REQUEST]: (state, action) => ({
        ...state,
        downloadState: {
            ...state.downloadState,
            [action.idRecord]: true,
        },
    }),
    [types.OPERATIONAL_DOWNLOAD_SUCCESS]: (state, action) => ({
        ...state,
        downloadState: {
            ...state.downloadState,
            [action.idRecord]: false,
        },
    }),
    [types.OPERATIONAL_DOWNLOAD_FAILURE]: (state, action) => ({
        ...state,
        downloadState: {
            ...state.downloadState,
            [action.idRecord]: false,
        },
    }),
});

export const actions = {
    clean: makeActionCreator(types.CLEAN_DATA),
    listDocumentsRequest: makeActionCreator(types.LIST_REQUEST, "filters", "next"),
    listDocumentsFailure: makeActionCreator(types.LIST_FAILURE),
    listDocumentsSuccess: makeActionCreator(
        types.LIST_SUCCESS,
        "documents",
        "totalRecords",
        "offset",
        "pageNumber",
        "totalPages",
    ),
    listTaxDocumentsRequest: makeActionCreator(types.TAX_LIST_REQUEST, "filters", "next"),
    listTaxDocumentsFailure: makeActionCreator(types.TAX_LIST_FAILURE),
    listTaxDocumentsSuccess: makeActionCreator(
        types.TAX_LIST_SUCCESS,
        "documents",
        "totalRecords",
        "offset",
        "pageNumber",
        "totalPages",
    ),
    loadMoreTaxDocumentsRequest: makeActionCreator(types.TAX_LOAD_MORE_REQUEST, "filters", "next"),

    operationalDocumentsRequest: makeActionCreator(types.OPERATIONAL_LIST_REQUEST, "filters"),
    operationalDocumentsFailure: makeActionCreator(types.OPERATIONAL_LIST_FAILURE),
    operationalDocumentsSuccess: makeActionCreator(types.OPERATIONAL_LIST_SUCCESS, "documents"),

    loadMoreDocumentsRequest: makeActionCreator(types.LOAD_MORE_REQUEST, "filters", "next"),
    setPageNumber: makeActionCreator(types.SET_PAGE_NUMBER_CACHE, "pageNumber"),
    downloadRequest: makeActionCreator(types.DOWNLOAD_REQUEST, "idRecord", "data", "onFinish"),
    downloadSuccess: makeActionCreator(types.DOWNLOAD_SUCCESS, "idRecord"),
    downloadFailure: makeActionCreator(types.DOWNLOAD_FAILURE, "idRecord"),
    downloadTaxRequest: makeActionCreator(types.TAX_DOWNLOAD_REQUEST, "idRecord", "data"),
    downloadTaxSuccess: makeActionCreator(types.TAX_DOWNLOAD_SUCCESS, "idRecord"),
    downloadTaxFailure: makeActionCreator(types.TAX_DOWNLOAD_FAILURE, "idRecord"),
    downloadOperationalRequest: makeActionCreator(types.OPERATIONAL_DOWNLOAD_REQUEST, "idRecord", "onFinish"),
    downloadOperationalSuccess: makeActionCreator(types.OPERATIONAL_DOWNLOAD_SUCCESS, "idRecord", "data"),
    downloadOperationalFailure: makeActionCreator(types.OPERATIONAL_DOWNLOAD_FAILURE, "idRecord", "data"),
};

export const selectors = {
    getFetching: ({ docs }) => docs.fetching,
    getDocuments: ({ docs }) => docs.documents,
    getOffset: ({ docs }) => docs.offset,
    getTotalPages: ({ docs }) => docs.totalPages,
    getTotalRecords: ({ docs }) => docs.totalRecords,
    getPageNumber: ({ docs }) => docs.pageNumber,
    downloadState: ({ docs }) => docs.downloadState,
    getTaxDocuments: ({ docs }) => docs.taxDocuments,
    getOperationalDocuments: ({ docs }) => docs.operationalDocuments,
};
