import React from "react";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import FieldError from "pages/_components/fields/FieldError";

export const renderDataDate = (labelKey, data, size = "6") => (
    <>
        <Box className="mt-2 mb-3 size-6 color-text-grey">
            <Text color="text-grey" size={size} labelKey={labelKey} />
        </Box>
        <Box className="text-bold size-6">{data ? <FormattedDate date={data} anotherFormat="DD/MM/YYYY" /> : "-"}</Box>
    </>
);

export const fieldInputError = (error) =>
    error ? (
        <Box className="mt-2">
            <FieldError error={error} />
        </Box>
    ) : null;

export const cleanToAlphanumeric = (value) => value.replace(/[^a-zA-Z0-9 ]/g, '');
