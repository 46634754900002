import * as API from "middleware/api";

export const preData = () => API.executeAnonymous("/enrollment.wizard.pre");

export const validateDocument = (documentCountry, documentType, documentNumber) =>
    API.executeAnonymous("/enrollment.wizard.validateDocument", {
        documentCountry,
        documentType,
        documentNumber,
    });

export const generateValidationSMS = (documentNumber, documentType, contactMail, contactTelephone) =>
    API.executeAnonymous("/enrollment.wizard.generateVerificationCode", {
        documentNumber,
        documentType,
        contactMail,
        contactTelephone,
        channel: "frontend",
        environmentType: "retail",
    });

export const validateInvitationCode = (_code, _captcha) =>
    API.executeAnonymous("/enrollment.wizard.validateInvitationCode", { _code, _captcha });

export const finishCreate = (
    _newUsername,
    _newPassword,
    _newPasswordRepeat,
    questionAndAnswers,
    _securitySeal,
    _exchangeToken,
) =>
    API.executeWithExchangeToken(
        "/enrollment.wizard.finishCreate",
        { _newUsername, _newPassword, _newPasswordRepeat, questionAndAnswers, _securitySeal },
        _exchangeToken,
    );
export const validateUser = (_usernameToValidate, _password, _captcha, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/enrollment.wizard.validateUser",
        { _usernameToValidate, _password, _captcha },
        _exchangeToken,
    );

export const finishAssociate = (_exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.wizard.finishAssociate", {}, _exchangeToken);

export const validateTokenActivationSelfie = (selfie, callbackType, callbackData, selfieNonTokenized, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/enrollment.token.activation.validate.selfie",
        { _selfie: selfie, callbackType, callbackData, _selfieNonTokenized: selfieNonTokenized },
        _exchangeToken,
    );

export const validateTokenActivationDocument = (
    documentSelected,
    documentScannedList,
    ocrData,
    callbackType,
    callbackData,
    _exchangeToken,
) =>
    API.executeWithExchangeToken(
        "/enrollment.token.activation.validate.document",
        {
            documentSelected,
            _documentScannedList: documentScannedList,
            ocrData,
            callbackType,
            callbackData,
        },
        _exchangeToken,
    );

/**
 * AUTH Services
 */
export const validateTokenActivationSelfieAuth = (selfie, callbackType, callbackData, selfieNonTokenized) =>
    API.executeWithAccessToken("/enrollment.token.activation.validate.selfie.auth", {
        _selfie: selfie,
        callbackType,
        callbackData,
        _selfieNonTokenized: selfieNonTokenized,
    });

export const validateTokenActivationDocumentAuth = (
    documentSelected,
    documentScannedList,
    ocrData,
    callbackType,
    callbackData,
) =>
    API.executeWithAccessToken("/enrollment.token.activation.validate.document.auth", {
        documentSelected,
        _documentScannedList: documentScannedList,
        ocrData,
        callbackType,
        callbackData,
    });

export const validateVerificationCode = (_exchangeToken, _code, userInfo) =>
    API.executeWithExchangeToken(
        "/enrollment.wizard.validateVerificationCode",
        {
            _secondFactor: _code,
            documentNumber: userInfo.documentNumber,
            documentType: userInfo.documentType,
            contactMail: userInfo.email,
            contactTelephone: userInfo.mobileNumber,
            clientName: `${userInfo.firstName} ${userInfo.lastName}`,
        },
        _exchangeToken,
    );

export const validateExistUsername = (_usernameToValidate, _code, _exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.wizard.validateUsername", { _usernameToValidate, _code }, _exchangeToken);

export const getQuestions = (userId, documentType) =>
    API.executeAnonymous("/enrollment.wizard.getQuestions", {
        userId,
        documentType,
    });
export const validateUserCredentials = (_usernameToValidate, _password, credentialType, _code, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/enrollment.wizard.validateUser",
        { _usernameToValidate, _newPassword: _password, credentialType, _code },
        _exchangeToken,
    );
