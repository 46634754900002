import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import TextField from "pages/_components/fields/TextField";
import Checkbox from "pages/_components/fields/formik/Checkbox";
import { bool, func, string } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { actions as softTokenActions, selectors as softTokenSelector } from "reducers/softToken";
import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";
import { actions as momentDayActions } from "reducers/momentDay";
import { isAndroidPlatform, isMobileNativeFunc } from "util/device";
import * as i18n from "util/i18n";

import Image from "pages/_components/Image";
import Tooltip from "pages/_components/Tooltip";
import { push } from "react-router-redux";
import { registerTextFieldRevelock, registerUsernameFieldRevelock } from "util/revelockMobile/revelockMobile.util";
import * as Yup from "yup";
import { maskUsername } from "util/mask";
import Head from "pages/_components/Head";
import Loader from "pages/_components/Loader";

const FORM_ID = "login.step1";

const Step1Content = (props) => {
    const {
        username: usernameSaved,
        isDesktop,
        savedPinCode,
        dispatch,
        deviceHasFingerPrinter,
        updateDeviceHasFingerPrinter,
        fetching,
        fingerprintFetching,
        isMobileNative,
    } = props;

    // const [isSubmittingForm, setIsSubmittingForm] = useState(false);

    const usernameRef = useRef(null);

    const setCredentialsRevelock = () => {
        if (!isMobileNativeFunc()) {
            return;
        }

        const usernameInput = usernameRef.current;
        if (usernameInput) {
            registerUsernameFieldRevelock(usernameInput);
            registerTextFieldRevelock(usernameInput);
        }
    };

    useEffect(() => {
        setCredentialsRevelock();
    }, []);

    useEffect(() => {
        const hour = new Date().getHours();
        const defineMomentDay =
            (hour > 6 && hour < 12 && "morning") || (hour >= 12 && hour < 18 && "afternoon") || "night";
        dispatch(momentDayActions.setMomentDay(defineMomentDay));
        if (isMobileNativeFunc()) {
            dispatch(fingerprintActions.configurationDeviceHasFingerPrinterRequest());
        }
    }, []);

    // useEffect(() => {
    //     if (deviceHasFingerPrinter) {
    //         dispatch(loginActions.fingerprintLoginPre());
    //     }
    // }, [deviceHasFingerPrinter, updateDeviceHasFingerPrinter]);

    const onChangeRemember = (e, setFieldValue) => {
        const newValue = !(e?.target?.value === "true");
        setFieldValue("rememberEmail", newValue);
        if (!newValue && usernameSaved) {
            dispatch(loginActions.removeRememberedUser());
            setFieldValue("username", "");
        }
    };

    const scrollToRef = (ref) => () => {
        if (ref && ref.current) {
            if (isAndroidPlatform()) {
                setTimeout(() => {
                    ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
                }, 150);
            } else {
                ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    };

    useEffect(() => {
        const scrollToUsername = scrollToRef(usernameRef);
        window.addEventListener("keyboardDidShow", scrollToUsername);

        return () => {
            window.removeEventListener("keyboardDidShow", scrollToUsername);
        };
    }, []);

    useEffect(() => {
        dispatch(softTokenActions.getDevicePin());
    }, []);

    const biometricLogin = (e, values) => {
        if (!fingerprintFetching && !fetching) {
            e.preventDefault();
            e.stopPropagation();
            dispatch(loginActions.biometricLoginRequest(values?.rememberEmail));
        }
        // dispatch(loginActions.fromBiometricMigrationRequest(false));
    };

    const generateSoftToken = (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log("PIN: ", savedPinCode);
        if (savedPinCode) {
            dispatch(push("/softTokenPin"));
        } else {
            dispatch(push("/softToken"));
        }
    };

    const getBiometricLabel = () => (isAndroidPlatform() ? "login.use.fingerprint" : "login.use.faceid");
    const getBiometricIcon = () => (isAndroidPlatform() ? "images/fingerprint-login.svg" : "images/faceid-login.svg");

    const renderDesktop = ({ values, isSubmitting, setFieldValue }) => (
        <>
            <Box display="flex" fullWidth className="login-title-container">
                <Box className="login-logo">
                    <Image src="images/logos/logo_sat.svg" />
                </Box>
            </Box>
            <Text
                color="text-secondary"
                className="mb-6 text-center"
                // labelKey=""
                size="6"
                defaultValue={i18n.get(
                    "login.step1.slogan.label",
                    "Acceda y experimente lo nuevo de banca para empresas",
                )}
            />
            <Box display="flex" column className="mt-4 username-saved">
                <Field
                    hideLabel={isDesktop}
                    idForm={FORM_ID}
                    name="username"
                    component={TextField}
                    autoFocus={isDesktop}
                    placeholder={i18n.get("", "Ingrese su usuario")}
                    noMarginBottom
                    className="mb-4"
                    idField="usernamefield"
                    labelText={i18n.get("", "Usuario")}
                    labelClassName="text-bold"
                    spellCheck="false"
                    disabled={usernameSaved}
                    autoComplete="off"
                />
                <Box display="flex" alignX="between" fullWidth alignY="center">
                    <Box>
                        <Field
                            idForm={FORM_ID}
                            name="rememberEmail"
                            component={Checkbox}
                            checked={values.rememberEmail}
                            block={false}
                            onChange={(e) => onChangeRemember(e, setFieldValue)}
                            noMarginBottom
                            label={i18n.get("", "Recordar usuario en este dispositivo")}
                        />
                    </Box>
                </Box>
            </Box>
            <Button
                className="mt-8"
                type="submit"
                bsStyle="primary"
                // label="global.continue"
                defaultLabelText={i18n.get("", "Continuar")}
                btnUppercase={false}
                loading={isSubmitting}
                disabled={values?.username?.length < 1}
                block
            />
            <Box display="flex" fullWidth>
                <Text
                    color="text-secondary"
                    defaultValue={i18n.get("", "¿Tiene una invitación? ingrese&nbsp;&nbsp;")}
                    className="mt-8 mb-8"
                    size="6"
                />
                <Button
                    href="/enrollment"
                    primaryLink
                    bsStyle="link"
                    // label="login.footer.forgotUser"
                    defaultLabelText={i18n.get("", "aquí")}
                    className="px-0"
                />
                <Tooltip
                    image="images/information.svg"
                    text="login.invitationCode.tooltip"
                    position="top-right"
                    className="align-self-center height-fit-content"
                />
            </Box>
            <Box display="flex" fullWidth gapX="3">
                <Button
                    href="/recoveryUser/step1"
                    primaryLink
                    bsStyle="box-icon"
                    image="images/iconTitles/boton_recuperar_usuario.svg"
                    // label="login.footer.forgotUser"
                    defaultLabelText={i18n.get("", "Recuperar usuario")}
                />
                <Button
                    href="/unlockUser/step1"
                    primaryLink
                    bsStyle="box-icon"
                    image="images/iconTitles/boton_desbloquear_usuario.svg"
                    // label="login.footer.forgotUser"
                    defaultLabelText={i18n.get("", "Desbloquear usuario")}
                />
                <Button
                    href="/recoveryPassword/step1"
                    primaryLink
                    bsStyle="box-icon"
                    image="images/iconTitles/boton_recuperar_contrasenia.svg"
                    // label="login.footer.forgotUser"
                    defaultLabelText={i18n.get("", "Recuperar contraseña")}
                />
            </Box>
        </>
    );

    const renderMobile = ({ values, isSubmitting, setFieldValue }) => (
        <>
            {(fetching || fingerprintFetching) && <Loader fixed bgTransparent/>}
            <Box display="flex" fullWidth className="login-title-container content-center">
                <Box className="login-logo">
                    <Image src="images/logos/logo_sat.svg" />
                </Box>
            </Box>
            <Text
                className="mb-6 align-center"
                color="text-secondary"
                size="5"
                defaultValue={i18n.get(
                    "login.step1.slogan.label",
                    "Acceda y experimente lo nuevo de banca para empresas",
                )}
            />
            <Box display="flex" column className="mt-4 username-saved">
                <Field
                    hideLabel={isDesktop}
                    idForm={FORM_ID}
                    name="username"
                    component={TextField}
                    autoFocus={isDesktop}
                    placeholder={i18n.get("", "Ingrese su usuario")}
                    noMarginBottom
                    className="mb-4"
                    idField="usernamefield"
                    labelText={i18n.get("", "Usuario")}
                    labelClassName="text-bold"
                    spellCheck="false"
                    disabled={usernameSaved}
                    autoComplete="off"
                />
                <Box display="flex" alignX="between" fullWidth alignY="center">
                    <Box>
                        <Field
                            idForm={FORM_ID}
                            name="rememberEmail"
                            component={Checkbox}
                            checked={values.rememberEmail}
                            block={false}
                            onChange={(e) => onChangeRemember(e, setFieldValue)}
                            noMarginBottom
                            label={i18n.get("", "Recordar usuario en este dispositivo")}
                        />
                    </Box>
                </Box>
            </Box>
            <Button
                className="mt-8"
                type="submit"
                bsStyle="primary"
                // label="global.continue"
                defaultLabelText={i18n.get("global.continue", "Continuar")}
                btnUppercase={false}
                loading={isSubmitting}
                disabled={values?.username?.length < 1}
                block
            />

            {deviceHasFingerPrinter && (!fetching && !fingerprintFetching) && (
                <Button
                    className="mt-8"
                    type="button"
                    bsStyle="outline"
                    label={getBiometricLabel()}
                    image={getBiometricIcon()}
                    onClick={(e) => biometricLogin(e, values)}
                    loading={fingerprintFetching}
                    disabled={fingerprintFetching}
                    btnUppercase={false}
                    block
                />
            )}

            {/* <Button
                className="mt-8"
                type="button"
                bsStyle="outline"
                label="softToken.generate"
                onClick={generateSoftToken}
                btnUppercase={false}
                block
            /> */}

            {/* <Box display="flex" alignX="center" fullWidth>
                <Text
                    defaultValue={i18n.get("", "¿Tiene una invitación? ingrese&nbsp;")}
                    className="mt-8 mb-8"
                    size="5"
                />
                <Button
                    href="/enrollment"
                    primaryLink
                    bsStyle="link"
                    // label="login.footer.forgotUser"
                    defaultLabelText={i18n.get("", "aquí")}
                    className="px-0 font-1rem"
                />
                <Tooltip
                    image="images/information.svg"
                    text="login.invitationCode.tooltip"
                    position="top-right"
                    className="align-self-center height-fit-content"
                />
            </Box> */}
            <Box display="flex" className="mt-5" fullWidth gapX="3">
                <Button
                    href="/recoverUser/step1"
                    primaryLink
                    bsStyle="box-icon"
                    image="images/iconTitles/boton_recuperar_usuario.svg"
                    // label="login.footer.forgotUser"
                    defaultLabelText={i18n.get("", "Recuperar usuario")}
                />
                <Button
                    href="/unlockUser/step1"
                    primaryLink
                    bsStyle="box-icon"
                    image="images/iconTitles/boton_desbloquear_usuario.svg"
                    defaultLabelText={i18n.get("", "Desbloquear usuario")}
                />
                <Button
                    href="/recoveryPassword/step1"
                    primaryLink
                    bsStyle="box-icon"
                    image="images/iconTitles/boton_recuperar_contrasenia.svg"
                    defaultLabelText={i18n.get("", "Recuperar contraseña")}
                />
            </Box>
        </>
    );

    const validationSchema = Yup.object().shape({
        username: Yup.string().required(i18n.get(`${FORM_ID}.username.required`)),
    });

    const handleSubmit = ({ username, rememberEmail }, formikBag) => {
        /* if (isSubmittingForm) {
            console.log("Step1Content.jsx isSubmittingForm: ", isSubmittingForm);
            return;
        } */
        let usernameLogin = username.trim();
        if (rememberEmail && usernameLogin === maskUsername(usernameSaved)) {
            usernameLogin = usernameSaved;
        }
        // setIsSubmittingForm(true);
        console.log("Step1Content.jsx dispatch loginActions.loginStep1Username");
        dispatch(
            loginActions.loginStep1Username(usernameLogin, rememberEmail, formikBag, () => {
                // setIsSubmittingForm(false);
                formikBag.setSubmitting(false);
            }),
        );
        dispatch(loginActions.fromBiometricMigrationRequest(false));
    };

    const handleBack = () => {
        // if(handleBackPage==='OK'){
        //     console.log("handleBack lobby");
        //     dispatch(routerActions.push("/lobby"));
        //     return;
        // }
        // console.log("handleBack web");
        // dispatch(routerActions.push("/"));
        // dispatch(notificationActions.removeNotification());
        if (!fetching) {
            dispatch(push("/"));
        }
    };

    return (
        <>
            {isMobileNative && (
                <Head isMobile={isMobileNative} title="global.login" position="fixed" onBack={handleBack} />
            )}
            <Formik
                initialValues={{ username: maskUsername(usernameSaved) || "", rememberEmail: !!usernameSaved }}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={false}
                validationSchema={validationSchema}>
                {(formik) => (
                    <Form
                        className={classNames({
                            "pointer-events-none": formik.isSubmitting,
                        })}
                        noValidate="novalidate">
                        <Box display="flex" column fullWidth>
                            {isDesktop ? renderDesktop(formik) : renderMobile(formik)}
                        </Box>
                    </Form>
                )}
            </Formik>
        </>
    );
};

const mapStateToProps = (state) => ({
    ...loginSelectors.getRememberedUser(state),
    savedPinCode: softTokenSelector.getDevicePin(state),
    deviceHasFingerPrinter: fingerprintSelectors.deviceHasFingerPrinter(state),
    updateDeviceHasFingerPrinter: fingerprintSelectors.updateDeviceHasFingerPrinter(state),
    fetching: loginSelectors.getFetching(state),
    fingerprintFetching: loginSelectors.getFingerprintFetching(state),
});

Step1Content.propTypes = {
    isDesktop: bool,
    username: string,
    savedPinCode: string,
    dispatch: func.isRequired,
    fetching: bool.isRequired,
    fingerprintFetching: bool,
    isMobileNative: bool.isRequired,
};

Step1Content.defaultProps = {
    username: "",
    isDesktop: false,
    savedPinCode: "",
    fingerprintFetching: false,
};

export default connect(mapStateToProps)(Step1Content);
