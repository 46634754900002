import { bool, func, oneOfType, shape } from "prop-types";
import React, { Component } from "react";
import I18n from "pages/_components/I18n";
// import AdditionalHeader from "./AdditionalHeader";
import Box from "pages/_components/Box";
import NoResults from "pages/_components/NoResultsMessage";
import Row from "pages/_components/Row";
import Col from "react-bootstrap/lib/Col";
import { isDesktop } from "react-device-detect";
import * as i18nUtils from "util/i18n";
import Link from "react-router-dom/Link";
import Info from "pages/_components/Info";
import classNames from "classnames";
import moment from "moment";
import Text from "pages/_components/Text";
import Movement from "./Movement";
import FormattedAmount from "pages/_components/FormattedAmount";
import GridTable from "pages/_components/GridTable/GridTable";

class CreditCardCorporateDetailPay extends Component {
    static propTypes = {
        consumptionDetails: oneOfType([shape({})]).isRequired,
        showProductIcon: bool,
        setFavoriteAccount: func,
        completeFavorites: bool,
    };

    static defaultProps = {
        showProductIcon: true,
        setFavoriteAccount: () => {},
        completeFavorites: false,
    };

    renderEmptyResults = () => (
        <Box className="my-9">
            <NoResults />
        </Box>
    );

    renderList = () => {
        const { consumptionDetails, isRequestAvailable } = this.props;
        if (!consumptionDetails?.length) {
            return null;
        }
        return (
            <>
                {consumptionDetails?.length === 0 && <>{this.renderEmptyResults()}</>}
                {consumptionDetails?.length > 0 && (
                    // children-bg-color
                    <Box display="flex" scrollable>
                    <GridTable>
                        <GridTable.Header tableHeaderBackground="layout-body-background">
                            <GridTable.Container
                                columnsTemplate="1fr 1fr 1fr 1fr 1fr 1fr 1fr"
                                className={isDesktop ? "gapX-9" : "gapX-3 min-height-9-i"}>
                                <GridTable.Data columnStart={1} alignX="flex-start" size="7" inHeader>
                                    <Text labelKey="creditCard.corporate.movement.detail.creditNote.table.consumptionId" />
                                </GridTable.Data>
                                <GridTable.Data columnStart={2} alignX="flex-end" size="7" inHeader>
                                    <Text labelKey="creditCard.corporate.movement.detail.creditNote.table.consumptionValue" />
                                </GridTable.Data>
                                <GridTable.Data columnStart={3} alignX="flex-end" size="7" inHeader>
                                    <Text labelKey="creditCard.corporate.movement.detail.creditNote.table.appliedValue" />
                                </GridTable.Data>
                                <GridTable.Data columnStart={4} alignX="flex-end" size="7" inHeader>
                                    <Text labelKey="creditCard.corporate.movement.detail.creditNote.table.capital" />
                                </GridTable.Data>
                                <GridTable.Data columnStart={5} alignX="flex-end" size="7" inHeader>
                                    <Text labelKey="creditCard.corporate.movement.detail.creditNote.table.normalInterest" />
                                </GridTable.Data>
                                <GridTable.Data columnStart={6} alignX="flex-end" size="7" inHeader>
                                    <Text labelKey="creditCard.corporate.movement.detail.creditNote.table.arrearsInterest" />
                                </GridTable.Data>
                                <GridTable.Data columnStart={7} alignX="flex-end" size="7" inHeader>
                                    <Text labelKey="creditCard.corporate.movement.detail.creditNote.table.contributionSolca" />
                                </GridTable.Data>
                            </GridTable.Container>
                        </GridTable.Header>
                        <GridTable.Body>
                            {consumptionDetails?.map((consumption) => (
                                <GridTable.Container
                                    columnsTemplate="1fr 1fr 1fr 1fr 1fr 1fr 1fr"
                                    className={isDesktop ? "gapX-9" : "gapX-3 min-height-9-i"}>
                                    <GridTable.Data columnStart={1} alignX="flex-start">
                                        <Box fullWidth>
                                            <Text bold size={isDesktop ? "7" : "6"} className="mr-2">
                                                {consumption.consumptionId}
                                            </Text>
                                            <br />
                                            <Text size={isDesktop ? "7" : "6"} className="mr-2">
                                                {consumption.applicationDate}
                                            </Text>
                                        </Box>
                                    </GridTable.Data>
                                    <GridTable.Data columnStart={2} alignX="flex-end">
                                        <Text size={isDesktop ? "7" : "6"}>{consumption.consumptionValue}</Text>
                                    </GridTable.Data>
                                    <GridTable.Data columnStart={3} alignX="flex-end">
                                        <Text size={isDesktop ? "7" : "6"}>{consumption.appliedValue}</Text>
                                    </GridTable.Data>
                                    <GridTable.Data columnStart={4} alignX="flex-end">
                                        <Text size={isDesktop ? "7" : "6"}>{consumption.capital}</Text>
                                    </GridTable.Data>
                                    <GridTable.Data columnStart={5} alignX="flex-end">
                                        <Text size={isDesktop ? "7" : "6"}>{consumption.normalInterest}</Text>
                                    </GridTable.Data>
                                    <GridTable.Data columnStart={6} alignX="flex-end">
                                        <Text size={isDesktop ? "7" : "6"}>{consumption.arrearsInterest}</Text>
                                    </GridTable.Data>
                                    <GridTable.Data columnStart={7} alignX="flex-end">
                                        <Text size={isDesktop ? "7" : "6"}>{consumption.contributionSolca}</Text>
                                    </GridTable.Data>
                                </GridTable.Container>
                            ))}
                        </GridTable.Body>
                    </GridTable>
                </Box>
                )}
            </>
        );
    };

    render() {
        // return <>{this.renderList()}</>;
        return <Box className="mt-3">{this.renderList()}</Box>;
    }
}

export default CreditCardCorporateDetailPay;
