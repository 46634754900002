import classNames from "classnames";
// import A11yNav from "pages/_components/A11yNav";
import Box from "pages/_components/Box";
import ErrorBoundary from "pages/_components/ErrorBoundary";
import I18n from "pages/_components/I18n";
import NavigationBarDesktop from "pages/_components/NavigationBarDesktop";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import ResizableComponent from "pages/_components/ResizableComponent";
import TourMobile from "pages/_components/TourMobile";
import { dashboardTourStepsMobile } from "pages/_components/TourMobile/dashboardTourStepsMobile";
import Menu from "pages/_components/menu/Menu";
import { arrayOf, bool, func, number, oneOf, shape, string } from "prop-types";
import RevelockPositionProvider from "providers/RevelockPositionProvider";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import withRouter from "react-router-dom/withRouter";
import PageTransition from "react-router-page-transition";
import { actions as desktopActions, selectors as desktopSelectors } from "reducers/desktop";
import { selectors as tourSelectors } from "reducers/tour";
import { getTransition } from "util/transition";
import WithPermissionsRedirect from "../_components/WithPermissionsRedirect";
// const SCREEN_WIDTH_REFERENCE = 1024;
class DefaultLayout extends Component {
    state = {
        transition: "",
        styles: {},
    };

    componentDidMount() {
        const { dispatch } = this.props;
        if (window.innerWidth < 1232) {
            dispatch(desktopActions.changeStatusSidebarCollapsed(true));
        }
    }

    componentDidUpdate() {
        const { dispatch, toggleMenuBtnPress } = this.props;
        if (window.innerWidth < 1232 && !toggleMenuBtnPress) {
            dispatch(desktopActions.changeStatusSidebarCollapsed(true));
        }
    }

    handlerResizeEventSidebar = () => {
        const { dispatch } = this.props;
        const isSizeTablet = window.innerWidth < 1232;
        dispatch(desktopActions.changeStatusSidebarCollapsed(isSizeTablet));
    };

    /* eslint-disable-next-line react/sort-comp, camelcase */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { location } = this.props;

        if (location.pathname !== nextProps.location.pathname) {
            this.setState({
                transition:
                    (nextProps.location.state && nextProps.location.state.transition) ||
                    nextProps.transition ||
                    getTransition(this.props, nextProps),
            });
        }
    }

    getMobileLayout = (matchProps) => {
        const {
            // activeEnvironment,
            component: ReceivedComponent,
            isMobile,
            isMobileNative,
            isDesktop,
            aditionalClassName,
            componentProps,
            skipsCounter,
            isTourHidden,
        } = this.props;
        const extras = { isMobile, isDesktop, ...componentProps };
        const { transition, styles } = this.state;
        return (
            <Box display="flex" column position="relative" className="transition-item-wrapper" fullHeight fullWidth>
                <Box display="flex" column className={classNames(transition)} style={styles} fullHeight>
                    <PageTransition timeout={transition ? 600 : 0}>
                        <div className="transition-item full-height">
                            <Box
                                className={classNames("view-app background-gradient", aditionalClassName)}
                                background="menu-background">
                                <ErrorBoundary>
                                    {skipsCounter < 3 && !isTourHidden && isMobileNative ? (
                                        <TourMobile steps={dashboardTourStepsMobile} />
                                    ) : (
                                        <>
                                            <Notification scopeToShow="menu" />
                                            <ReceivedComponent {...matchProps} {...extras} />
                                        </>
                                    )}
                                </ErrorBoundary>
                            </Box>
                        </div>
                    </PageTransition>
                </Box>
            </Box>
        );
    };

    getDesktopLayout = (matchProps) => {
        const {
            component: ReceivedComponent,
            dispatch,
            isMobile,
            isDesktop,
            isTablet,
            isMobileNative,
            componentProps,
            isSidebarCollapsed,
            scrollable,
        } = this.props;
        const extras = { isMobile, isDesktop, isTablet, isMobileNative, ...componentProps };
        const buildNumber = window.BUILD_NUMBER ? `(Build ${window.BUILD_NUMBER})` : "";

        return (
            <ErrorBoundary>
                <ResizableComponent onResize={this.handlerResizeEventSidebar}>
                    <Box
                        className={classNames("app-default-container", {
                            "sidebar-collapsed": isSidebarCollapsed,
                        })}>
                        {/* {!isMobile && (
                            <A11yNav
                                background="component-background"
                                // TODO (LABEL a11y)
                                ariaLabel="Atajo en Navegación secundaria"
                                buttonList={[
                                    { label: "Ir a Sección principal", focusOnSection: "main" },
                                    { label: "Ir a Menu Principal", focusOnSection: "menu" },
                                ]}
                            />
                        )} */}

                        <Box component="header" className="app-default-header">
                            <NavigationBarDesktop />
                        </Box>

                        {/* TODO (LABEL a11y) */}
                        <Box
                            component="nav"
                            id="menu"
                            role="navigation"
                            // TODO (LABEL a11y)
                            ariaLabel="Menú principal"
                            onClick={() => {
                                dispatch(desktopActions.changeStatusSidebarCollapsed(false));
                            }}
                            className="app-default-sidebar">
                            <Menu isMobile={isMobile} />
                            <p className="app-version">
                                {/* global process */}
                                <I18n id="global.version" component="small" /> {process.env.REACT_APP_VERSION}{" "}
                                {buildNumber}
                            </p>
                        </Box>
                        <Box
                            component="main"
                            id="main"
                            role="main"
                            background="menu-background"
                            className={classNames("app-default-main", {
                                "main-full-height": !scrollable,
                            })}
                            {...(scrollable && { scrollable: true })}>
                            <Box component="section" {...(!scrollable && { fullHeight: true })}>
                                <ReceivedComponent {...matchProps} {...extras} />
                                {/* {!isMobile && (
                                    <A11yNav
                                        position="relative"
                                        ariaLabel="Atajo en Sección Principal"
                                        buttonList={[
                                            { label: "Ir a Menu Principal", focusOnSection: "menu" },
                                            {
                                                label: "Volver al inicio",
                                                focusOnSection: "main",
                                            },
                                        ]}
                                    />
                                )} */}
                            </Box>
                        </Box>
                    </Box>
                </ResizableComponent>
            </ErrorBoundary>
        );
    };

    render() {
        const { component: ReceivedComponent, somePermissions, permissions, comparator, showAlertMessage, ...rest } = this.props;

        return (
            <WithPermissionsRedirect
                somePermissions={somePermissions}
                permissions={permissions}
                comparator={comparator}
                showAlertMessage={showAlertMessage}>
                <RevelockPositionProvider {...this.props}>
                    <Route
                        {...rest}
                        render={(matchProps) => {
                            const { isMobile } = this.props;

                            if (isMobile) {
                                return <>{this.getMobileLayout(matchProps)}</>;
                            }

                            return <>{this.getDesktopLayout(matchProps)}</>;
                        }}
                    />
                </RevelockPositionProvider>
            </WithPermissionsRedirect>
        );
    }
}

DefaultLayout.propTypes = {
    aditionalClassName: string,
    dispatch: func.isRequired,
    isDesktop: bool,
    isMobile: bool,
    isTablet: bool,
    isMobileNative: bool,
    component: func.isRequired,
    componentProps: shape({}),
    transition: oneOf([
        "transition-drill-out",
        "transition-drill-in",
        "transition-flow-close",
        "transition-flow-open",
        "transition-change-feature",
        "",
    ]),
    location: shape({
        pathname: string.isRequired,
        state: shape({
            transition: oneOf([
                "noTransition",
                "transition-drill-out",
                "transition-drill-in",
                "transition-flow-close",
                "transition-flow-open",
                "transition-change-feature",
            ]),
        }),
    }).isRequired,
    withoutLayout: bool,
    somePermissions: bool,
    permissions: arrayOf(string),
    comparator: func,
    scrollable: bool,
    isSidebarCollapsed: bool.isRequired,
    skipsCounter: number,
    isTourHidden: bool,
    toggleMenuBtnPress: bool.isRequired,
    showAlertMessage: bool,
};

DefaultLayout.defaultProps = {
    aditionalClassName: null,
    componentProps: null,
    isDesktop: false,
    scrollable: true,
    isMobile: false,
    isTablet: false,
    isMobileNative: false,
    transition: "",
    withoutLayout: false,
    somePermissions: false,
    permissions: null,
    comparator: null,
    skipsCounter: 0,
    isTourHidden: false,
    showAlertMessage: false,
};

const mapStateToProps = (state) => ({
    isSidebarCollapsed: desktopSelectors.isSidebarCollapsed(state),
    toggleMenuBtnPress: desktopSelectors.toggleMenuBtnPress(state),
    skipsCounter: tourSelectors.getSkipsCounter(state),
    isTourHidden: tourSelectors.isHidden(state),
});

export default withRouter(connect(mapStateToProps)(resizableRoute(DefaultLayout)));
