import React, { Component } from "react";
import { connect } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import { bool, number, func } from "prop-types";
import classNames from "classnames";
import { history } from "store";
import { MAX_FAILED_TIMES } from "constants.js";
import MainContainer from "pages/_components/MainContainer";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as configSelectors } from "reducers/config";
import { actions as themeActions } from "reducers/theme";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import Routes from "routes/Routes";
import * as configUtils from "util/config";
import { resizableRoute } from "pages/_components/Resizable";
import { isIOSPlatform, isMobileNativeFunc, sendPauseEvent } from "util/device";
// import * as session from "middleware/session";
// import getAxiosObject from "middleware/axiosUtils";

// const apiAxios = getAxiosObject(window.API_URL);

class App extends Component {
    static propTypes = {
        dispatch: func,
        loading: bool,
        timesConfigFailed: number,
        timesI18nFailed: number,
        hasActiveSession: bool,
    };

    static defaultProps = {
        dispatch: null,
        loading: false,
        hasActiveSession: false,
        timesConfigFailed: 0,
        timesI18nFailed: 0,
    };

    componentDidMount() {
        window.isReloading = false;

        window.addEventListener("beforeunload", (event) => {
            if (event.currentTarget.performance.navigation.type === 1) {
                window.isReloading = true;
            }
        });

        document.addEventListener("display-changed", (e) => {
            if (
                (e.detail.display === "mobile" && e.detail.newDisplay === "tablet") ||
                (e.detail.display === "tablet" && e.detail.newDisplay === "mobile")
            ) {
                window.isReloading = true;
                window.location.reload();
            }
        });

        this.loadRevelockScript();
        window.addEventListener("beforeunload", this.handleBeforeUnload);
        document.addEventListener("pause", this.handlePause, false);
        document.addEventListener("resume", this.handleResume, false);
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
        document.removeEventListener("pause", this.handlePause);
        document.removeEventListener("resume", this.handleResume);
        // document.removeEventListener("deviceready", this.deviceready);
        window.isReloading = false;
    }

    handleBeforeUnload = (event) => {
        const { accessToken, hasActiveSession } = this.props;
        // if (window.isReloading) {
        //     return;
        // }
        // if (hasActiveSession) {
        //     dispatch(sessionActions.logout());
        // }
        if (hasActiveSession) {
            const date = new Date();
            const statusApp = { pause: date.toISOString() };

            console.log("  window.API_URL", window.API_URL);
            console.log("llamada api userData accessToken", accessToken);
            const data = { statusApp };
            const params = { channel: "phonegap", data };

            const url = `${window.API_URL}/settings.userData.update.send`;
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${accessToken}`,
                },
                body: JSON.stringify(params),
                keepalive: true,
            });
        }
    };

    handlePause = () => {
        const { accessToken, hasActiveSession } = this.props;
        if (accessToken && hasActiveSession) {
            const date = new Date();
            const statusApp = { pause: date.toISOString() };

            console.log("  window.API_URL", window.API_URL);
            console.log("llamada api userData accessToken", accessToken);
            const data = { statusApp };
            const params = { channel: "phonegap", data };
            // apiAxios.defaults.headers.common.Authorization = `bearer ${accessToken}`;
            // apiAxios.post("/settings.userData.update.send", {
            //     ...params,
            // });

            const url = `${window.API_URL}/settings.userData.update.send`;
            // const data = { statusAppTestA };
            if (isIOSPlatform()) {
                sendPauseEvent(url, JSON.stringify(params), accessToken);
            } else {
                fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `bearer ${accessToken}`,
                    },
                    body: JSON.stringify(params),
                    keepalive: true,
                });
            }

            // navigator.sendBeacon(window.DAS_URL+window.API_URL+"/settings.userData.update.send", data);
            // session.updateUserData({ statusApp });
        }
    };

    handleResume = () => {
        const { dispatch, hasActiveSession } = this.props;
        if (hasActiveSession) {
            dispatch(sessionActions.setStateApp("resume"));
        }
    };

    componentDidUpdate() {
        const { dispatch } = this.props;
        const urlCorporate = configUtils.get("corporate.baseUrl");
        const isCorporate = window.location.href?.includes(urlCorporate);

        dispatch(sessionActions.isActiveCorporate(isCorporate));
        dispatch(themeActions.changeLoadTheme(isCorporate ? "corporate" : "retail"));
    }

    loadRevelockScript = () => {
        if (isMobileNativeFunc()) {
            return;
        }

        const script = document.createElement("script");
        script.id = "revelock-post-login";
        script.src = "scripts/revelock/loader_postlogin.js";
        script.async = true;
        document.body.appendChild(script);

        script.addEventListener("load", () => console.log(`Revelock post login script loaded successfully`));
        script.addEventListener("error", (e) => console.log(`Revelock post login script could not load ${e}`));
    };

    render() {
        const { loading, timesConfigFailed, timesI18nFailed } = this.props;
        const serverError = timesConfigFailed >= MAX_FAILED_TIMES || timesI18nFailed >= MAX_FAILED_TIMES;

        if (loading && !serverError) {
            return (
                <div className="view-page">
                    <div className="view-content">
                        <MainContainer showLoader className={classNames("main-container")} />
                    </div>
                </div>
            );
        }
        if (!isMobileNativeFunc()) {
            this.handleResume();
        }
        return (
            <ConnectedRouter history={history}>
                <Routes />
            </ConnectedRouter>
        );
    }
}

const mapStateToProps = ({ isLoading, ...state }) => ({
    loading: isLoading || !i18nSelectors.getMessages(state) || !configSelectors.getConfig(state),
    timesConfigFailed: configSelectors.getTimesFailed(state),
    timesI18nFailed: i18nSelectors.getTimesFailed(state),
    hasActiveSession: sessionSelectors.isLoggedIn(state),
    accessToken: sessionSelectors.accessTokenApp(state),
    // user: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps)(resizableRoute(App));
