import { func, string, bool } from "prop-types";

import React from "react";
import TextAndButtonsModal from "./TextAndButtonsModal";

const ConfirmDialog = ({
    showDialog,
    onCancel,
    onConfirm,
    title,
    description,
    confirmLabel,
    continueLabel,
    modalId,
    loading,
    textClassName,
    contentClassName,
}) => (
    <div className="modal-container">
        <TextAndButtonsModal
            modalShow={showDialog}
            acceptFunction={onConfirm}
            cancelFunction={onCancel}
            headingText={title || ""}
            text={description || ""}
            confirmLabel={confirmLabel}
            continueLabel={continueLabel}
            modalId={modalId}
            loading={loading}
            textClassName={textClassName}
            contentClassName={contentClassName}
        />
    </div>
);

ConfirmDialog.propTypes = {
    showDialog: bool,
    onCancel: func,
    onConfirm: func,
    title: string,
    description: string.isRequired,
    confirmLabel: string,
    continueLabel: string,
    modalId: string,
    loading: bool,
    textClassName: string,
};
ConfirmDialog.defaultProps = {
    showDialog: false,
    onCancel: undefined,
    onConfirm: undefined,
    title: "",
    confirmLabel: "global.accept",
    continueLabel: "global.continue",
    modalId: undefined,
    loading: false,
    textClassName: "mx-md-12",
    contentClassName: "p-7",
};

export default ConfirmDialog;
