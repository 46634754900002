import { Field } from "formik";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, shape, string, arrayOf } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCard";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import * as i18n from "util/i18n";
import Selector from "pages/_components/fields/formik/Selector";
import { dictionaryMiniatureCard } from "util/creditCards";
import Info from "pages/_components/Info";
import { format } from "date-fns";
import { formatNumber } from "util/format";
import TextField from "pages/_components/fields/TextField";
import AmountField from "pages/_components/fields/formik/AmountField";
import { push } from "react-router-redux";
import TicketData from "pages/_components/TicketData";
import { actions as transactionsActions } from "reducers/transactions";
import { isDesktop, isMobile } from "react-device-detect";
import classNames from "classnames";
import notification, { actions as notificationActions } from "reducers/notification";
import FormTransition from "../_components/FormTransition";
import {
    useCreditCardPaymentSelectorOptions,
    PARTIAL_PAYMENT,
    useDebitAccountSelectorOptions,
    usePaymentTypeOptions,
    useCreditCardIdParam,
} from "./hooks/PayCreditCardHooks";
import { useMetaData } from "./hooks/TransferInternalHooks";

const ID_FORM = "creditcard.pay.own";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;
const TITLE_FORM = `forms.${ID_FORM}.title`;

// forms.creditcard.pay.own.error.noCreditCards
// forms.creditcard.pay.own.error.insufficientFunds

// const validationSchema = Yup.object().shape({
//    creditCard: Yup.string().required("Credit card is required"),
//    amountPaymentOptions: Yup.string().required("Amount payment option is required"),
//    debitAccount: Yup.string().required("Debit account is required"),
//    amount: Yup.number()
//        .min(1, "Amount must be greater than 0")
//        .required("Amount is required"), // This makes `amount` always required
// });

export const validateForm = (values) => {
    let isValid = true;
    if (
        !values?.creditCard ||
        !values?.amountPaymentOptions ||
        !values?.debitAccount ||
        !values?.amount ||
        values?.amount <= 0
    ) {
        isValid = false;
    }
    return isValid;
};

const LocalCreditCardPaymentForm = (props) => {
    const {
        mode,
        dispatch,
        preDataForm,
        transaction,
        creditCardDetail,
        fetchingDetail,
        fetchingList,
        location,
    } = props;

    const accountsOptions = useDebitAccountSelectorOptions(preDataForm);
    const creditCardsOptions = useCreditCardPaymentSelectorOptions(preDataForm);
    const [metadata] = useMetaData(preDataForm, ID_ACTIVITY);

    const [creditCardIdParam] = useCreditCardIdParam(location);
    const [creditCardIdSelected, setCreditCardIdSelected] = useState(null);

    const [creditCardDetailSelected, setCreditCardDetailSelected] = useState({});
    const amountPaymentOptions = usePaymentTypeOptions(creditCardDetailSelected);
    const [enabledSubmit, setEnabledSubmit] = useState(false);

    const titleForm = TITLE_FORM;

    useEffect(() => {
        if (mode === "edit") {
            dispatch(formActions.preForm(
                { idActivity: ID_ACTIVITY_PRE }, 
                { message: "No tiene Tarjetas de Crédito asociadas", location: "/desktop"})
            );
        }

        if (mode === "view") {
            if (transaction?.idTransactionStatus === "FINISHED") {
                dispatch(transactionsActions.setActionSecondaryByPath("global.goToHome", null, "/desktop"));
            }
        }
    }, [dispatch, mode]);

    useEffect(() => {
        const id = creditCardIdParam || creditCardIdSelected;
        if (id) {
            dispatch(creditCardActions.readCreditCard(id));
        }
    }, [creditCardIdParam, creditCardIdSelected]);

    useEffect(() => {
        if (
            creditCardDetail &&
            (creditCardDetail.idProduct === creditCardIdParam || creditCardDetail.idProduct === creditCardIdSelected)
        ) {
            setCreditCardDetailSelected(creditCardDetail);
        }
    }, [creditCardDetail]);

    useEffect(
        () => () => {
            dispatch(transactionsActions.cleanActionSecondary());
            dispatch(creditCardActions.detailStopFetching());
        },
        [dispatch],
    );

    const renderTicket = () => <></>;

    const renderCreditCardInfoTitle = () => (
        <Text
            className="size-5 text-bold"
            labelKey="forms.creditcard.pay.own.cardData.title"
            defaultValue="Datos de Tarjeta"
        />
    );

    const renderCreditCardSelector = (setFieldValue) => (
        <Field
            component={Selector}
            idForm={ID_FORM}
            name="creditCard"
            options={creditCardsOptions}
            value={creditCardDetailSelected?.idProduct || ""}
            renderAs="combo"
            labelClassName="text-bold"
            handleChange={(creditCard) => {
                setCreditCardIdSelected(creditCard);
                setFieldValue("amountPaymentOptions", undefined);
            }}
            labelText={i18n.get("forms.creditcard.pay.own.cardData.cardSelector.label", "Tarjeta de crédito")}
            placeholder={i18n.get("forms.creditcard.pay.own.cardData.cardSelector.placeholder", "Seleccionar tarjeta")}
        />
    );

    const renderCreditCardImage = () => (
        <Image
            src={`images/${dictionaryMiniatureCard(creditCardDetailSelected?.cardStyle)}-icon-creditCard.svg`}
            className="img-header-card fill-transparent"
        />
    );

    const renderCreditCardLabel = () => (
        <Info
            labelText={creditCardDetailSelected?.shortLabel || ""}
            text={creditCardDetailSelected?.nameOnCard || ""}
        />
    );

    const renderCreditCardExpirationDate = () => (
        <Info
            labelKey="forms.creditcard.pay.own.ticketData.expirationDate.label"
            text={format(creditCardDetailSelected?.expirationDate, i18n.get("datepicker.format")) || ""}
        />
    );

    const renderCreditCardMinimumPayment = () => (
        <Info labelText="Mínimo a Pagar USD" text={formatNumber(creditCardDetailSelected?.minimumPayment || "")} />
    );

    const renderCreditCardTotalPayment = () => (
        <Info labelText="Total a Pagar USD" text={formatNumber(creditCardDetailSelected?.minimumPayment || "")} />
    );

    const renderPaymentOptionsTitle = () => (
        <Text
            className="size-5 text-bold"
            labelKey="forms.creditcard.pay.own.paymentData.title"
            defaultValue="Datos de Pago"
        />
    );

    const renderPaymentOptionsPaymentTypeLabel = () => (
        <Text
            semibold
            labelKey="forms.creditcard.pay.own.paymentData.paymentType.label"
            defaultValue="Seleccionar Tipo de pago"
        />
    );

    const renderPaymentOptionsPaymentTypeSelector = (setFieldValue) => (
        <Field
            component={Selector}
            idForm={ID_FORM}
            name="amountPaymentOptions"
            options={amountPaymentOptions}
            renderAs="radio"
            radioClassNames={classNames("radio-like-buttons ", { "flex-direction-column": isMobile })}
            labelClassName="text-bold"
            handleChange={(option) => {
                setFieldValue("amountPaymentOptions", option);
                setFieldValue("creditCard", creditCardDetailSelected?.idProduct);

                if (creditCardDetailSelected?.minimumPayment != null) {
                    const newAmount = option !== PARTIAL_PAYMENT ? creditCardDetailSelected?.minimumPayment : 0;

                    setFieldValue("amount", { currency: "USD", quantity: formatNumber(newAmount) });
                } else {
                    setFieldValue("amount", { currency: "USD", quantity: formatNumber(0) });
                }
                setFieldValue("nameOnCard", creditCardDetailSelected?.label);
                setFieldValue("cardHolder", creditCardDetailSelected?.nameOnCard);
                setFieldValue("expirationDate", creditCardDetailSelected?.expirationDate);
            }}
        />
    );

    const renderPaymentOptionsDebitAccountSelector = (setFieldValue) => (
        <Field
            component={Selector}
            idForm={ID_FORM}
            name="debitAccount"
            options={accountsOptions}
            renderAs="combo"
            labelClassName="text-bold"
            handleChange={(debitAccount) => {
                setFieldValue("debitAccount", debitAccount);
                const acc = preDataForm?.debitAccountList?.find((e) => e.idProduct === debitAccount);
                setFieldValue(
                    "debitAccountLabel",
                    `${acc.numberMask} - ${acc.client?.name} - ${acc.productTypeBackend} - ${acc.currency}`,
                );
            }}
            labelText={i18n.get("forms.creditcard.pay.own.paymentData.account.label", "Cuenta Ordenante")}
            placeholder={i18n.get("forms.creditcard.pay.own.paymentData.account.placeholder", "Seleccionar cuenta")}
        />
    );

    const renderPaymentOptionsConceptTextField = () => (
        <Field
            name="concept"
            idForm={ID_FORM}
            component={TextField}
            labelClassName="text-bold"
            maxLength={20}
            spellCheck="false"
            labelText={i18n.get("forms.creditcard.pay.own.paymentData.concept.label", "Concepto")}
            placeholder={i18n.get("forms.creditcard.pay.own.paymentData.concept.placeholder", "Ingresar un concepto")}
        />
    );

    const renderPaymentOptionsAmountField = (setFieldValue, values) => (
        <Field
            name="amount"
            component={AmountField}
            maxLength={15}
            data={{
                options: [{ id: 1, label: "USD" }],
            }}
            clearable={false}
            labelText={i18n.get("forms.creditcard.pay.own.paymentData.amount.label", "Monto USD")}
            bold={false}
            hideCurrency
            fixedDecimalScale
            onInputChange={(value) => {
                setFieldValue("amount", { currency: "USD", quantity: formatNumber(value) });
            }}
            amount={values?.amount?.quantity}
            placeholder={i18n.get("forms.creditcard.pay.own.paymentData.amount.placeholder", "Ingresar")}
            labelNoMarginTop="true"
        />
    );

    const renderTicketConfirmation = (values) => (
        <>
            <TicketData.Main
                label="forms.creditcard.pay.own.ticketData.amount.label"
                value={values.amount?.quantity}
                currency="USD"
                type="amount"
            />
            <Box>
                <Box className="mt-3 mb-4">
                    <Text
                        size="6"
                        bold
                        labelKey="forms.creditcard.pay.own.ticketData.title"
                        defaultValue="Datos de tarjeta"
                    />
                </Box>
                <TicketData.Data
                    label="forms.creditcard.pay.own.ticketData.card.label"
                    value={`${creditCardDetailSelected?.label}`}
                />
                <TicketData.Data
                    label="forms.creditcard.pay.own.ticketData.holder.label"
                    value={creditCardDetailSelected?.nameOnCard}
                />
                <TicketData.Data
                    label="forms.creditcard.pay.own.ticketData.expirationDate.label"
                    value={format(creditCardDetailSelected?.expirationDate, i18n.get("datepicker.format"))}
                />

                <Box className="mt-5 mb-4">
                    <Text
                        size="6"
                        bold
                        labelKey="forms.creditcard.pay.own.ticketData.paymentData.title"
                        defaultValue="Datos de pago"
                    />
                </Box>
                <TicketData.Data
                    label="forms.creditcard.pay.own.ticketData.paymentData.account.label"
                    value={(() => {
                        const account = preDataForm?.debitAccountList?.find(
                            (e) => e.idProduct === values?.debitAccount,
                        );
                        return account
                            ? `${account.numberMask} - ${account.client?.name} - ${account.productTypeBackend} - ${account.currency}`
                            : "";
                    })()}
                />
                <TicketData.Data
                    label="forms.creditcard.pay.own.ticketData.paymentData.concept.label"
                    value={values.concept}
                />
            </Box>
        </>
    );

    const renderDesktop = (values, setFieldValue) => (
        <>
            <Box className="full-width border-radius-lg pt-7 box-shadow-small background-white texture-header mb-7">
                <Box className="background-white p-7">
                    <Box className="border-radius-lg box-shadow-small mb-7 p-7">
                        {renderCreditCardInfoTitle()}
                        {renderCreditCardSelector(setFieldValue)}

                        {Object.keys(creditCardDetailSelected).length > 0 && (
                            <Box className="background-background-disabled border-radius-lg mt-7 px-5 display-flex flex-nowrap align-items-center gap-6">
                                <Box className="display-flex flex-2 align-items-center gap-6">
                                    <Box>{renderCreditCardImage()}</Box>
                                    <Box>{renderCreditCardLabel()}</Box>
                                </Box>
                                <Box className="flex">{renderCreditCardExpirationDate()}</Box>
                                <Box className="flex">{renderCreditCardMinimumPayment()}</Box>
                                <Box className="flex text-right">{renderCreditCardTotalPayment()}</Box>
                            </Box>
                        )}
                    </Box>

                    {/** payment options  */}
                    <Box className="border-radius-lg box-shadow-small p-7">
                        <Box className="mb-7">{renderPaymentOptionsTitle()}</Box>
                        <Box>
                            {renderPaymentOptionsPaymentTypeLabel()}
                            {renderPaymentOptionsPaymentTypeSelector(setFieldValue)}
                        </Box>
                        <Box className="display-flex gap-6">
                            <Box className="flex">{renderPaymentOptionsDebitAccountSelector(setFieldValue)}</Box>
                            <Box className="flex">{renderPaymentOptionsConceptTextField()}</Box>
                        </Box>
                        {values.amountPaymentOptions === PARTIAL_PAYMENT && (
                            <Box className="display-flex gap-6 mt-7">
                                <Box className="flex">{renderPaymentOptionsAmountField(setFieldValue, values)}</Box>
                                <Box className="flex" />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );

    const renderMobile = (values, setFieldValue) => (
        <>
            <Box className="pt-5">{renderCreditCardInfoTitle()}</Box>
            <Box className="mb-5">{renderCreditCardSelector(setFieldValue)}</Box>

            {Object.keys(creditCardDetailSelected).length > 0 && (
                <Box className="background-background-disabled display-flex flex-direction-column p-5 border-radius-lg gap-3 mb-5">
                    <Box className="display-flex align-items-center gap-6">
                        <Box className="">{renderCreditCardImage()}</Box>
                        <Box>{renderCreditCardLabel()}</Box>
                    </Box>

                    <Box className="display-flex align-items-center gap-6">
                        <Box>{renderCreditCardExpirationDate()}</Box>
                        <Box>{renderCreditCardMinimumPayment()}</Box>
                    </Box>
                    <Box className="display-flex align-items-center">
                        <Box>{renderCreditCardTotalPayment()}</Box>
                    </Box>
                </Box>
            )}

            {
                /** payment options  */
                <Box>
                    <Box className="mb-5">{renderPaymentOptionsTitle()}</Box>

                    <Box fullWidth>
                        <Box>{renderPaymentOptionsPaymentTypeLabel()}</Box>
                        <Box className="display-flex flex-direction-column" fullWidth>
                            {renderPaymentOptionsPaymentTypeSelector(setFieldValue)}
                        </Box>
                        <Box fullWidth className="display-flex flex-direction-column">
                            {renderPaymentOptionsDebitAccountSelector(setFieldValue)}
                            {renderPaymentOptionsConceptTextField()}
                        </Box>
                        {values.amountPaymentOptions === PARTIAL_PAYMENT && (
                            <Box className="mt-5">{renderPaymentOptionsAmountField(setFieldValue, values)}</Box>
                        )}
                    </Box>
                </Box>
            }
        </>
    );

    const renderFields = (setFieldValue, values, _setValues, _scheduler, errors, setErrors) => {
        if (mode !== "view" && (!preDataForm || fetchingDetail || fetchingList)) {
            return <PageLoading loading classicStyle={false} />;
        }
        if (mode === "edit" && validateForm(values)) {
            setEnabledSubmit(true);
        } else {
            setEnabledSubmit(false);
        }

        if (mode === "edit") {
            if (isDesktop) {
                return <>{renderDesktop(values, setFieldValue)}</>;
            }
            return <>{renderMobile(values, setFieldValue)}</>;
        }
        if (mode === "preview") {
            return <>{renderTicketConfirmation(values)}</>;
        }
    };

    const formProps = {
        title: titleForm,
        data: {},
        metadata,
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        cancelAction: () => {
            dispatch(push("/creditCardMenu"));
        },
        textBack: "forms.creditcard.pay.own.textBack",
        textBackConfirmation: "global.return",
        preData: preDataForm,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        // validationSchema,
        // validate: validateCustom,
        showSchedulerMessage: false,
        // invalidateCache: true,
        // usesJointAccount,
        showFilterChips: false,
        submitDisabled: !enabledSubmit,
        ticketConfirmation: true,
        // submitActionParams
    };
    return <FormTransition {...props} {...formProps} />;
};

LocalCreditCardPaymentForm.propTypes = {
    idForm: string,
    dispatch: func,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    fromTransaction: bool,
    isDesktop: bool,
    postData: shape({}),
    creditCardDetail: shape({}),
    fetchingDetail: bool,
    fetchingList: bool,
    creditCardList: arrayOf(shape({})),
    usesJointAccount: bool,
    data: arrayOf(shape({})),
};
LocalCreditCardPaymentForm.defaultProps = {
    idForm: ID_FORM,
    dispatch: () => {},
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: {},
    previewData: null,
    transaction: null,
    location: {},
    fromTransaction: false,
    isDesktop: false,
    postData: {},
    creditCardDetail: {},
    fetchingDetail: false,
    fetchingList: false,
    creditCardList: [],
    usesJointAccount: false,
    data: {},
};
const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    postData: formSelectors.getData(state),
    creditCardDetail: creditCardSelectors.getCreditCardDetail(state),
    fetchingDetail: creditCardSelectors.getFetching(state),
    fetchingList: creditCardSelectors.isFetching(state),
    usesJointAccount: formSelectors.getUsesJointAccount(state),
});

const areEqual = (prevProps, nextProps) =>
    // Compare props that are important for re-rendering
    prevProps.mode === nextProps.mode &&
    prevProps.transaction === nextProps.transaction &&
    prevProps.creditCardDetail === nextProps.creditCardDetail &&
    prevProps.fetchingDetail === nextProps.fetchingDetail &&
    prevProps.fetchingList === nextProps.fetchingList &&
    prevProps.location === nextProps.location &&
    // Only compare specific parts of preDataForm that you care about
    prevProps.preDataForm?.importantField === nextProps.preDataForm?.importantField &&
    prevProps.preDataForm?.anotherImportantField === nextProps.preDataForm?.anotherImportantField;
export default React.memo(
    compose(connect(mapStateToProps), withRouter)(resizableRoute(LocalCreditCardPaymentForm)),
    areEqual,
);
