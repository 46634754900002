import { createReducer, makeActionCreator } from "util/redux";

// const getService = (id, state) => state.listPayService.findIndex((e) => e.id === id);

export const types = {
    CLEAN: "payService/CLEAN",
    LIST_PAYSERVICE_REQUEST: "payService/LIST_PAYSERVICE_REQUEST",
    LIST_PAYSERVICE_FAILURE: "payService/LIST_PAYSERVICE_FAILURE",
    LIST_PAYSERVICE_SUCCESS: "payService/LIST_PAYSERVICE_SUCCESS",
    LIST_FAST_RECHARGE_SUCCESS: "payService/LIST_FAST_RECHARGE_SUCCESS",
    FAVORITE_REQUEST: "payService/FAVORITE_REQUEST",
    FAVORITE_SUCCESS: "payService/FAVORITE_SUCCESS",
    FAVORITE_FAILURE: "payService/FAVORITE_FAILURE",
    UPDATE_REQUEST: "payService/UPDATE_REQUEST",
    UPDATE_SUCCESS: "payService/UPDATE_SUCCESS",
    UPDATE_FAILURE: "payService/UPDATE_FAILURE",
    CREATE_REQUEST: "payService/CREATE_REQUEST",
    CREATE_SUCCESS: "payService/CREATE_SUCCESS",
    CREATE_FAILURE: "payService/CREATE_FAILURE",
    GET_REQUEST: "payService/GET_REQUEST",
    GET_SUCCESS: "payService/GET_SUCCESS",
    GET_FAILURE: "payService/GET_FAILURE",
    DELETE_REQUEST: "payService/DELETE_REQUEST",
    DELETE_SUCCESS: "payService/DELETE_SUCCESS",
    DELETE_FAILURE: "payService/DELETE_FAILURE",
    LIST_CATEGORIES_REQUEST: "payService/LIST_CATEGORIES_REQUEST",
    LIST_CATEGORIES_FAILURE: "payService/LIST_CATEGORIES_FAILURE",
    LIST_CATEGORIES_SUCCESS: "payService/LIST_CATEGORIES_SUCCESS",
    SET_SELECTED_BILLER: "payService/SET_SELECTED_BILLER",
    LIST_RULES_BILLER_REQUEST: "payService/LIST_RULES_BILLER_REQUEST",
    LIST_RULES_BILLER_FAILURE: "payService/LIST_RULES_BILLER_FAILURE",
    LIST_RULES_BILLER_SUCCESS: "payService/LIST_RULES_BILLER_SUCCESS",
    SET_QUERY_BILL_PRE: "payService/SET_QUERY_BILL_PRE",
    CLEAN_CACHE_PAYSERVICE: "payService/CLEAN_CACHE_PAYSERVICE",
    IS_FAST_RECHARGE: "payService/IS_FAST_RECHARGE",
    IS_LINK_FAST_RECHARGE: "payService/IS_LINK_FAST_RECHARGE",
    IS_FETCHING_FAST_RECHARGE: "payService/IS_FETCHING_FAST_RECHARGE",
    IS_SHOW_ALL_RECHARGES: "payService/IS_SHOW_ALL_RECHARGES",

    CHECK_DEBT_REQUEST: "payService/CHECK_DEBT_REQUEST",
    CHECK_DEBT_SUCCESS: "payService/CHECK_DEBT_SUCCESS",
    CHECK_DEBT_FAILURE: "payService/CHECK_DEBT_FAILURE",
    CHECK_DEBT_FAILURE_NO_DEBT: "payService/CHECK_DEBT_FAILURE_NO_DEBT",

    SET_SERVICE_PAY: "payService/SET_SERVICE_PAY",
    SET_LIST_MASSIVE_PAY: "payService/SET_LIST_MASSIVE_PAY",
    UPDATE_LIST_MASSIVE_PAY: "payService/UPDATE_LIST_MASSIVE_PAY",
    SET_LIST_MASSIVE_PAY_PROCESSED: "payService/SET_LIST_MASSIVE_PAY_PROCESSED",
    SET_SERVICE_MODIFY: "payService/SET_SERVICE_MODIFY",
    SET_SERVICE_DELETE: "payService/SET_SERVICE_DELETE",
    NEW_PAY_SERVICE: "payService/NEW_PAY_SERVICE",
    SET_PRE_DATA_PAY: "payService/SET_PRE_DATA_PAY",
};

export const INITIAL_STATE = {
    fetching: false,
    fetchingServices: true,
    fetchingFavorite: false,
    fetchingDebt: false,
    listPayService: undefined,
    listPayServiceDebt: {},
    listPayServiceSelected: undefined,
    massivePayService: {},
    listPayServiceChecked: [],
    listPayServiceProcessed: [],
    listPayServiceTotalPaid: undefined,
    service: null,
    listFastRecharge: [],
    payService: null,
    listCategories: [],
    selectedBiller: null,
    field: [],
    queryBill: null,
    isFastRecharge: false,
    isLinkFastRecharge: false,
    isFetchingFastRecharge: false,
    isShowAllRecharges: true,
    page: 1,
    search: "",
    fetchingNewPay: false,
};

export default createReducer(INITIAL_STATE, {
    [types.CLEAN]: () => ({ ...INITIAL_STATE }),
    [types.LIST_PAYSERVICE_REQUEST]: (state) => ({ ...state, fetchingServices: true }),
    [types.LIST_PAYSERVICE_FAILURE]: (state) => ({ ...state, fetchingServices: false, listPayService: [] }),
    [types.LIST_PAYSERVICE_SUCCESS]: (state, action) => ({
        ...state,
        fetchingServices: false,
        listPayService: action.list,
    }),
    [types.LIST_FAST_RECHARGE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        listFastRecharge: action.list,
    }),

    // [types.FAVORITE_REQUEST]: (state) => ({ ...state, fetchingFavorite: true }),
    // [types.FAVORITE_SUCCESS]: (state, action) => ({ ...state, listPayService: action.list, fetchingFavorite: false }),
    // [types.FAVORITE_FAILURE]: (state) => ({ ...state, fetchingFavorite: false }),

    [types.UPDATE_REQUEST]: (state) => ({ ...state, fetching: true }),
    [types.UPDATE_SUCCESS]: (state) => ({ ...state, fetching: false }),
    [types.UPDATE_FAILURE]: (state) => ({ ...state, fetching: false }),

    [types.CREATE_REQUEST]: (state) => ({ ...state, fetching: true }),
    [types.CREATE_SUCCESS]: (state) => ({ ...state, fetching: false }),
    [types.CREATE_FAILURE]: (state) => ({ ...state, fetching: false }),

    // [types.GET_REQUEST]: (state) => ({ ...state, fetching: true, payService: null }),
    // [types.GET_FAILURE]: (state) => ({ ...state, fetching: false, payService: null }),
    // [types.GET_SUCCESS]: (state, action) => ({ ...state, fetching: false, payService: action.object }),

    [types.DELETE_REQUEST]: (state) => ({ ...state, fetching: true }),
    [types.DELETE_SUCCESS]: (state, action) => ({
        ...state,
        listPayService: action.list,
        fetching: false,
    }),
    [types.DELETE_FAILURE]: (state) => ({ ...state, fetching: false }),

    [types.LIST_CATEGORIES_REQUEST]: (state) => ({ ...state, fetching: true, listCategories: [] }),
    [types.LIST_CATEGORIES_FAILURE]: (state) => ({ ...state, fetching: false, listCategories: [] }),
    [types.LIST_CATEGORIES_SUCCESS]: (state, action) => ({ ...state, fetching: false, listCategories: action.list }),

    [types.SET_SELECTED_BILLER]: (state, action) => ({ ...state, selectedBiller: action.biller }),

    [types.LIST_RULES_BILLER_REQUEST]: (state) => ({ ...state, fetching: true, field: [] }),
    [types.LIST_RULES_BILLER_FAILURE]: (state) => ({ ...state, fetching: false, field: [] }),
    [types.LIST_RULES_BILLER_SUCCESS]: (state, action) => ({ ...state, fetching: false, field: action.list }),

    [types.SET_QUERY_BILL_PRE]: (state, action) => ({ ...state, queryBill: action.object }),
    [types.CLEAN_CACHE_PAYSERVICE]: (state) => ({ ...state, payService: null, queryBill: null }),
    [types.IS_FAST_RECHARGE]: (state, action) => ({ ...state, isFastRecharge: action.isFastRecharge }),
    [types.IS_LINK_FAST_RECHARGE]: (state, action) => ({ ...state, isLinkFastRecharge: action.isLinkFastRecharge }),
    [types.IS_FETCHING_FAST_RECHARGE]: (state, action) => ({
        ...state,
        isFetchingFastRecharge: action.isFetchingFastRecharge,
    }),
    [types.IS_SHOW_ALL_RECHARGES]: (state, action) => ({
        ...state,
        isShowAllRecharges: action.isShowAllRecharges,
    }),

    [types.CHECK_DEBT_REQUEST]: (state) => ({ ...state, fetchingDebt: true }),
    [types.CHECK_DEBT_SUCCESS]: (state, action) => {
        const { serviceId, debt } = action;

        return {
            ...state,
            // listPayService: newList,
            fetchingDebt: false,
            fetchingNewPay: false,
            listPayServiceDebt: {
                ...state.listPayServiceDebt,
                [serviceId]: {
                    debt,
                    amountPay: debt?.total,
                    hasDue: true,
                    checked: true,
                },
            },
        };
    },
    [types.CHECK_DEBT_FAILURE]: (state) => ({ ...state, fetchingDebt: false, fetchingNewPay: false }),
    [types.CHECK_DEBT_FAILURE_NO_DEBT]: (state, action) => {
        const { serviceId } = action;

        return {
            ...state,
            // listPayService: newList,
            fetchingDebt: false,
            fetchingNewPay: false,
            listPayServiceDebt: {
                ...state.listPayServiceDebt,
                [serviceId]: {
                    debt: null,
                    amountPay: 0,
                    hasDue: false,
                    checked: false,
                },
            },
        };
    },

    [types.SET_SERVICE_PAY]: (state, action) => ({
        ...state,
        payService: action.payService,
        listPayServiceSelected: action.selected,
        page: action.page,
        search: action.search,
    }),
    [types.SET_LIST_MASSIVE_PAY]: (state, action) => ({
        ...state,
        massivePayService: { list: action.list, totalPay: action.totalPay },
        listPayServiceSelected: action.selected,
        page: action.page,
        search: action.search,
    }),
    [types.UPDATE_LIST_MASSIVE_PAY]: (state, action) => ({
        ...state,
        massivePayService: { list: action.list, totalPay: action.totalPay },
        // listPayServiceSelected: action.selected,
    }),
    [types.SET_LIST_MASSIVE_PAY_PROCESSED]: (state, action) => ({
        ...state,
        listPayServiceProcessed: action.listProcessed,
        listPayServiceTotalPaid: action.total,
    }),
    [types.SET_SERVICE_MODIFY]: (state, action) => ({
        ...state,
        service: action.service,
        page: action.page,
        search: action.search,
    }),
    [types.SET_SERVICE_DELETE]: (state, action) => ({
        ...state,
        service: action.service,
        page: action.page,
        search: action.search,
    }),
    [types.NEW_PAY_SERVICE]: (state) => ({
        ...state,
        fetchingNewPay: true,
    }),
    [types.SET_PRE_DATA_PAY]: (state, action) => ({
        ...state,
        preDataServicePay: action.service,
    }),
});

export const actions = {
    clean: makeActionCreator(types.CLEAN),
    cleanCachePayService: makeActionCreator(types.CLEAN_CACHE_PAYSERVICE),
    listPayServiceRequest: makeActionCreator(types.LIST_PAYSERVICE_REQUEST, "category"),
    listPayServiceFailure: makeActionCreator(types.LIST_PAYSERVICE_FAILURE),
    listPayServiceSuccess: makeActionCreator(types.LIST_PAYSERVICE_SUCCESS, "list"),
    listFastRechargeSuccess: makeActionCreator(types.LIST_FAST_RECHARGE_SUCCESS, "list"),

    favoriteRequest: makeActionCreator(types.FAVORITE_REQUEST, "payServiceObj"),
    favoriteSuccess: makeActionCreator(types.FAVORITE_SUCCESS, "list"),

    updateRequest: makeActionCreator(types.UPDATE_REQUEST, "payServiceObj"),
    updateSuccess: makeActionCreator(types.UPDATE_SUCCESS),

    createRequest: makeActionCreator(types.CREATE_REQUEST, "payServiceObj"),
    createSuccess: makeActionCreator(types.CREATE_SUCCESS),

    getPayServiceRequest: makeActionCreator(types.GET_REQUEST, "idPaymentUser"),
    getPayServiceFailure: makeActionCreator(types.GET_FAILURE),
    getPayServiceSuccess: makeActionCreator(types.GET_SUCCESS, "object"),

    deleteRequest: makeActionCreator(types.DELETE_REQUEST, "service"),
    deleteSuccess: makeActionCreator(types.DELETE_SUCCESS, "list"),

    listCategoriesRequest: makeActionCreator(types.LIST_CATEGORIES_REQUEST, "categoryId"),
    listCategoriesFailure: makeActionCreator(types.LIST_CATEGORIES_FAILURE),
    listCategoriesSuccess: makeActionCreator(types.LIST_CATEGORIES_SUCCESS, "list"),

    setSelectedBiller: makeActionCreator(types.SET_SELECTED_BILLER, "biller"),

    listRulesBillerRequest: makeActionCreator(types.LIST_RULES_BILLER_REQUEST, "idBiller"),
    listRulesBillerFailure: makeActionCreator(types.LIST_RULES_BILLER_FAILURE),
    listRulesBillerSuccess: makeActionCreator(types.LIST_RULES_BILLER_SUCCESS, "list"),

    setQueryBillPre: makeActionCreator(types.SET_QUERY_BILL_PRE, "object"),

    isFastRecharge: makeActionCreator(types.IS_FAST_RECHARGE, "isFastRecharge"),
    isLinkFastRecharge: makeActionCreator(types.IS_LINK_FAST_RECHARGE, "isLinkFastRecharge"),
    isFetchingFastRecharge: makeActionCreator(types.IS_FETCHING_FAST_RECHARGE, "isFetchingFastRecharge"),
    isShowAllRecharges: makeActionCreator(types.IS_SHOW_ALL_RECHARGES, "isShowAllRecharges"),

    checkDebtRequest: makeActionCreator(types.CHECK_DEBT_REQUEST, "serviceId", "onFinish", "isMassive"),
    checkDebtFailure: makeActionCreator(types.CHECK_DEBT_FAILURE),
    checkDebtFailureNoDebt: makeActionCreator(types.CHECK_DEBT_FAILURE_NO_DEBT, "serviceId"),
    checkDebtSuccess: makeActionCreator(types.CHECK_DEBT_SUCCESS, "serviceId", "debt"),

    setServicePayment: makeActionCreator(types.SET_SERVICE_PAY, "payService", "selected", "page", "search"),
    setListMassivePayment: makeActionCreator(
        types.SET_LIST_MASSIVE_PAY,
        "list",
        "totalPay",
        "selected",
        "page",
        "search",
    ),
    updateListMassivePayment: makeActionCreator(types.UPDATE_LIST_MASSIVE_PAY, "list", "totalPay"),
    setListMassivePaymentProcessed: makeActionCreator(types.SET_LIST_MASSIVE_PAY_PROCESSED, "listProcessed", "total"),

    setServiceModify: makeActionCreator(types.SET_SERVICE_MODIFY, "service", "page", "search"),
    setServiceDelete: makeActionCreator(types.SET_SERVICE_DELETE, "service", "page", "search"),
    newPayService: makeActionCreator(types.NEW_PAY_SERVICE, "category", "identification", "onFinish"),
    setPreDataServicePay: makeActionCreator(types.SET_PRE_DATA_PAY, "service"),
};

export const selectors = {
    isFetching: ({ payService }) => payService.fetching,
    isFetchingServices: ({ payService }) => payService.fetchingServices,
    isFetchingFavorite: ({ payService }) => payService.fetchingFavorite,
    isFetchingDebt: ({ payService }) => payService.fetchingDebt,
    isFetchingNewPay: ({ payService }) => payService.fetchingNewPay,
    isShowAllRecharges: ({ payService }) => payService.isShowAllRecharges,
    isFastRecharge: ({ payService }) => payService.isFastRecharge,
    isLinkFastRecharge: ({ payService }) => payService.isLinkFastRecharge,
    isFetchingFastRecharge: ({ payService }) => payService.isFetchingFastRecharge,
    getListPayService: ({ payService }) => payService.listPayService,
    getListPayServiceChecked: ({ payService }) => payService.listPayServiceChecked,
    getListFastRecharge: ({ payService }) => payService.listFastRecharge,
    getPayService: ({ payService }) => payService.payService,
    getCategories: ({ payService }) => payService.listCategories,
    getSelectedBiller: ({ payService }) => payService.selectedBiller,
    getField: ({ payService }) => payService.field,
    getQueryBill: ({ payService }) => payService.queryBill,
    getlistPayServiceDebt: ({ payService }) => payService.listPayServiceDebt,
    getlistPayServiceSelected: ({ payService }) => payService.listPayServiceSelected,
    getPage: ({ payService }) => payService.page,
    getSearch: ({ payService }) => payService.search,
    getMassivePayService: ({ payService }) => payService.massivePayService,
    getListServiceToPay: ({ payService }) => payService.listPayServiceChecked,
    getListServiceProcessed: ({ payService }) => payService.listPayServiceProcessed,
    getListServiceTotalPaid: ({ payService }) => payService.listPayServiceTotalPaid,
    getServiceAction: ({ payService }) => payService.service,
    getPreDataServicePay: ({ payService }) => payService.preDataServicePay,
};
