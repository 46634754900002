/* eslint-disable */
import session, { types as sessionTypes } from "reducers/session";

import accounts from "reducers/accounts";
import administration from "reducers/administration";
import administrationGroups from "reducers/administration/groups";
import administrationSignaturesSchemes from "reducers/administration/common/signaturesSchemes";
import administrationTicket from "reducers/administration/common/administrationTicket";
import administrationUsers from "reducers/administration/users";
import administrationUsersInvite from "reducers/administration/usersInvite";
import assistant from "reducers/assistant";
import bankReference from "reducers/bankReference";
import bankSelector from "reducers/bankSelector";
import bottomSheet from "reducers/bottomSheet";
import campaigns from "reducers/campaigns";
import chatbot from "reducers/chatbot";
import checks from "reducers/checks";
import documents from "reducers/documents";
import { combineReducers } from "redux";
import communication from "reducers/communication";
import communicationTrays from "reducers/communicationTrays";
import communications from "reducers/communications";
import config from "reducers/config";
import { createReducer } from "util/redux";
import creditCard from "reducers/creditCard";
import creditCardMovementDetails from "reducers/creditCardMovementDetails";
import creditCardRequest from "reducers/creditCardRequest";
import debitCards from "reducers/debitCards";
import deposits from "reducers/deposits";
import desktop from "reducers/desktop";
import enrollment from "reducers/enrollment";
import files from "reducers/files";
import fingerprint from "reducers/fingerprint";
import form from "reducers/form";
import formFields from "reducers/formFields";
import frequentDestination from "reducers/frequentDestination/frequentDestination";
import globalTypes from "reducers/types/global";
import i18n from "reducers/i18n";
import loans from "reducers/loans";
import loansPayment from "reducers/loansPayment";
import login from "reducers/login";
import modal from "reducers/modal";
import momentDay from "reducers/momentDay";
import notification from "reducers/notification";
import onboarding from "reducers/onboarding";
import pointsOfInterest from "reducers/pointsOfInterest";
import pushNotifications from "reducers/pushNotifications";
import recoveryPassword from "reducers/recoveryPassword";
import changeExpiredPassword from "reducers/changeExpiredPassword";
import recoveryUser from "reducers/recoveryUser";
import restrictions from "reducers/administration/restrictions";
import { routerReducer } from "react-router-redux";
import servicePayments from "reducers/servicePayments";
import settings from "reducers/settings";
import status from "reducers/status";
import template from "reducers/template";
import transactions from "reducers/transactions";
import widgets from "reducers/widgets";
import generalConditions from "reducers/generalConditions";
import products from "reducers/products";
import changeStatusProduct from "reducers/changeStatusProduct";
import wally from "reducers/wally";
import softToken from "reducers/softToken";
import authenticateHandler from "reducers/authenticateHandler";
import payService from "reducers/payService";
import softTokenDisclaimer from "reducers/softTokenDisclaimer/softTokenDisclaimer.reducer";
import tour from "reducers/tour";
import revelock from "reducers/revelock/revelock.reducer";
import fixedTermDepositReducer from "./fixedTermDeposit/fixedTermDeposit.reducer";
import finances from "reducers/finances";
import wallet from "reducers/wallet/wallet.reducer"
import updateUserData from "reducers/updateUserData/updateUserData.reducer"
import productRequest from "reducers/productRequest";
import creditLines from "reducers/creditLines";
import requestAndComplaints from "reducers/requestAndComplaints";
import transferSupport from "reducers/transferSupport/transferSupport.reducer";
import theme from "reducers/theme";
import unlockUser from "reducers/unlockUser";
import paginatedTable from "reducers/paginatedTable";
import loading from "reducers/loading";
import secondFactor from "reducers/secondFactor";
import serviceRegistration from "reducers/serviceRegistration";


export const actions = {
    init: () => ({
        type: globalTypes.INIT,
    }),
};

const appReducer = combineReducers({
    router: routerReducer,
    isLoading: createReducer(true, {
        [globalTypes.INIT]: () => false,
    }),

    assistant,
    accounts,
    bankReference,
    deposits,
    bottomSheet,
    campaigns,
    chatbot,
    checks,
    docs: documents,
    communications,
    communication,
    communicationTrays,
    config,
    creditCard,
    debitCards,
    creditCardMovementDetails,
    desktop,
    enrollment,
    fingerprint,
    form,
    i18n,
    loans,
    loansPayment,
    notification,
    onboarding,
    pushNotifications,
    recoveryPassword,
    changeExpiredPassword,
    recoveryUser,
    session,
    settings,
    status,
    template,
    widgets,
    transactions,
    bankSelector,
    login,
    administration,
    administrationGroups,
    administrationSignaturesSchemes,
    administrationTicket,
    administrationUsers,
    administrationUsersInvite,
    files,
    formFields,
    pointsOfInterest,
    creditCardRequest,
    restrictions,
    frequentDestination,
    modal,
    servicePayments,
    momentDay,
    generalConditions,
    products,
    changeStatusProduct,
    wally,
    requestAndComplaints,
    softToken,
    authenticateHandler,
    payService,
    softTokenDisclaimer,
    tour,
    revelock,
    fixedTermDepositReducer,
    finances,
    wallet,
    updateUserData,
    productRequest,
    creditLines,
    transferSupport,
    theme,
    unlockUser,
    paginatedTable,
    loading,
    secondFactor,
    serviceRegistration
});

const rootReducer = (state = {}, action) => {
    const initializationState = {
        i18n: state.i18n,
        config: state.config,
        notification: state.notification,
        isLoading: state.isLoading,
        session: state.session,
        fingerprint: state.fingerprint,
        login: state.login,
        momentDay: state.momentDay,
        status: state.status,
        revelock: state.revelock,
    };

    switch (action.type) {
        case globalTypes.CLEAN_UP:
        case sessionTypes.LOGOUT_REQUEST:
            return appReducer(
                {
                    ...initializationState,
                    login: state.login,
                    creditCard: state.creditCard,
                    products: state.products,
                },
                action,
            );
        // cleaning all specific environment data
        case sessionTypes.CHANGE_ENVIRONMENT_SUCCESS:
            return appReducer(
                {
                    ...initializationState,
                },
                action,
            );
        default:
            return appReducer(state, action);
    }
};

export default rootReducer;
