export const types = {
    RESET_FILTERS: "bankReferences/RESET_FILTERS",
    BANK_REFERENCES_PRE_REQUEST: "bankReferences/BANK_REFERENCE_PRE_REQUEST",
    BANK_REFERENCES_PRE_SUCCESS: "bankReferences/BANK_REFERENCE_PRE_SUCCESS",
    BANK_REFERENCES_PRE_FAILURE: "bankReferences/BANK_REFERENCE_PRE_FAILURE",
    DOWNLOAD_BANK_REFERENCES_REQUEST: "bankReferences/DOWNLOAD_BANK_REFERENCES_REQUEST",
    DOWNLOAD_BANK_REFERENCES_FAILURE: "bankReferences/DOWNLOAD_BANK_REFERENCES_FAILURE",
    DOWNLOAD_BANK_REFERENCES_SUCCESS: "bankReferences/DOWNLOAD_BANK_REFERENCES_SUCCESS",
};

export const INITIAL_STATE = {
    fetching: false,
    fetchingDownload: false,
    typeAccount: null,
    documentNumber: null,
    numberAccount: null,
    bankReferenceItem: null,
};

export default (state = INITIAL_STATE, action = {}) => {
    const { bankReferenceItem, documentNumber } = action;

    switch (action.type) {
        case types.BANK_REFERENCES_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.BANK_REFERENCES_PRE_SUCCESS:
            return {
                ...state,
                fetching: false,
                bankReferenceItem,
                documentNumber,
            };
        case types.BANK_REFERENCES_PRE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.DOWNLOAD_BANK_REFERENCES_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_BANK_REFERENCES_FAILURE:
        case types.DOWNLOAD_BANK_REFERENCES_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.RESET_FILTERS:
            return {
                ...state,
                ...INITIAL_STATE,
            };
        default:
            return state;
    }
};

export const actions = {
    readBankReference: (typeAccount, documentNumber, numberAccount) => ({
        type: types.BANK_REFERENCES_PRE_REQUEST,
        typeAccount,
        documentNumber,
        numberAccount,
    }),
    downloadBankReference: (bankReference, documentNumberClient) => ({
        type: types.DOWNLOAD_BANK_REFERENCES_REQUEST,
        bankReference,
        documentNumberClient,
    }),
    resetFilters: () => ({
        type: types.RESET_FILTERS,
    }),
};

export const selectors = {
    getBankReferenceItem: ({ bankReference }) => bankReference.bankReferenceItem,
    getFetching: ({ bankReference }) => bankReference.fetching,
    getFetchingDownload: ({ bankReference }) => bankReference.fetchingDownload,
    getDocumentNumber: ({ bankReference }) => bankReference.documentNumber,
};
