import { types as loginTypes } from "reducers/login";
import { types as settingsTypes } from "reducers/settings";
import globalTypes from "reducers/types/global";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import * as configUtils from "util/config";
import { makeActionCreator } from "util/redux";

export const types = {
    CHANGE_ENVIRONMENT_REQUEST: "session/CHANGE_ENVIRONMENT_REQUEST",
    CHANGE_ENVIRONMENT_FAILURE: "session/CHANGE_ENVIRONMENT_FAILURE",
    CHANGE_ENVIRONMENT_SUCCESS: "session/CHANGE_ENVIRONMENT_SUCCESS",
    UPDATE_PASSWORD_EXPIRATION_CONDITIONS: "session/UPDATE_PASSWORD_EXPIRATION_CONDITIONS",

    LOGOUT_REQUEST: "session/LOGOUT_REQUEST",
    LOGOUT_USER_BLOCKED_REQUEST: "session/LOGOUT_USER_BLOCKED_REQUEST",

    SET_TOKENS: "session/SET_TOKENS",

    SET_ENABLED_ASSISTANT: "session/SET_ENABLED_ASSISTANT",

    EXTEND: "session/EXTEND",
    EXTEND_SUCCESS: "session/EXTEND_SUCCESS",
    EXPIRE: "session/EXPIRE",

    LOCATION_REQUESTED: "session/LOCATION_REQUESTED",
    UPDATE_PENDINGACTIONS_PEP: "session/PENDING_ACTIONS_PEP",
    UPDATE_PENDINGACTIONS_IRS: "session/PENDING_ACTIONS_IRS",
    MASK_AMOUNT_UPDATE: "session/MASK_AMOUNT_UPDATE",
    MASK_AMOUNT_UPDATE_REQUEST: "session/MASK_AMOUNT_UPDATE_REQUEST",

    MAIL_UPDATE_SUCCESS: "settings/MAIL_UPDATE_SUCCESS",
    SET_IS_ACTIVE_CORPORATE: "session/SET_IS_ACTIVE_CORPORATE",

    SET_SIGNATURE_LEVEL: "session/SET_SIGNATURE_LEVEL",
    SET_PRODUCT_TYPES: "session/SET_PRODUCT_TYPES",
    SET_STATUS_MODALS: "session/SET_STATUS_MODALS",
    CHANGE_STATUS_MODAL: "session/CHANGE_STATUS_MODAL",
    SET_ACCEPT_MODAL_REQUEST: "session/SET_ACCEPT_MODAL_REQUEST",

    OPEN_SAT_SERVICES_REQUEST: "session/OPEN_SAT_SERVICES_REQUEST",
    OPEN_SAT_SERVICES_SUCCESS: "session/OPEN_SAT_SERVICES_SUCCESS",
    OPEN_SAT_SERVICES_FAILURE: "session/OPEN_SAT_SERVICES_FAILURE",

    SET_CREDENTIAL_TYPE: "session/SET_CREDENTIAL_TYPE",
    SET_OTP_METHOD: "session/SET_OTP_METHOD",
    SET_MEDIA_CONTACT: "session/SET_MEDIA_CONTACT",

    SET_MOBILE_MENU_CLOSED: "session/SET_MOBILE_MENU_CLOSED",
    LOAD_FP_DATA_REQUEST: "session/LOAD_FP_DATA_REQUEST",
    LOAD_FP_DATA_SUCCESS: "session/LOAD_FP_DATA_SUCCESS",
    LOAD_FP_DATA_FAILURE: "session/LOAD_FP_DATA_FAILURE",
    SET_STATE_APP: "session/SET_STATE_APP",
    SHOW_NOTIFICATION_NOT_AUTHORIZED: "session/SHOW_NOTIFICATION_NOT_AUTHORIZED",

    LOGOUT_EXTERNAL_REQUEST: "session/LOGOUT_EXTERNAL_REQUEST",
};

export const INITIAL_STATE = {
    activeEnvironment: null,
    environments: {},
    user: null,
    fetching: false,
    isLocationRequested: false,
    enabledAssistant: false,
    productsMaskAmount: {},
    isActiveCorporate: false,
    signatureLevel: null,
    productTypes: [],
    statusModals: {},
    isfetchingToken: false,
    mobileMenuClosed: false,
    changingEnvironment: false,
};

const reducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.LOCATION_REQUESTED:
            return {
                ...state,
                isLocationRequested: true,
            };
        case globalTypes.CLEAN_UP:
        case loginTypes.INIT_LOGIN_FLOW:
        case types.LOGOUT_REQUEST:
            return {
                ...INITIAL_STATE,
                isActiveCorporate: state.isActiveCorporate,
            };

        case loginTypes.LOGIN_SUCCESS:
            return {
                ...state,
                activeEnvironment: action.environment,
                environments: action.environments,
                user: action.user,
                isAdministrator: action.isAdministrator,
            };
        case types.SET_ENABLED_ASSISTANT:
            return {
                ...state,
                enabledAssistant: action.enabledAssistant,
            };
        case types.UPDATE_PENDINGACTIONS_PEP:
            return {
                ...state,
                user: {
                    ...state.user,
                    pepCompleted: true,
                },
            };
        case types.UPDATE_PENDINGACTIONS_IRS:
            return {
                ...state,
                user: {
                    ...state.user,
                    irsCompleted: true,
                },
            };
        case loginTypes.MARK_ENVIRONMENTS_DISABLED:
            return {
                ...state,
                environments: action.environments,
            };
        case loginTypes.FINGERPRINT_LOGIN_PRE_SUCCESS:
            return { ...state, activeEnvironment: action.environment };
        case types.CHANGE_ENVIRONMENT_REQUEST:
            return { ...state, fetching: true, changingEnvironment: true };
        case types.CHANGE_ENVIRONMENT_FAILURE:
            return { ...state, fetching: false, changingEnvironment: false };
        case types.CHANGE_ENVIRONMENT_SUCCESS:
            return {
                ...state,
                fetching: false,
                changingEnvironment: false,
                activeEnvironment: action.environment,
                environments: action.environments,
                isAdministrator: action.isAdministrator,
                signatureLevel: action.signatureLevel,
            };

        case types.UPDATE_PASSWORD_EXPIRATION_CONDITIONS:
            return {
                ...state,
                daysBeforeExpirePassword: action.daysBeforeExpirePassword,
                notifyPasswordExpiration: action.notifyPasswordExpiration,
            };
        case types.SET_TOKENS:
            return { ...state, accessToken: action.accessToken, refreshToken: action.refreshToken };
        case settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    securitySeal: action.securitySeal,
                },
            };
        case settingsTypes.CHANGE_DEFAULT_ENVIRONMENT_PRE_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    idDefaultEnvironment: action.idDefaultEnvironment,
                },
            };
        case settingsTypes.UPDATE_PERMISSION_LOCAL: {
            if (!action.permissions) {
                return state;
            }
            const { activeEnvironment } = state;
            const newEnviroment = { ...activeEnvironment, permissions: action.permissions };
            return {
                ...state,
                activeEnvironment: newEnviroment,
            };
        }
        case types.MASK_AMOUNT_UPDATE: {
            return { ...state, productsMaskAmount: action.productsMaskAmount };
        }
        case types.MAIL_UPDATE_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    email: action.emailChange,
                },
            };
        case types.SET_IS_ACTIVE_CORPORATE:
            return {
                ...state,
                isActiveCorporate: action.isActive,
            };
        case types.SET_SIGNATURE_LEVEL:
            return {
                ...state,
                signatureLevel: action.signatureLevel,
            };
        case types.SET_PRODUCT_TYPES:
            return {
                ...state,
                productTypes: action.productTypes,
            };
        case types.SET_STATUS_MODALS:
            return {
                ...state,
                statusModals: action.statusModals,
            };
        case types.CHANGE_STATUS_MODAL:
            return {
                ...state,
                statusModals: {
                    ...state.statusModals,
                    [action.modalId]: action.status,
                },
            };
        case types.OPEN_SAT_SERVICES_REQUEST:
            return { ...state, isfetchingToken: true };
        case types.OPEN_SAT_SERVICES_SUCCESS:
        case types.OPEN_SAT_SERVICES_FAILURE:
            return { ...state, isfetchingToken: false };
        case types.SET_CREDENTIAL_TYPE:
            return { ...state, credentialType: action.credentialType };
        case types.SET_OTP_METHOD:
            return { ...state, otpMethod: action.otpMethod };
        case types.SET_MEDIA_CONTACT:
            return { ...state, email: action.email, mobileNumber: action.mobileNumber };
        case types.SET_MOBILE_MENU_CLOSED:
            return { ...state, mobileMenuClosed: !state.mobileMenuClosed };
        case types.LOAD_FP_DATA_SUCCESS:
            return { ...state, fpDataDevice: action.fpDataDevice };
        case types.LOGOUT_EXTERNAL_REQUEST:
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default persistReducer(
    {
        key: "session",
        storage: storageSession,
        blacklist: ["showCaptcha"],
    },
    reducer,
);

export const actions = {
    logout: () => ({
        type: types.LOGOUT_REQUEST,
    }),
    logoutUserBlocked: () => ({
        type: types.LOGOUT_USER_BLOCKED_REQUEST,
    }),
    changeEnvironment: (idEnvironment, rememberEnvironment, formikBag, isDesktop) => ({
        type: types.CHANGE_ENVIRONMENT_REQUEST,
        idEnvironment,
        rememberEnvironment,
        formikBag,
        isDesktop,
    }),
    extend: () => ({
        type: types.EXTEND,
    }),
    expire: (lastHref) => ({
        type: types.EXPIRE,
        lastHref,
    }),
    setTokens: makeActionCreator(types.SET_TOKENS, "accessToken", "refreshToken"),
    // maskAmountUpdateRequest: (maskAmount) => ({ type: types.MASK_AMOUNT_UPDATE_REQUEST, "", "maskAmount" }),
    maskAmountUpdateRequest: makeActionCreator(types.MASK_AMOUNT_UPDATE_REQUEST, "idProduct", "maskAmount"),
    maskAmountUpdate: (productsMaskAmount) => ({ type: types.MASK_AMOUNT_UPDATE, productsMaskAmount }),

    emailUpdateSuccess: (emailChange) => ({ type: types.MAIL_UPDATE_SUCCESS, emailChange }),

    isActiveCorporate: makeActionCreator(types.SET_IS_ACTIVE_CORPORATE, "isActive"),

    changeModalStatus: makeActionCreator(types.CHANGE_STATUS_MODAL, "modalId", "status"),
    setAcceptModal: makeActionCreator(types.SET_ACCEPT_MODAL_REQUEST, "modalId"),
    openSATServices: makeActionCreator(types.OPEN_SAT_SERVICES_REQUEST, "onFinish"),
    setMobileMenuClosed: makeActionCreator(types.SET_MOBILE_MENU_CLOSED),
    loadFPData: makeActionCreator(types.LOAD_FP_DATA_REQUEST),
    setStateApp: makeActionCreator(types.SET_STATE_APP, "state"),
    showNotificationNotAuthorized: makeActionCreator(types.SHOW_NOTIFICATION_NOT_AUTHORIZED),
    logoutExternal: () => ({
        type: types.LOGOUT_EXTERNAL_REQUEST,
    }),
};

export const selectors = {
    getAccessToken: ({ session }) => {
        if (
            configUtils.get("core.sessionHandler.componentFQN") ===
            "com.technisys.omnichannel.core.session.DbSessionHandler"
        ) {
            return session.user ? session.user.accessToken : null;
        }
        return session.accessToken;
    },
    isLoggedIn: ({ session }) => {
        if (
            configUtils.get("core.sessionHandler.componentFQN") ===
            "com.technisys.omnichannel.core.session.DbSessionHandler"
        ) {
            return session.user && !!session.user.accessToken;
        }
        return !!session.accessToken;
    },
    getUser: ({ session }) => session.user,
    getUsername: ({ session }) => (session.user ? session.user.username : null),
    getUserFullName: ({ session }) => (session.user ? session.user.userFullName : ""),
    getEnvironments: ({ session }) => session.environments,
    getEnabledAssistant: ({ session }) => session.enabledAssistant,
    getActiveEnvironment: ({ session }) => session.activeEnvironment,
    getUserSecuritySeal: ({ session }) => (session.user ? session.user.securitySeal : null),
    isFetching: ({ session }) => session.fetching,
    isAdministrator: ({ session }) => session.isAdministrator,
    getAdministrationScheme: ({ session }) => session.activeEnvironment.administrationScheme,
    hasPermissions: ({ session }, permissions) =>
        !permissions ||
        !permissions.find(
            (permission) => Object.keys(session?.activeEnvironment?.permissions || {}).indexOf(permission) === -1,
        ),
    getMaskAmountUpdate: ({ session }) => session.productsMaskAmount || {},
    isActiveCorporate: ({ session }) => session.isActiveCorporate,
    getSignatureLevel: ({ session }) => session.signatureLevel,
    getProductTypes: ({ session }) => session.productTypes,
    getStatusModals: ({ session }) => session.statusModals,
    isfetchingToken: ({ session }) => session.isfetchingToken,
    getUserEmail: ({ session }) => (session.user ? session.user.email : null),
    getIdDefaultEnvironment: ({ session }) => (session.user ? session.user.idDefaultEnvironment : null),
    getCredentialType: ({ session }) => session.credentialType,
    getOtpMethod: ({ session }) => session.otpMethod,
    getEmail: ({ session }) => session.email,
    getMobileNumber: ({ session }) => session.mobileNumber,
    getMobileMenuClosed: ({ session }) => session.mobileMenuClosed,
    getFpData: ({ session }) => session.fpDataDevice,
    daysBeforeExpirePassword: ({ session }) => session.daysBeforeExpirePassword,
    notifyPasswordExpiration: ({ session }) => session.notifyPasswordExpiration,
    isChangingEnvironment: ({ session }) => session.changingEnvironment,
    accessTokenApp: ({ session }) => session.accessToken,
};
