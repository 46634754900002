import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Table from "rc-table";

import classNames from "classnames";
import * as i18n from "util/i18n";
import NoResults from "pages/_components/NoResultsMessage";
import PaginatorButtonsBar from "./PaginatorButtonsBar";
import Box from "./Box";
import Text from "./Text";
import { noop } from "./utils";

const PaginatedDataTable = ({
    data,
    rowsPerPage,
    className,
    columns,
    populateRows,
    onRowClick,
    hasFilterApplied,
    showNumbersResults,
    messageNoRecords,
    rowClassNameFunc,
    startPage,
    onChangePage,
    noRecords,
}) => {
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [currentPage, setCurrentPage] = useState(startPage || 1);

    // Calculo de indice de inicio y final
    const indexOfLastItem = currentPage * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);

            if (startPage) {
                setCurrentPage(startPage);
            }
        } else {
            setCurrentPage(1);
        }
    }, [data]);

    useEffect(() => {
        if (onChangePage) {
            onChangePage(currentPage);
        }
    }, [currentPage]);

    // filas a mostrar actual pagina
    const currentRows = data.slice(indexOfFirstItem, indexOfLastItem);

    // Cambiar de página
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const goToFirstPage = () => {
        paginate(1);
    };

    const goToPreviousPage = () => {
        paginate(currentPage - 1);
    };

    const goToNextPage = () => {
        paginate(currentPage + 1);
    };

    const goToLastPage = () => {
        paginate(Math.ceil(data.length / rowsPerPage));
    };

    const poulateData = () => populateRows(currentRows);

    return (
        <Box className="mb-7">
            {showNumbersResults && (
                <Box fullWidth display="flex" alignY="center" className="my-5">
                    <Text
                        labelKey="global.pagination.results"
                        color="text"
                        size="5"
                        rows={
                            currentPage === 1
                                ? currentRows.length
                                : (currentPage - 1) * rowsPerPage + currentRows.length
                        }
                        totalRows={data.length}
                    />
                </Box>
            )}

            {hasFilterApplied && currentRows.length === 0 ? (
                <Box className="my-9">
                    <NoResults />
                </Box>
            ) : (
                <>
                    {noRecords && currentRows.length === 0 ? (
                        <>{noRecords()}</>
                    ) : (
                        <Table
                            className={classNames("table-default", className)}
                            columns={columns}
                            data={poulateData()}
                            rowKey={(record) => record.key}
                            emptyText={i18n.get(messageNoRecords)}
                            rowClassName={rowClassNameFunc}
                            onRow={(record) =>
                                !record?.disabled && {
                                    onClick: () => onRowClick(record),
                                }
                            }
                        />
                    )}
                </>
            )}

            <Box className={classNames("pagination-component mt-5", {
                "empty-table": currentRows?.length === 0
            })}>
                <PaginatorButtonsBar
                    page={data.length === 0 ? 0 : currentPage}
                    totalpages={Math.ceil(data.length / rowsPerPage)}
                    goToFirstPage={goToFirstPage}
                    goToPreviousPage={goToPreviousPage}
                    goToNextPage={goToNextPage}
                    goToLastPage={goToLastPage}
                />
            </Box>
        </Box>
    );
};

PaginatedDataTable.propTypes = {
    columns: arrayOf(shape({})).isRequired,
    data: arrayOf(shape({})),
    rowsPerPage: number,
    populateRows: func.isRequired,
    onRowClick: func,
    className: string,
    hasFilterApplied: bool,
    showNumbersResults: bool,
    messageNoRecords: string,
    rowClassNameFunc: func,
    startPage: number,
    onChangePage: func,
    noRecords: func,
};

PaginatedDataTable.defaultProps = {
    data: [],
    rowsPerPage: 5,
    onRowClick: noop,
    className: "",
    hasFilterApplied: false,
    showNumbersResults: true,
    messageNoRecords: "global.noRecords",
    rowClassNameFunc: undefined,
    startPage: undefined,
    onChangePage: undefined,
    noRecords: undefined,
};

export default PaginatedDataTable;
