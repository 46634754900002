/* eslint-disable no-nested-ternary */
import { resizableRoute } from "pages/_components/Resizable";

import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, func, node, number, string } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import CircularProgressBar from "../CircularProgressBar";
import { isMobileNativeFunc } from "util/device";

const Tooltip = ({
    ariaLabel,
    children,
    className,
    classNameTooltip,
    forEllipsis,
    image,
    inText,
    inverseIconColor,
    isDesktop,
    onHover,
    position,
    text,
    title,
    tooltipInfo,
    tooltipLightSyled,
    showProgressBar,
    percentProgressBar,
    buttonId,
    showTooltip,
    handleClick,
}) => {
    const botonRef = useRef(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (!showTooltip) {
            setShow(showTooltip);
        }
    }, [showTooltip]);

    // mobile click fuera del tooltip
    const handleClickOutside = (event) => {
        if (isMobileNativeFunc()) {
            const clickedOutside =  (botonRef.current && !botonRef.current.contains(event.target));
            if (clickedOutside) {
                setShow(false);
            }
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            {children !== null ? (
                forEllipsis ? (
                    <>
                        <Button
                            id={`buttonId${buttonId}`}
                            // ref={botonRef}
                            // ref={(el) => (botonRef.current[buttonId] = el)}
                            ref={botonRef}
                            {...(onHover && isDesktop
                                ? {
                                      onMouseEnter: (e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setShow(!show);
                                      },
                                      onMouseLeave: (e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setShow(!show);
                                      },
                                  }
                                : {
                                      onClick: (e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setShow(!show);
                                      },
                                  })}
                            onKeyDown={(e) => {
                                if (e.key === "Escape") {
                                    setShow(false);
                                }
                            }}
                            onBlur={() => {
                                setShow(false);
                            }}
                            className={classNames(
                                "tooltip-trigger-container tooltip-trigger-container-with-children  ml-0",
                                className,
                                {
                                    "z-index-win": show,
                                },
                            )}>
                            {children}
                            {show ? (
                                <Box
                                    role="tooltip"
                                    show={show}
                                    className={classNames(
                                        "tooltip-text-container",
                                        classNameTooltip,
                                        `tooltip-${position}`,
                                    )}>
                                    {inText ? (
                                        <Text className="tooltip-text">{inText}</Text>
                                    ) : (
                                        <Text labelKey={text} className="tooltip-text" />
                                    )}
                                </Box>
                            ) : null}
                        </Button>
                    </>
                ) : (
                    <>
                        {show ? (
                            <Box
                                role="tooltip"
                                className={classNames(
                                    "tooltip-text-container tooltip-in-input",
                                    classNameTooltip,
                                    `tooltip-${position}`,
                                    { "tooltip-light-styled": tooltipLightSyled },
                                )}>
                                {children}
                            </Box>
                        ) : null}
                    </>
                )
            ) : (
                <>
                    <Button
                        id={`buttonId${buttonId}`}
                        // ref={(el) => (botonRef.current[buttonId] = el)}
                        ref={botonRef}
                        ariaLabel={ariaLabel}
                        // onClick={(e) => {
                        //     e.preventDefault();
                        //     e.stopPropagation();
                        //     setShow(!show);
                        //     handleClick();
                        // }}
                        onKeyDown={(e) => {
                            if (e.key === "Escape") {
                                setShow(false);
                            }
                        }}
                        onBlur={() => {
                            setShow(false);
                        }}
                        className={classNames("tooltip-trigger-container", className, {
                            "z-index-win": show,
                        })}
                        {...(onHover && isDesktop
                            ? {
                                  onMouseEnter: (e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setShow(!show);
                                  },
                                  onMouseLeave: (e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setShow(!show);
                                  },
                              }
                            : {
                                  onClick: (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setShow(!show);
                                    handleClick();
                                  },
                              })}
                        >
                        {image && !showProgressBar && (
                            <Image
                                className={classNames("tooltip-img", { "tooltip-img-inverse": inverseIconColor })}
                                src={image}
                            />
                        )}
                        {showProgressBar && <CircularProgressBar percentage={percentProgressBar} />}

                        {title && <span className="tooltip-title">{title}</span>}
                        {show ? (
                            <Box
                                role="tooltip"
                                show={show}
                                className={classNames(
                                    "tooltip-text-container",
                                    classNameTooltip,
                                    `tooltip-${position}`,
                                    { "tooltip-light-styled": tooltipLightSyled },
                                )}>
                                {inText ? (
                                    <Text className="tooltip-text">{inText}</Text>
                                ) : tooltipInfo ? (
                                    <>{tooltipInfo}</>
                                ) : (
                                    <Text labelKey={text} className="tooltip-text" />
                                )}
                            </Box>
                        ) : null}
                    </Button>
                </>
            )}
        </>
    );
};

Tooltip.propTypes = {
    ariaLabel: string,
    children: node,
    className: string,
    classNameTooltip: string,
    forEllipsis: bool,
    image: string,
    inText: string,
    inverseIconColor: bool,
    isDesktop: bool.isRequired,
    onHover: bool,
    position: string,
    text: string,
    title: string,
    tooltipInfo: node,
    tooltipLightSyled: bool,
    showProgressBar: bool,
    percentProgressBar: number,
    buttonId: string,
    showTooltip: bool,
    handleClick: func,
};

Tooltip.defaultProps = {
    ariaLabel: "Tooltip",
    children: null,
    className: null,
    classNameTooltip: null,
    forEllipsis: false,
    image: "images/tooltip-icon.svg",
    inText: null,
    inverseIconColor: false,
    onHover: false,
    position: "bottom-centered",
    text: null,
    title: null,
    tooltipInfo: null,
    tooltipLightSyled: false,
    showProgressBar: false,
    percentProgressBar: 0,
    showTooltip: false,
    buttonId: "tooltip",
    handleClick: () => {},
};

export default resizableRoute(Tooltip);
