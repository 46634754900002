import { resizableRoute } from "pages/_components/Resizable";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";

import React, {Fragment} from "react";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";


const DummyPage = ( {title,backURL} ) => {




    return (
        <>
            <Head titleText={title} backLinkTo={backURL} />
            <MainContainer
                className="main-container border-radius-lg p-7 mt-3 box-shadow-small background-white"
                showLoader={false}>
               <Fragment>
                   Página en desarrollo
               </Fragment>
            </MainContainer>

        </>
    );
};

const mapStateToProps = (state) => ({
    userFullName: sessionSelectors.getUserFullName(state),
});



export default connect(mapStateToProps)(resizableRoute(DummyPage));
