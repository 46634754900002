import React, { Component } from "react";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { selectors as sessionSelectors, actions as sessionActions } from "reducers/session";
import { func, shape, bool, arrayOf, string } from "prop-types";
import { withRouter } from "react-router-dom";
import { resizableRoute } from "pages/_components/Resizable";
import { actions as notificationActions } from "reducers/notification";
import { get as getMessage } from "util/i18n";
import WithPermissions from "./WithPermissions";

class WithPermissionsRedirect extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        permissions: arrayOf(string),
        children: shape({}),
        comparator: func,
        somePermissions: bool,
        activeEnvironment: shape({}).isRequired,
        location: shape({}).isRequired,
        showAlertMessage: bool,
    };

    static defaultProps = {
        permissions: null,
        children: null,
        comparator: null,
        somePermissions: false,
        showAlertMessage: false,
    };

    redirectFunc = () => {
        const { dispatch, permissions, activeEnvironment, location, showAlertMessage } = this.props;

        if (permissions != null && (permissions.includes("loans") || permissions.includes("accounts"))) {
            dispatch(
                notificationActions.showNotification(getMessage("products.operation.NoDispose"), "error", ["menu"]),
            );
        } else {
            dispatch(notificationActions.showNotification(getMessage("returnCode.COR005E"), "error", ["menu"]));
        }
        if (
            permissions?.includes("transferInternal") ||
            permissions?.includes("transferLocal") ||
            permissions?.includes("transferForeign")
        ) {
            if (activeEnvironment.permissions.transferInternal) {
                dispatch(push("/formCustom/transferInternal"));
                return;
            }
            if (activeEnvironment.permissions.transferLocal) {
                if (location.pathname === "/formCustom/transferInternal") {
                    dispatch(push("/desktop"));
                    dispatch(
                        notificationActions.showNotification(getMessage("products.operation.NoDispose"), "error", [
                            "menu",
                        ]),
                    );
                } else {
                    dispatch(push("/formCustom/transferLocal"));
                }
                return;
            }
            if (activeEnvironment.permissions.transferForeign) {
                dispatch(push("/formCustom/transferForeign"));
                return;
            }
        }
        if (showAlertMessage) { 
            dispatch(
                sessionActions.showNotificationNotAuthorized(),
            );
        } else {
            dispatch(push("/desktop"));
        }
    };

    render() {
        const { permissions, children, comparator, somePermissions } = this.props;

        return (
            <WithPermissions
                somePermissions={somePermissions}
                permissions={permissions}
                comparator={comparator || null}
                redirectFunc={() => this.redirectFunc()}>
                {children}
            </WithPermissions>
        );
    }
}

function mapStateToProps(state) {
    return {
        activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    };
}

export default withRouter(connect(mapStateToProps)(resizableRoute(WithPermissionsRedirect)));
