import * as i18n from "util/i18n";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { DEFAULT_REQUIRED, validationDocumentNumberEcu } from "util/validationSchemaUtil";

export const PREFIX = "check.request.form";

const ID_FORM = "check.request";
const ID_ACTIVITY = `${ID_FORM}.send`;

export const validationSchema = () =>
    Yup.object().shape({
        checkTypeCode: Yup.string().required(i18n.get("global.field.required")),
        accountNumber: Yup.string().required(i18n.get("global.field.required")),
        cityCode: Yup.string().required(i18n.get("global.field.required")),
        deliveryOfficeCode: Yup.string().required(i18n.get("global.field.required")),
        enableWithdraw: Yup.boolean(),
        documentType: Yup.string().when("enableWithdraw", {
            is: "true",
            then: Yup.string().required(i18n.get("global.field.required")),
            otherwise: Yup.string().nullable(),
        }),
        withdrawPersonIdentification: Yup.string().when("enableWithdraw", {
            is: "true",
            then: Yup.string().required(i18n.get("global.field.required")),
            otherwise: Yup.string().nullable(),
        }),
        withdrawFullName: Yup.string().when("enableWithdraw", {
            is: "true",
            then: Yup.string().required(i18n.get("global.field.required")),
            otherwise: Yup.string().nullable(),
        }),
    });

export const validateForm = (values) => {
    let isValid = false;
    let isValidWithdraw = false;

    if (
        values.accountNumber == "" ||
        values.checkTypeCode == "" ||
        values.checkSequential == "" ||
        values.cityCode == "" ||
        values.deliveryOfficeCode == ""
    ) {
        isValid = false;
    } else {
        isValid = true;
    }
    if (
        values.enableWithdraw &&
        values.withdrawPersonIdentificationType != "" &&
        values.withdrawPersonIdentification != "" &&
        values.withdrawFullName != ""
    ) {
        isValidWithdraw = true;
    } else if (isValid) {
        isValidWithdraw = false;
    }
    return values.enableWithdraw ? isValidWithdraw && isValid : isValid;
};

export const initialValues = (props) => {
    const values = {
        accountNumber: "",
        checkTypeCode: "",
        checkSequential: "",
        cityCode: "",
        deliveryOfficeCode: "",
        enableWithdraw: true,
        withdrawPersonIdentificationType: "",
        withdrawPersonIdentification: "",
        withdrawFullName: "",
        document: {
            type: "CED",
            document: "",
        },
    };

    return values;
};

export const useAccountPaySelectorOptions = (debitAccountList) => {
    const [accountOptions, setAccountOptions] = useState([]);

    const createAccountList = (acc) => ({
        ...acc,
        balance: { currency: acc.currency, quantity: acc.balance },
        id: acc.idProduct,
        disabled: acc.isDisabled,
    });
    useEffect(() => {
        setAccountOptions({
            options: debitAccountList?.map(createAccountList),
        });
    }, [debitAccountList]);
    return accountOptions;
};

export const useMetaData = (preDataForm, idActivity) => {
    const [metadata, setMetadata] = useState({
        draftsEnabled: false,
        templatesEnabled: false,
        schedulable: true,
        programable: true,
        idActivity: idActivity || ID_ACTIVITY,
    });
    useEffect(() => {
        const { nonWorkingDays, enabledWeekDays, firstWorkingDate = new Date(), maxDaysToSchedule } = preDataForm;
        setMetadata((state) => ({
            ...state,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
        }));
    }, [preDataForm]);
    return [metadata];
};
