import React from "react";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func } from "prop-types";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { routerActions } from "react-router-redux/actions";
import { selectors as payServiceSelectors, actions as actionsSelectors } from "reducers/payService";
import { actions as notificationActions } from "reducers/notification";
import { compose } from "redux";
import classNames from "classnames";
import Image from "pages/_components/Image";
import ServiceCategoryCard from "pages/servicePayments/_components/ServiceCategoryCard";
import Dropdown from "pages/_components/Dropdown";
import * as i18n from "util/i18n";
import { isMobile } from "react-device-detect";
import { getServiceCategoriesList } from "./hooks/servicePaymentsHooks";
import { push } from "react-router-redux";

export const FORM_ID = "servicePayments.list";
export const ID_ACTIVITY = `payService.list`;

const ServicePaymentList = (props) => {
    const { fetching, dispatch, isDesktop } = props;

    const handleBack = () => {
        dispatch(routerActions.push("/desktop"));
    };

    const options = [
        {
            label: "servicePayments.btn.newPayment",
            onClick: () => {
                handleGoClick("/servicePayments/newPay/form")},
        },
        {
            label: "servicePayments.btn.enroll",
            onClick: () => {
                handleGoClick("/servicePayments/registerService/form")
            },

        },
    ];

    const handleGoClick = (url) => {
        dispatch(push(url));
    };


    const handleClick = (enabled) => {
        if (!enabled) {
            dispatch(
                notificationActions.showNotification(i18n.get(`${FORM_ID}.channel.disabled`), "warning", [
                    "servicePayments",
                ]),
            );
        }
        dispatch(actionsSelectors.clean());
    };

    return (
        <>
            <Notification scopeToShow="servicePayments" />
            <Head
                title={`${FORM_ID}.title`}
                {...(!isDesktop && { onBack: handleBack })}
                rightChildren={
                    isDesktop ? (
                        <Box display="flex" gap="7">
                            <Button
                                bsStyle="outline"
                                className="btn-width-lg"
                                label="servicePayments.btn.enroll"
                                onClick={() => {
                                    handleGoClick("/servicePayments/registerService/form")
                                }}
                            />
                            <Button
                                imageMd
                                image="images/icons/circlePlus.svg"
                                bsStyle="primary"
                                className="btn-width-lg"
                                label="servicePayments.btn.newPayment"
                                onClick={() => {
                                    handleGoClick("/servicePayments/newPay/form")
                                }}
                            />
                        </Box>
                    ) : (
                        <Dropdown image="images/icons/dropdown-dots.svg">
                            {options.map((item) => (
                                <Button
                                    key={item.label}
                                    label={item.label}
                                    onClick={() => {
                                        item.onClick();
                                    }}
                                    className="dropdown__item-btn"
                                    bsStyle="link"
                                />
                            ))}
                        </Dropdown>
                    )
                }
            />
            <MainContainer
                showLoader={fetching}
                className={classNames(
                    "main-container border-radius-lg box-shadow-small background-white texture-header",
                    {
                        "p-7": isDesktop,
                        "p-5": !isDesktop,
                    },
                )}>
                <Box
                    borderRadius="lg"
                    fullWidth
                    display="flex"
                    background="white"
                    className="px-7 py-6 mb-7 box-shadow-small">
                    <Box display="flex" alignY="center" fullWidth>
                        <Box fullWidth>
                            <Box>
                                <Text
                                    component="h3"
                                    labelKey={`${FORM_ID}.index.title`}
                                    size={3}
                                    bold
                                />
                            </Box>
                            <Box className="mt-4">
                                <Text
                                    labelKey={`${FORM_ID}.index.info`}
                                    color="text-grey-color"
                                    size={isMobile ? "4" : "5"}
                                    light
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" alignY="center" className="width-security ml-3">
                        <Image src="images/icons/servicePayments_header.svg" />
                    </Box>
                </Box>
                <Box>
                    <Text size={isMobile ? "3" : "5"} labelKey={`${FORM_ID}.choose.label`} bold />
                </Box>
                <Box className="mt-5 mb-7">
                    <Row
                        gapY={isDesktop ? "5" : "3"}
                        className={classNames({
                            "row-template-1fr": !isDesktop,
                        })}>
                        {getServiceCategoriesList().map((elem) => (
                            <Col xs={3} key={elem?.idServicePayment}>
                                <ServiceCategoryCard elem={elem} onClick={handleClick} />
                            </Col>
                        ))}
                    </Row>
                </Box>
            </MainContainer>
        </>
    );
};

const mapStateToProps = (state) => ({
    fetching: payServiceSelectors.isFetching(state),
});

ServicePaymentList.propTypes = {
    dispatch: func.isRequired,
    fetching: bool,
    isDesktop: bool,
};

ServicePaymentList.defaultProps = {
    fetching: false,
    isDesktop: true,
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(ServicePaymentList));
