import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Box from "pages/_components/Box/Box";
import Button from "pages/_components/Button/Button";
import Table from "rc-table";
import PaginatorButtonsBar from "pages/_components/PaginatorButtonsBar";
import NoResults from "pages/_components/NoResultsMessage";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, number, shape } from "prop-types";
import { actions as documentsActions, selectors as documentsSelectors } from "reducers/documents";
import Text from "pages/_components/Text/Text";
import * as i18n from "util/i18n";
import Image from "pages/_components/Image";
import FormattedDate from "pages/_components/FormattedDate";
import classNames from "classnames";
import { formatNumber } from "util/format";
import { parseISOAlternative } from "util/date";
import { Col, Collapse } from "react-bootstrap";
import Row from "pages/_components/Row";

const TableOperationalDocuments = ({
    dispatch,
    isDesktop,
    isFetching,
    documents,
    afterSearch,
    rowsPerPage,
    downloadState,
    renderFilterDesktop,
}) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [offset, setOffset] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [open, setOpen] = useState(true);

    const getCurrentDocuments = () => documents.slice(offset, offset + rowsPerPage);

    const currentDocuments = getCurrentDocuments();

    useEffect(() => {
        if (documents) {
            setTotalCount(documents.length);
        }
    }, [documents]);

    const paginate = (nextIndex) => {
        setOffset(nextIndex);
    };

    const goToFirstPage = () => {
        paginate(0);
        setPageNumber(1);
    };

    const goToPreviousPage = () => {
        paginate((pageNumber - 1) * rowsPerPage - rowsPerPage);
        setPageNumber(pageNumber - 1);
    };

    const goToNextPage = () => {
        paginate((pageNumber + 1) * rowsPerPage - rowsPerPage);
        setPageNumber(pageNumber + 1);
    };

    const goToLastPage = () => {
        if (totalCount % rowsPerPage === 0) {
            paginate(totalCount - rowsPerPage);
        } else {
            paginate(Math.floor(totalCount / rowsPerPage) * rowsPerPage);
        }
        setPageNumber(Math.ceil(totalCount / rowsPerPage));
    };

    const renderEmptyList = () => (
        <Box className="m-7">
            <Image src="images/empty.svg" />
            <Box className="text-center mt-6">
                <Text light labelKey="checks.none.text" />
            </Box>
        </Box>
    );

    const openDownloadWindow = (url) => {
        window.open(
            url,
            "_blank",
            `width=${610},height=${420},top=${180},left=${200},resizable=no,scrollbars=yes,status=0`,
        );
    };

    const onClickDonwload = (id) => {
        const data = {
            documentNumber: id,
        };
        console.log("data", data);
        dispatch(documentsActions.downloadOperationalRequest(id.idDocumento, openDownloadWindow));
    };

    const generateTableColumns = () => {
        const columnArray = [];

        columnArray.push({
            key: "date",
            dataIndex: "date",
            title: i18n.get("documents.operational.table.date.label", "Fecha"),
            width: "30%",
        });
        columnArray.push({
            key: "operationalCode",
            dataIndex: "operationalCode",
            title: i18n.get("documents.operational.table.operationalCode.label", "Número de operación"),
            width: "20%",
        });
        columnArray.push({
            key: "amount",
            dataIndex: "amount",
            title: i18n.get("documents.operational.table.amount.label", "Monto"),
            width: "20%",
            className: "text-right pr-3",
        });
        columnArray.push({
            key: "donwload",
            dataIndex: "donwload",
            title: "",
            width: "30%",
            align: "end",
        });

        return columnArray;
    };

    const docsData = () =>
        currentDocuments.map((document) => ({
            key: document?.idDocumento,
            date: (
                <div className="data-wrapper data-wrapper-flex">
                    <FormattedDate date={parseISOAlternative(document?.fecha)} />
                </div>
            ),
            operationalCode: (
                <div className="data-wrapper data-wrapper-flex">
                    <span className="data-desc">{document?.operacion}</span>
                </div>
            ),
            amount: (
                <div>
                    <span className="data-desc text-right">{`${formatNumber(document?.valor, 2)} USD`}</span>
                </div>
            ),
            donwload: (
                <Box display="flex" fullWidth alignX="end">
                    <Button
                        bsStyle="outline"
                        className="outline-download text-regular btn-small"
                        image="images/download.svg"
                        label="accounts.statementLine.button.download"
                        onClick={() => onClickDonwload(document)}
                        imageRight
                        btnUppercase={false}
                        labelClassName="py-0"
                        loading={downloadState[document?.idDocumento]}
                    />
                </Box>
            ),
        }));

    const getResultCounter = () => (
        <Text
            labelKey="global.pagination.results"
            color="text"
            size="5"
            rows={
                totalCount < 1
                    ? totalCount
                    : pageNumber === 1
                    ? currentDocuments?.length
                    : (pageNumber - 1) * rowsPerPage + currentDocuments?.length
            }
            totalRows={totalCount}
        />
    );

    return (
        <>
            {isFetching ? (
                <></>
            ) : (
                <>
                    <Collapse in={isDesktop ? true : open}>{renderFilterDesktop()}</Collapse>

                    <Row>
                        <Col xs={6}>
                            <Box
                                display="flex"
                                alignY="center"
                                alignX="left"
                                className={classNames("full-width justify-content-between", {
                                    "mb-5": !isDesktop,
                                    "mb-7": isDesktop,
                                })}
                                fullHeight>
                                {getResultCounter()}
                            </Box>
                        </Col>
                        <Col xs={6}>
                            {!isDesktop && (
                                <Box display="flex" alignY="center" alignX="end" full>
                                    <Button
                                        label={
                                            open
                                                ? "global.productFilters.btnFilter.opened.label"
                                                : "transactions.button.showFilters"
                                        }
                                        bsStyle="text"
                                        image="images/icons/filter.svg"
                                        className={classNames("mt-auto")}
                                        onClick={() => {
                                            setOpen(!open);
                                        }}
                                    />
                                </Box>
                            )}
                        </Col>
                    </Row>

                    {totalCount > 0 && (
                        <>
                            {isDesktop ? (
                                <Table
                                    className={classNames("table-default")}
                                    columns={generateTableColumns()}
                                    data={docsData()}
                                    rowKey={(record) => record.key}
                                    emptyText={i18n.get("checks.list.noRecords")}
                                />
                            ) : (
                                <Box className="pagination-component">
                                    <Box className="children-alternate-bg-color">
                                        {currentDocuments.map((document) => (
                                            <Box className="border-radius-md p-5 ">
                                                <Box className="full-width display-flex flex-direction-row flex-no-wrap gapX-5">
                                                    <Box className="full-width display-flex flex-direction-column gapY-3 flex">
                                                        <Box className="line-height-15">
                                                            <Text
                                                                className="color-text-grey text-light size-6 display-block"
                                                                labelKey=""
                                                                defaultValue="Fecha"
                                                            />
                                                            <FormattedDate
                                                                size="5"
                                                                date={parseISOAlternative(document?.fecha)}
                                                            />
                                                        </Box>
                                                        <Box className="line-height-15">
                                                            <Text
                                                                className="color-text-grey text-light size-6 display-block"
                                                                labelKey=""
                                                                defaultValue="Número de operación"
                                                            />
                                                            <Text
                                                                className="size-5"
                                                                defaultValue={document.operacion}
                                                            />
                                                        </Box>
                                                    </Box>

                                                    <Box className="full-width display-flex flex-direction-column gapY-3 flex justify-content-between">
                                                        <Box className="line-height-15 text-right">
                                                            <Text
                                                                className="color-text-grey text-light size-6 display-block"
                                                                labelKey=""
                                                                defaultValue="Monto"
                                                            />
                                                            <Text
                                                                className="size-5"
                                                                defaultValue={`${formatNumber(document?.valor, 2)} USD`}
                                                            />
                                                        </Box>
                                                        <Box className="display-flex justify-content-end">
                                                            <Button
                                                                bsStyle="outline"
                                                                className="outline-download text-regular btn-small"
                                                                image="images/download.svg"
                                                                label="accounts.statementLine.button.download"
                                                                onClick={() => onClickDonwload(document)}
                                                                imageRight
                                                                btnUppercase={false}
                                                                labelClassName="py-0"
                                                                loading={downloadState[document?.idDocumento]}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            )}

                            <Box className="pagination-component mt-5">
                                <PaginatorButtonsBar
                                    page={totalCount === 0 ? 0 : pageNumber}
                                    totalpages={Math.ceil(totalCount / rowsPerPage)}
                                    goToFirstPage={goToFirstPage}
                                    goToPreviousPage={goToPreviousPage}
                                    goToNextPage={() => goToNextPage()}
                                    goToLastPage={goToLastPage}
                                />
                            </Box>
                        </>
                    )}
                </>
            )}
            {totalCount === 0 &&
                (!afterSearch ? (
                    renderEmptyList()
                ) : (
                    <Box className="mt-10">
                        {!isFetching && <NoResults description={!isDesktop ? "global.noResults.text.mobile" : null} />}
                    </Box>
                ))}
        </>
    );
};

TableOperationalDocuments.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isFetching: bool.isRequired,
    documents: shape([]).isRequired,
    downloadState: shape({}).isRequired,
    totalRecords: number.isRequired,
    totalPages: number.isRequired,
    pageNumber: number.isRequired,
    rowsPerPage: number.isRequired,
    afterSearch: bool.isRequired,
    renderFilterDesktop: func.isRequired,
};

const mapStateToProps = (state) => ({
    isFetching: documentsSelectors.getFetching(state),
    documents: documentsSelectors.getOperationalDocuments(state),
    totalPages: documentsSelectors.getTotalPages(state),
    totalRecords: documentsSelectors.getTotalRecords(state),
    pageNumber: documentsSelectors.getPageNumber(state),
    downloadState: documentsSelectors.downloadState(state),
});

export default connect(mapStateToProps)(resizableRoute(TableOperationalDocuments));
