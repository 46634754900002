import { types, actions, selectors } from "reducers/documents";
import { call, put, select, takeLatest } from "redux-saga/effects";
import * as docsMiddleware from "middleware/documents";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { isMobileNativeFunc } from "util/device";
import b64toBlob from "b64-to-blob";
import { downloadCSVFile, downloadMobileFile, downloadXml, downloadPdf } from "util/download";

const sagas = [
    takeLatest(types.LIST_REQUEST, swiftDocumentsRequest),
    takeLatest(types.TAX_LIST_REQUEST, taxDocumentsRequest),
    takeLatest(types.TAX_LOAD_MORE_REQUEST, taxLoadMoreData),
    takeLatest(types.OPERATIONAL_LIST_REQUEST, operationalDocumentsRequest),
    takeLatest(types.LOAD_MORE_REQUEST, loadMoreData),
    takeLatest(types.DOWNLOAD_REQUEST, downloadDocument),
    takeLatest(types.TAX_DOWNLOAD_REQUEST, taxDownloadDocument),
    takeLatest(types.OPERATIONAL_DOWNLOAD_REQUEST, operationalDownloadDocument),
];

export default sagas;

function* swiftDocumentsRequest({ filters }) {
    const { typeOperation, startDate, endDate, idAccount, beneficary } = filters;
    try {
        const response = yield call(
            docsMiddleware.swiftDocuments,
            typeOperation,
            startDate,
            endDate,
            idAccount,
            beneficary,
            0,
        );
        if (response && response?.type === "I") {
            const { documents, totalRecords, offset, totalPages } = response.data.data;
            const pageNumber = 1;
            yield put(actions.listDocumentsSuccess(documents, totalRecords, offset, pageNumber, totalPages));
        } else {
            throw new Error();
        }
    } catch (error) {
        yield put(actions.listDocumentsFailure());
    }
}

function* taxDocumentsRequest({ filters }) {
    const { typeDocument, startDate, endDate } = filters;
    try {
        const response = yield call(docsMiddleware.taxDocuments, typeDocument, startDate, endDate, 0);

        if (response && response?.type === "I") {
            const { documents, totalRecords, offset, totalPages } = response.data.data;
            const pageNumber = 1;
            yield put(actions.listTaxDocumentsSuccess(documents, totalRecords, offset, pageNumber, totalPages));
        } else {
            throw new Error();
        }
    } catch (error) {
        yield put(actions.listTaxDocumentsFailure());
    }
}

function* operationalDocumentsRequest({ filters }) {
    const {
        selectedProduct: typeOperation,
        selectedTypeProduct: typeProduct,
        selectedTransaction: typeTransaction,
        selectedDocumentType: typeDocument,
        startDate,
        endDate,
    } = filters;

    try {
        const response = yield call(
            docsMiddleware.operationalDocumentsRequest,
            typeOperation,
            typeProduct,
            typeTransaction,
            typeDocument,
            startDate,
            endDate,
        );

        if (response && response?.type === "I") {
            const { documents } = response.data.data;
            yield put(actions.operationalDocumentsSuccess(documents));
        } else {
            throw new Error();
        }
    } catch (error) {
        yield put(actions.operationalDocumentsFailure());
    }
}

function* loadMoreData({ filters, next }) {
    try {
        const docsMap = yield select(selectors.getDocuments);

        if (docsMap && docsMap[next]) {
            yield put(actions.setPageNumber(next));
            return;
        }

        const { typeOperation, startDate, endDate, idAccount, beneficary } = filters;
        const response = yield call(
            docsMiddleware.swiftDocuments,
            typeOperation,
            startDate,
            endDate,
            idAccount,
            beneficary,
            next,
        );
        if (response && response?.type === "I") {
            const { documents, totalRecords, offset, totalPages } = response.data.data;
            yield put(actions.listDocumentsSuccess(documents, totalRecords, offset, next, totalPages));
        } else {
            throw new Error();
        }
    } catch (error) {
        yield put(actions.listDocumentsFailure());
    }
}

function* taxLoadMoreData({ filters, next }) {
    try {
        const docsMap = yield select(selectors.getTaxDocuments);

        if (docsMap && docsMap[next]) {
            yield put(actions.setPageNumber(next));
            return;
        }

        const { typeDocument, startDate, endDate } = filters;
        const response = yield call(docsMiddleware.taxDocuments, typeDocument, startDate, endDate, next);
        if (response && response?.type === "I") {
            const { documents, totalRecords, offset, totalPages } = response.data.data;
            yield put(actions.listTaxDocumentsSuccess(documents, totalRecords, offset, next, totalPages));
        } else {
            throw new Error();
        }
    } catch (error) {
        yield put(actions.listTaxDocumentsFailure());
    }
}

function* downloadDocument({ idRecord, data: dataFilters, onFinish }) {
    try {
        const { type, data } = yield call(docsMiddleware.downloadDocument, dataFilters);
        onFinish();
        if (type === "W") {
            yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["documents"]));

            yield put(actions.downloadFailure(idRecord));
        } else {
            yield put(actions.downloadSuccess(idRecord));
            if (!data?.data) {
                yield put(
                    notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["documents"]),
                );
                yield;
                return;
            }
            const contentType = "text/plain";
            const fileName = `document_${idRecord}.txt`;
            const { document } = data.data;

            if (isMobileNativeFunc()) {
                const fileBlob = b64toBlob(document, contentType);
                downloadMobileFile(fileBlob, fileName, contentType);
                yield;
                return;
            }
            downloadCSVFile(fileName, document);
        }
    } catch (error) {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["documents"]));
        yield put(actions.downloadFailure(idRecord));
        onFinish();
    }
}

function* taxDownloadDocument({ idRecord, data: dataFilters }) {
    try {
        const { type, data } = yield call(docsMiddleware.taxDownloadDocument, dataFilters);

        if (type === "W") {
            yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["documents"]));

            yield put(actions.downloadTaxFailure(idRecord));
        } else {
            yield put(actions.downloadTaxSuccess(idRecord));
            if (!data?.data) {
                yield put(
                    notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["documents"]),
                );
                yield;
                return;
            }
            const { format } = dataFilters;
            const contentType = "text/plain";
            const fileName = `document_${idRecord}.${format}`;
            const { document } = data.data;

            if (isMobileNativeFunc()) {
                const content = btoa(encodeURIComponent(document));
                const fileBlob = b64toBlob(content, contentType);
                downloadMobileFile(fileBlob, fileName, contentType);
                yield;
                return;
            }
            if (format === "pdf") {
                downloadPdf(fileName, document);
            } else {
                downloadXml(fileName, document);
            }
        }
    } catch (error) {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["documents"]));
        yield put(actions.downloadTaxFailure(idRecord));
    }
}

function* operationalDownloadDocument({ idRecord, onFinish }) {
    try {
        const response = yield call(docsMiddleware.operationalDownloadDocument, idRecord);

        if (response.type === "W") {
            yield put(
                notificationActions.showNotification(
                    i18n.get(
                        "operationalDownload.unexpectedError",
                        "Estimado cliente, su documento no se encuentra disponible.",
                    ),
                    "warning",
                    ["documents"],
                ),
            );
            yield put(actions.downloadFailure(idRecord));
        } else {
            yield put(actions.downloadSuccess(idRecord));
            onFinish(response?.data?.data?.downloadUrl);
        }
    } catch (error) {
        yield put(
            notificationActions.showNotification(
                i18n.get(
                    "operationalDownload.unexpectedError",
                    "Estimado cliente, su documento no se encuentra disponible.",
                ),
                "warning",
                ["documents"],
            ),
        );
        yield put(actions.downloadFailure(idRecord));
    }
}
