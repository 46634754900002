import * as i18n from "util/i18n";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import * as creditCardsUtil from "util/creditCards";
import { ouputFieldValues, inputFieldValues } from "./servicePaymentsHooks";

export const PREFIX = "servicePayments.registeredServices.list";


const ID_FORM = "serviceRegistration";
const ID_ACTIVITY = `${ID_FORM}.send`;

export const getOuputFieldValues = (billBalance, field) => {
    let ouputField = [];
    if (billBalance) {
        const fieldFiltered = field.filter((el) => el.fieldInq === false && el.serviceOper === "BillInqRs");
        ouputField = ouputFieldValues(fieldFiltered, billBalance);
    }
    return [ouputField];
};

export const getPmtUtilAddRqValues = (billBalance, field, queryBill) => {
    const ouputField = [];
    const arrayNoFields = ["SubTotal", "TotalTax", "TotalPmt", "TotalBalance"];
    // aumento noFields los valores ya ingresados por el usuario
    if (queryBill) {
        const tmp = queryBill?.paymentUser?.billInqRq;
        tmp.forEach((el) => {
            arrayNoFields.push(el.label);
        });
    }
    if (billBalance) {
        // Datos resultado de consulta
        const fieldFiltered = field.filter(
            (el) => el.serviceOper === "PmtUtilAddRq" && !arrayNoFields.some((str) => str === el.serviceField),
        );

        const tmp = ouputFieldValues(fieldFiltered, billBalance, true);
        tmp.forEach((el) => {
            ouputField.push({ label: el?.serviceField, value: `${el?.value}` });
        });
    }
    // Datos ingresados por usuario
    if (queryBill) {
        const fieldFiltered = field.filter(
            (el) =>
                el.serviceOper === "PmtUtilAddRq" &&
                !arrayNoFields.some((str) => str === el.serviceField && el.fieldInq),
        );
        const values = queryBill?.paymentUser?.pmtUtilAddRq;
        fieldFiltered.forEach((element) => {
            if (values.length > 0) {
                values.forEach((el) => {
                    if (
                        el?.label === element.serviceField &&
                        ouputField.filter((elem) => elem.label === el?.label).length === 0
                    ) {
                        ouputField.push({ label: element.serviceField, value: el?.value });
                    }
                });
            }
        });
    }
    return ouputField;
};

export const getInputFieldValues = (props) => {
    const { queryBill, field, transaction } = props;
    let inputField = [];
    const fieldFiltered = field.filter((el) => el.fieldInq === true);
    if (queryBill) {
        inputField = inputFieldValues(fieldFiltered, queryBill);
    } else {
        const tmpPaymentUser = {
            ...transaction?.data?.paymentUser,
            billInqRq: JSON.parse(transaction?.data?.paymentUser?.billInqRq),
            pmtUtilAddRq: JSON.parse(transaction?.data?.paymentUser?.pmtUtilAddRq),
        };
        const tmp = {
            paymentUser: tmpPaymentUser,
            biller: transaction?.data?.biller,
            savePayment: false,
        };
        inputField = inputFieldValues(fieldFiltered, tmp);
    }
    return [inputField];
};

export const validateDinamicForm = (values, props, typeAccount) => {
    const errors = {};

    // validacion cuenta|tarjeta seleccionada
    if (typeAccount === "debitAccount" && values.debitAccount === "") {
        errors.debitAccount = i18n.get(`servicePayment.dinamicForm.validate.required`);
    }
    if (typeAccount === "creditCard" && values.creditCard === "") {
        errors.creditCard = i18n.get(`servicePayment.dinamicForm.validate.required`);
    }

    // validacion dinamica minValue, maxValue

    const fieldSubTotal = props.field.filter((el) => el.serviceField === "SubTotal");
    if (fieldSubTotal.length > 0) {
        const { restriction } = fieldSubTotal[0];
        if (restriction.maxValue !== null && values.SubTotal.quantity > restriction.maxValue) {
            errors.SubTotal = i18n.get(`servicePayment.dinamicForm.validate.maxValue`, null, {
                NUM: restriction.maxValue,
            });
        }
        if (restriction.minValue !== null && values.SubTotal.quantity < restriction.minValue) {
            errors.SubTotal = i18n.get(`servicePayment.dinamicForm.validate.minValue`, null, {
                NUM: restriction.minValue,
            });
        }
    }

    // validacion monto requerido
    if (!values.SubTotal?.quantity) {
        errors.SubTotal = i18n.get(`servicePayment.dinamicForm.validate.required`);
    }

    // validacion al consultar valores
    // if (props.selectedBiller?.hasQuery && props.preDataForm?.billBalance == null) {
    //     errors.SubTotal = "Error al consultar valores";
    // }

    return errors;
};


export const validationSchema = () =>
    Yup.object().shape({
        list: Yup.array().of(
            Yup.object().shape({
                debitPaymentMethod: Yup.string().required(i18n.get("global.field.required")),
                debitProduct: Yup.string().required(i18n.get("global.field.required")),
                creditCardPaymentType: Yup.string().when("debitPaymentMethod", {
                    is: "creditCard",
                    then: Yup.string().required(i18n.get("global.field.required")),
                    otherwise: Yup.string(),
                }),
            }),
        ),
    });

export const validateForm = (values) => {
        let isValid = true;
        values.list.forEach((item) => {
            if (!item?.debitProduct || item?.amountPay < 0.01) {
                isValid = false;
            }
        });
        return isValid;
    };

export const validateMassiveForm = (values) => {
    let isValid = true;
    values.list.forEach((item) => {
        if (!item?.debitProduct) {
            isValid = false;
        }
    });
    return isValid;
};
export const initialValuesMassive = (props) => {
    let values = {};
    const data = props?.transaction?.data;
    if (!data) {
        const { rates, accounts } = props?.preDataForm;
        const {
            massivePayService: { list, totalPay },
        } = props;
        if (!list) {
            return values;
        }
        const listPay = list.map((item) => ({
            ...item,
            commission: rates ? rates[item.id] : 0,
            debitPaymentMethod: accounts && accounts?.length > 0 ? "account" : "creditCard",
            creditCardPaymentType: "",
            creditCardPaymentTypeLabel: "",
            debitProductLabel: "",
            debitProduct: "",
            description: "",
        }));
        values = {
            list: listPay,
            totalPay,
        };
    } else {
        values = {};
    }

    // console.log("---------------- initialValuesMassive values", values);
    return values;
};

export const initialValues = (props) => {
    let values = {};
    const data = props?.transaction?.data;
    if (!data) {
        const { rate, accounts } = props?.preDataForm;
        const {
            payService,
        } = props;
        if (!payService) {
            return values;
        }
        const payServiceValue = {
            ...payService,
            commission: rate || 0,
            debitPaymentMethod: accounts && accounts?.length > 0 ? "account" : "creditCard",
            amount: {
                amount: payService.amountPay
            },
            creditCardPaymentType: "",
            creditCardPaymentTypeLabel: "",
            debitProductLabel: "",
            debitProduct: "",
            description: "",
        };
        values = {
            list: [payServiceValue],
        };
    } else {
        values = {};
    }

    // console.log("---------------- initialValues one values", values);
    return values;
};

export const useCreditCardPaySelectorOptions = (creditCardList) => {
    const [creditCardOptions, setCreditCardOptions] = useState([]);

    const createCreditCardList = (card) => ({
        ...card,
        balance: { currency: card.currency, quantity: card.totalAmount },
        id: card.idProduct,
        additionalCreditCardList: [],
    });

    useEffect(() => {
        const nonPrepaidWithAdditionals = creditCardsUtil.getCreditCardsWithAdditionals(creditCardList);
        setCreditCardOptions({
            options: nonPrepaidWithAdditionals.map(createCreditCardList),
            // ?.filter((card) => card.statusCode === creditCardsUtil.CREDIT_CARD_STATUS.ACTIVA),
        });
    }, [creditCardList]);
    return creditCardOptions;
};

export const useAccountPaySelectorOptions = (debitAccountList) => {
    const [accountOptions, setAccountOptions] = useState([]);

    const createAccountList = (acc) => ({
        ...acc,
        balance: { currency: acc.currency, quantity: acc.balance },
        id: acc.idProduct,
        disabled: acc.isDisabled,
    });
    useEffect(() => {
        setAccountOptions({
            options: debitAccountList?.map(createAccountList),
        });
    }, [debitAccountList]);
    return accountOptions;
};

export const useServicioConvenioData = (companyList) => {
    const [companies, setCompanies] = useState([]);
    const [agreements, setAgreements] = useState([]);

    useEffect(() => {
      if (companyList && companyList.length > 0) {
        // Cargar todas las empresas
        setCompanies(companyList);

        // Cargar todos los convenios de todas las empresas
        const agreementsList = companyList.flatMap(company => company.agreements);
        setAgreements(agreementsList);
      }
    }, [companyList]);

    return {
        companies,
        agreements,
    };
  };


export const useMetaData = (preDataForm, idActivity) => {
    const [metadata, setMetadata] = useState({
        draftsEnabled: false,
        templatesEnabled: false,
        schedulable: true,
        programable: true,
        idActivity: idActivity || ID_ACTIVITY,
    });
    useEffect(() => {
        const { nonWorkingDays, enabledWeekDays, firstWorkingDate = new Date(), maxDaysToSchedule } = preDataForm;
        setMetadata((state) => ({
            ...state,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
        }));
    }, [preDataForm]);
    return [metadata];
};

export const initialValuesServiceRegistration = (props, isNewPay = false) => {
    let values = {};

        values = {
            supply: "",
            supplyCatastro: "",
            alias: "",
            category: "",
            categoryCode: "",
            companyId: "",
            companyLabel: "",
            agreementId: "",
            agreementLabel: "",
            identificationType: "",
            identificationValue: "",
            identificationLabel: "",
            extendedData: "",
            deleted: false,
            isNewPay
        };
    return values;
};


export const validateFormServiceRegistration = (values) => {
    let isValid = true;

    if (!values.category ||
        !values.companyLabel ||
        !values.agreementLabel ||
        !values.identificationLabel ||
        //!values.identificationValue ||
        !values.alias) {
        isValid = false;
    }

    return isValid;
};

export const initialValuesEditAlias = (props) => {
    return {
        id: props.id || "",
        newAlias: props.alias || "",
        category: props.category || "",
        categoryCode: props.categoryCode || "",
        agreementId: props.agreementId || "",
        agreementLabel: props.agreementLabel || "",
        companyId: props.company || "",
        companyLabel: props.companyLabel || "",
        extendedData: props.extendedData || "",
        identificationLabel: props.identificationLabel || "",
        identificationType: props.identificationType || "",
        identificationValue: props.identificationValue || "",
        service: props.id || "",
    };
};


export const validateEditAliasFormService = (values) => {
    let isValid = true;

    if (!values.newAlias) {
        isValid = false;
    }

    return isValid;
};

export const useEnrolledServiceData = (enrolledService) => {
    const [agreementId, setAgreementId] = useState(null);
    const [agreementLabel, setAgreementLabel] = useState('');
    const [company, setCompany] = useState(null);
    const [alias, setAlias] = useState('');
    const [category, setCategory] = useState('');
    const [companyLabel, setCompanyLabel] = useState('');
    const [extendedData, setExtendedData] = useState(null);
    const [identificationLabel, setIdentificationLabel] = useState(null);
    const [identificationType, setIdentificationType] = useState(null);
    const [identificationValue, setIdentificationValue] = useState(null);
    useEffect(() => {
        if (enrolledService) {
            setAgreementId(enrolledService.agreementId);
            setAgreementLabel(enrolledService.agreementLabel);
            setAlias(enrolledService.alias);
            setCategory(enrolledService.category);
            setCompany(enrolledService.companyId);
            setCompanyLabel(enrolledService.companyLabel);
            setExtendedData(enrolledService.extendedData)
            setIdentificationLabel(enrolledService.identificationLabel);
            setIdentificationType(enrolledService.identificationType);
            setIdentificationValue(enrolledService.identificationValue);
        }
    }, [enrolledService]);

    return {
        agreementId,
        agreementLabel,
        company,
        alias,
        category,
        companyLabel,
        extendedData,
        identificationLabel,
        identificationType,
        identificationValue,
    };
};
