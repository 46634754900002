import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Text from "pages/_components/Text";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import { array, bool, func, oneOf, oneOfType, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { push, routerActions } from "react-router-redux/actions";
import {
    actions as authenticateHandlerActions,
    selectors as authenticateHandlerSelector,
} from "reducers/authenticateHandler";
import { actions as enrollmentActions } from "reducers/enrollment";
import { actions as notificationActions } from "reducers/notification";
import { actions as secondFactorActions, selectors as secondFactorSelectors } from "reducers/secondFactor";
import { actions as softTokenActions } from "reducers/softToken";
import { AUTHENTICATOR_TOKEN, BIOMETRIC_CALLBACK_UNLOCK_TOKEN } from "util/biometric.util";
import { isMobileNative, isMobileNativeFunc } from "util/device";
import * as config from "util/config";
import * as i18nUtils from "util/i18n";
import {
    TOKEN_STATUS_AS_PENDING_LIST,
    TOKEN_STATUS_AS_UNAVAILABLE,
    USER_AUTHENTICATOR_TOKENRO,
    USER_TOKEN_STATUS_ACTIVE,
    USER_TOKEN_STATUS_AUTHENTICATE,
    USER_TOKEN_STATUS_INACTIVE,
    USER_TOKEN_STATUS_LOCKED_ENTRUST,
    USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID,
    USER_TOKEN_STATUS_MIGRATE_ENTRUST,
    USER_TOKEN_STATUS_MIGRATE_LOCAL,
    USER_TOKEN_STATUS_PENDING,
    USER_TOKEN_STATUS_STARTED,
} from "util/userToken.util";

export const MODAL_TEXT_MIGRATE = "migrate";
export const MODAL_TEXT_ACTIVATE = "activate";
export const MODAL_TEXT_UNLOCK = "unlock";
export const MODAL_TEXT_INFO = "info";

// const MODAL_TEXT_UNLOCK = "unlock";

class AuthenticatorHandler extends Component {
    static propTypes = {
        fetching: func.isRequired,
        dispatch: func.isRequired,
        // unlockingAuthenticator: func.isRequired,
        isMobile: bool.isRequired,
        listUserAuthenticators: oneOfType([array.isRequired, oneOf([null]).isRequired]),
        tokenStatus: string,
        // pinCode: bool.isRequired,
        serialToken: string,
        registeredQuestions: shape({}),
    };

    static defaultProps = {
        listUserAuthenticators: null,
        tokenStatus: "",
        serialToken: "",
        registeredQuestions: undefined,
    };

    state = {
        showDesactivationTokenModal: false,
        showSideBarTokenModal: false,
        tokenStatusAction: "",
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(authenticateHandlerActions.getTokenStatusRequest());
        dispatch(
            secondFactorActions.secondFactorVerificationQuestionsRequest(
                {
                    exchangeToken: undefined,
                    scopeToShow: "",
                },
                false,
            ),
        );
    }

    onClickTokenActionHandler(tokenStatusAction) {
        this.setState({ tokenStatusAction });
        this.setState({ showSideBarTokenModal: true });
    }

    isPendingStatus = (tokenStatus) => tokenStatus && TOKEN_STATUS_AS_PENDING_LIST.some((item) => item === tokenStatus);

    activateTokenAction = () => {
        const { dispatch } = this.props;
        if (isMobileNativeFunc()) {
            dispatch(softTokenActions.setActivationStep("making"));
            dispatch(
                routerActions.push({
                    pathname: "/activateSoftToken",
                }),
            );

            return;
        }

        this.onClickTokenActionHandler(MODAL_TEXT_ACTIVATE);
    };

    unlockTokenAction = () => {
        const { dispatch } = this.props;
        if (isMobileNativeFunc()) {
            const callBackSelfie = {
                type: BIOMETRIC_CALLBACK_UNLOCK_TOKEN,
                data: { authenticatorType: AUTHENTICATOR_TOKEN, deviceUuid: window?.app?.getDeviceUUID() || "" },
                redirectSuccess: "/settings/authenticatorHandler",
                redirectError: "/settings/authenticatorHandler",
                scopeSuccess: ["settings/authenticatorHandler"],
                scopeError: ["settings/authenticatorHandler"],
                redirectDocument: "/settings/authenticatorHandler",
            };

            dispatch(enrollmentActions.setCallbackDataSelfie(callBackSelfie));

            dispatch(
                routerActions.push({
                    pathname: "/auth/tokenActivationStep1",
                }),
            );

            return;
        }

        this.onClickTokenActionHandler(MODAL_TEXT_UNLOCK);
    };

    columnTemplates = () => {
        const { isMobile } = this.props;
        return isMobile ? "1fr 1fr" : "10rem 1fr 1fr";
    };

    /* handleToggle = () => {
        const { pinCode, dispatch } = this.props;
        if (pinCode) {
            dispatch(authenticateHandlerActions.deleteTokenPinRequest());
        } else {
            dispatch(
                routerActions.push({
                    pathname: "/createTokenPinForm",
                }),
            );
        }
    }; */

    isTokenStatusAvailable = (tokenStatus) => tokenStatus && !TOKEN_STATUS_AS_UNAVAILABLE.includes(tokenStatus);

    handleOnBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop"));
    };

    getColorClass = (tokenStatus) => {
        let colorClass = "";
        switch (tokenStatus) {
            case USER_TOKEN_STATUS_ACTIVE:
            case USER_TOKEN_STATUS_MIGRATE_LOCAL:
                colorClass = "active";
                break;
            case USER_TOKEN_STATUS_LOCKED_ENTRUST:
                colorClass = "locked";
                break;
            default:
                if (this.isPendingStatus(tokenStatus)) {
                    colorClass = "pending";
                } else {
                    colorClass = "inactive";
                }
                break;
        }
        return colorClass;
    };

    handleUnlockDevice = () => {
        const { dispatch } = this.props;
        dispatch(push("/unlockDevice"));
    };

    hideDesactivationTokenModal() {
        this.setState({ showDesactivationTokenModal: false });
    }

    desactivateHandleFuncion() {
        const { dispatch } = this.props;
        dispatch(push("/deactivateSoftToken"));
    }

    handleTokenAction() {
        const { dispatch, tokenStatus } = this.props;
        if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_ENTRUST) {
            return isMobileNative
                ? dispatch(
                      routerActions.push({
                          pathname: "/migrateSoftTokenEntrust",
                          state: { isFromAuthenticateHandler: true },
                      }),
                  )
                : this.onClickTokenActionHandler(MODAL_TEXT_MIGRATE);
        }
        /* if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_LOCAL) {
            return this.activateTokenAction();
        } */
        if (tokenStatus === USER_TOKEN_STATUS_AUTHENTICATE) {
            return this.activateTokenAction();
        }
        const ACTIVE_ACTION_STATUS = [USER_TOKEN_STATUS_INACTIVE, USER_TOKEN_STATUS_STARTED];
        if (ACTIVE_ACTION_STATUS.includes(tokenStatus)) {
            return this.activateTokenAction();
        }

        if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID) {
            if (isMobileNativeFunc()) {
                dispatch(
                    notificationActions.showNotification(
                        i18nUtils.get("token.entrust.migrateEntrust.validate.error.message"),
                        "error",
                        ["tokenActivation"],
                        false,
                    ),
                );
                return this.activateTokenAction();
            }

            return this.onClickTokenActionHandler(MODAL_TEXT_MIGRATE);
        }

        if (tokenStatus === USER_TOKEN_STATUS_LOCKED_ENTRUST) {
            return this.unlockTokenAction();
        }

        return null;
    }

    showDesactivationTokenModal() {
        const { dispatch, registeredQuestions, userHasToken, tokenStatus } = this.props;
        const hasSecurityQuestions = registeredQuestions && Object.keys(registeredQuestions).length > 0;

        if (hasSecurityQuestions) {
            this.setState({ showDesactivationTokenModal: true });
            if (isMobileNativeFunc()) {
                console.log("Ingresando a eliminacion desde mobile. Token status: ", tokenStatus, userHasToken);
                if (tokenStatus === USER_TOKEN_STATUS_ACTIVE && !userHasToken) {
                    // Aplica para moviles. Tiene que estar activo localmente (no aplica para MIGRATE LOCAL) pero no tener tokenSeed (userHasToken)
                    dispatch(
                        notificationActions.showNotification(
                            i18nUtils.get("token.entrust.deactivate.inconsistent.activation.error.message"),
                            "error",
                            ["generalNotification"],
                            false,
                        ),
                    );
                }
            }
        } else {
            dispatch(
                notificationActions.showNotification(
                    i18nUtils.get("token.entrust.deactivate.registeredQuestions.error.message"),
                    "error",
                    ["settings/authenticatorHandler"],
                    false,
                ),
            );
        }
    }

    // eslint-disable-next-line class-methods-use-this
    renderAuthenticatorStatusLabel(authenticatorStatusEnum, authenticatorType) {
        if (authenticatorType === USER_AUTHENTICATOR_TOKENRO) {
            if (authenticatorStatusEnum !== null && authenticatorStatusEnum !== "") {
                const inactiveStatusList = [
                    USER_TOKEN_STATUS_INACTIVE,
                    USER_TOKEN_STATUS_MIGRATE_ENTRUST,
                    USER_TOKEN_STATUS_AUTHENTICATE,
                    USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID,
                    USER_TOKEN_STATUS_STARTED,
                    // USER_TOKEN_STATUS_MIGRATE_LOCAL,
                ];

                const activeStatusList = [USER_TOKEN_STATUS_ACTIVE, USER_TOKEN_STATUS_MIGRATE_LOCAL];

                if (inactiveStatusList.includes(authenticatorStatusEnum)) {
                    return i18nUtils.get(`softToken.status.${USER_TOKEN_STATUS_INACTIVE.toLowerCase()}`);
                }
                if (activeStatusList.includes(authenticatorStatusEnum)) {
                    return i18nUtils.get(`softToken.status.${USER_TOKEN_STATUS_ACTIVE.toLowerCase()}`);
                }

                if (authenticatorStatusEnum === USER_TOKEN_STATUS_LOCKED_ENTRUST) {
                    return i18nUtils.get(`softToken.status.locked`);
                }
                if (this.isPendingStatus(authenticatorStatusEnum)) {
                    return i18nUtils.get(`softToken.status.${USER_TOKEN_STATUS_PENDING.toLowerCase()}`);
                }

                return i18nUtils.get(`softToken.status.${authenticatorStatusEnum.toLowerCase()}`);
            }
        }
        return "";
    }

    renderTokenActions() {
        const { tokenStatus, isMobile } = this.props;
        if (
            tokenStatus === USER_TOKEN_STATUS_MIGRATE_ENTRUST ||
            tokenStatus === USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID
        ) {
            return (
                <Button
                    type="submit"
                    bsStyle="outline"
                    className="btn-small"
                    label="settings.options.authenticateHandler.migrate.button"
                    image="images/icons/unlock.svg"
                    {...(!isMobile && { small: true })}
                    onClick={() => this.handleTokenAction()}
                />
            );
        }
        const INACTIVE_STATUS_LIST = [
            USER_TOKEN_STATUS_STARTED,
            USER_TOKEN_STATUS_AUTHENTICATE,
            USER_TOKEN_STATUS_INACTIVE,
            // USER_TOKEN_STATUS_MIGRATE_LOCAL,
        ];
        if (INACTIVE_STATUS_LIST.includes(tokenStatus)) {
            return (
                <Button
                    type="submit"
                    bsStyle="outline"
                    className="btn-small"
                    label="settings.options.authenticateHandler.activate.button"
                    image="images/icons/unlock.svg"
                    {...(!isMobile && { small: true })}
                    onClick={() => this.handleTokenAction()}
                />
            );
        }
        if (
            tokenStatus === USER_TOKEN_STATUS_ACTIVE ||
            tokenStatus === USER_TOKEN_STATUS_MIGRATE_LOCAL ||
            tokenStatus === USER_TOKEN_STATUS_LOCKED_ENTRUST
        ) {
            return (
                <>
                    <Button
                        type="submit"
                        bsStyle="outline"
                        className="btn-small"
                        image="images/icons/trash.svg"
                        label="settings.options.authenticateHandler.desactivate.button"
                        {...(!isMobile && { small: true })}
                        {...(((isMobile &&
                            config.get("frontend.pathList.enabled.channel.mobile") &&
                            config.get("frontend.pathList.enabled.channel.mobile").includes("/deactivateSoftToken")) ||
                            (!isMobile &&
                                config.get("frontend.pathList.enabled.channel.web") &&
                                config
                                    .get("frontend.pathList.enabled.channel.web")
                                    .includes("/deactivateSoftToken"))) && {
                            onClick: (event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                this.showDesactivationTokenModal();
                            },
                        })}
                        disabled={
                            !(
                                (isMobile &&
                                    config.get("frontend.pathList.enabled.channel.mobile") &&
                                    config
                                        .get("frontend.pathList.enabled.channel.mobile")
                                        .includes("/deactivateSoftToken")) ||
                                (!isMobile &&
                                    config.get("frontend.pathList.enabled.channel.web") &&
                                    config
                                        .get("frontend.pathList.enabled.channel.web")
                                        .includes("/deactivateSoftToken"))
                            )
                        }
                    />
                </>
            );
        }
        return undefined;
    }

    renderTokenStatus = (tokenStatus) => (
        <>
            {tokenStatus && this.isTokenStatusAvailable(tokenStatus) && (
                <span className={`token-status-${this.getColorClass(tokenStatus)}`}>
                    {this.renderAuthenticatorStatusLabel(tokenStatus, USER_AUTHENTICATOR_TOKENRO)}
                </span>
            )}
        </>
    );

    renderDevices() {
        const { isMobile, tokenStatus, serialToken } = this.props;
        const { showSideBarTokenModal, tokenStatusAction } = this.state;

        return (
            <>
                <Box
                    fullWidth
                    display="flex"
                    alignY="center"
                    className="px-5 py-5 mb-7 border-radius-lg box-shadow-small">
                    <Box className={isMobile ? "pr-3" : ""}>
                        <Image src="images/icons/icn-security_device.svg" color="transparent" className="icon-md" />
                    </Box>
                    <Box fullWidth display="flex">
                        <div className="info-data ">
                            <div className="info-data-value">
                                {/* <Text labelKey="softToken.device.label" bold />
                                {this.isTokenStatusAvailable(tokenStatus)
                                    ? this.renderTokenStatus(tokenStatus)
                                    : this.renderTokenStatus(USER_TOKEN_STATUS_INACTIVE, isMobile)} */}
                                <Text className="mx-1" labelKey="softToken.device.label" bold />
                                {this.isTokenStatusAvailable(tokenStatus)
                                    ? this.renderTokenStatus(tokenStatus)
                                    : this.renderTokenStatus(USER_TOKEN_STATUS_INACTIVE, isMobile)}
                            </div>
                            {serialToken && this.isTokenStatusAvailable(tokenStatus) && (
                                <div className="info-data-label mt-3">{`${i18nUtils.get(
                                    "softToken.serial.label",
                                )} ${serialToken}`}</div>
                            )}
                            {tokenStatus === USER_TOKEN_STATUS_LOCKED_ENTRUST && isMobile && (
                                <Button
                                    type="button"
                                    className="btn-bold p-0 btn-unlock"
                                    bsStyle="link"
                                    label="settings.options.authenticateHandler.unlock.button"
                                    {...(!isMobile && { small: true })}
                                    {...(((isMobile &&
                                        config.get("frontend.pathList.enabled.channel.mobile") &&
                                        config
                                            .get("frontend.pathList.enabled.channel.mobile")
                                            .includes("/unlockDevice")) ||
                                        (!isMobile &&
                                            config.get("frontend.pathList.enabled.channel.web") &&
                                            config
                                                .get("frontend.pathList.enabled.channel.web")
                                                .includes("/unlockDevice"))) && {
                                        onClick: (event) => {
                                            event.stopPropagation();
                                            event.preventDefault();
                                            this.handleUnlockDevice();
                                        },
                                    })}
                                    disabled={
                                        !(
                                            (isMobile &&
                                                config.get("frontend.pathList.enabled.channel.mobile") &&
                                                config
                                                    .get("frontend.pathList.enabled.channel.mobile")
                                                    .includes("/unlockDevice")) ||
                                            (!isMobile &&
                                                config.get("frontend.pathList.enabled.channel.web") &&
                                                config
                                                    .get("frontend.pathList.enabled.channel.web")
                                                    .includes("/unlockDevice"))
                                        )
                                    }
                                />
                            )}
                        </div>
                        <Box className="ml-2">
                            {tokenStatus === USER_TOKEN_STATUS_LOCKED_ENTRUST && !isMobile && (
                                <Button
                                    type="button"
                                    className="btn-bold"
                                    bsStyle="link"
                                    label="settings.options.authenticateHandler.unlock.button"
                                    {...(!isMobile && { small: true })}
                                    {...(((isMobile &&
                                        config.get("frontend.pathList.enabled.channel.mobile") &&
                                        config
                                            .get("frontend.pathList.enabled.channel.mobile")
                                            .includes("/unlockDevice")) ||
                                        (!isMobile &&
                                            config.get("frontend.pathList.enabled.channel.web") &&
                                            config
                                                .get("frontend.pathList.enabled.channel.web")
                                                .includes("/unlockDevice"))) && {
                                        onClick: (event) => {
                                            event.stopPropagation();
                                            event.preventDefault();
                                            this.handleUnlockDevice();
                                        },
                                    })}
                                    disabled={
                                        !(
                                            (isMobile &&
                                                config.get("frontend.pathList.enabled.channel.mobile") &&
                                                config
                                                    .get("frontend.pathList.enabled.channel.mobile")
                                                    .includes("/unlockDevice")) ||
                                            (!isMobile &&
                                                config.get("frontend.pathList.enabled.channel.web") &&
                                                config
                                                    .get("frontend.pathList.enabled.channel.web")
                                                    .includes("/unlockDevice"))
                                        )
                                    }
                                />
                            )}
                        </Box>
                    </Box>
                    {this.renderTokenActions()}
                    <TextAndButtonsModal
                        modalShow={showSideBarTokenModal}
                        acceptFunction={() => {
                            this.handleOnBack();
                            this.setState({ showSideBarTokenModal: false });
                        }}
                        confirmLabel="global.exit"
                        headingText={i18nUtils.get(`token.drawer.${tokenStatusAction.toLowerCase()}.title`)}
                        text={`${i18nUtils.get(`token.drawer.${tokenStatusAction.toLowerCase()}.text`)} ${i18nUtils.get(
                            `token.drawer.disclaimer`,
                        )}`}
                    />
                </Box>
            </>
        );
    }

    render() {
        const { fetching } = this.props;
        const { showDesactivationTokenModal } = this.state;

        return (
            <>
                <Notification scopeToShow="settings/authenticatorHandler" />
                <Head titleText={i18nUtils.get("settings.softToken")} onBack={this.handleOnBack} />
                <MainContainer
                    className="main-container rounded-border-top p-7 box-shadow-small background-white texture-header"
                    showLoader={fetching}>
                    <div className="above-the-fold security-wrapper">
                        <Box fullWidth display="flex" className="px-5 py-5 mb-7 border-radius-lg box-shadow-small">
                            <Box fullWidth>
                                <Box>
                                    <Text
                                        component="h2"
                                        defaultValue={i18nUtils.get("settings.softToken.title")}
                                        bold
                                    />
                                </Box>
                                <Box>
                                    <Text defaultValue={i18nUtils.get("settings.softToken.text")} />
                                </Box>
                            </Box>
                            <Box display="flex">
                                <Image
                                    className="token-img"
                                    src="images/icons/settings_st_header.svg"
                                    color="transparent"
                                />
                            </Box>
                        </Box>
                    </div>

                    <TextAndButtonsModal
                        modalShow={showDesactivationTokenModal}
                        acceptFunction={() => {
                            this.desactivateHandleFuncion();
                        }}
                        cancelFunction={() => {
                            this.hideDesactivationTokenModal();
                        }}
                        confirmLabel="global.continue"
                        cancelLabel="global.cancel"
                        continueLabel="global.cancel"
                        headingText={i18nUtils.get("token.drawer.desactivate.title")}
                        text={i18nUtils.get("token.drawer.desactivate.text")}
                        modalId="widgets.update.data.modal"
                    />

                    {this.renderDevices()}
                </MainContainer>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    tokenStatus: authenticateHandlerSelector.getTokenStatus(state),
    // pinCode: authenticateHandlerSelector.getPinCode(state),
    fetching: authenticateHandlerSelector.isFetching(state),
    // unlockingAuthenticator: authenticateHandlerSelector.isUnlockingAuthenticator(state),
    serialToken: authenticateHandlerSelector.getSerialToken(state),
    registeredQuestions: secondFactorSelectors.getQuestions(state),
    userHasToken: secondFactorSelectors.getUserHasToken(state),
});

export default connect(mapStateToProps)(AuthenticatorHandler);
