export const cleanRegex = (regex) => {
    //console.log("regex servicio: ", regex);
    let newRegex = regex.replace(/\\/g, "\\\\");
    console.log("new regex servicio: ", newRegex);
    return newRegex;
};

export const replaceZerosInMask = (mask) => {
  if (!mask) return ''; // Si la máscara es nula o indefinida, retorna una cadena vacía
  return mask.replace(/0/g, '9'); // Reemplaza todos los dígitos 0 por 9
};

export const cleanMask = (mask) => {
  return mask.replace(/\n/g, ''); // Elimina todos los caracteres \n
};
  

export const generatePlaceholderFromMask = (mask) => {
  return mask.replace(/0/g, '_');
};

export const generatePlaceholder = (regexp) => {
    try {
        // Reemplazar los patrones de números y letras por 0 y A
        let placeholder = regexp.replace(/\[0-9\]/g, '0')
                                .replace(/\[A-Za-z\]/g, 'A')
                                .replace(/\[A-Z\]/g, 'A')
                                .replace(/\[a-z\]/g, 'a');

        // Reemplazar los patrones con cantidades repetidas {n,m} o {n}
        placeholder = placeholder.replace(/{(\d+),(\d+)}/g, (match, p1, p2) => {
            return '0'.repeat(p2);  // Tomar el segundo número para la repetición máxima
        });

        placeholder = placeholder.replace(/{(\d+)}/g, (match, p1) => {
            return '0'.repeat(p1);  // Repetir el 0 por el número indicado
        });

        // Eliminar paréntesis, caracteres especiales como \ ^ $
        placeholder = placeholder.replace(/[()\\\^\$]/g, '');

        // Retornar el placeholder con el formato deseado
        return `Ej: ${placeholder}`;
    } catch (error) {
        console.error('Error generating placeholder:', error);
        return '';  // Retornar cadena vacía en caso de error
    }
};


export const getMaxLengthFromRegex = (regex, defaultLength = null) => {
    try {
      // Encontrar todas las ocurrencias de números entre llaves (ej. {2}, {1,5})
      const match = regex.match(/\{(\d+),?(\d+)?\}/g);
  
      let totalLength = 0;
  
      // Si no hay match con el formato "{n}", lo intentamos con otros casos
      if (!match) {
        // Intentar casos donde simplemente hay un número seguido de un patrón sin llaves, como ([0-9]){3}
        const simpleMatch = regex.match(/\[0-9\]/g);
        
        if (simpleMatch) {
          totalLength = simpleMatch.length;
        }
  
        // También contar guiones y otros caracteres literales que pueden estar en el patrón
        const literalCharsCount = (regex.match(/[-.]/g) || []).length;
  
        return totalLength + literalCharsCount || defaultLength;
      }
  
      // Si encontramos match con el patrón {n} o {n,m}
      totalLength = match.reduce((acc, curr) => {
        const [_, min, max] = curr.match(/\{(\d+),?(\d+)?\}/);
  
        // Si hay un rango (ej. {1,3}), tomamos el máximo, si no, tomamos el único número
        return acc + (max ? parseInt(max, 10) : parseInt(min, 10));
      }, 0);
  
      // Contamos la cantidad de guiones u otros caracteres no numéricos permitidos en el regex
      const hyphenCount = (regex.match(/[-.]/g) || []).length;
  
      console.log("Max lenght: ", totalLength + hyphenCount || defaultLength);
      return totalLength + hyphenCount || defaultLength;
    } catch (e) {
      console.error('Error in getMaxLengthFromRegex:', e);
      return defaultLength; // Retornar valor por defecto en caso de error
    }
  };
  
  
  