import * as b64toBlob from "b64-to-blob";
import { getDisplay, getMobileOS, isAndroidPlatform, isMobileNativeFunc } from "./device";

export const CONTENT_TYPE_PDF = "PDF";
export const CONTENT_TYPE_XLS = "XLS";

export const CONTENT_TYPE_PDF_EXTENSION = ".pdf";
export const CONTENT_TYPE_XLS_EXTENSION = ".xls";

const fakeClick = (element) => {
    const event = document.createEvent("MouseEvents");

    event.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    element.dispatchEvent(event);
};

function normalizeDownloadName(name) {
    // Convert escaped characters in the original string to their corresponding characters
    const unescaped = decodeURIComponent(name.replace(/\+/g, " "));

    // Define invalid characters for the filename
    const invalidChars = /[<>:"\\/|?*\x00-\x1F]/g; // eslint-disable-line no-control-regex

    // Replace invalid characters with an underscore
    let normalized = unescaped.replace(invalidChars, "_");

    // Replace accented vowels and other symbols
    normalized = normalized
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z0-9_\-.\s]/g, "");

    return normalized;
}

export const downloadPdf = (name, data, transformToBlob = true) => {
    const urlObject = window.URL || window.webkitURL || window;
    const exportBlob = transformToBlob ? b64toBlob(data, "application/pdf") : data;

    if ("msSaveBlob" in navigator) {
        /**
         * Prefer msSaveBlob if available - Edge supports a[download] but ignores the filename provided,
         * using the blob UUID instead
         * msSaveBlob will respect the provided filename
         */
        navigator.msSaveBlob(exportBlob, name);
    } else if ("download" in HTMLAnchorElement.prototype) {
        const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");

        saveLink.href = urlObject.createObjectURL(exportBlob);
        saveLink.download = normalizeDownloadName(name);
        fakeClick(saveLink);
    } else {
        throw new Error("Neither a[download] nor msSaveBlob is available");
    }
};

export const downloadXls = (name, data) => {
    const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");

    saveLink.href = `data:application/vnd.ms-excel;base64,${window.atob(data)}`;
    saveLink.download = name;
    fakeClick(saveLink);
};

export const downloadTxt = (name, data) => {
    const buff = window.Buffer.from(data, "base64");
    const text = buff.toString("ascii");

    const element = document.createElement("a");
    element.setAttribute("href", `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
    element.setAttribute("download", name);

    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

export const downloadTextTxt = (name, text) => {
    const element = document.createElement("a");
    element.setAttribute("href", `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
    element.setAttribute("download", name);

    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

export const download = (name, data) => {
    const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
    const mediaType = "application/octet-stream";
    saveLink.href = `data:${mediaType};base64,${data}`;
    saveLink.download = name;
    fakeClick(saveLink);
};

export const downloadLink = (name, path) => {
    const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
    saveLink.href = path;
    saveLink.target = "_blank";
    saveLink.download = name;
    fakeClick(saveLink);
};

export const downloadCSVFile = (name, data) => {
    const binaryString = atob(data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
   
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    const decodedText = new TextDecoder("ISO-8859-1").decode(bytes);
    const blob = new Blob([decodedText], { type: 'text/plain;charset=ISO-8859-1' });
   
    const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
    saveLink.href = URL.createObjectURL(blob);
    saveLink.download = name;
    fakeClick(saveLink);
};

export const shareSocialText = (text) => {
    if (!isMobileNativeFunc() || !text || text === "") {
        return;
    }
    window.plugins.socialsharing.share(text);
};

export const downloadMobileFile = async (blob, filename, contentType) => {
    let storageLocation;
    if (getMobileOS(getDisplay()) === "iOS") {
        storageLocation = window.cordova.file.documentsDirectory;
    } else {
        storageLocation = window.cordova.file.externalDataDirectory;
    }

    if (!storageLocation) {
        return;
    }
    window.resolveLocalFileSystemURL(storageLocation, (dir) => {
        dir.getFile(
            filename,
            {
                create: true,
            },
            (file) => {
                file.createWriter(
                    (fileWriter) => {
                        try {
                            fileWriter.write(blob);
                            let openerFile = isAndroidPlatform() ? window.cordova.plugins.fileOpener2.showOpenWithDialog : window.cordova.plugins.fileOpener2.open;
                            openerFile(`${storageLocation}${filename}`, contentType, {
                                error() {},
                                success() {},
                            });
                            // eslint-disable-next-line no-empty
                        } catch (e) {}
                    },
                    () => {},
                );
            },
        );
    });
};
export const downloadXml = (name, data) => {
    const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
    const mediaType = "data:application/xml";
    saveLink.href = `data:${mediaType};base64,${data}`;
    saveLink.download = name;
    fakeClick(saveLink);
};