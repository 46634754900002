import { bool, func, oneOfType, shape } from "prop-types";
import React, { Component } from "react";
import Container from "pages/_components/Container";
import Image from "pages/_components/Image";
import CreditCardsList from "pages/creditCards/List";
import Link from "react-router-dom/Link";
import I18n from "pages/_components/I18n";
import Box from "pages/_components/Box";
import Col from "react-bootstrap/lib/Col";
import Text from "pages/_components/Text";
import Info from "pages/_components/Info";
import classNames from "classnames";
import * as i18nUtils from "util/i18n";
import { isDesktop } from "react-device-detect";

class CreditCardListCorporate extends Component {
    static propTypes = {
        corporateCreditCards: oneOfType([shape({})]).isRequired,
        showProductIcon: bool,
        setFavoriteAccount: func,
        completeFavorites: bool,
    };

    static defaultProps = {
        showProductIcon: true,
        setFavoriteAccount: () => {},
        completeFavorites: false,
    };

    renderList = () => {
        const { corporateCreditCards, isRequestAvailable } = this.props;
        if (!corporateCreditCards.length) {
            return null;
        }
        return (
            <>
                {/* <CreditCardsList creditCards={creditCards} isDesktop={isDesktop} />
                {!isDesktop && isRequestAvailable && (
                    <Container className="container--layout items-center">
                        <Container.Column className="col-12">
                            <Link to="/form/creditCardRequest/" className="btn btn-outline btn-block">
                                <Image src="images/plus.svg" />
                                <I18n id="creditCard.new" />
                            </Link>
                        </Container.Column>
                    </Container>
                )} */}

                <Box>
                    {!corporateCreditCards.length ? (
                        <I18n id="creditCards.list.empty" />
                    ) : (
                        corporateCreditCards.map((creditCard) => (
                            <Col xs={12} md={6}>
                                <Box
                                    display="flex"
                                    alignX="between"
                                    alignY="center"
                                    className="box-shadow-small border-radius-lg mb-4">
                                    <Link
                                        aria-describedby={creditCard.internalNumber}
                                        className="row-credit-card full-width border-radius-lg"
                                        to={`/CreditCardsCorporate/${creditCard.internalNumber}`}>
                                        <Box fullWidth>
                                            {!isDesktop && (
                                                <Box display="flex" className="pt-5 px-5">
                                                    <Box className="mr-5">
                                                        <Image
                                                            className="img-card-mobile"
                                                            color="text-inverse-color"
                                                            src={`images/${creditCard.cardStyle}-miniature-creditCardCorp.svg`}
                                                        />
                                                    </Box>
                                                    <Box fullWidth>
                                                        <Info
                                                            className="info-account-list"
                                                            labelText={creditCard.nameOnCard}
                                                            text={creditCard.numberMask}
                                                        />
                                                    </Box>
                                                </Box>
                                            )}
                                            <Box
                                                display={isDesktop ? "flex" : "grid"}
                                                alignY="center"
                                                gapX="5"
                                                fullWidth
                                                className="item-account-list p-5 border-radius-lg justify-content-between">
                                                {isDesktop && (
                                                    <>
                                                        <Box display="flex" alignY="center" className="info-account-list card-list-head">
                                                            <Image 
                                                                src={`images/${creditCard.cardStyle}-miniature-creditCardCorp.svg`}
                                                                className="img-header-card fill-transparent"
                                                            />
                                                            <Info
                                                                className={classNames("info-account-list", { "text-left ml-5": isDesktop })}
                                                                labelText={creditCard.nameOnCard}
                                                                text={creditCard.numberMask}
                                                            />
                                                        </Box>
                                                        {/* <Box className="info-account-list card-list-head">
                                                       
                                                        </Box> */}
                                                    </>
                                                )}
                                                <Info
                                                    className={classNames("info-account-list", { "text-left": isDesktop })}
                                                    labelText="Moneda"
                                                    text="USD"
                                                />
                                                <Info
                                                    className={classNames("info-account-list", { "text-left": isDesktop })}
                                                    labelText="Oficial de cuenta"
                                                    text={creditCard.official}
                                                />
                                            </Box>
                                        </Box>
                                    </Link>
                                </Box>
                            </Col>
                        ))
                    )}
                </Box>
            </>
        );
    };

    render() {
        return <>{this.renderList()}</>;
    }
}

export default CreditCardListCorporate;
