import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, func, number, oneOfType, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import FieldError from "../FieldError";

const PinInput = ({
    form,
    field,
    className,
    noMarginBottom,
    isMasked,
    inputMode,
    handleChangeProp,
    labelKey,
    defaultLabelText,
    autoFocus,
    defaultMessage,
    pin,
    setPin,
}) => {
    // const [pin, setPin] = useState(new Array(inputLenght).fill(""));

    const [isVisible, setVisible] = useState(false);

    const handleChange = (element, index) => {
        const valueInput = element?.value && element.value !== "" ? element.value : "";

        const resultPin = [...pin].map((item, idx) => (idx === index ? valueInput : item));
        setPin(resultPin);
        const pinReduce = resultPin.reduce((acc, item) => acc + item, "");
        form.setFieldValue(field.name, pinReduce);
        if (handleChangeProp) {
            handleChangeProp(pinReduce);
        }
    };

    const fieldHasError = () => {
        if (!form || !form.errors || !field || !field.name) {
            return false;
        }

        const { errors } = form;
        const { name } = field;
        return Object.keys(errors).includes(name);
    };

    const renderError = () => {
        if (!fieldHasError()) {
            return <div />;
        }
        const { errors } = form;
        const { name } = field;
        return <FieldError error={errors[name]} />;
    };

    const preventSpace = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };

    const handleFocus = (e) => {
        e.target.select();
    };

    const handleKeyUp = (e) => {
        if ((e.key >= "0" && e.key <= "9") || e.key === "Backspace" || typeof e.key === "string") {
            if (
                e.target.nextSibling &&
                e.target.value !== "" &&
                e.target.value.length === 2 &&
                e.key !== "Tab" &&
                e.Key !== "Shift"
            ) {
                e.target.nextSibling.focus();
            }

            if (e.target.previousSibling && e.key === "Backspace" && e.key !== "Tab" && e.Key !== "Shift") {
                e.target.previousSibling.focus();
            }
        }
    };

    const toggleIsVisible = () => {
        setVisible((prevState) => !prevState);
    };

    useEffect(() => {
        pin.forEach((_item, index) => {
            document.getElementById(`pin_${index}`).addEventListener("paste", (e) => {
                e.preventDefault();
            });
        });
    }, []);

    return (
        <Box
            className={classNames("form-group", className, {
                "has-error": fieldHasError(),
            })}>
            {(labelKey || defaultLabelText) && (
                <Text size="5" bold labelKey={labelKey} defaultValue={defaultLabelText} />
            )}
            <Box
                display="flex"
                className={classNames("height-auto", {
                    "mb-0": noMarginBottom,
                    "mb-10 mb-md-8": !noMarginBottom,
                    "mt-3": labelKey || defaultLabelText,
                })}>
                {pin.map((data, index) => (
                    <input
                        id={`pin_${index}`}
                        className={classNames("form-control input", { "mask-input": !isVisible })}
                        type="text"
                        name="pin"
                        placeholder="- -"
                        maxLength="2"
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        value={data}
                        onChange={(e) => handleChange(e.target, index)}
                        onKeyPress={preventSpace}
                        onFocus={handleFocus}
                        onKeyUp={handleKeyUp}
                        inputMode={inputMode}
                        autoFocus={autoFocus && index === 0}
                    />
                ))}
                {isMasked && (
                    <Button
                        className="btn-only-icon"
                        bsStyle="link"
                        block={false}
                        onClick={toggleIsVisible}
                        ariaLabel={
                            isVisible
                                ? "login.step1.secondFactor.maskPassword.a11y"
                                : "login.step1.secondFactor.unmaskPassword.a11y"
                        }>
                        <Image
                            src={isVisible ? "images/icons/showPass.svg" : "images/icons/hidePass.svg"}
                            className="svg-icon"
                            color="primary-color-i"
                        />
                    </Button>
                )}
            </Box>
            {isMasked && (
                <Text
                    component="p"
                    size="7"
                    align="center"
                    labelKey="activities.creditCard.assignPin.inputLabel"
                    className="mr-10 mb-0 mt-5"
                />
            )}
            {defaultMessage && <Box className="mt-3 line-height-125">{defaultMessage}</Box>}

            {renderError()}
        </Box>
    );
};

PinInput.propTypes = {
    setPin: func.isRequired,
    className: string,
    field: shape({}).isRequired,
    form: shape({}).isRequired,
    inputLenght: number,
    isMasked: bool,
    noMarginBottom: bool,
    inputMode: string,
    handleChangeProp: func.isRequired,
    labelKey: string,
    defaultLabelText: string,
    autoFocus: bool,
    defaultMessage: oneOfType([string, shape({})]),
};

PinInput.defaultProps = {
    className: "mb-10 mb-md-8",
    inputLenght: 4,
    isMasked: false,
    noMarginBottom: false,
    inputMode: "text",
    labelKey: undefined,
    defaultLabelText: undefined,
    autoFocus: false,
    defaultMessage: undefined,
};

export default PinInput;
