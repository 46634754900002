/* eslint-disable react/no-unescaped-entities */
import { Field, withFormik } from "formik";
import Collapsible from "pages/UiComponents/_components/Collapsible";
import Accordion from "pages/_components/Accordion";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/Checkbox";
import Dropdown from "pages/_components/Dropdown";
import Credential from "pages/_components/fields/credentials/Credential";
import Document from "pages/_components/fields/Document";
import Selector from "pages/_components/fields/formik/Selector";
import PinInput from "pages/_components/fields/PinInput";
import TextField from "pages/_components/fields/TextField";
import FormattedAmount from "pages/_components/FormattedAmount";
import MainContainer from "pages/_components/MainContainer";
import Row from "pages/_components/Row";
import SwitchToggle from "pages/_components/SwitchToggle/SwitchToggle";
import Text from "pages/_components/Text";
// import TokenButton from "pages/_components/TokenButton";
import Tooltip from "pages/_components/Tooltip";
import WidgetsError from "pages/_components/WidgetsError";
import { arrayOf, shape, string } from "prop-types";
import React from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { selectors as loginSelectors } from "reducers/login";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import { compose } from "redux";
import * as i18nUtils from "util/i18n";
import * as Yup from "yup";
import "./UiComponents.scss";

const UiComponents = ({ countries }) => {
    const FORM_ID = "settings.changeAddresses";

    return (
        <MainContainer>
            <Text component="h1" color="primary" size="big" uppercase bold>
                Componentes
            </Text>

            <WidgetsError />

            {/*  ************* Accordion ************* */}

            <Collapsible title="Accordion" id="Accordion">
                <Text component="h3" className="mt-0" color="primary" bold>
                    Accordion
                </Text>

                <Box className="mb-5">
                    <Text color="primary" bold>
                        Props:{" "}
                    </Text>
                    <Text component="p" bold>
                        En Accordion: grayBox
                    </Text>
                    <Text component="p" bold>
                        En Accordion.item: title
                    </Text>
                </Box>
                <Text component="p" className="mt-0">
                    Se debe utilizar siempre Accordion envolviendo Accordion.item que lleva el children. El componente
                    Accordion acepta como children cualquier elemento que se le pase. Ver ejemplo de uso en archivo
                    Uicomponents.jsx
                </Text>

                <Accordion grayBox>
                    <Accordion.Item title="Accordion grayBox true">
                        <Box display="flex" className="mb-5" column>
                            <Text component="p" bold>
                                Solo icono
                            </Text>
                            <Text component="p">
                                Cuando solo se pasa la prop image se renderea solo el icono / imagen.{" "}
                            </Text>
                            <Button bsStyle="outline" image="images/delete-message.svg" />
                            <Button className="mt-5" bsStyle="primary" image="images/delete-message.svg" />
                            <Button className="mt-5" bsStyle="primary" image="images/delete-message.svg" secondary />
                            <Button
                                className="mt-5"
                                bsStyle="primary"
                                image="images/delete-message.svg"
                                secondary
                                inverse
                            />
                            <Box display="flex" column className="my-8">
                                <Text>Icono de 24pxs -agregar prop imageMd-</Text>
                                <Button className="mt-5" bsStyle="primary" image="images/delete-message.svg" imageMd />
                            </Box>
                        </Box>
                    </Accordion.Item>
                </Accordion>

                <Accordion>
                    <Accordion.Item title="Accordion simple">
                        <Box display="flex" className="mb-5" column>
                            <Text component="p" bold>
                                Solo icono
                            </Text>
                            <Text component="p">
                                Cuando solo se pasa la prop image se renderea solo el icono / imagen.{" "}
                            </Text>
                            <Button bsStyle="outline" image="images/delete-message.svg" />
                            <Button className="mt-5" bsStyle="primary" image="images/delete-message.svg" />
                            <Button className="mt-5" bsStyle="primary" image="images/delete-message.svg" secondary />
                            <Button
                                className="mt-5"
                                bsStyle="primary"
                                image="images/delete-message.svg"
                                secondary
                                inverse
                            />
                            <Box display="flex" column className="my-8">
                                <Text>Icono de 24pxs -agregar prop imageMd-</Text>
                                <Button className="mt-5" bsStyle="primary" image="images/delete-message.svg" imageMd />
                            </Box>
                        </Box>
                    </Accordion.Item>
                </Accordion>
            </Collapsible>
            {/*  ************* Row y Col ************* */}

            <Collapsible title="Row y Col" id="RowYCol">
                <Row className="mb-5" heightAuto>
                    <Col xs={4} className="full-height">
                        <Box
                            className="test-2 p-5 pl-6"
                            display="flex"
                            background="primary"
                            borderRadius="xl"
                            fullWidth
                            fullHeight
                            alignY="center"
                            position="relative"
                            column>
                            <Box
                                position="absolute"
                                right="5"
                                top="5"
                                className="test-2-switch-toggle"
                                display="flex"
                                alignX="flex-end"
                                fullWidth>
                                <SwitchToggle
                                    color="inverse"
                                    greyStyle
                                    size="6"
                                    labelOnKey="creditCard.unblocked"
                                    labelOffKey="creditCard.blocked"
                                />
                            </Box>
                            <Box>
                                <Text size="5" color="inverse">
                                    Disponible
                                </Text>
                                <FormattedAmount color="inverse" size="big" bold currency="USD" quantity={1500} />
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={8}>
                        <Row gapX="3" gapY="3">
                            <Col xs={4}>
                                <Box
                                    className="px-5 py-4"
                                    display="flex"
                                    background="grey"
                                    borderRadius="xl"
                                    alignY="center"
                                    fullWidth
                                    column>
                                    <Box display="flex" className="mb-2" alignY="center" fullWidth>
                                        <Text size="6" color="text">
                                            Total Retenido
                                        </Text>
                                        <Tooltip
                                            className="ml-3"
                                            image="images/tooltip-icon.svg"
                                            text="Acá iria un texto de tooltip"
                                        />
                                    </Box>
                                    <Box>
                                        <FormattedAmount color="text" size="5" bold currency="USD" quantity={150} />
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={4}>
                                <Box
                                    className="px-5 py-4"
                                    display="flex"
                                    background="grey"
                                    borderRadius="xl"
                                    alignY="center"
                                    fullWidth
                                    column>
                                    <Box display="flex" className="mb-2" alignY="center" fullWidth>
                                        <Text size="6" color="text">
                                            Total Retenido
                                        </Text>
                                        <Tooltip
                                            className="ml-3"
                                            image="images/tooltip-icon.svg"
                                            text="Acá iria un texto de tooltip"
                                        />
                                    </Box>
                                    <Box>
                                        <FormattedAmount color="text" size="5" bold currency="USD" quantity={150} />
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={4}>
                                <Box
                                    className="px-5 py-4"
                                    display="flex"
                                    background="grey"
                                    borderRadius="xl"
                                    alignY="center"
                                    fullWidth
                                    column>
                                    <Box display="flex" className="mb-2" alignY="center" fullWidth>
                                        <Text size="6" color="text">
                                            Total Retenido
                                        </Text>
                                        <Tooltip
                                            className="ml-3"
                                            image="images/tooltip-icon.svg"
                                            text="Acá iria un texto de tooltip"
                                        />
                                    </Box>
                                    <Box>
                                        <FormattedAmount color="text" size="5" bold currency="USD" quantity={150} />
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={4}>
                                <Box
                                    className="px-5 py-4"
                                    display="flex"
                                    background="grey"
                                    borderRadius="xl"
                                    alignY="center"
                                    fullWidth
                                    column>
                                    <Box display="flex" className="mb-2" alignY="center" fullWidth>
                                        <Text size="6" color="text">
                                            Total Retenido
                                        </Text>
                                        <Tooltip
                                            className="ml-3"
                                            image="images/tooltip-icon.svg"
                                            text="Acá iria un texto de tooltip"
                                        />
                                    </Box>
                                    <Box>
                                        <FormattedAmount color="text" size="5" bold currency="USD" quantity={150} />
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={4}>
                                <Box
                                    className="px-5 py-4"
                                    display="flex"
                                    background="grey"
                                    borderRadius="xl"
                                    alignY="center"
                                    fullWidth
                                    column>
                                    <Box display="flex" className="mb-2" alignY="center" fullWidth>
                                        <Text size="6" color="text">
                                            Total Retenido
                                        </Text>
                                        <Tooltip
                                            className="ml-3"
                                            image="images/tooltip-icon.svg"
                                            text="Acá iria un texto de tooltip"
                                        />
                                    </Box>
                                    <Box>
                                        <FormattedAmount color="text" size="5" bold currency="USD" quantity={150} />
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={4}>
                                <Box
                                    className="px-5 py-4"
                                    display="flex"
                                    background="grey"
                                    borderRadius="xl"
                                    alignY="center"
                                    fullWidth
                                    column>
                                    <Box display="flex" className="mb-2" alignY="center" fullWidth>
                                        <Text size="6" color="text">
                                            Total Retenido
                                        </Text>
                                        <Tooltip
                                            className="ml-3"
                                            image="images/tooltip-icon.svg"
                                            text="Acá iria un texto de tooltip"
                                        />
                                    </Box>
                                    <Box>
                                        <FormattedAmount color="text" size="5" bold currency="USD" quantity={150} />
                                    </Box>
                                </Box>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Text component="h3" className="mt-0" color="primary" bold>
                    Row
                </Text>
                <Box className="mb-5">
                    <Text color="primary" bold>
                        Props:{" "}
                    </Text>
                    <Text bold>className, gapX, gapY, children, heightAuto</Text>
                </Box>
                <Text component="p">0px a 359px =&gt; xs (mobile)</Text>
                <Text component="p">360px a 767px =&gt; sm (mobile)</Text>
                <Text component="p">768px a 1024px =&gt; md (desktop)</Text>
                <Text component="p">1024px a infinito =&gt; lg (desktop)</Text>
                <Text component="p">No existe XL en el componente col</Text>

                <Row className="row-bg mb-5" heightAuto>
                    <Col xs={1} className="primary-bg">
                        1
                    </Col>
                    <Col xs={1} className="primary-background-bg">
                        2
                    </Col>
                    <Col xs={1} className="primary-bg">
                        3
                    </Col>
                    <Col xs={1} className="primary-background-bg">
                        4
                    </Col>
                    <Col xs={1} className="primary-bg">
                        5
                    </Col>
                    <Col xs={1} className="primary-background-bg">
                        6
                    </Col>
                    <Col xs={1} className="primary-bg">
                        7
                    </Col>
                    <Col xs={1} className="primary-background-bg">
                        8
                    </Col>
                    <Col xs={1} className="primary-bg">
                        9
                    </Col>
                    <Col xs={1} className="primary-background-bg">
                        10
                    </Col>
                    <Col xs={1} className="primary-bg">
                        11
                    </Col>
                    <Col xs={1} className="primary-background-bg">
                        12
                    </Col>
                </Row>

                <Text component="p">
                    El componente row permite modificar el gapX y el gapY en los valores de 1 a 12 como gapX="2"
                    gapY="3" en este ejemplo
                </Text>
                <Text component="p">
                    Por defecto su height es del 100% del padre. Esto se puede modificar con heightAuto el cual se
                    ajusta a su contenido.
                </Text>
                <Row className="row-bg my-5" gapX="2" gapY="3" heightAuto>
                    <Col xs={1} className="primary-bg">
                        1
                    </Col>
                    <Col xs={1} className="primary-background-bg">
                        2
                    </Col>
                    <Col xs={1} className="primary-bg">
                        3
                    </Col>
                    <Col xs={1} className="primary-background-bg">
                        4
                    </Col>
                    <Col xs={1} className="primary-bg">
                        5
                    </Col>
                    <Col xs={1} className="primary-background-bg">
                        6
                    </Col>
                    <Col xs={1} className="primary-bg">
                        7
                    </Col>
                    <Col xs={1} className="primary-background-bg">
                        8
                    </Col>
                    <Col xs={1} className="primary-bg">
                        9
                    </Col>
                    <Col xs={1} className="primary-background-bg">
                        10
                    </Col>
                    <Col xs={1} className="primary-bg">
                        11
                    </Col>
                    <Col xs={1} className="primary-background-bg">
                        12
                    </Col>
                    <Col xs={1} className="primary-bg">
                        1
                    </Col>
                    <Col xs={1} className="primary-background-bg">
                        2
                    </Col>
                    <Col xs={1} className="primary-bg">
                        3
                    </Col>
                    <Col xs={1} className="primary-background-bg">
                        4
                    </Col>
                    <Col xs={1} className="primary-bg">
                        5
                    </Col>
                    <Col xs={1} className="primary-background-bg">
                        6
                    </Col>
                    <Col xs={1} className="primary-bg">
                        7
                    </Col>
                    <Col xs={1} className="primary-background-bg">
                        8
                    </Col>
                    <Col xs={1} className="primary-bg">
                        9
                    </Col>
                    <Col xs={1} className="primary-background-bg">
                        10
                    </Col>
                    <Col xs={1} className="primary-bg">
                        11
                    </Col>
                    <Col xs={1} className="primary-background-bg">
                        12
                    </Col>
                </Row>
                <Text component="p">
                    El componente row define 12 columnas. Siempre debe usarse el componente row como padre del
                    componente col, donde se agrega el contenido. Si se quiere agregar un contenido del total de las 12
                    columnas siempre respetar padre row con hijo col (con el valor xs={12} para que ocupe las 12
                    columnas en todas las resoluciones) NUNCA USAR EL COMPONENTE Row solo.
                </Text>
                <Text component="h3" className="mt-0" color="primary" bold>
                    Col
                </Text>
                <Box className="mb-5">
                    <Text color="primary" bold>
                        Props:{" "}
                    </Text>
                    <Text bold>lg, lgOffset, md, mdOffset, sm, smOffset, xs, xsOffset;</Text>
                </Box>
                <Text component="p">
                    El comportamiento responsivo de las col es Mobile First. Esto significa que la declaración a
                    resoluciones pequeñas es la primera y siempre debe estar. En caso de querer un comportamiento
                    responsivo a mayores resoluciones se pueden utilizar los tamaños sm, md y lg. El componente no
                    acepta un tamaño xl. Vease en el ejemplo de abajo el comportamiento responsivo.
                </Text>
                <Row className="row-bg mb-5" heightAuto>
                    <Col xs={12} sm={9} md={6} lg={3} className="primary-bg">
                        1
                    </Col>
                    <Col xs={12} sm={9} md={6} lg={3} className="primary-background-bg">
                        2
                    </Col>
                    <Col xs={12} sm={9} md={6} lg={3} className="primary-bg">
                        xs={12} y lg={6}
                    </Col>
                </Row>
                <Text component="p">
                    Para hacer que un hijo de row comience en otra columna que no sea la primera se puede utilizar el
                    offSet. Este se puede definir para los mismos tamaños de pantalla que la declaración de ancho de
                    columnas. Para esto se utilizan xsOffset, smOffset, mdOffset y lgOffset.
                </Text>
                <Text component="p">
                    Esto es muy útil para centrar contenidos en los cuales su diseño se basa en la grilla. Es importante
                    destacar que no se pueden centrar los siguientes anchos de columnas. 11, 9, 7, 5, 3 y 1.
                </Text>
                <Row className="row-bg mb-5" heightAuto>
                    <Col xs={6} xsOffset={3} md={8} mdOffset={2} lg={6} lgOffset={3} className="primary-bg">
                        1 offset
                    </Col>
                </Row>
            </Collapsible>

            {/*  ************* BOX ************* */}

            <Collapsible title="Box Component" id="BoxComponent">
                <Text component="h3" className="mt-0" color="primary" bold>
                    Box
                </Text>

                <Box className="mb-5">
                    <Text color="primary" bold>
                        Props:{" "}
                    </Text>
                    <Text bold>
                        alignX, alignY, background, borderRadius, children, className, column, component, display,
                        fitWidth, fullWidth, id, wrap,
                    </Text>
                </Box>
                <Text component="p" className="mt-0">
                    El componente Box se debe utilizar para definir los tags de HTML contenedores
                </Text>
                <Text component="p" className="mt-8">
                    La prop{" "}
                    <Text color="primary" bold>
                        component{" "}
                    </Text>
                    acepta cualquiera de los siguientes tags:{" "}
                    <Text bold>
                        "article", "aside", "div", "footer", "header", "main", "nav", "section", "ul", "ol"
                    </Text>
                    <Text component="p">
                        Es importante intentar mantener una estructura semantica. Podriamos utilizar div como contenedor
                        para todo, pero no le estariamos agregando información semantica de que es cada sección del
                        contenido. Es por esto que existen diferentes tags.
                    </Text>
                    <Button
                        className="px-0"
                        bsStyle="link"
                        secondary
                        externalHref="https://www.w3schools.com/html/html5_semantic_elements.asp">
                        Link que amplía info sobre uso de tags semanticamnet
                    </Button>
                </Text>

                <Text component="p" className="mt-8">
                    La prop{" "}
                    <Text color="primary" bold>
                        display
                    </Text>{" "}
                    acepta cualquiera de los siguientes valores:{" "}
                    <Text bold>"flex", "inline-flex", "grid", "block", "inline-block"</Text>
                </Text>

                <Box background="primary" className="p-5">
                    <Text color="inverse">Siempre debe tener un children</Text>
                </Box>
                <Text component="p" color="primary" size="3" className="mt-8" bold>
                    Display Flex-box
                </Text>
                <Text component="p" className="mt-8" color="primary" bold>
                    Alineación en el eje X
                </Text>
                <Text component="p" size="6">
                    Permite utilizar todas las propiedades de flex-box para alinear los contenidos. Para eso se debe
                    definir display="flex"
                </Text>

                <Box background="primary" display="flex" className="mb-5" column>
                    <Text className="p-5" color="inverse">
                        alignX="flex-start" por default
                    </Text>
                </Box>

                <Box background="primary" display="flex" alignX="center" className="mb-5">
                    <Text background="primary" fitWidth className="p-5" color="inverse">
                        alignX="center"
                    </Text>
                </Box>
                <Box background="primary" display="flex" alignX="flex-end" className="mb-5">
                    <Text className="p-5" color="inverse">
                        alignX="flex-end"
                    </Text>
                </Box>
                <Box display="flex" alignX="around" className="mb-5" wrap>
                    <Box background="primary" display="flex" fullWidth>
                        <Text background="primary" fitWidth className="p-5" color="inverse">
                            alignX="around" Genera espacio entre los hijos. Genera la mitad de espacio en los extremos
                            que entre los hijos.
                        </Text>
                    </Box>
                    <Box background="secondary" display="flex" fitWidth>
                        <Text className="p-5" color="inverse">
                            elemento 1
                        </Text>
                    </Box>
                    <Box background="secondary" display="flex" fitWidth>
                        <Text className="p-5" color="inverse">
                            elemento 2
                        </Text>
                    </Box>
                    <Box background="secondary" display="flex" fitWidth>
                        <Text className="p-5" color="inverse">
                            elemento 3
                        </Text>
                    </Box>
                </Box>

                <Box display="flex" alignX="between" className="mb-5" wrap>
                    <Box background="primary" display="flex" fullWidth>
                        <Text background="primary" fitWidth className="p-5" color="inverse">
                            alignX="between" Genera espacio entre los hijos sin generar espaciado a los extremos.
                        </Text>
                    </Box>
                    <Box background="secondary" display="flex" fitWidth>
                        <Text className="p-5" color="inverse">
                            elemento 1
                        </Text>
                    </Box>
                    <Box background="secondary" display="flex" fitWidth>
                        <Text className="p-5" color="inverse">
                            elemento 2
                        </Text>
                    </Box>
                    <Box background="secondary" display="flex" fitWidth>
                        <Text className="p-5" color="inverse">
                            elemento 3
                        </Text>
                    </Box>
                </Box>

                <Box display="flex" alignX="evenly" className="mb-5" wrap>
                    <Box background="primary" display="flex" fullWidth>
                        <Text background="primary" fitWidth className="p-5" color="inverse">
                            alignX="evenly" Genera espacio entre los hijos. El espaciado es el mismo en los extremos que
                            entre los hijos.
                        </Text>
                    </Box>
                    <Box background="secondary" display="flex" fitWidth>
                        <Text className="p-5" color="inverse">
                            elemento 1
                        </Text>
                    </Box>
                    <Box background="secondary" display="flex" fitWidth>
                        <Text className="p-5" color="inverse">
                            elemento 2
                        </Text>
                    </Box>
                    <Box background="secondary" display="flex" fitWidth>
                        <Text className="p-5" color="inverse">
                            elemento 3
                        </Text>
                    </Box>
                </Box>

                <Text component="p" className="mt-8" color="primary" bold>
                    Alineación en el eje Y
                </Text>
                <Box display="flex" background="primary" className="mb-5 h-7">
                    <Text className="p-5" color="inverse">
                        alignY="flex-start" por default
                    </Text>
                </Box>
                <Box display="flex" alignY="center" background="primary" className="mb-5 h-7">
                    <Text className="p-5" color="inverse">
                        alignY="center"
                    </Text>
                </Box>
                <Box display="flex" alignY="flex-end" background="primary" className="mb-5 h-7">
                    <Text className="p-5" color="inverse">
                        alignY="flex-end"
                    </Text>
                </Box>

                <Box display="flex" alignX="center" alignY="center" className="mb-5 colored-bg h-7" fullWidth>
                    <Text className="p-5" color="inverse">
                        Contenido alineado al bottom alignX="center" alignY="center"
                    </Text>
                </Box>
                <Text component="p" bold>
                    Se puede alinear utilizando cualquiera de las variantes y acepta las mismas propiedades que flex-box
                    en css.
                </Text>
                <Text component="p" className="mt-8" color="primary" bold>
                    Background Color
                </Text>
                <Text component="p">El background por default es transparente</Text>

                <Box background="primary" display="flex" className="mb-5">
                    <Text className="p-5" color="inverse">
                        background="primary"
                    </Text>
                </Box>
                <Box background="secondary" display="flex" className="mb-5">
                    <Text className="p-5" color="inverse">
                        background="secondary"
                    </Text>
                </Box>
                <Box background="white" display="flex" className="mb-5">
                    <Text className="p-5">background="white"</Text>
                </Box>
                <Box background="grey" display="flex" className="mb-5">
                    <Text className="p-5">background="grey"</Text>
                </Box>
                <Text component="p" className="mt-8" color="primary" bold>
                    Border Radius
                </Text>
                <Text component="p">El border-radius por default es 0</Text>

                <Box borderRadius="min" background="primary" display="flex" className="mb-5">
                    <Text className="p-5" color="inverse">
                        borderRadius="min" 1px
                    </Text>
                </Box>
                <Box borderRadius="xs" background="primary" display="flex" className="mb-5">
                    <Text className="p-5" color="inverse">
                        borderRadius="xs" 2px
                    </Text>
                </Box>
                <Box borderRadius="sm" background="primary" display="flex" className="mb-5">
                    <Text className="p-5" color="inverse">
                        borderRadius="sm" 4px
                    </Text>
                </Box>
                <Box borderRadius="md" background="primary" display="flex" className="mb-5">
                    <Text className="p-5" color="inverse">
                        borderRadius="md" 8px
                    </Text>
                </Box>
                <Box borderRadius="lg" background="primary" display="flex" className="mb-5">
                    <Text className="p-5" color="inverse">
                        borderRadius="lg" 12px
                    </Text>
                </Box>
                <Box borderRadius="xl" background="primary" display="flex" className="mb-5">
                    <Text className="p-5" color="inverse">
                        borderRadius="xl" 16px
                    </Text>
                </Box>
                <Box borderRadius="xxl" background="primary" display="flex" className="mb-5">
                    <Text className="p-5" color="inverse">
                        borderRadius="xxl" 20px
                    </Text>
                </Box>
                <Box borderRadius="extreme" background="primary" display="flex" className="mb-5">
                    <Text className="p-5" color="inverse">
                        borderRadius="extreme" 32px
                    </Text>
                </Box>
                <Box borderRadius="circle" background="primary" display="flex" className="mb-5">
                    <Text className="p-5" color="inverse">
                        borderRadius="circle" 50%
                    </Text>
                </Box>
                <Text component="p" className="mt-8" color="primary" bold>
                    Column
                </Text>
                <Box display="flex" className="mb-5 colored-bg">
                    <Text className="p-5" color="inverse">
                        Sin column declarada
                    </Text>
                    <Text className="p-5" color="inverse">
                        Se puede usar alignY="flex-start"
                    </Text>
                </Box>
                <Box display="flex" className="mb-5 colored-bg" column>
                    <Text className="p-5" color="inverse">
                        Con column declarada
                    </Text>
                    <Text className="p-5" color="inverse">
                        Se puede usar alignY="flex-start"
                    </Text>
                </Box>
                <Text className="p-5" color="text">
                    **** Los ejes se invierten cuando se aplica column. alignY pasa a afectar horizontalmente y alignX
                    verticalmente.
                </Text>

                <Text component="p" className="mt-8" color="primary" bold>
                    fitWidth
                </Text>
                <Box display="flex" className="mb-5 colored-bg" fitWidth>
                    <Text className="p-5" color="inverse">
                        El ancho se ajusta al contenido
                    </Text>
                </Box>
                <Text component="p" className="mt-8" color="primary" bold>
                    FullWidth
                </Text>
                <Box display="flex" className="mb-5 colored-bg" fullWidth>
                    <Text className="p-5" color="inverse">
                        ancho del 100% del padre
                    </Text>
                </Box>
                <Text component="p" className="mt-8" color="primary" bold>
                    Wrap
                </Text>
                <Box display="flex" className="mb-5 colored-bg">
                    <Text className="p-5" color="inverse">
                        Sin wrap
                    </Text>
                    <Text className="p-5" color="inverse">
                        Texto para ver el comportamineto
                    </Text>
                    <Text className="p-5" color="inverse">
                        Texto para ver el comportamineto
                    </Text>
                    <Text className="p-5" color="inverse">
                        Texto para ver el comportamineto
                    </Text>
                </Box>
                <Box display="flex" className="mb-5 colored-bg" wrap>
                    <Text className="p-5" color="inverse">
                        Con wrap declarada
                    </Text>
                    <Text className="p-5" color="inverse">
                        Texto para ver el comportamineto
                    </Text>
                    <Text className="p-5" color="inverse">
                        Texto para ver el comportamineto
                    </Text>
                    <Text className="p-5" color="inverse">
                        Texto para ver el comportamineto
                    </Text>
                </Box>
            </Collapsible>

            {/*  ************* TEXT ************* */}

            <Collapsible title="Text Component" id="textComponent">
                <Box display="flex" column>
                    <Text component="h3" className="mt-0" color="primary" bold>
                        Text
                    </Text>
                    <Box className="mb-5">
                        <Text color="primary" bold>
                            Props:{" "}
                        </Text>
                        <Text bold>
                            align, bold, children, className, component, color, ellipsis, ellipsisShow, id, italic,
                            noWrap, labelKey, underlined, uppercase, size, wrap,
                        </Text>
                    </Box>

                    <Box display="flex" className="mb-8" column>
                        <Text component="h2" color="primary" bold>
                            Align
                        </Text>
                        <Text align="left" className="mb-5">
                            <Text color="primary" bold>
                                left (default):
                            </Text>{" "}
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt egestas metus, sed
                            pharetra lorem tincidunt nec. Pellentesque sapien nulla, facilisis eu odio eu, auctor tempus
                            ex Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt egestas metus,
                            sed pharetra lorem tincidunt nec. Pellentesque sapien nulla, facilisis eu odio eu, auctor
                            tempus ex Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt egestas
                            metus, sed pharetra lorem tincidunt nec. Pellentesque sapien nulla, facilisis eu odio eu,
                            auctor tempus ex
                        </Text>
                        <Text align="center" className="mb-5">
                            <Text color="primary" bold>
                                center:
                            </Text>{" "}
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt egestas metus, sed
                            pharetra lorem tincidunt nec. Pellentesque sapien nulla, facilisis eu odio eu, auctor tempus
                            ex Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt egestas metus,
                            sed pharetra lorem tincidunt nec. Pellentesque sapien nulla, facilisis eu odio eu, auctor
                            tempus ex Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt egestas
                            metus, sed pharetra lorem tincidunt nec. Pellentesque sapien nulla, facilisis eu odio eu,
                            auctor tempus ex
                        </Text>
                        <Text align="right" className="mb-5">
                            <Text color="primary" bold>
                                right:
                            </Text>{" "}
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt egestas metus, sed
                            pharetra lorem tincidunt nec. Pellentesque sapien nulla, facilisis eu odio eu, auctor tempus
                            ex Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt egestas metus,
                            sed pharetra lorem tincidunt nec. Pellentesque sapien nulla, facilisis eu odio eu, auctor
                            tempus ex Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt egestas
                            metus, sed pharetra lorem tincidunt nec. Pellentesque sapien nulla, facilisis eu odio eu,
                            auctor tempus ex
                        </Text>
                        <Text align="justify" className="mb-5">
                            <Text color="primary" bold>
                                justify:
                            </Text>{" "}
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt egestas metus, sed
                            pharetra lorem tincidunt nec. Pellentesque sapien nulla, facilisis eu odio eu, auctor tempus
                            ex Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt egestas metus,
                            sed pharetra lorem tincidunt nec. Pellentesque sapien nulla, facilisis eu odio eu, auctor
                            tempus ex Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt egestas
                            metus, sed pharetra lorem tincidunt nec. Pellentesque sapien nulla, facilisis eu odio eu,
                            auctor tempus ex
                        </Text>
                    </Box>
                    <Box display="flex" className="mb-8" column>
                        <Text component="h2" color="primary" bold>
                            Bold
                        </Text>
                        <Text component="p" bold>
                            Este es un texto bold
                        </Text>
                    </Box>
                    <Box display="flex" className="mb-8" column>
                        <Text component="h2" color="primary" bold>
                            ClassName
                        </Text>
                        <Text component="p">
                            Mediante la prop className se pueden agregar las clases que se quieran, como si fuera un tag
                            de HTML.
                        </Text>
                    </Box>
                    <Box display="flex" className="mb-8" column>
                        <Text component="h2" color="primary" bold>
                            Component
                        </Text>
                        <Text component="p" className="mb-5">
                            Se puede utilizar cualquiera de los siguientes componentes. <Text bold>Recordar </Text>que
                            por página se debe tener solo un componente h1 y los siguientes componentes se pueden
                            repetir pero respetando la jerarquía.{" "}
                            <Text component="p">
                                Por ejemplo un h3 no podrá ser padre de un h2. Siempre el orden es decreciente. Un h1
                                debe ser único pero puede contener a varios hijos h2 y estos a su vez a varios hijos h3.
                                Si puedo saltear la jerarquía. Ejm: Padre h1 con hijo h3.
                            </Text>
                        </Text>
                        <Text component="h1">h1</Text>
                        <Text component="h2">h2</Text>
                        <Text component="h3">h3</Text>
                        <Text component="h4">h4</Text>
                        <Text component="h5">h5</Text>
                        <Text component="h6">h6</Text>
                        <Text component="p">p</Text>
                        <Text>span (default)</Text>
                        <Text component="label">label</Text>
                    </Box>
                    <Box display="flex" className="mb-8" column>
                        <Text component="h2" color="primary" bold>
                            Size
                        </Text>
                        <Text component="p" className="mb-5">
                            No confundir Size con tipo de componente. Los tipos de componentes tienen un tamaño
                            determinado. Su aporte es semantico. Usar size solo para definiciones de estilo.
                        </Text>
                        <Text size="big">Size: big</Text>
                        <Text size="1">Size: 1</Text>
                        <Text size="2">Size: 2</Text>
                        <Text size="3">Size: 3</Text>
                        <Text size="4">Size: 4</Text>
                        <Text size="5">Size: 5</Text>
                        <Text size="6">Size: 6</Text>
                        <Text size="7">Size: 7</Text>
                        <Text size="small">Size: small</Text>
                    </Box>
                    <Box className="mb-8">
                        <Text component="h2" color="primary" bold>
                            Color
                        </Text>
                        <Text component="p" className="mb-5">
                            La prop color acepta cualquiera de los siguientes valores: "primary", "secondary", "text",
                            "inverse"
                        </Text>
                        <Text component="p" color="primary">
                            primary
                        </Text>
                        <Text component="p" color="secondary">
                            secondary
                        </Text>
                        <Text component="p" color="text">
                            text
                        </Text>
                        <Text className="colored-bg" component="p" color="inverse">
                            inverse
                        </Text>
                        <Text component="p" color="text-boton">
                            text-boton
                        </Text>
                    </Box>
                    <Box display="flex" className="mb-8" column>
                        <Text component="h2" color="primary" bold>
                            Ellipsis
                        </Text>
                        <Box>
                            <Text>Con ellipsis</Text>
                            <Text ellipsis>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt egestas metus,
                                sed pharetra lorem tincidunt nec. Pellentesque sapien nulla, facilisis eu odio eu,
                                auctor tempus ex
                            </Text>
                        </Box>
                    </Box>
                    <Box display="flex" className="mb-8" column>
                        <Text component="h2" color="primary" bold>
                            EllipsisShow
                        </Text>
                        <Box display="flex" column>
                            <Text>Con ellipsisShow (Esto no está implementado correctamente - no usar)</Text>
                            <Text ellipsisShow>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt egestas metus,
                                sed pharetra lorem tincidunt nec. Pellentesque sapien nulla, facilisis eu odio eu,
                                auctor tempus ex
                            </Text>
                        </Box>
                    </Box>
                    <Box display="flex" className="mb-8" column>
                        <Text component="h2" color="primary" bold>
                            italic
                        </Text>
                        <Text component="p" italic>
                            Este es un texto italic
                        </Text>
                    </Box>
                    <Box display="flex" className="mb-8" column>
                        <Text component="h2" color="primary" bold>
                            noWrap
                        </Text>
                        <Text component="p" noWrap>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt egestas metus, sed
                            pharetra lorem tincidunt nec. Pellentesque sapien nulla, facilisis eu odio eu, auctor tempus
                            ex
                        </Text>
                    </Box>
                    <Box display="flex" className="mb-8" column>
                        <Text component="h2" color="primary" bold>
                            wrap
                        </Text>
                        <Text component="p" wrap>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt egestas metus, sed
                            pharetra lorem tincidunt nec. Pellentesque sapien nulla, facilisis eu odio eu, auctor tempus
                            ex
                        </Text>
                    </Box>
                    <Box display="flex" className="mb-8" column>
                        <Text component="h2" color="primary" bold>
                            LabelKey
                        </Text>
                        <Text labelKey="settings.changePassword.passwordHelp.rule2" />
                        <Text component="p">
                            El texto <Text bold>Debe contener números</Text> corresponde al labelKey =
                            settings.changePassword.passwordHelp.rule2
                        </Text>
                    </Box>
                    <Box display="flex" className="mb-8" column>
                        <Text component="h2" color="primary" bold>
                            Underlined
                        </Text>
                        <Text component="p" underlined>
                            Este es un texto subrayado
                        </Text>
                    </Box>
                    <Box display="flex" className="mb-8" column>
                        <Text component="h2" color="primary" bold>
                            Uppercase
                        </Text>
                        <Text component="p" uppercase>
                            Este es un texto en minusculas pasado a mayusculas con estilos.
                        </Text>
                        <Text>
                            Nunca harcodear las mayusculas. Siempre deben ser estiladas y no venir en mayusculas todo el
                            texto.
                        </Text>
                    </Box>
                </Box>
            </Collapsible>

            {/*  ************* BUTTON ************* */}

            <Collapsible title="Button Component" id="buttonComponent">
                <Text component="h3" className="mt-0" color="primary" bold>
                    Button
                </Text>
                <Box className="mb-5">
                    <Text color="primary" bold>
                        Props:{" "}
                    </Text>
                    <Text bold>
                        id, type, image, imageRight, label, labelClassName, href, to, bsStyle, secondary, inverse,
                        small, expandable, block, disabled, loading, onClick, replace, defaultLabelText, className,
                        style, children, ...props
                    </Text>
                </Box>
                <Box display="flex" className="mb-5" column>
                    <Text component="p" bold>
                        Loading
                    </Text>
                    <Button loading label="global.cancel" />
                </Box>
                <Box display="flex" className="mb-5" column>
                    <Text component="p" bold>
                        Disabled
                    </Text>
                    <Button disabled bsStyle="primary">
                        Button block
                    </Button>
                </Box>
                <Text component="p">
                    El componente button se puede estilar modificando las siguientes props. En cuanto a la prop
                    principal se utiliza <Text bold>bsStyle: "primary", "outline", "link".</Text> También se utilizan
                    las props: <Text bold>small, secondary, inverse</Text>
                </Text>
                <Box display="flex" className="mb-5" column>
                    <Text component="p" bold>
                        bsStyle="primary"
                    </Text>
                    <Button bsStyle="primary" label="global.cancel" />
                </Box>
                <Box display="flex" className="mb-5" column>
                    <Text component="p" bold>
                        bsStyle="outline"
                    </Text>
                    <Button bsStyle="outline" label="global.cancel" />
                </Box>
                <Box display="flex" className="mb-5" column>
                    <Text component="p" bold>
                        bsStyle="link"
                    </Text>
                    <Button bsStyle="link" label="global.cancel" />
                </Box>
                <Box display="flex" background="menu-background" className="mb-5 p-7" column>
                    <Text component="p" bold>
                        Big-Link
                    </Text>
                    <Button
                        bsStyle="big-link"
                        image="images/icons/token/documentToken.svg"
                        label="global.cancel"
                        block
                    />
                </Box>
                <Box display="flex" className="mb-5" column>
                    <Text component="p" bold>
                        Sin definiciones de estilo
                    </Text>
                    <Button label="global.cancel" />
                </Box>
                <Box display="flex" className="mb-5" column>
                    <Text component="p" bold>
                        small
                    </Text>
                    <Button bsStyle="primary" label="global.cancel" small />
                </Box>
                <Box display="flex" className="mb-5" column>
                    <Text component="p" bold>
                        secondary
                    </Text>
                    <Button bsStyle="primary" label="global.cancel" secondary />
                </Box>
                <Box display="flex" className="mb-5" column>
                    <Text component="p" bold>
                        Inverse
                    </Text>
                    <Button bsStyle="primary" label="global.cancel" secondary inverse />
                </Box>
                <Box display="flex" className="mb-5" column>
                    <Text component="p" bold>
                        Inverse
                    </Text>
                    <Button bsStyle="primary" label="global.cancel" inverse />
                </Box>
                <Box display="flex" className="mb-5" column>
                    <Text component="p" bold>
                        Solo icono
                    </Text>
                    <Text component="p">Cuando solo se pasa la prop image se renderea solo el icono / imagen. </Text>
                    <Button bsStyle="outline" image="images/delete-message.svg" />
                    <Button className="mt-5" bsStyle="primary" image="images/delete-message.svg" />
                    <Button className="mt-5" bsStyle="primary" image="images/delete-message.svg" secondary />
                    <Button className="mt-5" bsStyle="primary" image="images/delete-message.svg" secondary inverse />
                    <Box display="flex" column className="my-8">
                        <Text>Icono de 24pxs -agregar prop imageMd-</Text>
                        <Button className="mt-5" bsStyle="primary" image="images/delete-message.svg" imageMd />
                    </Box>
                </Box>
                <Box display="flex" className="mb-5" column>
                    <Text component="p" bold>
                        Icono y texto
                    </Text>
                    <Text component="p">Se puede agregar el texto como children o como label </Text>
                    <Button bsStyle="outline" image="images/delete-message.svg">
                        <Text>Eliminar</Text>
                    </Button>
                </Box>
                <Box display="flex" className="mb-5" column>
                    <Text component="p" bold>
                        Texto e Icono (icono a la derecha)
                    </Text>
                    <Text component="p">Se puede agregar el texto como children o como label </Text>
                    <Button
                        bsStyle="outline"
                        label="settings.fingerprintConfiguration.device.remove"
                        imageRight
                        image="images/delete-message.svg"
                    />
                </Box>
                <Box display="flex" className="mb-5" column>
                    <Text component="p" bold>
                        href declarado
                    </Text>
                    <Text component="p">
                        Cuando se define un href="url" el componente utiliza Link de react router.{" "}
                    </Text>
                    <Button bsStyle="link" href="/desktop" className="px-0" secondary>
                        Ir al dashboard
                    </Button>
                </Box>
                <Box display="flex" className="mb-5" column>
                    <Text component="p" bold>
                        Block
                    </Text>
                    <Text component="p">
                        Al pasar la prop block se hace que el componente ocupe el 100% del ancho de su padre.{" "}
                    </Text>
                    <Button bsStyle="primary" block>
                        Button block
                    </Button>
                </Box>
                <Box display="flex" className="mb-5" column>
                    <Text component="p" bold>
                        Tooltip Hover
                    </Text>
                    <Text component="p">
                        Al pasar la prop block se hace que el componente ocupe el 100% del ancho de su padre.{" "}
                    </Text>
                    <Button bsStyle="primary" tooltipHover="Esto es un tooltip" block>
                        Button block
                    </Button>
                </Box>
            </Collapsible>

            {/*  ************* FORMS ************* */}

            <Collapsible title="Forms Components" id="formsComponents">
                <Row>
                    <Col xs={12} md={6} mdOffset={3}>
                        <Field
                            countries={[
                                { id: "UY", name: "Uruguay" },
                                { id: "BR", name: "Brasil" },
                                { id: "AR", name: "Argentina" },
                                { id: "VE", name: "Venezuela" },
                                { id: "PA", name: "Panamá" },
                            ]}
                            documentCountryMap={[
                                {
                                    id_country_code: "PA",
                                    id_document_type: "CIP",
                                },
                                {
                                    id_country_code: "PA",
                                    id_document_type: "CRP",
                                },
                                {
                                    id_country_code: "UY",
                                    id_document_type: "CED",
                                },
                                {
                                    id_country_code: "UY",
                                    id_document_type: "PAS",
                                },
                                {
                                    id_country_code: "BR",
                                    id_document_type: "CED",
                                },
                                {
                                    id_country_code: "BR",
                                    id_document_type: "PAS",
                                },
                                {
                                    id_country_code: "AR",
                                    id_document_type: "CED",
                                },
                                {
                                    id_country_code: "AR",
                                    id_document_type: "PAS",
                                },
                                {
                                    id_country_code: "VE",
                                    id_document_type: "CED",
                                },
                                {
                                    id_country_code: "VE",
                                    id_document_type: "PAS",
                                },
                            ]}
                            idNumberMap={[
                                {
                                    id_country_code: "PA",
                                    id_document_type: "CIP",
                                    idNumber: "Pan",
                                },
                                {
                                    id_country_code: "PA",
                                    id_document_type: "CIP",
                                    idNumber: "E",
                                },
                                {
                                    id_country_code: "PA",
                                    id_document_type: "CIP",
                                    idNumber: "N",
                                },
                                {
                                    id_country_code: "PA",
                                    id_document_type: "CIP",
                                    idNumber: "Pi",
                                },
                                {
                                    id_country_code: "PA",
                                    id_document_type: "CIP",
                                    idNumber: "Pe",
                                },
                                {
                                    id_country_code: "PA",
                                    id_document_type: "CRP",
                                    idNumber: "E",
                                },
                                {
                                    id_country_code: "VE",
                                    id_document_type: "CED",
                                    idNumber: "E",
                                },
                                {
                                    id_country_code: "VE",
                                    id_document_type: "CED",
                                    idNumber: "V",
                                },
                            ]}
                            idNumber2Map={{
                                PA: [
                                    "01",
                                    "02",
                                    "03",
                                    "04",
                                    "05",
                                    "06",
                                    "07",
                                    "08",
                                    "09",
                                    "10",
                                    "11",
                                    "12",
                                    "13",
                                    "14",
                                ],
                                VE: [
                                    "01",
                                    "02",
                                    "03",
                                    "04",
                                    "05",
                                    "06",
                                    "07",
                                    "08",
                                    "09",
                                    "10",
                                    "11",
                                    "12",
                                    "13",
                                    "14",
                                ],
                            }}
                            component={Document}
                            idForm={FORM_ID}
                            name="addressLine1M"
                        />
                    </Col>
                </Row>

                <Field component={TextField} idForm={FORM_ID} name="addressLine2M" hideLabel />

                <Field
                    component={Selector}
                    options={countries && countries.map((c) => ({ value: c.id, label: c.name }))}
                    idForm={FORM_ID}
                    name="countryM"
                />
                <Field component={TextField} idForm={FORM_ID} name="cityM" />
                <Field component={TextField} idForm={FORM_ID} name="federalStateM" />
                <Field component={TextField} idForm={FORM_ID} name="zipcodeM" />
                <Field idForm="settings.changeAddresses" name="otp" component={Credential} type="otp" />
                <Field idForm="enrollment.associate.step2" name="password" component={Credential} type="password" />
                <Field component={Checkbox} idForm={FORM_ID} name="setMailing" />
                <Field
                    name="pinCode1"
                    component={PinInput}
                    isMasked
                    placeholder="*"
                    maxLength={1}
                    labelNoMarginTop
                    showLabel={false}
                    noMarginBottom
                    className="mt-7"
                />
            </Collapsible>

            {/*  ************* DROPDOWN ************* */}

            <Collapsible title="Dropdown Component" id="dropdownComponents">
                <Box className="mb-5" display="flex" column>
                    <Text component="p" bold>
                        Loading
                    </Text>
                    <Dropdown image="images/download.svg" label="global.download" bsStyle="outline" fetching pullRight>
                        <Button label="accounts.pdfFile" className="dropdown__item-btn" bsStyle="link" />
                        <Button label="accounts.xlsFile" className="dropdown__item-btn" bsStyle="link" />
                        <Button label="accounts.csvFile" className="dropdown__item-btn" bsStyle="link" />
                    </Dropdown>
                </Box>
                <Box className="mb-5" display="flex" column>
                    <Text component="p" bold>
                        Disabled
                    </Text>
                    <Dropdown disabled image="images/download.svg" label="global.download" bsStyle="outline" pullUp>
                        <Button label="accounts.pdfFile" className="dropdown__item-btn" bsStyle="link" />
                        <Button label="accounts.xlsFile" className="dropdown__item-btn" bsStyle="link" />
                        <Button label="accounts.csvFile" className="dropdown__item-btn" bsStyle="link" />
                    </Dropdown>
                </Box>
                <Text component="p">
                    El componente dropdown acepta los mismos estilos que el componente button mediante el parametro
                    <Text bold> bsStyle: "primary", "outline", "link".</Text>
                </Text>
                <Box className="mb-5" display="flex" column>
                    <Text component="p" bold>
                        Dropdown Primary
                    </Text>
                    <Box display="flex" column fitWidth>
                        <Dropdown image="images/download.svg" label="global.download" bsStyle="primary">
                            <Button label="accounts.pdfFile" className="dropdown__item-btn" bsStyle="link" />
                            <Button label="accounts.xlsFile" className="dropdown__item-btn" bsStyle="link" />
                            <Button label="accounts.csvFile" className="dropdown__item-btn" bsStyle="link" />
                        </Dropdown>
                    </Box>
                </Box>
                <Box className="mb-5" display="flex" column>
                    <Text component="p" bold>
                        Dropdown Outline
                    </Text>
                    <Box display="flex" column fitWidth>
                        <Dropdown image="images/download.svg" label="global.download" bsStyle="outline">
                            <Button label="accounts.pdfFile" className="dropdown__item-btn" bsStyle="link" />
                            <Button label="accounts.xlsFile" className="dropdown__item-btn" bsStyle="link" />
                            <Button label="accounts.csvFile" className="dropdown__item-btn" bsStyle="link" />
                        </Dropdown>
                    </Box>
                </Box>
                <Box className="mb-5" display="flex" column>
                    <Text component="p" bold>
                        Dropdown link
                    </Text>
                    <Box display="flex" column fitWidth>
                        <Dropdown image="images/download.svg" label="global.download" bsStyle="link">
                            <Button label="accounts.pdfFile" className="dropdown__item-btn" bsStyle="link" />
                            <Button label="accounts.xlsFile" className="dropdown__item-btn" bsStyle="link" />
                            <Button label="accounts.csvFile" className="dropdown__item-btn" bsStyle="link" />
                        </Dropdown>
                    </Box>
                </Box>

                <Box className="mb-5" display="flex" column>
                    <Text component="p" bold>
                        Solo Icono: al no declarar label y si declarar image.
                    </Text>
                    <Box display="flex" column fitWidth>
                        <Dropdown image="images/download.svg" bsStyle="outline">
                            <Button label="accounts.pdfFile" className="dropdown__item-btn" bsStyle="link" />
                            <Button label="accounts.xlsFile" className="dropdown__item-btn" bsStyle="link" />
                            <Button label="accounts.csvFile" className="dropdown__item-btn" bsStyle="link" />
                        </Dropdown>
                    </Box>
                </Box>

                <Box className="mb-5" display="flex" column>
                    <Text component="p" bold>
                        Solo label: al no declarar image y si declarar label.
                    </Text>
                    <Box display="flex" column fitWidth>
                        <Dropdown label="global.download" bsStyle="outline">
                            <Button label="accounts.pdfFile" className="dropdown__item-btn" bsStyle="link" />
                            <Button label="accounts.xlsFile" className="dropdown__item-btn" bsStyle="link" />
                            <Button label="accounts.csvFile" className="dropdown__item-btn" bsStyle="link" />
                        </Dropdown>
                    </Box>
                </Box>
                {/*  
                <Box className="mb-5" display="flex" alignY="flex-end" column>
                    <Text component="p" bold>
                        Dropdown pullRight
                    </Text>
                    <Box display="flex" column fitWidth>
                        <Dropdown image="images/download.svg" bsStyle="outline" pullRight>
                            <Button label="accounts.pdfFile" className="dropdown__item-btn" bsStyle="link" />
                            <Button label="accounts.xlsFile" className="dropdown__item-btn" bsStyle="link" />
                            <Button label="accounts.csvFile" className="dropdown__item-btn" bsStyle="link" />
                        </Dropdown>
                    </Box>
                </Box>
                 */}
                <Box className="mb-8" display="flex" column>
                    <Text component="p" bold>
                        Dropdown pullUp
                    </Text>
                    <Box display="flex" column fitWidth>
                        <Dropdown image="images/download.svg" label="global.download" bsStyle="outline" pullUp>
                            <Button label="accounts.pdfFile" className="dropdown__item-btn" bsStyle="link" />
                            <Button label="accounts.xlsFile" className="dropdown__item-btn" bsStyle="link" />
                            <Button label="accounts.csvFile" className="dropdown__item-btn" bsStyle="link" />
                        </Dropdown>
                    </Box>
                </Box>
            </Collapsible>

            {/*  ************* TOOLTIP ************* */}

            <Collapsible title="Tooltip Component" id="tooltipComponents">
                <Box className="mb-5" display="flex" alignX="center" column fullWidth>
                    <Text component="p" bold>
                        Solo label: al no declarar image y si declarar label.
                    </Text>
                    <Tooltip
                        image="images/tooltip-icon.svg"
                        text="Acá se manda un texto contenido en el tooltip"
                        position="top-right"
                    />
                </Box>
            </Collapsible>

            {/*  ************* TOKEN  ************* */}

            {/* <Collapsible title="Token Component" id="tokenComponents">
                <Box className="mb-5 py-2" display="flex" alignY="center" column fullWidth background="secondary">
                    <TokenButton />
                </Box>
            </Collapsible> */}
        </MainContainer>
    );
};

UiComponents.propTypes = {
    countries: arrayOf(shape({ id: string, name: string })).isRequired,
};

// UiComponents.defaultProps = {

// };

const mapStateToProps = (state) => ({
    fetching: settingsSelectors.isFetching(state),
    address: settingsSelectors.getAddress(state),
    mailingAddress: settingsSelectors.getMailingAdress(state),
    countries: settingsSelectors.getCountries(state),
    activeRegion: loginSelectors.getRegion(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: (props) => {
            const { address, mailingAddress } = props;
            return {
                addressLine1: address.addressLine1,
                addressLine2: address.addressLine2,
                country: address.country,
                city: address.city,
                federalState: address.federalState,
                zipcode: address.zipcode,
                setMailing: mailingAddress != null,
                addressLine1M: mailingAddress ? mailingAddress.addressLine1 : "",
                addressLine2M: mailingAddress ? mailingAddress.addressLine2 : "",
                countryM: mailingAddress ? mailingAddress.country : address.country,
                cityM: mailingAddress ? mailingAddress.city : "",
                federalStateM: mailingAddress ? mailingAddress.federalState : "",
                zipcodeM: mailingAddress ? mailingAddress.zipcode : "",
                otp: "",
            };
        },

        validationSchema: () =>
            Yup.lazy((values) => {
                const { setMailing } = values;

                return Yup.object().shape({
                    addressLine1: Yup.string().required(
                        i18nUtils.get("settings.changeAddresses.validations.requiredField"),
                    ),
                    city: Yup.string().required(i18nUtils.get("settings.changeAddresses.validations.requiredField")),
                    country: Yup.string().required(i18nUtils.get("settings.changeAddresses.validations.requiredField")),
                    federalState: Yup.string().required(
                        i18nUtils.get("settings.changeAddresses.validations.requiredField"),
                    ),
                    zipcode: Yup.string().required(i18nUtils.get("settings.changeAddresses.validations.requiredField")),
                    addressLine1M: setMailing
                        ? Yup.string().required(i18nUtils.get("settings.changeAddresses.validations.requiredField"))
                        : Yup.string(),
                    cityM: setMailing
                        ? Yup.string().required(i18nUtils.get("settings.changeAddresses.validations.requiredField"))
                        : Yup.string(),
                    countryM: setMailing
                        ? Yup.string().required(i18nUtils.get("settings.changeAddresses.validations.requiredField"))
                        : Yup.string(),
                    federalStateM: setMailing
                        ? Yup.string().required(i18nUtils.get("settings.changeAddresses.validations.requiredField"))
                        : Yup.string(),
                    zipcodeM: setMailing
                        ? Yup.string().required(i18nUtils.get("settings.changeAddresses.validations.requiredField"))
                        : Yup.string(),
                });
            }),
        handleSubmit: (
            {
                addressLine1,
                addressLine2,
                country,
                city,
                federalState,
                zipcode,
                addressLine1M,
                addressLine2M,
                countryM,
                cityM,
                federalStateM,
                zipcodeM,
                setMailing,
                otp,
            },
            formikBag,
        ) => {
            const { dispatch } = formikBag.props;
            const address = {
                addressLine1,
                addressLine2,
                country,
                city,
                federalState,
                zipcode,
            };
            const mailingAddress = {
                addressLine1: addressLine1M,
                addressLine2: addressLine2M,
                country: countryM,
                city: cityM,
                federalState: federalStateM,
                zipcode: zipcodeM,
            };

            dispatch(
                settingsActions.setAddressesInformation(address, setMailing ? mailingAddress : null, otp, formikBag),
            );
        },
    }),
)(UiComponents);
