import classNames from "classnames";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { goBack, replace } from "react-router-redux";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { getLocationBasePath } from "util/revelock";
import * as i18n from "util/i18n";
import Info from "pages/_components/Info";
import Image from "pages/_components/Image";
import { isDesktop } from "react-device-detect";

const PAGE_IDENTIFIER = "accounts.movement";
const PREFIX_KEY = "accounts.movement.detail";
class MovementDetail extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        movement: shape({
            idAccount: string,
            idStatement: string,
            note: string,
        }).isRequired,
        account: shape({
            currency: string,
        }).isRequired,
        fetching: bool,
        setLocationCustomPath: func,
        match: shape().isRequired,
        movementDetail: shape({}),
        documentImage: shape({
            frontImage: string,
            backImage: string,
        }),
    };

    static defaultProps = {
        fetching: false,
        setLocationCustomPath: () => {},
        movementDetail: null,
        documentImage: null,
    };

    state = {
        downloadingDetail: false,
    };

    componentDidMount() {
        const { dispatch, movement, account } = this.props;

        if (!account || !movement) {
            dispatch(replace("/desktop"));
            return;
        }

        if (account && movement && (movement.indicatorDetail === "C" || movement.indicatorDetail === "D")) {
            dispatch(accountsActions.movementDetails(movement.idAccount, account.productTypeBackend, movement));
        }
        this.setRevelockLocation();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath, match } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath(getLocationBasePath(match), PAGE_IDENTIFIER);
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    onFinishDownload = () => {
        this.setState({
            downloadingDetail: false,
        });
    };

    handleDownload = () => {
        const { dispatch, account, movement, movementDetail, documentImage } = this.props;
        this.setState({
            downloadingDetail: true,
        });

        dispatch(
            accountsActions.downloadMovementDetail(
                account.idProduct,
                account.ownerName,
                movement,
                movementDetail,
                documentImage || {},
                this.onFinishDownload,
            ),
        );
    };

    renderTitle = (title, image) => (
        <Box
            display="flex"
            alignY="center"
            className={classNames({
                "mb-5": isDesktop,
                "mb-2": !isDesktop,
            })}
            fullWidth>
            <Box
                display="flex"
                alignY="center"
                background="background-primary"
                borderRadius="circle"
                className="mr-5 ellipse-s-38">
                <Image src={`images/util/${image}`} />
            </Box>
            <Text bold labelKey={title} />
        </Box>
    );

    renderAccount = () => {
        const { account, movement } = this.props;
        const { indicatorDetail } = movement;
        const { ownerName } = account;

        switch (indicatorDetail) {
            case "D":
                return <Info labelKey={`${PREFIX_KEY}.accountName`} text={ownerName} flex={!isDesktop} />;
            default:
                return <Info labelKey={`${PREFIX_KEY}.nameOrigin`} text={ownerName} flex={!isDesktop} />;
        }
    };

    isNotNull = (value) => value !== undefined && value !== null;

    renderAmounts = () => {
        const { movement } = this.props;

        const {
            accountCurrency,
            indicatorDetail,
            transactionType,
            debit,
            credit,
            ownChecks,
            localChecks,
            otherChecks,
            totalChecks,
            total,
        } = movement;

        switch (indicatorDetail) {
            case "D":
                return (
                    <>
                        {this.isNotNull(ownChecks) && ownChecks > 0 && (
                            <Col xs={3}>
                                <Info
                                    labelKey={`${PREFIX_KEY}.ownChecks`}
                                    amount={ownChecks}
                                    currency={accountCurrency}
                                    flex={!isDesktop}
                                    classNameValue="size-6"
                                />
                            </Col>
                        )}
                        {this.isNotNull(otherChecks) && otherChecks > 0 && (
                            <Col xs={3}>
                                <Info
                                    labelKey={`${PREFIX_KEY}.otherChecks`}
                                    amount={otherChecks}
                                    currency={accountCurrency}
                                    flex={!isDesktop}
                                    classNameValue="size-6"
                                />
                            </Col>
                        )}
                        {this.isNotNull(localChecks) && localChecks > 0 && (
                            <Col xs={3}>
                                <Info
                                    labelKey={`${PREFIX_KEY}.localChecks`}
                                    amount={localChecks}
                                    currency={accountCurrency}
                                    flex={!isDesktop}
                                    classNameValue="size-6"
                                />
                            </Col>
                        )}
                        {this.isNotNull(totalChecks) && totalChecks > 0 && (
                            <Col xs={3}>
                                <Info
                                    labelKey={`${PREFIX_KEY}.totalChecks`}
                                    amount={totalChecks}
                                    currency={accountCurrency}
                                    flex={!isDesktop}
                                    classNameValue="size-6"
                                />
                            </Col>
                        )}
                        <Col xs={3}>
                            <Info
                                labelKey={`${PREFIX_KEY}.total`}
                                amount={total}
                                currency={accountCurrency}
                                flex={!isDesktop}
                                classNameValue="size-6"
                            />
                        </Col>
                    </>
                );
            default:
                return (
                    <Col xs={3}>
                        <Info
                            labelKey={`${PREFIX_KEY}.amount`}
                            amount={transactionType === "DEBITO" ? debit : credit}
                            currency={accountCurrency}
                            flex={!isDesktop}
                            classNameValue="size-6"
                        />
                    </Col>
                );
        }
    };

    renderMovementDetail = (movementDetail) => {
        const { bank, name, account } = movementDetail;
        return (
            <>
                {account && (
                    <Col xs={3}>
                        <Info
                            labelKey={`${PREFIX_KEY}.destinationAccount`}
                            text={account}
                            flex={!isDesktop}
                            classNameValue="size-6"
                        />
                    </Col>
                )}
                {name && (
                    <Col xs={3}>
                        <Info
                            labelKey={`${PREFIX_KEY}.destinationName`}
                            text={name}
                            flex={!isDesktop}
                            classNameValue="size-6"
                        />
                    </Col>
                )}
                {bank && (
                    <Col xs={3}>
                        <Info
                            labelKey={`${PREFIX_KEY}.destinationBank`}
                            text={bank}
                            flex={!isDesktop}
                            classNameValue="size-6"
                        />
                    </Col>
                )}
            </>
        );
    };

    renderEmptyImgs = () => (
        <Box className="m-8">
            <Image src="images/emptyFile.svg" />
            <Box className="text-center mt-5 mb-4">
                <Text labelKey={`${PREFIX_KEY}.noImage.title`} bold size="3" />
            </Box>
            <Box className="text-center">
                <Text labelKey={`${PREFIX_KEY}.noImage.text`} />
            </Box>
        </Box>
    );

    renderImgs = (documentImage) => {
        const { frontImage, backImage } = documentImage;
        return (
            <>
                {(frontImage || backImage) && (
                    <Box
                        background="background-disabled"
                        borderRadius="lg"
                        className="p-5 box-detail-img"
                        display="flex"
                        gapX="5"
                        alignX="center">
                        {frontImage && <img src={`data:image/jpg;base64, ${frontImage}`} alt="anverso" />}
                        {backImage && <img src={`data:image/jpg;base64, ${backImage}`} alt="reverso" />}
                    </Box>
                )}

                {!frontImage && !backImage && <>{this.renderEmptyImgs()}</>}
            </>
        );
    };

    render() {
        const { downloadingDetail } = this.state;
        const { account, fetching, movement, movementDetail, documentImage } = this.props;

        if (!account || !movement) {
            return <></>;
        }

        const { numberMask } = account;

        const { valueDate, date, transaction, concept, channel, transactionType, officeName } = movement;

        return (
            movement && (
                <Fragment>
                    <Head
                        onBack={this.handleBack}
                        textBack="accounts.detail.movement.returnToDetail"
                        title="accounts.movement.detail.title"
                        actionButton={isDesktop && this.handleDownload}
                        actionButtonLabel={isDesktop && "global.download"}
                        actionButtonBsStyle={isDesktop && "primary"}
                        actionButtonImage={isDesktop && "images/download.svg"}
                        actionButtonLoading={downloadingDetail}
                    />
                    <Notification scopeToShow="movementDetail" />

                    <MainContainer showLoader={fetching}>
                        <Box
                            background={isDesktop && "white"}
                            borderRadius="lg"
                            className={classNames("mt-5", {
                                "p-9 box-shadow-small": isDesktop,
                            })}>
                            {this.renderTitle(`${PREFIX_KEY}.data.title`, "finance.svg")}
                            <Row
                                gapY={isDesktop ? null : "0"}
                                className={classNames("mb-9", {
                                    "row-template-1fr": !isDesktop,
                                })}>
                                <Col xs={3}>
                                    <Info
                                        labelKey={`${PREFIX_KEY}.account`}
                                        text={numberMask}
                                        flex={!isDesktop}
                                        classNameValue="size-6"
                                    />
                                </Col>
                                <Col xs={3}>{this.renderAccount()}</Col>
                                <Col xs={3}>
                                    <Info
                                        labelKey={`${PREFIX_KEY}.valueDate`}
                                        date={valueDate}
                                        flex={!isDesktop}
                                        classNameValue="size-6"
                                    />
                                </Col>
                                <Col xs={3}>
                                    <Info
                                        labelKey={`${PREFIX_KEY}.date`}
                                        date={date}
                                        anotherFormatDate="DD/MM/YYYY hh:mm a"
                                        flex={!isDesktop}
                                        classNameValue="size-6"
                                    />
                                </Col>
                                <Col xs={3}>
                                    <Info
                                        labelKey={`${PREFIX_KEY}.transaction`}
                                        text={transaction}
                                        flex={!isDesktop}
                                        classNameValue="size-6"
                                    />
                                </Col>
                                <Col xs={3}>
                                    <Info
                                        labelKey={`${PREFIX_KEY}.type`}
                                        text={transactionType}
                                        flex={!isDesktop}
                                        classNameValue="size-6"
                                    />
                                </Col>
                                <Col xs={3}>
                                    <Info
                                        labelKey={`${PREFIX_KEY}.office`}
                                        text={officeName}
                                        flex={!isDesktop}
                                        classNameValue="size-6"
                                    />
                                </Col>
                                <Col xs={3}>
                                    <Info
                                        labelKey={`${PREFIX_KEY}.channel`}
                                        text={i18n.get(`channel.shortDescription.${channel}`, channel)}
                                        flex={!isDesktop}
                                        classNameValue="size-6"
                                    />
                                </Col>
                                <Col xs={3}>
                                    <Info
                                        labelKey={`${PREFIX_KEY}.concept`}
                                        text={concept}
                                        flex={!isDesktop}
                                        classNameValue="size-6"
                                    />
                                </Col>
                                {movementDetail && this.renderMovementDetail(movementDetail)}
                                {this.renderAmounts()}
                            </Row>

                            {documentImage && (
                                <>
                                    {this.renderTitle(`${PREFIX_KEY}.image.title`, "camera.svg")}
                                    {this.renderImgs(documentImage)}
                                </>
                            )}

                            {!isDesktop && (
                                <Button
                                    className="btn-block"
                                    onClick={this.handleDownload}
                                    bsStyle="primary"
                                    label="global.download"
                                    image="images/download.svg"
                                    loading={downloadingDetail}
                                />
                            )}
                        </Box>
                    </MainContainer>
                </Fragment>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
    fetching: accountsSelectors.getFetching(state),
    movement: accountsSelectors.getSelectedMovement(state),
    movementDetail: accountsSelectors.getMovementDetail(state),
    documentImage: accountsSelectors.getDocumentImage(state),
});

export default connect(mapStateToProps)(resizableRoute(withRevelockPositionContext(MovementDetail)));
