import React from "react";
import PropTypes, { shape } from "prop-types";

const Iframe = ({ src, width, height, style, onLoad, onError, title, ...restProps }) => (
    <div className="iframe-container">
        <iframe
            src={src}
            width={width}
            height={height}
            style={style}
            onLoad={onLoad}
            onError={onError}
            title={title}
            {...restProps}
        />
    </div>
);

Iframe.propTypes = {
    src: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: shape({}),
    onLoad: PropTypes.func,
    onError: PropTypes.func,
    title: PropTypes.string,
};

Iframe.defaultProps = {
    width: null,
    height: null,
    style: {},
    onLoad: () => {},
    onError: () => {},
    title: "Iframe",
};

export default Iframe;
