import React, { Component } from "react";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { bool, func, shape } from "prop-types";
import { connect } from "react-redux";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import { GET_PAGINATED_DATA_LIST, selectors as paginatedTableSelectors } from "reducers/paginatedTable";
import { loadingSelector } from "reducers/loading";
import TransactionFilterProvider, { PDF_CONTENT_TYPE, XLS_CONTENT_TYPE } from "providers/TransactionFilterProvider";
import TransactionHeader from "pages/transactions/_components/TransactionHeader";
import TransactionsItem from "pages/transactions/_components/TransactionItem";
import * as configUtil from "util/config";
import Box from "pages/_components/Box";
import NoResults from "pages/_components/NoResultsMessage";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import classNames from "classnames";
import { Collapse } from "react-bootstrap";
import { replace } from "react-router-redux";
import Table from "pages/_components/PaginatedTable/Table";
import FiltersButtons from "./_components/FiltersButtons";
import HistoricHiddenFilters from "./_components/HistoricHiddenFilters";

class TransactionsList extends Component {
    static propTypes = {
        isFetching: bool.isRequired,
        isDesktop: bool.isRequired,
        setFilters: func.isRequired,
        goDesktop: func.isRequired,
        resetFrom: func.isRequired,
        fromTicket: bool.isRequired,
        filtersBkP: shape({}),
    };

    static defaultProps = {
        filtersBkP: null,
    };

    state = {
        ascending: false,
        filtersValue: {
            onlyPendings: false,
            pendingDispatch: false,
        },
        hasFiltersApplied: false,
        showFilters: false,
        currentPage: 1,
    };

    componentWillMount = () => {
        const { filtersBkP, setFilters, fromTicket, resetFrom } = this.props;

        if (fromTicket && filtersBkP) {
            this.setState({
                filtersValue: { ...filtersBkP, onlyPendings: false, pendingDispatch: false },
                hasFiltersApplied: filtersBkP !== null,
                showFilters: true,
            });
        } else {
            setFilters(null);
        }
        resetFrom();
    };

    handleOrder = () => {
        const { ascending } = this.state;
        this.setState({ ascending: !ascending });
    };

    resetHandleOrder = () => {
        this.setState({ ascending: false });
    };

    handleShowFiltersMobile = () => {
        this.setState({ showFilters: true });
    };

    handleHideFiltersMobile = () => {
        this.setState({ showFilters: false });
    };

    filtersButtons = () => {
        const { showFilters } = this.state;
        return (
            <FiltersButtons
                showFilters={showFilters}
                handleHideFilters={this.handleHideFiltersMobile}
                handleShowFilters={this.handleShowFiltersMobile}
            />
        );
    };

    setCurrentPage = (page) => {
        this.setState({ currentPage: page });
    };

    render() {
        const { isFetching, isDesktop, paginatedData } = this.props;
        const { filtersValue, hasFiltersApplied, showFilters, currentPage } = this.state;

        const documents = [
            {
                label: "global.txtFile",
                onClick: (callback) => {
                    callback("text/plain", filtersValue);
                },
            },
            {
                label: "global.pdfFile",
                onClick: (callback) => {
                    callback(PDF_CONTENT_TYPE, filtersValue);
                },
            },
            {
                label: "global.xlsFile",
                onClick: (callback) => {
                    callback(XLS_CONTENT_TYPE, filtersValue);
                },
            },
        ];

        const setFilterValues = (newFilters) => {
            const { setFilters } = this.props;
            const { selectedOperationType, selectedTransactionType, selectedFilter } = newFilters;
            if (selectedOperationType === "all" && selectedTransactionType === "all" && selectedFilter === "all") {
                this.setState({ filtersValue: newFilters, hasFiltersApplied: true });
            } else {
                this.setState({ filtersValue: newFilters, hasFiltersApplied: true });
            }
            setFilters(newFilters);
        };

        const renderEmptyList = () => (
            <Box className="m-7">
                <Image src="images/empty.svg" />
                <Box className="text-center mt-5 mb-4">
                    <Text labelKey="transactions.none.title" bold size="3" />
                </Box>
                <Box className="text-center">
                    <Text labelKey="transactions.historic.none.text" defaultValue=""/>
                </Box>
            </Box>
        );

        const maxRangeDays = configUtil.getInteger("transactions.filters.maxRangeDays");

        return (
            <TransactionFilterProvider>
                <Notification scopeToShow="transactions" />
                <Head
                    onBack={!isDesktop && this.props.goDesktop}
                    title="transactions.list.title"
                    exportList={documents}
                    exportListBsStyle={isDesktop ? "download" : "only-icon"}
                    disabledExportList={isFetching || paginatedData["transactions.list.transactions"]?.paginatedList?.elementList?.length === 0}
                />
                
                <MainContainer
                    showChildrenWithLoader
                    className={classNames("main-container", {
                        "background-white": !isDesktop,
                    })}>
                    <Box
                        borderRadius="lg"
                        background="white"
                        className={classNames("mt-3", {
                            "box-shadow-small texture-header p-7": isDesktop,
                        })}>
                       
                            <Box
                                fullWidth
                                display="flex"
                                className="px-7 py-4 mb-5 border-radius-lg box-shadow-small background-white">
                                <Box fullWidth>
                                    <Box>
                                        <Text component="h3" labelKey="transactions.list.index.title" bold />
                                    </Box>
                                    <Box className="mt-4">
                                        <Text
                                            labelKey="transactions.list.index.info"
                                            textParams={{
                                                NUM_DAYS_TRX: maxRangeDays,
                                            }}
                                            color="text-disabled-color"
                                            light
                                        />
                                    </Box>
                                </Box>
                                <Box>
                                    <Image src="images/transaction_historial.svg" />
                                </Box>
                            </Box>
                      
                        
                        {isDesktop ? (
                            <Box>
                                <HistoricHiddenFilters
                                    // dispatch={dispatch}
                                    isDesktop={isDesktop}
                                    fetching={isFetching}
                                    resetHandleOrder={this.resetHandleOrder}
                                    filterValues={filtersValue}
                                    setFilterValues={setFilterValues}
                                    isTransactionList
                                />
                            </Box>
                        ) : (
                            <Collapse in={showFilters}>
                                <Box>
                                    <HistoricHiddenFilters
                                        // dispatch={dispatch}
                                        isDesktop={isDesktop}
                                        fetching={isFetching}
                                        resetHandleOrder={this.resetHandleOrder}
                                        filterValues={filtersValue}
                                        setFilterValues={setFilterValues}
                                        isTransactionList
                                    />
                                </Box>
                            </Collapse>
                        )}
                        <div className="above-the-fold">
                            <Box className={classNames({ "mt-5": isDesktop, "pb-5": !isDesktop })}>
                                <Table
                                    idActivity="transactions.list"
                                    notificationScopes={["transactions"]}
                                    name="transactions"
                                    hasFiltersApplied={hasFiltersApplied}
                                    headerProps={{}}
                                    itemProps={{
                                        isDesktop,
                                        fromPending: false,
                                    }}
                                    itemComponent={TransactionsItem}
                                    headerComponent={TransactionHeader}
                                    filters={filtersValue}
                                    isDesktop={isDesktop}
                                    withoutItemsMessage={renderEmptyList()}
                                    withoutItemsMessageFilter={<NoResults />}
                                    // noMoreDataMessage={i18n.get("transactions.list.noMore")}
                                    showFilters={false}
                                    extraActionMobile={!isDesktop && this.filtersButtons}
                                    currentPage={currentPage}
                                    setCurrentPage={this.setCurrentPage}
                                    autoClearWhenUnmounted
                                />
                            </Box>
                        </div>
                    </Box>
                </MainContainer>
            </TransactionFilterProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    isFetching: loadingSelector(state, [GET_PAGINATED_DATA_LIST]),
    pageNumber: transactionsSelectors.getPageNumber(state),
    hasMoreData: transactionsSelectors.getHasMoreData(state),
    filtersBkP: transactionsSelectors.getFilters(state),
    fromTicket: transactionsSelectors.getFromTicket(state),
    paginatedData: paginatedTableSelectors.getPaginatedData(state),
});

const mapDispatchToProps = (dispatch) => ({
    setFilters: (filters) => dispatch(transactionsActions.setFilters(filters)),
    resetFrom: () => dispatch(transactionsActions.resetFrom()),
    goDesktop: () => dispatch(replace("/desktop")),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsList);
