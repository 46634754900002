import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    CLEAN: "serviceRegistration/CLEAN",
    CHECK_IF_ALIAS_EXISTS_REQUEST: "serviceRegistration/CHECK_IF_ALIAS_EXISTS_REQUEST",
    CHECK_IF_ALIAS_EXISTS_FAILURE: "serviceRegistration/CHECK_IF_ALIAS_EXISTS_FAILURE",
    CHECK_IF_ALIAS_EXISTS_SUCCESS: "serviceRegistration/CHECK_IF_ALIAS_EXISTS_SUCCESS",
};

export const INITIAL_STATE = {
    fetching: false,
    fetchingAliasExists: false,
    aliasExists: false,
};

export default createReducer(INITIAL_STATE, {
    [types.CLEAN]: () => ( { ...INITIAL_STATE} ),
    [types.CHECK_IF_ALIAS_EXISTS_REQUEST]: (state) => ({ ...state, fetchingAliasExists: true }),
    [types.CHECK_IF_ALIAS_EXISTS_FAILURE]: (state) => ({ ...state, fetchingAliasExists: false, aliasExists: false }),
    [types.CHECK_IF_ALIAS_EXISTS_SUCCESS]: (state, action) => {
        return {
            ...state,
            fetchingAliasExists: false,
            aliasExists: action.aliasExists,
        };
    }
});

export const actions = {
    clean: makeActionCreator(types.CLEAN),
    checkIfAliasExistsRequest: makeActionCreator(types.CHECK_IF_ALIAS_EXISTS_REQUEST, "category", "alias"),
    checkIfAliasExistsFailure: makeActionCreator(types.CHECK_IF_ALIAS_EXISTS_FAILURE),
    checkIfAliasExistsSuccess: makeActionCreator(types.CHECK_IF_ALIAS_EXISTS_SUCCESS, "aliasExists"),
};

export const selectors = {
    isFetching: ({ serviceRegistration }) => serviceRegistration.fetching,
    isFetchingAliasExists: ({ serviceRegistration }) => serviceRegistration.fetchingAliasExists,
    getCheckIfAliasExists: ({ serviceRegistration }) => serviceRegistration.aliasExists,
};
