import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { resizableRoute } from "pages/_components/Resizable";

const FeatureCard = ({ icon, title, description, handleClick, isLastInRow, isMobile, isAvailableOnMobile }) => (
    <Box
        flex1
        className={classNames("py-7 mb-7 pl-7 pr-10 border-radius-lg", {
            "mr-7": !isLastInRow,
            "box-shadow-small": !!title,
            "cursor-pointer": !!title,
            "display-none": isMobile && !isAvailableOnMobile,
        })}
        onClick={handleClick}>
        {icon && (
            <Box fitWidth className="mb-3">
                <Image src={icon} color="transparent" />
            </Box>
        )}
        {title && (
            <Box className="mb-3">
                <Text size={isMobile ? "5" : "4"} bold defaultValue={title} />
            </Box>
        )}
        {description && (
            <Box>
                <Text
                    wordSpacing="sm"
                    size="5"
                    color="dark-gray"
                    className="line-height-15"
                    defaultValue={description}
                />
            </Box>
        )}
    </Box>
);

FeatureCard.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    handleClick: PropTypes.func.isRequired,
    isLastInRow: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isAvailableOnMobile: PropTypes.bool.isRequired,
};

FeatureCard.defaultProps = {
    icon: null,
    title: null,
    description: null,
};

export default connect()(resizableRoute(FeatureCard));
