import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { shape, bool, func, arrayOf } from "prop-types";
import { push } from "react-router-redux/actions";
// import { actions } from "reducers/administration/common/groupFormData";
// import { actions } from "reducers/administration/common";
// import { groupFormDataSelectors } from "reducers/administration";
import PageLoading from "pages/_components/PageLoading";
import FlowTransition from "pages/_components/FlowTransition";
// import { bindActionCreators } from "redux";
import { detailsActions } from "reducers/administration/advanced";
import { detailsSelectors } from "reducers/administration";
import { actions } from "reducers/administration/users";
import ModifyContactsConfirm from "./ModifyContactsConfirm";
import ModifyContacts from "./ModifyContacts";

const FORM_ID = "administration.advanced.user.modify.contacts";

const steps = {
    making: "making",
    confirmation: "confirmation",
};

const ModifyContactsFlow = ({ fetching, dispatch, match, user, products, groups }) => {
    const [currentStep, setCurrentStep] = useState([steps.making]);
    const [confirmationData, setConfirmationData] = useState({ name: "", description: "", permissions: {} });
    const [oldEmail, setOldEmail] = useState("");
    const [oldPhone, setOldPhone] = useState("");

    useEffect(() => {
        dispatch(detailsActions.loadDetailsRequest(match.params.id));
    }, []);

    useEffect(() => {
        if (user) {
            const { idUser, fullName, email, mobileNumber: phone } = user;
            let code;
            let phoneNumber = phone;
            if (phone && phone.length > 11) {
                code = phone.substring(0, 3);
                phoneNumber = phone.substring(3);
            }
            const mobileNumber = {
                areaCode: "",
                prefix: code || "593",
                value: phoneNumber && phoneNumber.length === 10 ? phoneNumber.substring(1) : phoneNumber,
            };
            setOldEmail(email);
            setOldPhone(mobileNumber);
            setConfirmationData({ idUser, fullName, email, mobileNumber });
        }
    }, [user]);

    const handleBack = () => {
        if (!match.params.id) {
            dispatch(push("/administration/users"));
        } else {
            dispatch(push(`/administration/advanced/details/${match.params.id}`));
        }
    };

    const handleContinue = (values) => {
        let emailUpdate;
        let mobileNumberUpdate;
        if (oldEmail !== values.email) {
            emailUpdate = values.email;
        }

        if (oldPhone.value !== `${values.mobileNumber.value}` || oldPhone.prefix !== `${values.mobileNumber.prefix}`) {
            mobileNumberUpdate = `${values.mobileNumber.prefix}${values.mobileNumber.value}`;
        }

        setConfirmationData({ ...confirmationData, emailUpdate, mobileNumberUpdate });
        setCurrentStep(steps.confirmation);
    };

    const handleSubmit = (values, formikBag) => {
        const data = { ...confirmationData, ...values };
        dispatch(actions.updateUserContactsRequest(data, formikBag));
    };

    return (
        <PageLoading loading={fetching}>
            <FlowTransition
                currentPage={currentStep}
                pages={{
                    [steps.making]: () => (
                        <ModifyContacts
                            idForm={FORM_ID}
                            data={confirmationData}
                            oldEmail={oldEmail}
                            oldPhone={oldPhone}
                            handleContinue={handleContinue}
                            handleBack={handleBack}
                        />
                    ),
                    [steps.confirmation]: () => (
                        <ModifyContactsConfirm
                            idForm={FORM_ID}
                            isCreate={!match.params.id}
                            data={confirmationData}
                            handleSubmit={handleSubmit}
                            handleBack={handleBack}
                            products={products}
                            groups={groups}
                        />
                    ),
                }}
            />
        </PageLoading>
    );
};

ModifyContactsFlow.propTypes = {
    fetching: bool,
    dispatch: func.isRequired,
    user: shape({}).isRequired,
    match: shape({}).isRequired,
    products: arrayOf(shape({})).isRequired,
    groups: shape({}).isRequired,
    // formValues: shape({}),
    // updateMembers: bool,
};

ModifyContactsFlow.defaultProps = {
    fetching: false,
    // formValues: undefined,
    // updateMembers: false,
};

const mapStateToProps = (state) => ({
    // formValues: groupFormDataSelectors.getFormValues(state),
    fetching: detailsSelectors.isFetching(state),
    user: detailsSelectors.getUser(state),
});
// const mapDispatchToProps = (dispatch) => ({
//     actions: bindActionCreators({ ...actions, ...detailsActions, ...restrictionActions }, dispatch),
// });

export default connect(mapStateToProps)(ModifyContactsFlow);
