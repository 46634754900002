import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    SECOND_FACTOR_PREVIEW_SUCCESS: "secondFactor/SECOND_FACTOR_PREVIEW_SUCCESS",
    SECOND_FACTOR_PREVIEW_REQUEST: "secondFactor/SECOND_FACTOR_PREVIEW_REQUEST",
    SECOND_FACTOR_PREVIEW_FAILURE: "secondFactor/SECOND_FACTOR_PREVIEW_FAILURE",
    SECOND_FACTOR_VERIFICATION_CODE_SUCCESS: "secondFactor/SECOND_FACTOR_VERIFICATION_CODE_SUCCESS",
    SECOND_FACTOR_VERIFICATION_CODE_REQUEST: "secondFactor/SECOND_FACTOR_VERIFICATION_CODE_REQUEST",
    SECOND_FACTOR_VERIFICATION_CODE_FAILURE: "secondFactor/SECOND_FACTOR_VERIFICATION_CODE_FAILURE",
    SECOND_FACTOR_VERIFICATION_QUESTIONS_SUCCESS: "secondFactor/SECOND_FACTOR_VERIFICATION_QUESTIONS_SUCCESS",
    SECOND_FACTOR_VERIFICATION_QUESTIONS_REQUEST: "secondFactor/SECOND_FACTOR_VERIFICATION_QUESTIONS_REQUEST",
    SECOND_FACTOR_VERIFICATION_QUESTIONS_FAILURE: "secondFactor/SECOND_FACTOR_VERIFICATION_QUESTIONS_FAILURE",
    SECOND_FACTOR_ATTEMPTS_FAILURE: "secondFactor/SECOND_FACTOR_ATTEMPTS_FAILURE",
    SECOND_FACTOR_ATTEMPTS_CLEAR: "secondFactor/SECOND_FACTOR_ATTEMPTS_CLEAR",
    CLEAR_FETCHING: "secondFactor/CLEAR_FETCHING",
    SECOND_FACTOR_STATUS_TOKEN_REQUEST: "secondFactor/SECOND_FACTOR_STATUS_TOKEN_REQUEST",
    SECOND_FACTOR_STATUS_TOKEN_SUCCESS: "secondFactor/SECOND_FACTOR_STATUS_TOKEN_SUCCESS",
    SECOND_FACTOR_SET_CONTACT_OTP: "secondFactor/SECOND_FACTOR_SET_CONTACT_OTP",
};

export const INITIAL_STATE = {
    fetching: false,
    fetchingOTP: false,
    credentials: undefined,
    configuredToken: undefined,
    idActivityToRead: undefined,
    codeSent: false,
    questions: undefined,
    error: undefined,
    attempts: 0,
    userHasToken: undefined,
    email: undefined,
    mobileNumber: undefined,
    attemptsChangeQuestion: 0,
};

export default createReducer(INITIAL_STATE, {
    [types.SECOND_FACTOR_PREVIEW_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        credentials: undefined,
        configuredToken: undefined,
        idActivityToRead: undefined,
    }),
    [types.SECOND_FACTOR_PREVIEW_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        credentials: action?.credentials,
        attempts: action?.attempts,
        configuredToken: action?.configuredToken,
        idActivityToRead: action?.idActivityToRead,
    }),
    [types.SECOND_FACTOR_PREVIEW_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.SECOND_FACTOR_VERIFICATION_CODE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        fetchingOTP: true,
        codeSent: false,
    }),
    [types.SECOND_FACTOR_VERIFICATION_CODE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        fetchingOTP: false,
        codeSent: true,
    }),
    [types.SECOND_FACTOR_VERIFICATION_CODE_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        fetchingOTP: false,
        codeSent: false,
    }),
    [types.SECOND_FACTOR_VERIFICATION_QUESTIONS_REQUEST]: (state, action) => ({
        ...state,
        fetching: true,
        questions: action?.isChange ? state.questions : {},
        error: undefined,
        attemptsChangeQuestion: 0,
    }),
    [types.SECOND_FACTOR_VERIFICATION_QUESTIONS_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        questions: action?.questions,
        error: undefined,
        attemptsChangeQuestion: action?.attemptsChangeQuestion,
    }),
    [types.SECOND_FACTOR_VERIFICATION_QUESTIONS_FAILURE]: (state, action) => ({
        ...state,
        fetching: false,
        questions: {},
        error: {
            code: action.error?.code,
            msg: action.error?.msg,
        }
    }),
    [types.CLEAR_FETCHING]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.SECOND_FACTOR_ATTEMPTS_CLEAR]: (state) => ({
        ...state,
        attempts: 0,
    }),
    [types.SECOND_FACTOR_ATTEMPTS_FAILURE]: (state, action) => ({
        ...state,
        fetching: false,
        attempts: state.attempts + 1,
    }),
    [types.SECOND_FACTOR_STATUS_TOKEN_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        userHasToken: undefined,
    }),
    [types.SECOND_FACTOR_STATUS_TOKEN_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        userHasToken: action.userHasToken,
    }),
    [types.SECOND_FACTOR_SET_CONTACT_OTP]: (state, action) => ({
        ...state,
        email: action.email,
        mobileNumber: action.mobileNumber,
    }),
});

export const actions = {
    secondFactorPreviewRequest: makeActionCreator(types.SECOND_FACTOR_PREVIEW_REQUEST, "preview"),
    secondFactorPreviewSuccess: makeActionCreator(
        types.SECOND_FACTOR_PREVIEW_SUCCESS,
        "credentials",
        "attempts",
        "configuredToken",
        "idActivityToRead",
    ),
    secondFactorVerificationCodeRequest: makeActionCreator(types.SECOND_FACTOR_VERIFICATION_CODE_REQUEST, "preview"),
    secondFactorVerificationCodeSuccess: makeActionCreator(types.SECOND_FACTOR_VERIFICATION_CODE_SUCCESS),
    secondFactorVerificationQuestionsRequest: makeActionCreator(
        types.SECOND_FACTOR_VERIFICATION_QUESTIONS_REQUEST,
        "preview",
        "isChange",
    ),
    secondFactorVerificationQuestionsSuccess: makeActionCreator(
        types.SECOND_FACTOR_VERIFICATION_QUESTIONS_SUCCESS,
        "questions",
        "attemptsChangeQuestion",
    ),
    clearFetching: makeActionCreator(types.CLEAR_FETCHING),
    secondFactorStatusTokenRequest: makeActionCreator(types.SECOND_FACTOR_STATUS_TOKEN_REQUEST, "preview"),
    secondFactorStatusTokenSuccess: makeActionCreator(types.SECOND_FACTOR_STATUS_TOKEN_SUCCESS, "userHasToken"),
};

export const selectors = {
    isFetching: ({ secondFactor }) => secondFactor.fetching || false,
    getCredentials: ({ secondFactor }) => secondFactor.credentials,
    isCodeSent: ({ secondFactor }) => secondFactor.codeSent,
    getQuestions: ({ secondFactor }) => secondFactor.questions,
    getError: ({ secondFactor }) => secondFactor.error,
    getAttempts: ({ secondFactor }) => secondFactor.attempts,
    getAttemptsChangeQuestion: ({ secondFactor }) => secondFactor.attemptsChangeQuestion,
    getUserHasToken: ({ secondFactor }) => secondFactor.userHasToken,
    getEmail: ({ secondFactor }) => secondFactor.email,
    getMobileNumber: ({ secondFactor }) => secondFactor.mobileNumber,
    isFetchingOTP: ({ secondFactor }) => secondFactor.fetchingOTP,
};
