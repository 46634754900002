import React from "react";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import FieldError from "pages/_components/fields/FieldError";
import Row from "pages/_components/Row";
import { Col } from "react-bootstrap";

export const renderData = (labelKey, data, size = "6") => (
    <>
        <Box className="mt-2 mb-3 size-6 color-text-grey">
            <Text color="text-grey" size={size} labelKey={labelKey} />
        </Box>
        <Box className="text-bold size-6">{data}</Box>
    </>
);

export const renderDataDate = (labelKey, data, size = "6") => (
    <>
        <Box className="mt-2 mb-3 size-6 color-text-grey">
            <Text color="text-grey" size={size} labelKey={labelKey} />
        </Box>
        <Box className="text-bold size-6">{data ? <FormattedDate date={data} anotherFormat="DD/MM/YYYY" /> : "-"}</Box>
    </>
);

export const renderDataAmount = (labelKey, data, className = "justify-content-end", size = "6") => (
    <>
        <Box className="mt-2 mb-3 size-6 color-text-grey">
            <Text color="text-grey" size={size} labelKey={labelKey} />
        </Box>
        <Box className="text-bold size-6">
            <FormattedAmount quantity={data} className={className} noCurrency />
        </Box>
    </>
);

export const fieldInputError = (error) =>
    error ? (
        <Box className="mt-2">
            <FieldError error={error} />
        </Box>
    ) : null;

export const renderDataModal = ({ xs, label, labelKey, data, date, amount }) => (
    <Row className="mt-3 mb-4">
        <Col xs={xs || 6}>
            {label && (
                <Text size="6" light>
                    {label}
                </Text>
            )}
            {labelKey && <Text size="6" light labelKey={labelKey} />}
        </Col>
        <Col xs={xs ? 12 - xs : 6} className="text-right text-bold size-6">
            {data && (
                <Text size="6" bold>
                    {data}
                </Text>
            )}
            {date && <FormattedDate date={date} anotherFormat="DD/MM/YYYY" />}
            {amount && <FormattedAmount quantity={amount} className="justify-content-end" noCurrency />}
        </Col>
    </Row>
);
