import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, shape } from "prop-types";
import React from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import withRouter from "react-router-dom/withRouter";
import * as config from "util/config";

const CHANNEL = window.cordova ? "phonegap" : "frontend";
const ServiceCategoryCard = ({ elem, onClick }) => {
    const enabledChannels = config.getArray("servicePayments.enabledChannels", []);
    const enabled = enabledChannels?.includes(CHANNEL);
    const pathTo = enabled ? `/servicePayments/registered/${elem.idServicePayment}` : "#";
    return (
        <Link
            aria-label={elem.logo}
            aria-describedby={elem.logo}
            to={pathTo}
            //  TODO (onClick)
            onClick={() => onClick(enabled)}>
            <Box
                component="article"
                display="flex"
                alignY="center"
                background="white"
                // backgroundHover="background-secondary"
                borderRadius="default"
                className="btn-box-icon-bg box-shadow-small py-4 py-md-5 px-5"
                gap="5"
                fullHeight
                pointer>
                <Box display="flex">
                    <Image
                        src={`images/icons/servicesCategories/${elem.logo}`}
                        // {...(!isDesktop && { width: "9", height: "9" })}
                        className="img-icon-service"
                    />
                </Box>
                <Text
                    color="text"
                    size={isMobile ? "5" : "6"}
                    bold
                    className="line-height-15"
                    labelKey={`servicePayment.category.${elem?.idServicePayment}`}
                />
            </Box>
        </Link>
    );
};

ServiceCategoryCard.propTypes = {
    isDesktop: bool.isRequired,
    elem: shape({}),
    onClick: func,
};

ServiceCategoryCard.defaultProps = {
    elem: null,
    onClick: () => {},
};

export default withRouter(resizableRoute(ServiceCategoryCard));
