import { CORPORATE_ENVIRONMENT_TYPE, CORPORATE_GROUP_ENVIRONMENT_TYPE, FILTER_DATE_TRANSACTION, OPERATIONS_TRANSACTION_ENABLED, RETAIL_ENVIRONMENT_TYPE } from "constants.js";
import withTransactionFilterContext from "hoc/withTransactionFilterContext";
import moment from "moment";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import Select from "pages/forms/_components/_fields/Select";
import PeriodFilter from "pages/transactions/_components/PeriodFilter";
import StatusFilter from "pages/transactions/_components/StatusFilter";
import UserFilter from "pages/transactions/_components/UserFilter";
import NoOrderFilter from "pages/transactions/_components/NoOrderFilter";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { Component, createElement } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import * as configUtil from "util/config";
import * as i18n from "util/i18n";
import { format } from "date-fns";
import Dropdown from "pages/_components/Dropdown";
import ChipsButtonsGroup from "pages/_components/ChipsButtonsGroup";
// import { historicOperationTypes } from "../HistoricOperationTypes";

const components = {
    period: PeriodFilter,
    order: NoOrderFilter,
    status: StatusFilter,
    user: UserFilter,
};

class HiddenFilters extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool,
        onlyPendings: bool,
        onlyProcessing: bool,
        pendingDispatch: bool,
        activeEnvironment: shape({ type: string.isRequired }).isRequired,
        isTransactionList: bool,
        operationTypeListRetail: arrayOf(string).isRequired,
        operationTypeListCorporate: arrayOf(string).isRequired,
        historic: bool,
        resetHandleOrder: func.isRequired,
        setOperationType: func,
        clearFilters: func,
        setDateFrom: func,
        setDateTo: func,
        lastRegistryNumber: string.isRequired,
        status: arrayOf(string),
        dateFromIsRequired: bool,
        filterValues: shape({}).isRequired,
        setFilterValues: func.isRequired,
        isCreatedByMe: bool,
        operations: arrayOf(),
    };

    static defaultProps = {
        fetching: false,
        onlyPendings: false,
        onlyProcessing: false,
        pendingDispatch: false,
        isTransactionList: false,
        historic: false,
        setOperationType: () => {},
        clearFilters: () => {},
        setDateFrom: () => {},
        setDateTo: () => {},
        status: [],
        dateFromIsRequired: false,
        isCreatedByMe: false,
        operations: OPERATIONS_TRANSACTION_ENABLED,
    };

    state = {
        // eslint-disable-next-line react/no-unused-state
        operationTypeOptions: [],
        // eslint-disable-next-line react/no-unused-state
        transactionTypeOptions: [],
        // eslint-disable-next-line react/destructuring-assignment
        selectedFilter: this.props.historic ? "period" : "all",
        selectedOperationType: "all",
        selectedTransactionType: "all",
        selectedDateFilter: FILTER_DATE_TRANSACTION.TODAY,
    };

    componentDidMount() {
        const { activeEnvironment, filterValues, onlyPendings, operationTypeListRetail } = this.props;

        const { selectedOperationType} = this.state;


        let operationTypeOptions = null;

        let transactionTypeOptions = null;

        let operationsCorporateFinal = configUtil.getArray("transaction.list.operations.options.corporate"+ (onlyPendings ? ".pending" : ".historic"));

        if (activeEnvironment.type === CORPORATE_ENVIRONMENT_TYPE) {
            operationTypeOptions = operationsCorporateFinal.map((operationType) => ({
                value: operationType,
                label:
                    operationType !== "all"
                        ? i18n.get(`operation.${operationType}`)
                        : i18n.get("transactions.list.filters.operationType.all.label"),
            }));
            transactionTypeOptions = [
                {
                    value: "all",
                    label: i18n.get("transactions.list.filters.operationType.all.label"),
                },
            ];
            let listTransactions = [];

            operationsCorporateFinal.forEach((operation) => {
                const transactionConfig = configUtil.getArray("transaction.list.transactions.options.corporate."+operation);

                if (selectedOperationType === "all") {
                    listTransactions = transactionConfig.map((transaction) => ({
                        value: transaction,
                        label:
                            transaction !== "all"
                                ? i18n.get(`activities.${transaction}`)
                                : i18n.get("transactions.list.filters.operationType.all.label"),
                    }));
                }

                transactionTypeOptions = transactionTypeOptions.concat(listTransactions); 

            });

        } else if (activeEnvironment.type === RETAIL_ENVIRONMENT_TYPE) {
            operationTypeOptions = operationTypeListRetail.map((operationType) => ({
                value: operationType,
                label:
                    operationType !== "all"
                        ? i18n.get(`activities.${operationType}`)
                        : i18n.get("transactions.list.filters.operationType.all.label"),
            }));
        }

        this.setState({
            selectedOperationType: filterValues?.selectedOperationType || "all",
            selectedTransactionType: filterValues?.selectedTransactionType || "all",
            selectedFilter: filterValues?.selectedFilter || "all",
            operationTypeOptions: operationTypeOptions,
            transactionTypeOptions: transactionTypeOptions,
        });
    }

    handleChange = (selectedOption) => {
        const { clearFilters } = this.props;
        if (clearFilters) {
            clearFilters();
        }
        this.setState({ selectedFilter: selectedOption.value });
    };

    handleChangeOperationType = (selectedOption) => {
        this.setState({ selectedOperationType: selectedOption.value, selectedTransactionType: "all" });
        const { setOperationType, onlyPendings } = this.props;
        if (setOperationType && selectedOption?.value) {
            setOperationType(selectedOption.value);
            
            let transactionTypeOptions = null;

            transactionTypeOptions = [
                {
                    value: "all",
                    label: i18n.get("transactions.list.filters.operationType.all.label"),
                },
            ];

            if (selectedOption.value === "all") {
                let operationsCorporateFinal = configUtil.getArray("transaction.list.operations.options.corporate"+ (onlyPendings ? ".pending" : ".historic"));
                operationsCorporateFinal.forEach((operation) => {
                    const transactionConfig = configUtil.getArray("transaction.list.transactions.options.corporate."+operation);
                    const    listTransactions = transactionConfig.map((transaction) => ({
                            value: transaction,
                            label:
                                transaction !== "all"
                                    ? i18n.get(`activities.${transaction}`)
                                    : i18n.get("transactions.list.filters.operationType.all.label"),
                        }));
    
                    transactionTypeOptions = transactionTypeOptions.concat(listTransactions); 
    
                });
            }else{
                const transactionConfig = configUtil.getArray("transaction.list.transactions.options.corporate."+selectedOption.value);
                const listTransactions = transactionConfig.map((transaction) => ({
                    value: transaction,
                    label:
                        transaction !== "all"
                            ? i18n.get(`activities.${transaction}`)
                            : i18n.get("transactions.list.filters.operationType.all.label"),
                }));

                transactionTypeOptions = transactionTypeOptions.concat(listTransactions); 
            }        

            this.setState({
                transactionTypeOptions: transactionTypeOptions,
            });
            

        }
    };

    handleChangeTransactionType = (selectedOption) => {
        this.setState({ selectedTransactionType: selectedOption.value });
        // const { setOperationType } = this.props;
        // if (setOperationType && selectedOption?.value) {
        //     setOperationType(selectedOption.value);
        // }
    };

    handleClick = (dataFilter) => {
        const {
            filterValues,
            setFilterValues,
            dispatch,
            pendingDispatch,
            onlyPendings,
            onlyProcessing,
            // resetHandleOrder,
            // isCreatedByMe,
        } = this.props;
        const { selectedOperationType, selectedTransactionType, selectedFilter, selectedDateFilter } = this.state;

        if (selectedFilter === "lastMonth") {
            const date = new Date();
            date.setMonth(date.getMonth() - 1);
            const dateFrom = new Date(date.getFullYear(), date.getMonth(), 1);
            const dateTo = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            dispatch(
                transactionsActions.loadListRequest(
                    { operationType: selectedOperationType, dateFrom, dateTo },
                    onlyPendings,
                    onlyProcessing,
                    pendingDispatch,
                    false,
                ),
            );
        } else {
            // dispatch(
            //     transactionsActions.loadListRequest(
            //         { operationType: selectedOperationType, isCreatedByMe },
            //         onlyPendings,
            //         onlyProcessing,
            //         pendingDispatch,
            //         false,
            //     ),
            // );

            const { order, status, user, dateFrom, dateTo } = dataFilter || {};

            let dateFromFormat = dateFrom ? format(dateFrom, "YYYY-MM-DD HH:mm:ss") : null;
            let dateToFormat = dateTo ? format(dateTo, "YYYY-MM-DD HH:mm:ss") : null;

            if (!onlyPendings && selectedDateFilter === FILTER_DATE_TRANSACTION.TODAY) {
                dateFromFormat = dateFromFormat = moment().startOf('day').format("YYYY-MM-DD HH:mm:ss");
                dateToFormat = moment().endOf('day').format("YYYY-MM-DD HH:mm:ss");
            }else if (!onlyPendings && selectedDateFilter === FILTER_DATE_TRANSACTION.LAST_7_DAYS) {
                dateFromFormat = moment().subtract(7, 'days').startOf('day').format("YYYY-MM-DD HH:mm:ss");
                dateToFormat = moment().endOf('day').format("YYYY-MM-DD HH:mm:ss");
            }else if (!onlyPendings && selectedDateFilter === FILTER_DATE_TRANSACTION.LAST_30_DAYS) {
                dateFromFormat = moment().subtract(30, 'days').startOf('day').format("YYYY-MM-DD HH:mm:ss");
                dateToFormat = moment().endOf('day').format("YYYY-MM-DD HH:mm:ss");
            }   

            const data = { order, status, user, dateFrom: dateFromFormat, dateTo: dateToFormat };
            setFilterValues({
                ...filterValues,
                selectedFilter,
                selectedOperationType,
                selectedTransactionType,
                ...data,
            });
        }

        // resetHandleOrder();
    };

    setLastMonthFilter = (selectedFilter) => {
        if (selectedFilter === "lastMonth") {
            const date = new Date();
            date.setMonth(date.getMonth() - 1);
            const dateFrom = new Date(date.getFullYear(), date.getMonth(), 1);
            const dateTo = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            const { setDateFrom, setDateTo } = this.props;
            if (setDateFrom && setDateTo) {
                setDateFrom(moment(dateFrom).format("YYYY-MM-DD"));
                setDateTo(moment(dateTo).format("YYYY-MM-DD"));
            }
        }
    };

    handlerDateFilterValue = (value) => {
        const {
            filterValues,
            setFilterValues,
        } = this.props;
        console.log("handlerDateFilterValue ",value);
        this.setState({ selectedDateFilter: value });

        if(value === FILTER_DATE_TRANSACTION.OTHER){
            this.setState({ selectedFilter: 'period' });
            const dateFromFormat = moment().startOf('day').format("YYYY-MM-DD HH:mm:ss");
            const dateToFormat = moment().endOf('day').format("YYYY-MM-DD HH:mm:ss");
            const data = { dateFrom: dateFromFormat, dateTo: dateToFormat };
            setFilterValues({
                ...filterValues,
                ...data,
            });
        }else{
            this.setState({ selectedFilter: "all" });
        }   
    };

    renderFilter = () => {
        const { selectedFilter, selectedOperationType } = this.state;
        const {
            dispatch,
            fetching,
            onlyPendings,
            onlyProcessing,
            pendingDispatch,
            resetHandleOrder,
            historic,
            dateFromIsRequired,
            lastRegistryNumber,
            filterValues,
            setFilterValues,
        } = this.props;

        let props = {
            dispatch,
            isDesktop: true,
            fetching,
            onlyPendings,
            onlyProcessing,
            pendingDispatch,
            resetHandleOrder,
            historic,
            dateFromIsRequired,
            lastRegistryNumber,
            filterValues,
            setFilterValues,
            handleClick: (data) => this.handleClick(data),
        };
        // if (selectedFilter === "channel") {
        //     props = { ...props, selectedOperationType };
        // }
        if (selectedFilter === "period") {
            props = { ...props, selectedOperationType };
        }

        if (selectedFilter === "status") {
            props = { ...props, selectedOperationType };
        }

        if (selectedFilter === "all" || selectedFilter === "lastMonth") {
            this.setLastMonthFilter(selectedFilter);
            return (
                <>
                    <Row className="mt-5">
                        <Col xs={12} {...(!historic && { md: 5 })}>

                        </Col>
                        <Col xs={12} {...(!historic && { md: 2 })}>
                            <Button
                                bsStyle="primary"
                                label="global.search"
                                block
                                loading={fetching}
                                onClick={this.handleClick}
                                btnUppercase={false}
                            />
                        </Col>
                        <Col xs={12} {...(!historic && { md: 5 })}>

                        </Col>
                    </Row>
                </> 
                
            );
        }

        return selectedFilter && createElement(components[selectedFilter], props);
    };

    render() {
        const { selectedFilter, selectedOperationType, selectedTransactionType, transactionTypeOptions, operationTypeOptions, selectedDateFilter} = this.state;
        const {
            activeEnvironment,
            isTransactionList,
            /* eslint-disable react/prop-types */
            operationTypeListRetail,
            operations,
            historic,
            onlyPendings,
        } = this.props;

        

        let options = isTransactionList
            ? [
                  {
                      value: "all",
                      label: i18n.get("transactions.list.filters.operationType.all.label"),
                  },
                  {
                      value: "order",
                      label: i18n.get("transactions.list.filter.searchBy.order"),
                  },
                  {
                      value: "user",
                      label: i18n.get("transactions.list.filter.searchBy.user"),
                  },
                  {
                      value: "status",
                      label: i18n.get("transactions.list.filter.searchBy.status"),
                  },
              ]
            : [
                  {
                      value: "all",
                      label: i18n.get("transactions.list.filters.operationType.all.label"),
                  },
                  {
                      value: "period",
                      label: i18n.get("transactions.list.filter.searchBy.period"),
                  },
                  {
                      value: "order",
                      label: i18n.get("transactions.list.filter.searchBy.order"),
                  },
                  {
                      value: "user",
                      label: i18n.get("transactions.list.filter.searchBy.user"),
                  },
              ];

        if (activeEnvironment.type === CORPORATE_GROUP_ENVIRONMENT_TYPE) {
            options = options.concat({
                value: "environment",
                label: i18n.get("accounts.movements.filters.searchBy.client"),
            });
        }


        const buttonsGroup = [
            {
                id: FILTER_DATE_TRANSACTION.TODAY,
                label: "transactions.list.filter.date.label.today",
                labelDefault: "Hoy"
            },
            {
                id: FILTER_DATE_TRANSACTION.LAST_7_DAYS,
                label: "transactions.list.filter.date.label.last7Days",
                labelDefault: "Últimos 7 días"
            },
            {
                id: FILTER_DATE_TRANSACTION.LAST_30_DAYS,
                label: "transactions.list.filter.date.label.last30Days",
                labelDefault: "Últimos 30 días"
            },
            {
                id: FILTER_DATE_TRANSACTION.OTHER,
                label: "transactions.list.filter.date.label.other",
                labelDefault: "Otra fecha"
            },
        ];


        return (
            <>
                <>
                    {!onlyPendings && (
                        <Box 
                            background="component-background"
                            className="mx-n-5 p-5 mt-3 mt-md-0 mb-3 mb-md-0">
                            <ChipsButtonsGroup
                                buttonsGroup={buttonsGroup}
                                onClickHandler={this.handlerDateFilterValue}
                                defaultSelect={selectedDateFilter}
                            />
                        </Box>
                    )}                
                    <Col xs={12} md={!historic ? 5 : 3}>
                        <Row gapY="3" gapX={!historic ? "7" : "0"}>
                            <Col xs={12} {...(!historic && { md: 3 })}>
                                <Box className="form-group form-group--select">
                                    <Box display="flex" alignY="center" className="data-label-special-mb">
                                        <Text
                                            component="label"
                                            htmlFor="react-select-operationType-input"
                                            labelKey="transactions.list.filter.operationType.label"
                                            className="data-label"
                                        />
                                    </Box>
                                    <Box className="input-group ">
                                        <Select
                                            id="operationType"
                                            name="operationType"
                                            label="transactions.list.filter.operationType"
                                            onChange={this.handleChangeOperationType}
                                            options={operationTypeOptions}
                                            value={selectedOperationType}
                                            placeholder={i18n.get("global.all")}
                                        />
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={12} {...(!historic && { md: 6 })}>
                                <Box className="form-group form-group--select">
                                    <Box display="flex" alignY="center" className="data-label-special-mb">
                                        <Text
                                            component="label"
                                            htmlFor="react-select-transactionType-input"
                                            labelKey="transactions.list.filter.transactionType.label"
                                            className="data-label"
                                        />
                                    </Box>
                                    
                                    <Box className="input-group ">
                                    
                                        <Select
                                            id="transactionType"
                                            name="transactionType"
                                            label="transactions.list.filter.transactionType"
                                            onChange={this.handleChangeTransactionType}
                                            options={transactionTypeOptions}
                                            value={selectedTransactionType}
                                            searchable
                                        />
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={12} {...(!historic && { md: 3 })}>
                                <Box className="form-group form-group--select">
                                    <Box display="flex" alignY="center" className="data-label-special-mb">
                                        <Text
                                            component="label"
                                            htmlFor="react-select-searchBy-input"
                                            labelKey="transactions.list.filter.searchBy.label"
                                            className="data-label"
                                        />
                                    </Box>
                                    <Box className="input-group ">
                                        <Select
                                            id="searchBy"
                                            name="searchBy"
                                            label="transactions.list.filter.searchBy.label"
                                            onChange={this.handleChange}
                                            options={options}
                                            value={selectedFilter}
                                        />
                                    </Box>
                                </Box>
                            </Col>

                            <Col xs={12} {...(!historic && selectedFilter !== "all" && { md: 9 })}>
                            {this.renderFilter()}
                            </Col>
                        </Row>
                    </Col>
                </>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    transactionsAdmin: configUtil.getArray("transaction.list.transactions.options.corporate.Admin"),
    operationTypeListRetail: configUtil.getArray("transaction.list.operationType.options.retail"),
    operationTypeListCorporate: configUtil.getArray("transaction.list.operationType.options.corporate"),
    lastRegistryNumber: transactionsSelectors.getLastRegistryNumber(state),
});

export default connect(mapStateToProps)(withTransactionFilterContext(HiddenFilters));
