import React from "react";
import { arrayOf, func, number, shape } from "prop-types";
import { connect } from "react-redux";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import { actions as payServiceActions, selectors as payServiceSelectors } from "reducers/payService";
import { actions as transactionsActions } from "reducers/transactions";
import { renderData, renderDataAmount } from "./_components/utilForm";
import Row from "pages/_components/Row";
import { Col } from "react-bootstrap";
import Button from "pages/_components/Button";
import { PREFIX } from "./hooks/servicePaymentFormHooks";
import { push, replace } from "react-router-redux";
import { USD_CURRENCY } from "constants.js";
import FormattedAmount from "pages/_components/FormattedAmount";
import Image from "pages/_components/Image";

const ID_FORM = "servicePayments.massive.pay.result";

const MassiveServicePaymentResult = ({ list, totalPaid, dispatch }) => {
    if (!list || list.length === 0) {
        dispatch(replace("/servicePayments"));
    }

    const handleGoToHome = () => {
        dispatch(replace("/desktop"));
    };

    const handleGoToServicePayments = () => {
        dispatch(replace("/servicePayments"));
    };

    return (
        <>
            <Notification scopeToShow="servicePayments" />
            <Head title="servicePayments.massive.pay.title" />

            <MainContainer
                // showLoader={fetching}
                className="main-container border-radius-lg box-shadow-small background-white">
                <Row>
                    <Col xs={12} md={8} mdOffset={2} className="py-7">
                        <Box className="text-center mb-7 mt-5">
                            <Box className="mb-8">
                                <Image src="images/icons/tickets/massive-operation.svg" />
                            </Box>
                            <Text bold size="3" labelKey={`${ID_FORM}.transaction.title`} />
                        </Box>

                        <Box background="background-disabled" className="p-5 mb-7" borderRadius="lg">
                            <Row>
                                <Col xs={6} className="d-flex align-items-center">
                                    <Text size="6" labelKey={`${ID_FORM}.totalPaid`} />
                                </Col>
                                <Col xs={6}>
                                    <Box display="flex" fullWidth className="justify-content-end">
                                        <FormattedAmount
                                            quantity={totalPaid}
                                            className="size-1 text-semibold display-inline"
                                            noCurrency
                                        />
                                        &nbsp;&nbsp;
                                        <Text semibold size="1" color="text-grey-color" defaultValue={USD_CURRENCY} />
                                    </Box>
                                </Col>
                            </Row>
                        </Box>

                        <Box className="mb-7">
                            <Text bold labelKey={`${ID_FORM}.detail.title`} />
                        </Box>

                        {list.map((item, index) => {
                            const { service, alias, amount, status, idTransaction } = item;

                            return (
                                <Box
                                    key={index}
                                    background="background-disabled"
                                    className="p-5 mb-5"
                                    borderRadius="lg">
                                    <Row customColumns={15}>
                                        <Col xs={3}>{renderData(`${PREFIX}.service`, service, "7")}</Col>
                                        <Col xs={3}>{renderData(`${PREFIX}.alias`, alias, "7")}</Col>
                                        <Col xs={3} className="text-right">
                                            {renderDataAmount(`${ID_FORM}.item.total`, amount, "justify-content-end", "7")}
                                        </Col>
                                        <Col xs={3} className="d-flex align-items-center content-center">
                                            <Box className={`payment-status-${status} text-center`}>
                                                <Text size="6" labelKey={`${ID_FORM}.transaction.status.${status}`} />
                                            </Box>
                                        </Col>
                                        <Col xs={3} className="d-flex align-items-center justify-content-end">
                                            <Button
                                                className="btn-payment-status text-light btn-small"
                                                label={`${ID_FORM}.transaction.action.${status}`}
                                                bsStyle="outline"
                                                onClick={() => {
                                                    dispatch(transactionsActions.setActionSecondary("servicePayments.ticket.action.goToPayments", () => {dispatch(replace("/servicePayments"));}));
                                                    dispatch(push(`/transaction/${idTransaction}`));
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Box>
                            );
                        })}
                    </Col>
                </Row>
            </MainContainer>
            <Row gapX="7" className="mt-7 mb-7">
                <Col xs={3} xsOffset={3}>
                    <Button block bsStyle="outline" label="global.goToHome" onClick={handleGoToHome} />
                </Col>
                <Col xs={3}>
                    <Button
                        block
                        bsStyle="primary"
                        label={`${ID_FORM}.action.otherPay`}
                        onClick={handleGoToServicePayments}
                    />
                </Col>
            </Row>
        </>
    );
};

MassiveServicePaymentResult.propTypes = {
    list: arrayOf(shape({})).isRequired,
    totalPaid: number.isRequired,
    dispatch: func.isRequired,
};

const mapStateToProps = (state) => ({
    list: payServiceSelectors.getListServiceProcessed(state),
    totalPaid: payServiceSelectors.getListServiceTotalPaid(state),
});

export default connect(mapStateToProps)(MassiveServicePaymentResult);
