// React and Redux imports
import React, { useMemo } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { bool, func } from "prop-types";

// Utility and style imports
import classNames from "classnames";
import * as i18n from "util/i18n";

// Component imports
import Head from "pages/_components/Head";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import { resizableRoute } from "pages/_components/Resizable";
import FeatureCard from "./FeatureCard";

/**
 * A hook to create a grid layout based on items and columns per row.
 */
const useGridLayout = (items, columnsPerRow) =>
    useMemo(() => {
        const grouped = [];
        for (let i = 0; i < items.length; i += columnsPerRow) {
            const row = items.slice(i, i + columnsPerRow);
            while (row.length < columnsPerRow) {
                row.push({});
            }
            grouped.push(row);
        }
        return grouped;
    }, [items, columnsPerRow]);

/**
 * Component to display bank references with an optional mobile layout.
 */
const BankReferences = ({ isMobile, dispatch }) => {
    const columnsPerRow = useMemo(() => (isMobile ? 1 : 2), [isMobile]);

    const features = useMemo(
        () => [
            {
                icon: "images/bankReferences/feature-own-bank-references.svg",
                title: i18n.get("bankReferences.ownBankReferences.title"),
                description: i18n.get("bankReferences.ownBankReferences.description"),
                isAvailableOnMobile: true,
                link: "/bankReferences/own",
            },
            {
                icon: "images/bankReferences/feature-third-party-bank-references.svg",
                title: i18n.get("bankReferences.thirdPartyBankReferences.title"),
                description: i18n.get("bankReferences.thirdPartyBankReferences.description"),
                isAvailableOnMobile: false,
                link: "/bankReferences/thirdParties",
            },
        ],
        [],
    );

    const gridRows = useGridLayout(features, columnsPerRow);

    const handleNavigation = (url) => {
        if (url) {
            dispatch(push(url));
        }
    };

    return (
        <>
            <Head titleText={i18n.get("menu.bankReferences")} backLinkTo={isMobile ? "/desktop" : undefined} />
            <MainContainer className="main-container border-radius-lg box-shadow-small background-white texture-header p-7">
                <Box
                    component="article"
                    display="flex"
                    borderRadius="default"
                    fullWidth
                    className={classNames("box-shadow-small", {
                        "px-7 py-5 mb-5": !isMobile,
                        "p-5 mb-5": isMobile,
                    })}>
                    <Box fullWidth>
                        <Box
                            className={classNames({
                                "my-3": isMobile,
                                "mb-3 mt-6": !isMobile,
                            })}>
                            <Text
                                size={isMobile ? "5" : "3"}
                                semibold
                                labelKey="bankReferences.productCertificates.title"
                            />
                        </Box>
                        <Text
                            color="text-grey-color"
                            size={isMobile ? "5" : "4"}
                            labelKey="bankReferences.requestBankProductReferences.subtitle"
                        />
                    </Box>
                    <Box display="flex">
                        <Image src="images/bankReferences/bank-references-title.svg" className="img-header-card" />
                    </Box>
                </Box>
                {gridRows.map((row, rowIndex) => (
                    <Box key={rowIndex} fullWidth display="flex">
                        {row.map((feature, index) => (
                            <FeatureCard
                                key={index}
                                {...feature}
                                handleClick={() => handleNavigation(feature.link)}
                                isLastInRow={index === row.length - 1}
                            />
                        ))}
                    </Box>
                ))}
            </MainContainer>
        </>
    );
};

BankReferences.propTypes = {
    isMobile: bool.isRequired,
    dispatch: func.isRequired,
};

export default connect()(resizableRoute(BankReferences));
