import Fingerprint2 from "fingerprintjs2";
import { mobileVendor, mobileModel, isTablet, isAndroid, isIOS } from "react-device-detect";

export const ANDROID_DEVICE = "Android";

export const IOS_DEVICE = "iOS";

export const getDevicePlatform = () => window.deviceInfo.getPlatform();

export const getDeviceId = () => window.deviceInfo.getId();

export const getDeviceManufacturer = () => window.deviceInfo.getManufacturer();

export const getDeviceModel = () => window.deviceInfo.getModel();

export const getDisplay = () => {
    if (window.innerWidth >= 768) {
        return "desktop";
    }

    return "mobile";
};

export const getDisplayTablet = () => window.innerWidth >= 768 && window.innerWidth < 1024;

export const isMobileNative = !!window.cordova;
export const isMobileNativeFunc = () => !!window.cordova;

export const getMobileOS = (display) => {
    if (isMobileNative) {
        return getDevicePlatform();
    }
    if (display === "mobile") {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return ANDROID_DEVICE;
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return IOS_DEVICE;
        }
    }

    return "unknown";
};

const deviceOptions = {
    NOT_AVAILABLE: "false",
    ERROR: "error",
    EXCLUDED: "excluded",
    excludes: {
        language: true,
        timezone: true,
        timezoneOffset: true,
        userAgent: true,
        sessionStorage: true,
        localStorage: true,
        indexedDb: true,
        addBehavior: true,
        openDatabase: true,
        doNotTrack: true,
        plugins: true,
        canvas: true,
        webgl: true,
        adBlock: true,
        enumerateDevices: true,
        availableScreenResolution: true,
        deviceMemory: true,
        fonts: true,
        audio: true,
        pixelRatio: true,
        hardwareConcurrency: true,
    },
    fonts: { extendedJsFonts: true },
};

export const getExtraInfo = async () => {
    const components = await Fingerprint2.getPromise(deviceOptions);
    const obj = components.reduce((acc, curr) => {
        acc[curr.key] = curr.value;
        return acc;
    }, {});
    if (mobileVendor !== "none") {
        obj.model = `${mobileVendor} - ${mobileModel}`;
    }
    obj.isTablet = isTablet;
    obj.isAndroid = isAndroid;
    obj.isIOS = isIOS;
    return obj;
};

export const getDeviceFingerprint = async () => {
    const components = await Fingerprint2.getPromise(deviceOptions);

    const values = components.map((component) => component.value);
    const fingerprint = Fingerprint2.x64hash128(values.join(""), 31);

    return fingerprint;
};

let scaleFactor = 1;

export const deeper = {
    scaleFactor: 1,
};
export function svgScaleFactor() {
    // :Root font-size (in px) ****Important to set as is seted in variables.scss
    // So the behaivour will emulate the fonts scale factor behaivour****
    const mapGlobalFonSize = [
        { screenSize: 320, fontSize: 13 },
        { screenSize: 359, fontSize: 16 },
        { screenSize: 1367, fontSize: 16 },
        { screenSize: 1920, fontSize: 16 },
        { screenSize: 3839, fontSize: 32 },
    ];

    // Set default for 1rem
    const defaultFontSize = 16;
    // Set font sizes when device pixel ratio is 3 ***Important to set as is seted in variables.scss
    const dpr3 = 18;
    const dpr3Special = 17.25;

    mapGlobalFonSize.map((size, index, arr) => {
        const firstArrElement = arr[0];
        const lastArrElement = arr[arr.length - 1];

        if (window.innerWidth <= firstArrElement.screenSize) {
            scaleFactor = firstArrElement.fontSize / defaultFontSize;
        }

        if (arr[index + 1]) {
            const nextSize = arr[index + 1];
            if (window.innerWidth >= size.screenSize && window.innerWidth <= nextSize.screenSize) {
                const fontSizeSmall = size.fontSize;
                const fontSizeBig = nextSize.fontSize;
                const screenSmall = size.screenSize;
                const screenBig = nextSize.screenSize;
                if (window.devicePixelRatio >= 3 && window.innerWidth >= 390 && window.innerWidth <= 1024) {
                    scaleFactor = dpr3 / defaultFontSize;
                } else if (
                    window.devicePixelRatio >= 3 &&
                    window.innerWidth >= 390 &&
                    window.innerWidth <= 1024 &&
                    window.innerHeight >= 844 &&
                    window.innerHeight <= 853
                ) {
                    scaleFactor = dpr3Special / defaultFontSize;
                } else {
                    // Linear Extrapolation
                    scaleFactor =
                        (((fontSizeBig - fontSizeSmall) / (screenBig - screenSmall)) *
                            (window.innerWidth - screenSmall) +
                            fontSizeSmall) /
                        defaultFontSize;
                }
            }
        }

        if (window.innerWidth >= lastArrElement.screenSize) {
            scaleFactor = lastArrElement.fontSize / defaultFontSize;
        }
        return scaleFactor;
    });
    deeper.scaleFactor = scaleFactor;
}
svgScaleFactor();

export const isAndroidPlatform = () => {
    const devicePlatform = getDevicePlatform();
    return devicePlatform && devicePlatform.toLowerCase() === "android";
};

export const isIOSPlatform = () => {
    const devicePlatform = getDevicePlatform();
    return devicePlatform && devicePlatform.toLowerCase() === "ios";
};

export const getDeviceVersionPlatform = () => window?.device?.version;

export const validateVersion = (version, otherVersion) => {
    const versionDelimiter = ".";
    const versionComponents = version.split(versionDelimiter).map(Number);
    const otherVersionComponents = otherVersion.split(versionDelimiter).map(Number);
    const zeroDiff = versionComponents.length - otherVersionComponents.length;

    if (zeroDiff === 0) {
        return version.localeCompare(otherVersion, undefined, { numeric: true });
    }
    const zeros = Array(Math.abs(zeroDiff)).fill(0);

    if (zeroDiff > 0) {
        otherVersionComponents.push(...zeros);
    } else {
        versionComponents.push(...zeros);
    }
    return versionComponents
        .join(versionDelimiter)
        .localeCompare(otherVersionComponents.join(versionDelimiter), undefined, { numeric: true });
};

export const isSupportVersion = (version, otherVersion) => {
    const result = validateVersion(version, otherVersion);
    return result && result >= 0;
};

export const sendPauseEvent = (url, data, accessToken) =>
    new Promise((resolve) => {
        window.cordova.plugins.PauseDevicePlugin.sendPauseCommand(
            url,
            data,
            accessToken,
            (response) => {
                resolve(response);
            },
            (error) => {
                resolve(error);
            },
        );
    });
