import Box from "pages/_components/Box";
import FeatureFlag from "pages/_components/FeatureFlag";
import FormattedDate from "pages/_components/FormattedDate";
import Text from "pages/_components/Text";
import Signatures from "pages/forms/_components/Signatures";
import { bool, shape } from "prop-types";
import React, { Component } from "react";

const rename = (transformation, obj) =>
    Object.keys(obj).reduce((acc, key) => {
        const newKey = transformation[key] ? transformation[key] : key;
        acc[newKey] = obj[key];
        return acc;
    }, {});

class FormSignatures extends Component {
    static propTypes = {
        transaction: shape({}).isRequired,
        parentTransaction: shape({}).isRequired,
        usesJointAccount: bool,
        isInTicket: bool,
        isDesktop: bool,
    };

    static defaultProps = {
        usesJointAccount: false,
        isInTicket: false,
        isDesktop: true,
    };

    hasChanges = () => {
        const { transaction } = this.props;
        return transaction.creationDateTime !== transaction.modificationDateTime;
    };

    hasDispatcher = () => {
        const {
            transaction: { dispatcher },
        } = this.props;
        return dispatcher;
    };

    authorizerSignature = (signature) => {
        const extendedData = JSON.parse(signature.extendedData || "{}");
        return {
            fullName: `${signature.userFirstName} ${signature.userLastName}`,
            date:
                extendedData && extendedData.jointCreationTime
                    ? extendedData.jointCreationTime
                    : signature.creationDateTime,
            dateAsString: extendedData?.creationDateTimeAsString,
            signatureLevel: signature.signatureLevel,
            isValid: signature.valid,
        };
    };

    dispatcherSignature = (dispatcher) => ({
        lastName: dispatcher.split(" ")[1],
        firstName: dispatcher.split(" ")[0],
    });

    renderUserReject = () => {
        const {
            transaction: { idTransactionStatus, data },
            isDesktop,
        } = this.props;

        if (idTransactionStatus !== "CANCELLED" || !data?.userRejectTransaction) {
            return null;
        }

        return (
            <Box display="block" alignY="baseline">
                <div className="data-wrapper data-confirm mb-2">
                    <span className="data-label">
                        {data.userRejectTransaction}&nbsp;&nbsp;&nbsp;
                        {!isDesktop && (
                            <FormattedDate date={data.dateRejectTransaction} anotherFormat="DD/MM/YYYY HH:mm" />
                        )}
                    </span>
                    {isDesktop && (
                        <span className="data-desc">
                            <FormattedDate date={data.dateRejectTransaction} anotherFormat="DD/MM/YYYY HH:mm" />
                        </span>
                    )}
                </div>
            </Box>
        );
    };

    render() {
        const { transaction, parentTransaction, usesJointAccount, isDesktop, isInTicket } = this.props;
        const { signatures } = transaction;

        const creatorSignature = rename(
            {
                userCreatorLastName: "lastName",
                userCreatorFirstName: "firstName",
                creationDateTime: "date",
            },
            usesJointAccount && parentTransaction ? parentTransaction : transaction,
        );
        creatorSignature.isValid = true;

        const modifyerSignature = rename(
            {
                userEditorLastName: "lastName",
                userEditorFirstName: "firstName",
                modificationDateTime: "date",
            },
            transaction,
        );
        modifyerSignature.isValid = true;

        return (
            <Box
                display="flex"
                column
                fullWidth
                {...(usesJointAccount && { border: "border-base-color", borderRadius: "md" })}>
                {!usesJointAccount && (
                    <Text
                        component="h4"
                        labelKey="forms.transaction.ticket.title"
                        className="data-title"
                        {...(!isInTicket && { className: "data-title" })}
                        {...(isInTicket && { size: "5", bold: true })}
                    />
                )}

                {this.hasChanges() && !usesJointAccount && (
                    <Signatures
                        title="forms.transaction.ticket.modifiedBy"
                        list={[modifyerSignature]}
                        usesJointAccount={usesJointAccount}
                    />
                )}
                <Signatures
                    title={
                        usesJointAccount
                            ? "forms.transaction.joint.ticket.authorizedBy"
                            : "forms.transaction.ticket.authorizedBy"
                    }
                    list={signatures.map(this.authorizerSignature)}
                    usesJointAccount={usesJointAccount}
                    isDesktop={isDesktop}
                />

                {this.renderUserReject()}

                <FeatureFlag id="feature.signatureSchema.dispatchControl">
                    {this.hasDispatcher() && (
                        <Signatures
                            title="forms.transaction.ticket.dispatchedBy"
                            list={[creatorSignature]}
                            usesJointAccount={usesJointAccount}
                            isDesktop={isDesktop}
                        />
                    )}
                </FeatureFlag>
            </Box>
        );
    }
}

export default FormSignatures;
