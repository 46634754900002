import React, { useState } from "react";
import { connect } from "react-redux";
import Box from "pages/_components/Box/Box";
import Button from "pages/_components/Button/Button";
import Table from "rc-table";
import PaginatorButtonsBar from "pages/_components/PaginatorButtonsBar";
import NoResults from "pages/_components/NoResultsMessage";
import { resizableRoute } from "pages/_components/Resizable";
import { string, bool, func, number, shape } from "prop-types";
import { actions as documentsActions, selectors as documentsSelectors } from "reducers/documents";
import { selectors as accountsSelectors } from "reducers/accounts";
import Text from "pages/_components/Text/Text";
import * as i18n from "util/i18n";
import Image from "pages/_components/Image";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import Loader from "pages/_components/Loader";
import Row from "pages/_components/Row";
import { Col } from "react-bootstrap";
import classNames from "classnames";
import Tooltip from "pages/_components/Tooltip";

const TableDocuments = ({
    typeOperation,
    dispatch,
    isDesktop,
    isFetching,
    documents,
    totalRecords,
    totalPages,
    pageNumber,
    filters,
    afterSearch,
    rowsPerPage,
    downloadState,
    showTooltip,
    setShowTooltip,
}) => {
    const [downloading, setDownloading] = useState(false);
    const paginate = (page) => {
        dispatch(documentsActions.loadMoreDocumentsRequest(filters, page));
    };

    const goToFirstPage = () => {
        paginate(1);
    };

    const goToPreviousPage = () => {
        paginate(pageNumber - 1);
    };

    const goToNextPage = () => {
        paginate(pageNumber + 1);
    };

    const goToLastPage = () => {
        // if (totalRecords > rowsPerPage) {
        //     paginate(Math.ceil(totalRecords / rowsPerPage));
        // }
        paginate(totalPages);
    };

    const renderEmptyList = () => (
        <Box className="m-7">
            <Image src="images/empty.svg" />
            <Box className="text-center mt-6">
                <Text labelKey="checks.none.text" />
            </Box>
        </Box>
    );

    const onClickDonwload = (id, swiftCode, mti) => {
        const data = {
            swiftCode,
            mti,
            typeOperation,
            idAccount: filters?.idAccount || "",
        };
        setDownloading(true);
        dispatch(
            documentsActions.downloadRequest(id, data, () => {
                setDownloading(false);
            }),
        );
    };

    const generateTableColumns = () => {
        const columnArray = [];

        if (!isDesktop) {
            columnArray.push({
                key: "dataRow",
                dataIndex: "dataRow",
                title: "",
                width: 100,
            });
            return columnArray;
        }

        if (typeOperation === "N") {
            columnArray.push({
                key: "beneficiaryInstitution",
                dataIndex: "beneficiaryInstitution",
                title: i18n.get("documents.swift.beneficiaryInstitution.label"),
                width: 90,
            });
        } else {
            columnArray.push({
                key: "operation",
                dataIndex: "operation",
                title: i18n.get("documents.swift.operation.label"),
                width: 90,
            });
        }
        columnArray.push({
            key: "paymentDate",
            dataIndex: "paymentDate",
            title: i18n.get("documents.swift.paymentDate.label"),
            width: 90,
        });
        columnArray.push({
            key: "beneficiary",
            dataIndex: "beneficiary",
            title: i18n.get("documents.swift.beneficiary.label"),
            width: 150,
        });
        if (typeOperation === "N") {
            columnArray.push(
                {
                    key: "channel",
                    dataIndex: "channel",
                    align: "center",
                    title: i18n.get("documents.swift.channel.label"),
                    width: 100,
                },
                {
                    key: "state",
                    dataIndex: "state",
                    title: i18n.get("documents.swift.state.label"),
                    width: 70,
                },
                {
                    key: "amount",
                    align: "center",
                    dataIndex: "amount",
                    title: i18n.get("documents.swift.amount.label"),
                    width: 90,
                },
            );
        } else {
            columnArray.push(
                {
                    key: "transaction",
                    dataIndex: "transaction",
                    title: i18n.get("documents.swift.transaction.label"),
                    width: 120,
                },
                {
                    key: "mt",
                    dataIndex: "mt",
                    align: "center",
                    title: (
                        <>
                            {i18n.get("documents.swift.mt.label")}
                            <Tooltip
                                image="images/information.svg"
                                text="documents.swift.tooltip.tm"
                                classNameTooltip="default-tooltip"
                                position="top-centered"
                                className="swift-widget-tooltip swift-tm-tooltip"
                                onHover={isDesktop}
                            />
                        </>
                    ),
                    width: 80,
                },
            );
        }
        columnArray.push({
            key: "action",
            dataIndex: "action",
            align: "right",
            title: "",
            width: 75,
        });

        return columnArray;
    };

    const renderData = (label, value) => (
        <Box className="mb-3">
            <Box>
                <Text color="text-disabled-color" labelKey={label} />
            </Box>
            {value}
        </Box>
    );
    const docsData = () =>
        documents[pageNumber]?.map((document) => {
            const {
                idUniqueMovement,
                swiftCode,
                paymentDate,
                beneficiary,
                beneficiaryInstitution,
                transaction,
                mti,
                amount,
                state,
                channel,
            } = document;
            let data = { key: idUniqueMovement };

            if (!isDesktop) {
                data = {
                    dataRow: (
                        <Row>
                            <Col xs={6}>
                                {typeOperation === "N" ? (
                                    <>
                                        {renderData(
                                            "documents.swift.beneficiaryInstitution.label",
                                            beneficiaryInstitution,
                                        )}
                                    </>
                                ) : (
                                    <>{renderData("documents.swift.operation.label", idUniqueMovement)}</>
                                )}
                                {renderData("documents.swift.beneficiary.label", beneficiary)}
                                {typeOperation === "N" ? (
                                    <>
                                        {renderData("documents.swift.state.label", state)}
                                        {renderData(
                                            "documents.swift.amount.label",
                                            <FormattedAmount quantity={amount} currency="USD" />,
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Box className="mb-3">
                                            <Box>
                                                <Text color="text-disabled-color" labelKey="documents.swift.mt.label" />
                                                <Tooltip
                                                    buttonId={`${idUniqueMovement}`}
                                                    image="images/information.svg"
                                                    text="documents.swift.tooltip.tm"
                                                    classNameTooltip="default-tooltip"
                                                    position="top-left"
                                                    className="swift-widget-tooltip swift-tm-tooltip"
                                                    showTooltip={showTooltip === `${idUniqueMovement}`}
                                                    onHover={isDesktop}
                                                    handleClick={() => {
                                                        setShowTooltip(`${idUniqueMovement}`);
                                                    }}
                                                />
                                            </Box>
                                            {mti}
                                        </Box>
                                    </>
                                )}
                            </Col>
                            <Col xs={6} className="text-right">
                                {renderData(
                                    "documents.swift.paymentDate.label",
                                    <FormattedDate date={paymentDate} anotherFormat="DD/MM/YYYY" />,
                                )}
                                {typeOperation === "N" ? (
                                    <>
                                        {renderData(
                                            "documents.swift.channel.label",
                                            <Text
                                                labelKey={`documents.swift.channel.${channel}`}
                                                defaultValue={channel}
                                            />,
                                        )}
                                    </>
                                ) : (
                                    <>{renderData("documents.swift.transaction.label", transaction)}</>
                                )}
                                <Button
                                    small
                                    imageRight
                                    bsStyle="outline"
                                    className="outline-download text-regular"
                                    image="images/download.svg"
                                    label="global.download"
                                    loading={downloadState[idUniqueMovement]}
                                    onClick={() => {
                                        onClickDonwload(idUniqueMovement, swiftCode, mti);
                                        setShowTooltip("");
                                    }}
                                />
                            </Col>
                        </Row>
                    ),
                };

                return data;
            }

            if (typeOperation === "N") {
                data = {
                    ...data,
                    beneficiaryInstitution,
                    channel: <Text labelKey={`documents.swift.channel.${channel}`} defaultValue={channel} />,
                    amount: <FormattedAmount quantity={amount} currency="USD" />,
                    state,
                };
            } else {
                data = { ...data, operation: idUniqueMovement, transaction };
            }
            data = {
                ...data,
                paymentDate: (
                    <>
                        <FormattedDate date={paymentDate} anotherFormat="DD/MM/YYYY" />
                    </>
                ),
                beneficiary,
                mt: mti,
                action: (
                    <>
                        <Button
                            imageRight
                            small
                            bsStyle="outline"
                            className="outline-download text-regular"
                            image="images/download.svg"
                            label="global.download"
                            loading={downloadState[idUniqueMovement]}
                            onClick={() => onClickDonwload(idUniqueMovement, swiftCode, mti)}
                        />
                    </>
                ),
            };

            return data;
        });

    return (
        <>
            {isFetching ? (
                <Loader bgTransparent />
            ) : (
                <>
                    {totalRecords > 0 && (
                        <Box
                            className={classNames({
                                "pointer-events-none": downloading,
                            })}>
                            <Table
                                className={classNames("table-default overflowNone", {
                                    "table-td-border-radius": !isDesktop,
                                })}
                                columns={generateTableColumns()}
                                data={docsData()}
                                rowKey={(record) => record.key}
                                emptyText={i18n.get("checks.list.noRecords")}
                            />

                            <Box className="pagination-component mt-5">
                                <PaginatorButtonsBar
                                    page={totalRecords === 0 ? 0 : pageNumber}
                                    totalpages={Math.ceil(totalRecords / rowsPerPage)}
                                    goToFirstPage={goToFirstPage}
                                    goToPreviousPage={goToPreviousPage}
                                    goToNextPage={() => goToNextPage()}
                                    goToLastPage={goToLastPage}
                                />
                            </Box>
                        </Box>
                    )}
                </>
            )}
            {totalRecords === 0 &&
                (!afterSearch ? (
                    renderEmptyList()
                ) : (
                    <Box className="mt-10">
                        {!isFetching && <NoResults description="documents.swift.search.error" />}
                    </Box>
                ))}
        </>
    );
};

TableDocuments.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isFetching: bool.isRequired,
    typeOperation: string.isRequired,
    documents: shape({}).isRequired,
    downloadState: shape({}).isRequired,
    filters: shape({
        idAccount: string,
    }).isRequired,
    totalRecords: number.isRequired,
    totalPages: number.isRequired,
    pageNumber: number.isRequired,
    rowsPerPage: number.isRequired,
    afterSearch: bool.isRequired,
    showTooltip: string,
    setShowTooltip: func,
};

TableDocuments.defaultProps = {
    showTooltip: "",
    setShowTooltip: () => {},
};

const mapStateToProps = (state) => ({
    isFetching: documentsSelectors.getFetching(state),
    documents: documentsSelectors.getDocuments(state),
    accounts: accountsSelectors.getAccounts(state),
    totalPages: documentsSelectors.getTotalPages(state),
    totalRecords: documentsSelectors.getTotalRecords(state),
    pageNumber: documentsSelectors.getPageNumber(state),
    downloadState: documentsSelectors.downloadState(state),
});

export default connect(mapStateToProps)(resizableRoute(TableDocuments));
