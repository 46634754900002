import classNames from "classnames";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import withFocus from "pages/_components/withFocus";
import Select from "pages/forms/_components/_fields/Select";
import { arrayOf, bool, func, number, oneOfType, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import Text from "pages/_components/Text";

import * as i18n from "util/i18n";

class DocumentField extends Component {
    componentDidUpdate(prevProps) {
        const { form, field, defaultDocumentType } = this.props;
        if (!prevProps.defaultDocumentType && defaultDocumentType) {
            form.setFieldValue(field.name, {
                ...field.value,
                type: defaultDocumentType,
            });
        }
    }

    handleDocumentChange = (event) => {
        const { field, form, handleDocumentChange, maxLength } = this.props;
        if (event.target.value.length <= maxLength) {
            if (handleDocumentChange) {
                const result = handleDocumentChange(event);
                if (result === false) {
                    return;
                }
            }

            form.setFieldValue(field.name, {
                ...field.value,
                document: event.target.value,
            });
        }
    };

    handleTypeChange = (selectedOption) => {
        const { field, form } = this.props;

        form.setFieldValue(field.name, {
            document: "",
            type: selectedOption ? selectedOption.value : null,
        });
    };

    getLabelForValue = (val, options) => {
        if (val && val !== "") {
            return options.find((opt) => opt.value === val)?.label || "  ";
            // return options.map( opt =>  {
            //     if (opt.value === val) {
            //         return opt.label;
            //     }
            // });
        }
        return "  ";
    };

    render() {
        const {
            clearable,
            data: { documentTypes },
            defaultDocumentType,
            disableDocumentTypeSelect,
            // disableSelect,
            field,
            form: { touched, errors },
            hideDocumentCountryLabel,
            hideDocumentPlaceholder,
            idForm,
            inputRef,
            // searchable,
            mode,
            noMarginBottom,
            defaultCountry,
            labelWithSemibold,
        } = this.props;

        const hasError = {
            document:
                touched[field.name] && errors[field.name] ? touched[field.name] && errors[field.name].document : false,
            documentTypes:
                touched[field.name] && errors[field.name] ? touched[field.name].type && errors[field.name].type : false,
        };

        const typesOptions = documentTypes
            ? documentTypes
                  .filter((documentType) => documentType.id_country_code === defaultCountry)
                  .map(({ id_document_type }) => ({
                      value: id_document_type,
                      label: id_document_type === "CED" ? "CI" : "PAS",
                  }))
            : [];

        return (
            <Fragment>
                <div
                    className={classNames("form-group", "form-group--composite", {
                        "has-error": hasError.documentType || hasError.document,
                        "form-group-margin-bottom": !noMarginBottom,
                    })}>
                    {!hideDocumentCountryLabel && !labelWithSemibold && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}
                    { labelWithSemibold && <FieldLabel labelText={i18n.get(`${idForm}.${field.name}.label`)} /> }
                    {mode === "edit" ? (
                        <>
                            <div className="input-group select-doc-type">
                                <Select
                                    placeholder=""
                                    noResultsText=""
                                    clearable={clearable}
                                    disabled={disableDocumentTypeSelect}
                                    onChange={this.handleTypeChange}
                                    options={typesOptions}
                                    searchable={false}
                                    value={field.value.type || defaultDocumentType}
                                />
                                <input
                                    className="form-control"
                                    placeholder={
                                        hideDocumentPlaceholder ? "" : i18n.get(`${idForm}.${field.name}.placeholder`)
                                    }
                                    ref={inputRef}
                                    {...field}
                                    onChange={this.handleDocumentChange}
                                    value={field.value.document}
                                />
                            </div>
                            {hasError.documentTypes && errors[field.name].type !== errors[field.name].document && (
                                <FieldError error={errors[field.name].type} />
                            )}
                            {hasError.document && <FieldError error={errors[field.name].document} />}
                        </>
                    ) : (
                        <div>
                            {`${this.getLabelForValue(field.value.type, typesOptions)} ${
                                field.value.document ? field.value.document : ""
                            }`.replaceAll(",", "")}{" "}
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
}

DocumentField.propTypes = {
    clearable: bool,
    disableDocumentTypeSelect: bool,
    disableSelect: bool,
    documentTypes: arrayOf(shape({})),
    hideDocumentCountryLabel: bool,
    hideDocumentPlaceholder: bool,
    searchable: bool,
    mode: string,
    form: shape({}).isRequired,
    field: shape({}).isRequired,
    defaultDocumentType: string,
    defaultCountry: string,
    data: shape({}),
    handleDocumentChange: func,
    idForm: string,
    inputRef: oneOfType([func, shape({})]),
    noMarginBottom: bool,
    maxLength: oneOfType([number, string]),
    labelWithSemibold: bool,
};

DocumentField.defaultProps = {
    clearable: true,
    disableSelect: false,
    disableDocumentTypeSelect: false,
    hideDocumentCountryLabel: false,
    hideDocumentPlaceholder: false,
    searchable: true,
    documentTypes: [],
    mode: "edit",
    defaultDocumentType: null,
    defaultCountry: null,
    data: null,
    handleDocumentChange: null,
    idForm: null,
    inputRef: null,
    noMarginBottom: false,
    maxLength: 200,
    labelWithSemibold: false,
};

export default withFocus(DocumentField);
