import classNames from "classnames";
import Text from "pages/_components/Text";
import Box from "pages/_components/Box";
import { bool, func, string } from "prop-types";
import { Modal } from "react-bootstrap";
import { useRevelockPositionProvider } from "providers/RevelockPositionProvider";
import React, { useEffect } from "react";
import { selectors as revelockSelectors } from "reducers/revelock/revelock.reducer";
import { connect } from "react-redux";

const DEFAULT_REVELOCK_ID = "/sideBarModal";
const SideBarModal = ({
    children,
    onClose,
    show,
    title,
    modalId,
    currentRevelockPosition,
    scrollable,
    dialogClassName,
    modalContainerClassName,
    modalBodyClassName,
    modalHeaderClassName,
    closeButton,
}) => {
    const { setLocationCustomPath } = useRevelockPositionProvider();

    const setLocationRevelock = (basePath, prefij) => {
        if (!setLocationCustomPath || !show) {
            return;
        }

        setLocationCustomPath(basePath, prefij);
    };

    useEffect(() => {
        setLocationRevelock(currentRevelockPosition || window.location.pathname, modalId || DEFAULT_REVELOCK_ID);
        return () => {
            setLocationRevelock(currentRevelockPosition || window.location.pathname);
        };
    }, [show]);

    return (
        <Modal
            aria-labelledby="modalTitleID"
            aria-modal="true"
            dialogClassName={dialogClassName}
            onHide={() => {
                onClose();
            }}
            show={show}
            className={classNames("drawer")}>
            <div className={classNames("modal-container", modalContainerClassName)}>
                <Modal.Header closeButton={closeButton} className={modalHeaderClassName}>
                    <Text id="modalTitleID" component="h4" labelKey={title} className="modal-title" />
                </Modal.Header>
                <Modal.Body className={modalBodyClassName}>
                    <Box fullWidth className={classNames("px-0 px-md-9", { "modal-scrollable-content": scrollable })}>
                        {children}
                    </Box>
                </Modal.Body>
            </div>
        </Modal>
    );
};

SideBarModal.propTypes = {
    children: func,
    onClose: func,
    show: bool,
    title: string,
    modalId: string,
    currentRevelockPosition: string,
    scrollable: bool,
    dialogClassName: string,
    modalContainerClassName: string,
    modalBodyClassName: string,
    modalHeaderClassName: string,
    closeButton: bool,
};

SideBarModal.defaultProps = {
    children: "",
    onClose: () => {},
    show: false,
    title: "",
    modalId: "",
    currentRevelockPosition: "",
    scrollable: false,
    dialogClassName: null,
    modalContainerClassName: null,
    modalBodyClassName: null,
    modalHeaderClassName: null,
    closeButton: false,
};

const mapStateToProps = (state) => ({
    currentRevelockPosition: revelockSelectors.getPosition(state),
});

export default connect(mapStateToProps)(SideBarModal);
