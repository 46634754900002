import * as API from "middleware/api";

export const listCheckbooks = (idAccount) => API.executeWithAccessToken("/checkbooks.list", { idAccount });

export const listChecks = (idAccount, idCheckbook) =>
    API.executeWithAccessToken("/checks.list", { idAccount, idCheckbook });

export const listAllChecks = (
    idAccount,
    typeFilter,
    startDate,
    endDate,
    amountStart,
    amountEnd,
    checkNumber,
    nextIndex,
) =>
    API.executeWithAccessToken("/checks.listAll", {
        idAccount,
        typeFilter,
        startDate,
        endDate,
        amountStart,
        amountEnd,
        checkNumber,
        nextIndex,
    });

export const getCheckUrl = (idAccount, checkNumber) =>
    API.executeWithAccessToken("/checks.image", {
        idAccount,
        checkNumber,
    });

export const downloadChecks = (
    { idAccount, typeFilter, startDate, endDate, amountStart, amountEnd, checkNumber, nextIndex },
    format,
) =>
    API.executeWithAccessToken("/checks.download", {
        idAccount,
        typeFilter,
        startDate,
        endDate,
        amountStart,
        amountEnd,
        checkNumber,
        nextIndex,
        format,
    });

export const imageCheck = (idAccount, checkNumber, dateCheck) =>
    API.executeWithAccessToken("/checks.image", {
        idAccount,
        checkNumber,
        dateCheck,
    });

export const listRemittanceChecks = (idAccount, status, startDate) =>
    API.executeWithAccessToken("/remittance.checks.list", {
        idAccount,
        status,
        startDate,
    });

export const downloadRemittanceChecks = ({ idAccount, status, startDate }, format) =>
    API.executeWithAccessToken("/remittance.checks.download", {
        idAccount,
        status,
        startDate,
        format,
    });

export const getEmissionCost = ({ accountType, accountNumber, checkTypeCode, checkTypeDesc }, format) =>
    API.executeWithAccessToken("/check.request.emission.cost", {
        accountType,
        accountNumber,
        checkTypeCode,
        checkTypeDesc,
    });

export const getOfficesList = ({ cityCode }, format) =>
    API.executeWithAccessToken("/check.request.listCheckOffice", {
        cityCode,
    });

export const validateCI = ({ docType, identification }, format) =>
    API.executeWithAccessToken("/check.request.validate.ci", {
        docType,
        identification,
    });
